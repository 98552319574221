import { Box, Checkbox, Flex, Select, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import AceEditor from 'react-ace'
// import mode-<language> , this imports the style and colors for the selected language.
import 'ace-builds/src-noconflict/mode-c_cpp'
import 'ace-builds/src-noconflict/mode-text'
import 'ace-builds/src-noconflict/theme-dracula'
import 'ace-builds/src-noconflict/theme-chrome'
import 'ace-builds/src-noconflict/ext-language_tools'
import 'ace-builds/src-noconflict/ext-beautify'
import { IDynamicFragment } from "../../../types";


type CodeFragmentProps = {
    body: IDynamicFragment;
    update_callback: Function;
};

const SimpleCodeFragment: React.FC<CodeFragmentProps> = ({body, update_callback}) => {

    const [code, setCode] = useState<IDynamicFragment>(body);

    useEffect(() => {
        // Add drop event to every component which classname is ace-editor
        const ace_editors = document.getElementsByClassName("ace_content");
        for (let i = 0; i < ace_editors.length; i++) {
            const element = ace_editors[i];
            element.addEventListener("drop", (event: any) => {
                event.preventDefault();
                event.stopPropagation();
                return false;
            });
        }
    }, []);

    const update_code_content = (new_code:string) => {
        if (!code.content)
            code.content = "";
        let new_body:IDynamicFragment = {
            ...code,
            content: new_code
        }
        setCode(new_body);
        update_callback(new_body);
    }

    const update_code_language = (new_language:string) => {
        let new_body:IDynamicFragment = {
            ...code,
            language: new_language
        }
        setCode(new_body);
        update_callback(new_body);
    }

    const update_code_compilable = (new_compilable:boolean) => {
        let new_body:IDynamicFragment = {
            ...code,
            compilable: new_compilable
        }
        setCode(new_body);
        update_callback(new_body);
    }

    const file_mode = () => {
        if (!code.language)
            return 'text';
        if (code.language == 'c')
            return 'c_cpp';
        else if (code.language == 'text')
            return 'text';
        else if (code.language == 'assembly')
            return 'assembly_x86';
        return 'c_cpp';
    }

    return <>
        <Text mt={2} fontSize={'sm'} fontWeight={'bold'} color={'purple.500'} mb={2} cursor={'default'}>Langage</Text>
        <Select value={code.language} onChange={(event) => { update_code_language(event.target.value) }}>
            <option value='c'>C</option>
            <option value='assembly'>Assembleur</option>
            <option value='text'>Texte</option>
        </Select>
        
        <Text mt={2} fontSize={'sm'} fontWeight={'bold'} color={'purple.500'} mb={2} cursor={'default'}>Code</Text>

        <Flex mt={0} border={'1pt solid gray'} borderBottom={0} bgColor={'#222226'} p={0}>
            <Text p={1} fontSize={'sm'} fontWeight={'bold'} color={'purple.300'} cursor={'default'}>Extrait de code</Text>
        </Flex>
        <Box shadow={'sm'} border={'1pt solid gray'}>
            <AceEditor height="250px" width="100%" mode={file_mode()} fontSize={14} 
        onChange={
                (new_content) => { update_code_content(new_content); }
            } 
            defaultValue={code.content || ''}
            value={code.content || ''}
            theme={(file_mode() == "text") ? "chrome" : "dracula"}
            />
        </Box>

        <Checkbox mt={3} ms={2}
            isChecked={code.compilable}
            onChange={(event) => { update_code_compilable(event.target.checked) }}
        >Doit être compilable</Checkbox>
        
    </>
};

export default SimpleCodeFragment;