import { Box, Divider, Flex, Text } from "@chakra-ui/react";
import TextFragment from "./TextFragment";
import { FragmentType, IStaticFragment, StaticFragmentType } from "../../../types";
import CodeFragment from "./CodeFragment";
import PageVisibility from "react-page-visibility";
import { useContext, useEffect, useRef, useState } from "react";
import VisibilityObserver from "../../../VisibilityObserver";
import { TabContext } from "../../../TabContext";
import { sendHeartbeat } from "../../../API/logs";
import HeartbeatController from "../HeartbeatController";
import BinaryFragment from "./BinaryFragment";

type FragmentProps = {
    type: StaticFragmentType;
    fragment_id: string;
    content?: any;
    withMargin?: boolean;
};

const StaticFragmentComponent: React.FC<FragmentProps> = ({type, fragment_id, content, withMargin=true}) => {
    let fragment_jsx:JSX.Element;
    switch (type) {
        case StaticFragmentType.Text:
            fragment_jsx = <TextFragment content={content} />;
            break;
        case StaticFragmentType.Code:
            fragment_jsx = <CodeFragment fragment_id={fragment_id} body={content} />;
            break;
        case StaticFragmentType.Binary:
            fragment_jsx = <BinaryFragment content={content} />;
            break;
        default:
            fragment_jsx = <TextFragment content={content} />;
            break;
    }

    return <HeartbeatController fragment_id={fragment_id} fragment_type={FragmentType.Static}>
        <Box mt={withMargin ? 10 : 0}>{fragment_jsx}</Box>
    </HeartbeatController>
};


export default StaticFragmentComponent;