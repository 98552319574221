import React, { useEffect, useState } from 'react';
import { AbsoluteCenter, AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, AvatarBadge, Box, Button, Card, CardBody, CardHeader, Center, Collapse, Container, Flex, FormControl, Heading, Image, Select, SimpleGrid, Skeleton, Spacer, Square, Tab, TabList, TabPanel, TabPanels, Table, TableContainer, Tabs, Tbody, Td, Text, Tfoot, Th, Thead, Tooltip, Tr, useDisclosure, useToast } from '@chakra-ui/react';
import { MainLayout } from '../MainLayout';
import { AddIcon, DeleteIcon, EditIcon, ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import { Link as ReactLink, useNavigate} from 'react-router-dom';
import { IErrorLog, IGamecode } from '../types';
import { deleteGamecode, getGamecodes, newGamecode, updateGamecode } from '../API/gamecode';
import RemindersEditor from './RemindersEditor';
import no_data from '../resources/no_data.svg'
import { deleteFixedServerLog, fixEveryServerLog, fixServerLog, getServerLogs } from '../API/logs';
import { useUser } from '../UserContext';
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// function addUser(isOpen:any, onOpen:any, onClose:any) {

//     return <Collapse in={isOpen} animateOpacity>
//         <Box
//         p={5}
//         pt={2}
//         color='#131313'
//         mt='3'
//         bg='white'
//         borderWidth='1px'
//         rounded='md'>
//         <Heading mt={3} mb={4} as={'h3'} size={'md'} color={'gray.600'}>Nouvel utilisateur</Heading>
//         </Box>
//     </Collapse>
// }

const Admin: React.FC = () => {
    const navigate = useNavigate();
    const [gamecodes, setGamecodes] = useState<IGamecode[]>([]);
    const [deletion, setDeletion] = useState<string>('');
    const { isOpen, onOpen, onClose } = useDisclosure()
    const cancelRef = React.useRef<null>(null);
    const toast = useToast()
    const [serverErrors, setServerErrors] = useState<IErrorLog[]>([]);
    const { user } = useUser();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (!user || user.rank != 'admin') {
            navigate('/');
        }
    }, []);

    // When the component mounts, fetch the gamecodes using the API
    useEffect(() => {
        getGamecodes().then(({ data: { gamecodes } }: IGamecode[] | any) => {
            setGamecodes(gamecodes);
            setLoading(false);
        });

        getServerLogs().then((res) => {
            setServerErrors(res.data.serverlogs);
        });
    }, []);

    const updateGamecodeVisibility = (gamecode_id: string, visible: boolean) => {
        const gamecode:IGamecode | undefined = gamecodes.find((gamecode: IGamecode) => gamecode._id === gamecode_id);
        if (!gamecode) return;

        updateGamecode({...gamecode, visible: visible}).then((res) => {
            // Update the gamecode in the state
            setGamecodes(gamecodes.map((gamecode: IGamecode) => {
                if (gamecode._id === gamecode_id) {
                    return {...gamecode, visible: visible};
                }
                return gamecode;
            }));
        });
    }

    const new_gamecode = () => {
        newGamecode().then((res) => {
            navigate(`/admin/edit/gamecode/${res.data.gamecode?._id}`);
        });
    }

    const delete_gamecode = () => {
        deleteGamecode(deletion).then((res) => {
            setGamecodes(gamecodes.filter((gamecode: IGamecode) => gamecode._id !== deletion));
        });
    }

    function ShowToast() {
        toast({
            title: 'Gamecode supprimé',
            description: "Le Gamecode a bien été supprimé.",
            status: 'success',
            duration: 3000,
            isClosable: true,
        })
    }

    const fix_error = (error_id: string, fixed:boolean) => {
        fixServerLog(error_id, fixed).then((res) => {
            setServerErrors(serverErrors.map((error) => {
                if (error._id === error_id) {
                    return {...error, fixed: fixed};
                }
                return error;
            }));
        }
        );
    }

    const fix_all_errors = () => {
        fixEveryServerLog().then((res) => {
            setServerErrors(serverErrors.map((error) => {
                return {...error, fixed: true};
            }));
        });
    }

    const delete_fixed_errors = () => {
        deleteFixedServerLog().then((res) => {
            setServerErrors(serverErrors.filter((error) => {
                return error.fixed == false;
            }));
        });
    }

    // const adduser_disclosure = useDisclosure();

    return (
        <>
        {user && user.rank == 'student' && <></>}
        {user && user.rank == 'admin' &&
        <MainLayout>
            <Container maxWidth={'4xl'} marginBlock={5}>
                <Heading mb={5} as={'h3'} size={'xl'} color={'gray.900'}>Panneau d'administration</Heading>

                <Tabs variant='soft-rounded' colorScheme='green' mb={5}>
                    <TabList>
                        <Tab isDisabled={loading}>Gamecodes</Tab>
                        <Tab isDisabled={loading}>Rappels</Tab>
                        <Tab isDisabled={loading}>Alertes serveur <Text hidden={serverErrors.filter(
                            // only when "fixed" is true
                            (error) => error.fixed == false
                        ).length == 0} color={"red"} fontWeight={'500'} ms={1} fontSize={'sm'}>({serverErrors && serverErrors.filter(
                            // only when "fixed" is true
                            (error) => error.fixed == false
                        ).length})</Text></Tab>
                        {/* <Tab>Classes</Tab> */}
                    </TabList>
                    <TabPanels>
                        <TabPanel>
                            {!loading && gamecodes.length == 0 && 
                            <>
                                <Center m={4}>
                                    <Image height={'90px'} src={no_data} alt='Aucun Gamecode' />
                                </Center>
                                <Center m={4}>
                                    <Text>Il n'y a aucun Gamecode pour le moment.</Text>
                                </Center>
                            </>}

                            {loading && <SimpleGrid spacing={5} templateColumns='repeat(auto-fill, minmax(250px, 1fr))'>
                                {[1, 2, 3].map((i) => (
                                    <Card key={i} mb={5}>
                                        <CardHeader m={0} pb={0} mt={5}>
                                            <Heading size='sm'><Skeleton width={"100%"} height={"20px"} /></Heading>
                                        </CardHeader>
                                        <CardBody pt={0}>
                                            <Skeleton width={"70%"} height={"15px"} />
                                            <Skeleton mt={2} width={"80%"} height={"15px"} />
                                            <Skeleton mt={2} mb={5} width={"60%"} height={"15px"} />
                                        </CardBody>
                                    </Card>
                                ))}
                            
                            </SimpleGrid>
                            }

                            <SimpleGrid spacing={5} templateColumns='repeat(auto-fill, minmax(250px, 1fr))'>
                                {!loading && gamecodes.map((gamecode: IGamecode) => (
                                    <Card key={gamecode._id} >
                                        <CardHeader m={0} pb={0}>
                                            <Heading size='sm'>{gamecode.title}</Heading>
                                        </CardHeader>

                                        <CardBody pt={0}>
                                            <Text pb={2} fontSize='10pt'><strong>Cours :</strong> {gamecode.class.toUpperCase()}</Text>

                                            <Text fontSize='10pt'><strong>Startline :</strong> Le {new Date(gamecode.startline).toLocaleDateString('fr-FR')} à {new Date(gamecode.startline).toLocaleTimeString('fr-FR', { hour: 'numeric', minute: 'numeric' })}</Text>

                                            <Text color={gamecode.visible ? "black":"red.500"} fontSize='10pt' pt={2} pb={2}><strong>Statut :</strong> {gamecode.visible ? 'Visible' : 'Invisible'}</Text>
                                        </CardBody>
                                        
                                        <Flex color='white'>
                                            <Tooltip label='Éditer'>
                                                <Button flex='1' rounded={0} roundedBottomLeft={5} colorScheme='blue' onClick={
                                                    () => { navigate('/admin/edit/gamecode/'+gamecode._id) }
                                                }><EditIcon /></Button>
                                            </Tooltip>
                                            <Tooltip label={gamecode.visible ? 'Rendre invisible' : 'Rendre visible'}>
                                                <Button onClick={(e) => {
                                                    updateGamecodeVisibility(gamecode._id, !gamecode.visible)
                                                    toast({
                                                        title: 'Gamecode mis à jour',
                                                        description: "Le Gamecode est désormais " + (gamecode.visible ? 'invisible' : 'visible') + " par les étudiants.",
                                                        status: 'info',
                                                        duration: 3000,
                                                        isClosable: true,
                                                    })
                                                }} flex='1' rounded={0} colorScheme='blackAlpha'>{gamecode.visible ? <ViewOffIcon /> : <ViewIcon />}</Button>
                                            </Tooltip>
                                            <Tooltip label='Supprimer'>
                                                <Button onClick={() => {setDeletion(gamecode._id); onOpen();}} flex='1' rounded={0} roundedBottomRight={5} colorScheme='red'><DeleteIcon /></Button>
                                            </Tooltip>
                                        </Flex>
                                    </Card>
                                ))}
                                
                                {!loading && 
                                    <Card bgColor={'green.100'} className='hvr-rotate' cursor={'pointer'} minHeight={180} onClick={() => {new_gamecode();}}>
                                        <AbsoluteCenter p='4' color='green.500' axis='both'>
                                            <AddIcon boxSize={10}/>
                                        </AbsoluteCenter>
                                    </Card>
                                }
                            </SimpleGrid>
                        </TabPanel>
                        <TabPanel>
                            <RemindersEditor />
                        </TabPanel>
                        <TabPanel>
                            <Heading mt={3} mb={4} as={'h3'} size={'lg'} color={'red.500'}>Journal d'alertes du serveur</Heading>
                            <Flex mb={2}>
                                <Spacer />
                                <Button onClick={() => {delete_fixed_errors()}} colorScheme='red' mr={2} size={'xs'}>Supprimer les alertes vues</Button>
                                <Button onClick={() => {fix_all_errors()}} colorScheme='green' mr={2} size={'xs'}>Marquer tout comme vu</Button>
                            </Flex>
                            <TableContainer>
                                <Table size='sm'>
                                    <Thead>
                                    <Tr>
                                        <Th>Date</Th>
                                        <Th>Type</Th>
                                        <Th>Message</Th>
                                        <Th>Vu</Th>
                                    </Tr>
                                    </Thead>
                                    <Tbody>
                                        {serverErrors.map((error) => {
                                            return <Tr key={error._id}>
                                                <Td>{new Date(error.timestamp).toLocaleDateString('fr-FR')} à {new Date(error.timestamp).toLocaleTimeString('fr-FR', { hour: 'numeric', minute: 'numeric' })}</Td>
                                                <Td>{error.level}</Td>
                                                <Td fontSize={'sm'} maxWidth={'700px'} whiteSpace={'pre-wrap'} fontFamily={'JetBrains Mono'}>
                                                    <Text>
                                                        {error.message}
                                                    </Text>
                                                </Td>
                                                <Td>
                                                    {!error.fixed &&
                                                        <Tooltip label='Marquer comme vu'>
                                                            <ViewIcon cursor={'pointer'} color={'red'} onClick={
                                                                () => { fix_error(error._id, true); }
                                                            } />
                                                        </Tooltip>
                                                    }
                                                    {error.fixed &&
                                                        <Tooltip label='Marquer comme non-vu'>
                                                            <ViewOffIcon cursor={'pointer'} color={'green'} onClick={
                                                                () => { fix_error(error._id, false); }
                                                            } />
                                                        </Tooltip>
                                                    }
                                                </Td>
                                            </Tr>
                                        })}
                                    </Tbody>
                                </Table>
                            </TableContainer>
                        </TabPanel>
                        {/* <TabPanel>
                            <Heading mt={3} mb={4} as={'h3'} size={'lg'} color={'gray.600'}>Gestion des classes</Heading>
                            <FormControl>
                                <Select>
                                    <option value='info0946'>INFO0946 - Introduction à la programmation</option>
                                    <option value='info0061'>INFO0061 - Organisation des ordinateurs</option>
                                </Select>
                            </FormControl>

                            <TableContainer mt={5}>
                                <Table size='sm'>
                                    <Thead>
                                    <Tr>
                                        <Th>Nom</Th>
                                        <Th>E-mail</Th>
                                        <Th>Rôle</Th>
                                        <Th></Th>
                                    </Tr>
                                    </Thead>
                                    <Tbody>
                                    <Tr>
                                        <Td>MALCEV Lev</Td>
                                        <Td>l.malcev@student.uliege.be</Td>
                                        <Td>Administrateur</Td>
                                        <Td isNumeric>
                                            <Tooltip label='Modifier'>
                                            <Button size={"sm"}><FontAwesomeIcon icon={faPenToSquare} /></Button>
                                            </Tooltip>
                                        </Td>
                                    </Tr>
                                    </Tbody>
                                    <Tfoot>
                                    <Tr>
                                        <Th colSpan={3} textAlign={"end"}>Total : 1</Th>
                                    </Tr>
                                    </Tfoot>
                                </Table>
                            </TableContainer>

                            <Flex>
                                <Spacer />
                                <Button onClick={adduser_disclosure.onToggle} colorScheme='green' mt={4}>Ajouter un utilisateur</Button>
                            </Flex>
                            {addUser(adduser_disclosure.isOpen, adduser_disclosure.onOpen, adduser_disclosure.onClose)}
                        </TabPanel> */}
                    </TabPanels>
                </Tabs>
            </Container>
            <AlertDialog
                isOpen={isOpen}
                leastDestructiveRef={cancelRef}
                onClose={() => {}}
                >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                    <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                        Supprimer un Gamecode
                    </AlertDialogHeader>

                    <AlertDialogBody>
                        Attention, le Gamecode ainsi que tous ses fragments seront définitivement supprimés. <br />Cependant, les rappels utilisés dans ce Gamecode ne seront pas supprimés.
                    </AlertDialogBody>

                    <AlertDialogFooter>
                        <Button ref={cancelRef} onClick={onClose}>
                        Annuler
                        </Button>
                        <Button colorScheme='red' onClick={() => {delete_gamecode(); onClose(); ShowToast()}} ml={3}>
                        Supprimer
                        </Button>
                    </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </MainLayout>
        }
        </>
    );
}

export default Admin;