import axios, { AxiosResponse } from "axios";
import API, { API_URL } from "./params";
import { ApiDataType, IGamecode } from "../types";

/**
 * GET request to the server to get all gamecodes.
 */
export const getGamecodes = async (): Promise<AxiosResponse<ApiDataType>> => {
	try {
		const gamecodes: AxiosResponse<ApiDataType> = await API.get(
			`${API_URL}/gamecodes`
		);
		return gamecodes;
	} catch (error) {
		throw error;
	}
};

export const getGamecode = async (
    id: string
): Promise<AxiosResponse<ApiDataType>> => {
    try {
        const gamecode: AxiosResponse<ApiDataType> = await API.get(
            `${API_URL}/gamecodes/${id}`
        );
        return gamecode;
    } catch (error) {
        throw error;
    }
}

export const getGamecodeBySlug = async (
    slug: string
): Promise<AxiosResponse<ApiDataType>> => {
    try {
        const gamecode: AxiosResponse<ApiDataType> = await API.get(
            `${API_URL}/gamecodes/slug/${slug}`
        );
        return gamecode;
    } catch (error) {
        throw error;
    }
}

export const newGamecode = async (): Promise<AxiosResponse<ApiDataType>> => {
    try {
        const gamecode: AxiosResponse<ApiDataType> = await API.put(
            `${API_URL}/gamecodes/new`
        );
        return gamecode;
    } catch (error) {
        throw error;
    }
}

export const updateGamecode = async (gamecode:IGamecode): Promise<AxiosResponse<ApiDataType>> => {
    try {
        const updatedGamecode: AxiosResponse<ApiDataType> = await API.put(
            `${API_URL}/gamecodes/update/${gamecode._id}`,
            gamecode
        );
        return updatedGamecode;
    } catch (error) {
        throw error;
    }
}

export const deleteGamecode = async (id: string): Promise<AxiosResponse<ApiDataType>> => {
    try {
        const deletedGamecode: AxiosResponse<ApiDataType> = await API.delete(
            `${API_URL}/gamecodes/delete/${id}`
        );
        return deletedGamecode;
    } catch (error) {
        throw error;
    }
}