import axios, { AxiosResponse } from "axios";
import API, { API_URL } from "./params";
import { ApiDataType, IGamecode } from "../types";

/**
 * GET request to the server to get all gamecodes.
 */
export const getClasses = async (): Promise<AxiosResponse<ApiDataType>> => {
	try {
		const gamecodes: AxiosResponse<ApiDataType> = await API.get(
			`${API_URL}/classes`
		);
		return gamecodes;
	} catch (error) {
		throw error;
	}
};