import { Box, Center, Checkbox, Flex, FormControl, FormHelperText, FormLabel, Input, InputGroup, InputRightAddon, PinInput, PinInputField, Select, Text, VStack } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import AceEditor from 'react-ace'
// import mode-<language> , this imports the style and colors for the selected language.
import 'ace-builds/src-noconflict/mode-c_cpp'
import 'ace-builds/src-noconflict/mode-text'
import 'ace-builds/src-noconflict/theme-dracula'
import 'ace-builds/src-noconflict/theme-chrome'
import 'ace-builds/src-noconflict/ext-language_tools'
import 'ace-builds/src-noconflict/ext-beautify'
import { IDynamicFragment } from "../../../types";
import TextEditor from "../TextEditor";


type DecBinFragmentProps = {
    body: IDynamicFragment;
    update_callback: Function;
};

const DecBinFragment: React.FC<DecBinFragmentProps> = ({body, update_callback}) => {

    const [fragment, setFragment] = useState<IDynamicFragment>(body);
    const [nbBits, setNbBits] = useState<number>(body.decbin?.bits || 8);
    const [format, setFormat] = useState<string>(body.decbin?.format || 'unsigned');
    const [solution, setSolution] = useState<string>(body.decbin?.solution || '');

    useEffect(() => {
    }, []);

    const update_content = (new_content:string) => {
        if (!fragment.content)
            fragment.content = "";
        let new_body:IDynamicFragment = {
            ...fragment,
            content: new_content
        }
        setFragment(new_body);
        update_callback(new_body);
    }

    const update_hint = (hint:boolean) => {
        let new_body:IDynamicFragment = fragment;
        new_body.decbin!.hint = hint;
        setFragment(new_body);
        update_callback(new_body);
    }

    const update_format = (format:string) => {
        let new_body:IDynamicFragment = fragment;
        new_body.decbin!.format = format;

        if (format == "ieee") {
            setNbBits(32);
            new_body.decbin!.bits = 32;
            if (solution.length > 32) {
                new_body.decbin!.solution = solution.slice(0, 32);
                setSolution(solution.slice(0, 32));
            }
        }
        setFragment(new_body);
        update_callback(new_body);
        setFormat(format);
    }

    const update_bits = (bits:number) => {
        if (isNaN(bits))
            return;
        if (bits < 1)
            bits = 1;
        if (bits > 64)
            bits = 64;
        let new_body:IDynamicFragment = fragment;
        new_body.decbin!.bits = bits;
        
        // If the solution is too long, we truncate it
        if (solution.length > bits) {
            new_body.decbin!.solution = new_body.decbin!.solution.slice(0, bits);
            setSolution(new_body.decbin!.solution.slice(0, bits));
        }
        setFragment(new_body);
        update_callback(new_body);
        setNbBits(bits);
    }

    const update_solution = (solution:string) => {
        if (isNaN(parseInt(solution)))
            return;
        if (solution.length > nbBits)
            solution = solution.slice(0, nbBits);
        let new_body:IDynamicFragment = fragment;
        new_body.decbin!.solution = solution;
        setFragment(new_body);
        update_callback(new_body);
        setSolution(solution);
    }

    return <>
        
        <Text mt={2} fontSize={'sm'} fontWeight={'bold'} color={'purple.500'} mb={2} cursor={'default'}>Énoncé</Text>

        <TextEditor content={fragment.content} update_callback={update_content} />

        
        <Text mt={3} fontSize={'sm'} fontWeight={'bold'} color={'purple.500'} mb={2} cursor={'default'}>Format de la réponse</Text>

        <Select value={format} onChange={(event) => update_format(event.target.value)}>
            <option value='unsigned'>Binaire non signé</option>
            <option value='signed'>Binaire signé</option>
            <option value='c1'>Complément à 1</option>
            <option value='c2'>Complément à 2</option>
            <option value='ieee'>IEEE 754 simple précision</option>
        </Select>
        
        <FormControl>
            <FormLabel mt={3} fontSize={'sm'} fontWeight={'bold'} color={'purple.500'} mb={2} cursor={'default'}>Nombre de bits</FormLabel>
            <InputGroup ms={2} size='sm' width={"150px"}>
                <Input isDisabled={format == "ieee"} value={(format == "ieee") ? 32 : nbBits} type='number' onChange={(event) => update_bits(parseInt(event.target.value))} />
                <InputRightAddon>
                 bits
                </InputRightAddon>
            </InputGroup>
        </FormControl>
        
        <FormControl>
            <FormLabel mt={3} fontSize={'sm'} fontWeight={'bold'} color={'purple.500'} mb={2} cursor={'default'}>Options</FormLabel>
            <Checkbox
                ms={2}
                isChecked={fragment.large_area}
                onChange={(event) => { update_hint(event.target.checked) }}
            >Avec indice de couleur</Checkbox>
        </FormControl>

        <FormControl>
            <FormLabel mt={3} fontSize={'sm'} fontWeight={'bold'} color={'purple.500'} mb={2} cursor={'default'}>Réponse attendue</FormLabel>

            {nbBits > 16 &&
                <Input size='md' letterSpacing={"0.5rem"} fontFamily={"JetBrains Mono"} type="number" textAlign={"center"} maxLength={nbBits} value={solution} onChange={(event) => update_solution(event.target.value)} />
            }
            {nbBits <= 16 &&
                <Center>
                    <PinInput size='sm' type="number" value={solution} onChange={(value) => update_solution(value)}>
                        {[...Array(nbBits)].map((_, i) => (
                            <PinInputField ms={1} key={i} />
                        ))}
                    </PinInput>
                </Center>
            }

        </FormControl>
        
    </>
};

export default DecBinFragment;