import { AbsoluteCenter, Box, Button, Center, Flex, Grid, GridItem, Heading, Spacer, Tab, TabList, TabPanel, TabPanels, Tabs, Text, Tooltip } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { GCModule, IReminder, IResolutionStep } from "../types";
import { getReminder } from "../API/reminder";
import { ArrowBackIcon, StarIcon } from "@chakra-ui/icons";
import { Link, useNavigate, useParams } from "react-router-dom";
import Reminder from "./Reminder";
import { getResolutionStep } from "../API/resolution_step";
import ResolutionStep from "./ResolutionStep";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faHandPointRight } from "@fortawesome/free-solid-svg-icons";
import { THEME_COLORS } from "../config";
import play_button from '../resources/play.svg';
import ok_button from '../resources/ok_btn_purple.svg';
import lady_drawing from '../resources/lady_drawing.svg';
import { getRightAnswersPerRs, getRsProgress } from "../API/gcinstance";
import JSConfetti from "js-confetti";

type ReminderProps = {
    rs_ids:string[];
};
const ResolutionSteps: React.FC<ReminderProps> = ({rs_ids}) => {
    const [resolutionSteps, setResolutionSteps] = useState<IResolutionStep[]>([]);
    const gc_params = useParams();
    const slug = gc_params.slug as string;
    const rs_index:string = gc_params.index ?? '-1';
    const [progress, setProgress] = useState<number[]>([]);
    const [finished, setFinished] = useState<boolean>(false);
    const jsConfetti = new JSConfetti();
    const [rightAnswersPerRs, setRightAnswersPerRs] = useState<number[]>([]);
    const [submittedAnswersPerRs, setSubmittedAnswersPerRs] = useState<number[]>([]);
    const [questionsPerRs, setQuestionsPerRs] = useState<number[]>([]);

    // IMAGES PRELOAD
    useEffect(() => {
        new Image().src = ok_button;
        new Image().src = play_button;
        new Image().src = lady_drawing;
    }, []);
    
    let navigate = useNavigate();
    useEffect(() => {
        if (rs_index == '-1')
            return;
        const index:number = parseInt(rs_index);
        // if (index-1 < 0 || index-1 > fragments.length) {
        //     navigate(`/gamecode/${gc_slug}/${GCModule.REMINDERS}`);
        // }
        // else {
        //     setComponentIndex(index-1);
        // }
    }, [rs_index]);

    useEffect(() => {
        // Fetch the number of right answers for each RS
        getRightAnswersPerRs(slug).then(({ data: { right_answers_per_rs, questions_per_rs, submitted_answers_per_rs } }: any) => {
            setRightAnswersPerRs(right_answers_per_rs);
            setQuestionsPerRs(questions_per_rs);
            setSubmittedAnswersPerRs(submitted_answers_per_rs);
        });
    }, [rs_ids]);

    useEffect(() => {
        const fetchResolutionSteps = async () => {
          const promises = rs_ids.map((id) => getResolutionStep(id));
          const responses = await Promise.all(promises);
          const fetchedResolutionSteps = responses.map((res) => res.data.resolution_step).filter(Boolean);
          setResolutionSteps(fetchedResolutionSteps.map((reminder: any) => {
                return reminder as IResolutionStep;
            }));
        };
      
        fetchResolutionSteps();

        update_progress();
        // add listener on document to the event RS_PROGRESS_UPDATED
        document.addEventListener('RS_PROGRESS_UPDATED', update_progress);

        // Remove the listener when the component is unmounted
        return () => {
            document.removeEventListener('RS_PROGRESS_UPDATED', update_progress);
        }
    }, [rs_ids]);

    const update_progress = () => {
        // Fetch progress of each RS
        getRsProgress(slug).then(({ data: { rs_progress } }: any) => {
            setProgress(rs_progress);
            const progression = rs_progress.reduce((a:number, b:number) => a + b, 0) / rs_progress.length;
            if (progression == 1)
                setFinished(true);
        });
        getRightAnswersPerRs(slug).then(({ data: { right_answers_per_rs, questions_per_rs, submitted_answers_per_rs } }: any) => {
            setRightAnswersPerRs(right_answers_per_rs);
            setQuestionsPerRs(questions_per_rs);
            setSubmittedAnswersPerRs(submitted_answers_per_rs);
        });
    }

    return <>
        {rs_index == '-1' &&
        <>
        <Heading mb={8} fontSize={'4xl'} color={THEME_COLORS.RESOLUTION_STEPS}>Étapes de résolution</Heading>

        <Text align={'justify'}>
            <strong>À toi de jouer !</strong><br/> Les étapes de résolution sont les différentes étapes à suivre pour résoudre le problème.
        </Text>

        <Box mt={5}>
            {resolutionSteps.map((rs, index) => {
                // if it's not the last, add a separator
                if (index != resolutionSteps.length-1) {
                    return <Box key={'box'+index}><Link to={`/gamecode/${slug}/resolution/${index+1}`} key={index}>
                    <Flex mb={1}>
                        <Tooltip p={2} me={4} hasArrow label={progress[index] == 1 ? 'Tu as tout lu' : (progress[index] == 0 ? 'Commencer' : 'En cours...')} placement={'left'} bg={progress[index] == 1 ? THEME_COLORS.RESOLUTION_STEPS : 'gray.600'} color={'white'} fontSize={'md'}>
                            <Box width={'50px'} height={'50px'}>
                                <img className="playbutton hvr-grow" src={progress[index] == 1 ? ok_button : play_button} style={{width:'50px'}} />
                            </Box>
                        </Tooltip>

                        {progress[index] > 0 && progress[index] < 1 &&
                            <Box ms={10} pt={1}>
                                <Box height={'50%'}>
                                    <Text fontWeight={'bold'} fontSize={'xl'}>{rs.title}</Text>
                                </Box>
                                <Text height={'50%'} fontSize={'sm'}>En cours... {submittedAnswersPerRs[index]} {submittedAnswersPerRs[index] == 1 ? 'réponse' : 'réponses'}  sur {questionsPerRs[index]}</Text>
                            </Box>
                        }
                        {progress[index] == 0 &&
                            <Box ms={10} pt={1}>
                                <Box height={'50%'}>
                                    <Text fontWeight={'bold'} fontSize={'xl'}>{rs.title}</Text>
                                </Box>
                            </Box>
                        }
                        {progress[index] == 1 && submittedAnswersPerRs[index] != questionsPerRs[index] &&
                            <Box ms={10} pt={1}>
                                <Box height={'50%'}>
                                    <Text fontWeight={'bold'} fontSize={'xl'}>{rs.title}</Text>
                                </Box>
                                <Text height={'50%'} fontSize={'sm'}>{submittedAnswersPerRs[index]} {submittedAnswersPerRs[index] == 1 ? 'réponse' : 'réponses'} sur {questionsPerRs[index]}</Text>
                            </Box>
                        }
                        {progress[index] == 1 && submittedAnswersPerRs[index] == questionsPerRs[index] &&
                            <Box ms={10} pt={1}>
                                <Box height={'50%'}>
                                    <Text fontWeight={'bold'} fontSize={'xl'}>{rs.title}</Text>
                                </Box>
                                <Text height={'50%'} fontSize={'sm'}>Tu as répondu à tout !</Text>
                            </Box>
                        }
                    </Flex>
                </Link>
                <Flex key={'separator_'+index}>
                    <Box width={'50px'} height={'50px'}>
                        {/* vertical align */}
                        <Center height={'100%'}>
                            <Box rounded={'xl'} height={'35px'} width={'5px'} bgColor={'gray.300'}></Box>
                        </Center>
                    </Box>
                </Flex>
                </Box>
                }
                else {
                    return <Link to={`/gamecode/${slug}/resolution/${index+1}`} key={'nosep_'+index}>
                    <Flex key={'flex_'+index} mb={1}>
                        <Tooltip p={2} me={4} hasArrow label={progress[index] == 1 ? 'Tu as tout lu' : (progress[index] == 0 ? 'Commencer' : 'En cours...')} placement={'left'} bg={progress[index] == 1 ? THEME_COLORS.RESOLUTION_STEPS : 'gray.600'} color={'white'} fontSize={'md'}>
                            <Box width={'50px'} height={'50px'}>
                                <img className="playbutton hvr-grow" src={progress[index] == 1 ? ok_button : play_button} style={{width:'50px'}} />
                            </Box>
                        </Tooltip>

                        
                        {progress[index] > 0 && progress[index] < 1 &&
                            <Box ms={10} pt={1}>
                                <Box height={'50%'}>
                                    <Text fontWeight={'bold'} fontSize={'xl'}>{rs.title}</Text>
                                </Box>
                                <Text height={'50%'} fontSize={'sm'}>En cours... {submittedAnswersPerRs[index]} {submittedAnswersPerRs[index] == 1 ? 'réponse' : 'réponses'} sur {questionsPerRs[index]}</Text>
                            </Box>
                        }
                        {progress[index] == 0 &&
                            <Box ms={10} pt={1}>
                                <Box height={'50%'}>
                                    <Text fontWeight={'bold'} fontSize={'xl'}>{rs.title}</Text>
                                </Box>
                            </Box>
                        }
                        {progress[index] == 1 && submittedAnswersPerRs[index] != questionsPerRs[index] &&
                            <Box ms={10} pt={1}>
                                <Box height={'50%'}>
                                    <Text fontWeight={'bold'} fontSize={'xl'}>{rs.title}</Text>
                                </Box>
                                <Text height={'50%'} fontSize={'sm'}>{submittedAnswersPerRs[index]} {submittedAnswersPerRs[index] == 1 ? 'réponse' : 'réponses'} sur {questionsPerRs[index]}</Text>
                            </Box>
                        }
                        {progress[index] == 1 && submittedAnswersPerRs[index] == questionsPerRs[index] &&
                            <Box ms={10} pt={1}>
                                <Box height={'50%'}>
                                    <Text fontWeight={'bold'} fontSize={'xl'}>{rs.title}</Text>
                                </Box>
                                <Text height={'50%'} fontSize={'sm'}>Tu as répondu à tout !</Text>
                            </Box>
                        }
                    </Flex>
                </Link>
                }
            })}
        </Box>
        
        {finished &&
        <>
            {/* If the nb of submitted answers in submittedAnswersPerRs is equal to the nb of questions for each of the elements in questionsPerRs*/}
            {submittedAnswersPerRs.every((val, i, arr) => val == questionsPerRs[i]) &&
                <Center mt={10}>
                    <Box>
                        <Center>
                            <Text me={1} fontWeight={'bold'} fontSize={'xl'} color={THEME_COLORS.RESOLUTION_STEPS}>Bravo !</Text>

                            <Text me={1} fontSize={'md'}>Tu as répondu à toutes les questions de la résolution </Text>

                            <i onClick={() => {jsConfetti.addConfetti({confettiRadius: 4,})}} style={{marginBottom:'5px', marginRight:'5px', cursor:'pointer'}} className={"hvr-grow em-svg em-star-struck"}></i>
                        </Center>
                        
                    </Box>
                </Center>
            }
            {!submittedAnswersPerRs.every((val, i, arr) => val == questionsPerRs[i]) &&
                <Center mt={10}>
                    <Box>
                        <Center>
                            <Text me={1} fontWeight={'bold'} fontSize={'xl'} color={THEME_COLORS.RESOLUTION_STEPS}>Tu y es presque !</Text>
                        </Center>
                        <Center>

                            <Text me={1} fontSize={'md'}>Tu as tout lu, mais tu n'as pas encore répondu à tout. </Text>

                        </Center>
                        
                    </Box>
                </Center>
            }
            <Center>
                <Flex>
                    <Spacer />
                    <Button mt={4} _hover={{bgColor: THEME_COLORS.CONCLUSION}} color={'white'} bgColor={THEME_COLORS.CONCLUSION} onClick={() => {navigate('/gamecode/' + slug + '/conclusion')}} rightIcon={<FontAwesomeIcon icon={faHandPointRight} />}>Passer à la conclusion</Button>
                </Flex>
            </Center>
            </>
            }

            
        <Flex mb={4} position={'relative'} left={'100px'}>
            <Spacer />
            <img draggable='false' src={lady_drawing} style={{width:'300px'}} />
        </Flex>
        </>
        }
        
        {rs_index != '-1' && parseInt(rs_index) <= resolutionSteps.length &&
            <>
                <ResolutionStep rs_id={resolutionSteps[parseInt(rs_index)-1]._id} />
            </>
        }
    </>
};


export default ResolutionSteps;