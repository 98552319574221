import React, { useEffect, useState } from "react";
import { Box, Button, Center, Flex, Image, Input, Spacer, Stack, Text, Textarea, Tooltip, useDisclosure } from '@chakra-ui/react';
import { CheckIcon } from '@chakra-ui/icons';
import { IDynamicFragment, IGCAnswer } from '../../../types';
import LatexText from "../LatexText";
import HintBulb from "./HintBulb";

type TextareaFragmentProps = {
    body: IDynamicFragment;
    display_hint_callback: Function;
    update_answer_callback: Function;
    latest_answer: IGCAnswer | null;
};

const TextareaFragment: React.FC<TextareaFragmentProps> = ({body, display_hint_callback, update_answer_callback, latest_answer}) => {
    
    const CHARS_DIFFERENCE_THRESHOLD = 3; // to update in database

    const [answer, setAnswer] = React.useState<string>(latest_answer ? latest_answer.answer : "");
    const [previous_answer, setPreviousAnswer] = React.useState<string>(latest_answer ? latest_answer.answer : "");

    useEffect(() => {
        if (latest_answer) {
            setAnswer(latest_answer.answer);
            setPreviousAnswer(latest_answer.answer);
        }
    }, [latest_answer]);

    const update_entry = (value:string, force:boolean=false) => {
        setAnswer(value);

        // if the answer is different from the previous one, we update the database
        // (if it's greater than CHARS_DIFFERENCE_THRESHOLD)
        if (force || Math.abs(value.length - previous_answer.length) > CHARS_DIFFERENCE_THRESHOLD) {
            update_answer_callback(value);
            setPreviousAnswer(value);
        }
    }

    return <>
    <Text fontSize={'sm'} fontWeight={'bold'} color={'blackAlpha.800'} mb={2} cursor={'default'}>À toi de jouer !</Text>
    <Box shadow={'md'} bgColor={'#F0F0F0'} rounded={8} pt={2}>
        <Box rounded={8} m={4} p={4} bgColor={'white'} border={'1pt solid #e6e6e6'} mb={5} color={'black'}>
            <LatexText content={body.content} />
        </Box>

        {body.large_area &&
            <Center>
            <Textarea placeholder='Ta réponse...'
                value={answer}
                maxWidth={"550px"}
                onChange={(e) => update_entry(e.target.value)}
                rows={4}
                onBlur={() => update_answer_callback(answer, true)}
                roundedBottomEnd={8} roundedBottomStart={8}
                maxLength={500}
                m={4} p={4} bgColor={'white'} border={'1pt solid #e6e6e6'} mb={5} color={'black'}
                />
            </Center>
        }

        {!body.large_area &&
            <Center>
                <Input placeholder='Ta réponse...'
                    fontFamily={body.codify ? "JetBrains Mono" : "inherit"}
                    value={answer}
                    maxWidth={"550px"}
                    onBlur={() => update_answer_callback(answer, true)}
                    onChange={(e) => update_entry(e.target.value)}
                    roundedBottomEnd={8} roundedBottomStart={8}
                    m={4} p={4} bgColor={'white'} border={'1pt solid #e6e6e6'} mb={5} color={'black'}
                    maxLength={500}
                    />
            </Center>
        }

        <Flex roundedBottomEnd={8} roundedBottomStart={8} mt={4} bgColor={'blackAlpha.100'} p={4}>
            <Spacer />
            <HintBulb display_hint_callback={display_hint_callback} hints_nb={body.hints?.length || 0} />
        </Flex>
    </Box>
    </>

};


export default TextareaFragment;