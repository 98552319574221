import React, { useEffect, useState } from "react";
import { IDynamicFragment, IGCAnswer, ITextualSP } from "../../../types";
import { Box, Button, Center, Checkbox, Divider, Flex, Input, InputGroup, InputRightAddon, Link, Radio, RadioGroup, Spacer, Stack, Tab, TabList, TabPanel, TabPanels, Tabs, Text, Tooltip, useToast } from "@chakra-ui/react";
import LatexText from "../LatexText";
import HintBulb from "./HintBulb";
import { CheckIcon } from "@chakra-ui/icons";
import AceEditor from 'react-ace'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock, faRotateRight } from "@fortawesome/free-solid-svg-icons";
import { getAssociatedAnswer } from "../../../API/corrector";
import { useParams } from "react-router-dom";

type CodeConstructFragmentProps = {
    body: IDynamicFragment;
    check_answer: Function;
    display_hint_callback: Function;
    update_answer_callback: Function;
    latest_answer: any;
};

interface ICodeConstructAnswer {
    SP: number;
    withLoop: {
        seen:boolean, 
        value:boolean
    }
    gli: {
        seen: boolean;
        value: string;
    };
    zone1: {
        seen: boolean;
        inputs: {
            type: string;
            id: string;
            value: string;
            description: string;
        }[];
        outputs: {
            type: string;
            id: string;
            value: string;
            description: string;
        }[];
    };
    guardian: {
        seen: boolean;
        value: string;
    };
    termination: {
        seen: boolean;
        value: string;
    };
    withInterface: {
        seen:boolean, 
        value:boolean
    };
    interface: {
        seen: boolean;
        value: string;
    };
    code: {
        seen: boolean;
        value: string;
    };
}

const CodeConstructFragment: React.FC<CodeConstructFragmentProps> = ({body, check_answer, display_hint_callback, update_answer_callback, latest_answer}) => {
    const [submitted, setSubmitted] = useState<boolean>(false);
    const [form, setForm] = React.useState<IDynamicFragment>(body);
    const [answers, setAnswers] = React.useState<ICodeConstructAnswer[]>([]);
    const toast = useToast();
    const gc_params = useParams();
    const gc_slug = (gc_params.slug || '') as string;
    const [lastToastTime, setLastToastTime] = React.useState<number>(0);
    const [relatedSp, setRelatedSp] = React.useState<ITextualSP[]>([]);
    const [currentTab, setCurrentTab] = React.useState<number>(0);

    useEffect(() => {
        if (relatedSp.length == 0) {
            get_related_sps();
            return;
        }
        let valid = true;
        if (latest_answer) {
            // Check if the length of the answer is the same as the number of related SPs.
            // Also check if the length of the inputs and outputs is the same as the number of inputs and outputs of the related SPs.
            if (latest_answer.answer.length != relatedSp.length) {
                valid = false;
            } else {
                for (let i = 0; i < latest_answer.answer.length; i++) {
                    if (latest_answer.answer[i].zone1.inputs.length != relatedSp[i].inputs.length || latest_answer.answer[i].zone1.outputs.length != relatedSp[i].outputs.length) {
                        valid = false;
                        break;
                    }
                }
            }
            if (valid)
                setAnswers(latest_answer.answer);
        }
        if (!latest_answer || !valid){
            if (relatedSp.length == 0)
                return;
            // Create empty answers
            const new_answers = [];
            for (let i = 0; i < relatedSp.length; i++) {

                let inputs = [];
                for (let j = 0; j < relatedSp[i].inputs.length; j++) {
                    inputs.push({
                        type: relatedSp[i].inputs[j].type,
                        id: relatedSp[i].inputs[j].id,
                        value: '',
                        description: relatedSp[i].inputs[j].description
                    });
                }

                let outputs = [];
                for (let j = 0; j < relatedSp[i].outputs.length; j++) {
                    outputs.push({
                        type: relatedSp[i].outputs[j].type,
                        id: relatedSp[i].outputs[j].id,
                        value: '',
                        description: relatedSp[i].outputs[j].description
                    });
                }

                new_answers.push({
                    SP: i+1,
                    withLoop: {seen:false, value:true},
                    gli: {
                        seen: false,
                        value: ''
                    },
                    zone1: {
                        seen: false,
                        inputs: inputs,
                        outputs: outputs
                    },
                    guardian: {
                        seen: false,
                        value: ''
                    },
                    termination: {
                        seen: false,
                        value: ''
                    },
                    withInterface: {seen:false, value:false},
                    interface: {
                        seen: false,
                        value: ''
                    },
                    code: {
                        seen: false,
                        value: ''
                    }
                });
            }
            setAnswers(new_answers);
        }
    }, [latest_answer, relatedSp]);

    const get_related_sps = () => {
        const association_id = body.association_id;
        getAssociatedAnswer(body._id, association_id, gc_slug).then((res) => {
            const related_answer:IGCAnswer = res.data.answer;
            setRelatedSp(related_answer.answer);
        }).catch((err) => {
        });
    }

    const check_answer_wrapper = () => {
        // if at least one answer has been checked, check the answer
        // const checked_answers = answers.filter((answer) => answer.checked);
        // if (!submitted && checked_answers.length > 0) {
        //     check_answer(answers);
            setSubmitted(true);
        // }
    }

    const update_entry = (index:number, value:string) => {
        // const new_answers = [...answers];
        // new_answers[index] = value;
        // setAnswers(new_answers);
        // update_answer_callback(new_answers);
    }

    const update_loop = (value:boolean) => {
        const new_answers = [...answers];
        // new_answers[currentTab].withLoop.seen = true;
        new_answers[currentTab].withLoop.value = value;
        setAnswers(new_answers);
        update_answer_callback(new_answers);
    }

    const update_interface = (value:boolean) => {
        const new_answers = [...answers];
        // new_answers[currentTab].withInterface.seen = true;
        new_answers[currentTab].withInterface.value = value;
        setAnswers(new_answers);
        update_answer_callback(new_answers);
    }

    const everything_seen = () => {
        const current_answer = answers[currentTab];
        if (!current_answer)
            return false;

        if (current_answer.withLoop.value) {
            return current_answer.withLoop.seen
                && current_answer.gli.seen
                && current_answer.zone1.seen
                && current_answer.guardian.seen
                && current_answer.termination.seen
                && current_answer.withInterface.seen
                && current_answer.code.seen;
        } else if(!current_answer.withLoop.value) {
            return current_answer.withLoop.seen
                && current_answer.withInterface.seen
                && current_answer.code.seen;
        }
    }

    const next_step = () => {
        const current_answer = answers[currentTab];

        if (current_answer.withLoop.value) {
            if (!current_answer.withLoop.seen) {
                const new_answers = [...answers];
                new_answers[currentTab].withLoop.seen = true;
                setAnswers(new_answers);
                update_answer_callback(new_answers);
            }
            else if (!current_answer.gli.seen) {
                const new_answers = [...answers];
                new_answers[currentTab].gli.seen = true;
                setAnswers(new_answers);
                update_answer_callback(new_answers);
            }
            else if (!current_answer.zone1.seen) {
                const new_answers = [...answers];
                new_answers[currentTab].zone1.seen = true;
                setAnswers(new_answers);
                update_answer_callback(new_answers);
            }
            else if (!current_answer.guardian.seen) {
                const new_answers = [...answers];
                new_answers[currentTab].guardian.seen = true;
                setAnswers(new_answers);
                update_answer_callback(new_answers);
            }
            else if (!current_answer.termination.seen) {
                const new_answers = [...answers];
                new_answers[currentTab].termination.seen = true;
                setAnswers(new_answers);
                update_answer_callback(new_answers);
            }
            else if (!current_answer.withInterface.seen) {
                const new_answers = [...answers];
                new_answers[currentTab].withInterface.seen = true;
                setAnswers(new_answers);
                update_answer_callback(new_answers);
            }
            else if (!current_answer.interface.seen) {
                const new_answers = [...answers];
                new_answers[currentTab].interface.seen = true;
                setAnswers(new_answers);
                update_answer_callback(new_answers);
            }
            else if (!current_answer.code.seen) {
                const new_answers = [...answers];
                new_answers[currentTab].code.seen = true;
                setAnswers(new_answers);
                update_answer_callback(new_answers);
            }
        } else if(!current_answer.withLoop.value) {
            if (!current_answer.withLoop.seen) {
                const new_answers = [...answers];
                new_answers[currentTab].withLoop.seen = true;
                setAnswers(new_answers);
                update_answer_callback(new_answers);
            }
            else if (!current_answer.withInterface.seen) {
                const new_answers = [...answers];
                new_answers[currentTab].withInterface.seen = true;
                setAnswers(new_answers);
                update_answer_callback(new_answers);
            }
            else if (!current_answer.code.seen) {
                const new_answers = [...answers];
                new_answers[currentTab].code.seen = true;
                setAnswers(new_answers);
                update_answer_callback(new_answers);
            }
        }
    }

    const update_gli = (value:string) => {
        const new_answers = [...answers];
        new_answers[currentTab].gli.value = value;
        setAnswers(new_answers);
        update_answer_callback(new_answers);
    }

    const update_zone1_inputs = (index:number, value:string) => {
        // Update the initial value of the input
        const new_answers = [...answers];
        if (index < new_answers[currentTab].zone1.inputs.length) {
            new_answers[currentTab].zone1.inputs[index].value = value;
            setAnswers(new_answers);
            update_answer_callback(new_answers);
        }
    }

    const update_zone1_outputs = (index:number, value:string) => {
        // Update the initial value of the output
        const new_answers = [...answers];
        if (index < new_answers[currentTab].zone1.outputs.length) {
            new_answers[currentTab].zone1.outputs[index].value = value;
            setAnswers(new_answers);
            update_answer_callback(new_answers);
        }
    }

    const update_guardian = (value:string) => {
        const new_answers = [...answers];
        new_answers[currentTab].guardian.value = value;
        setAnswers(new_answers);
        update_answer_callback(new_answers);
    }

    const update_termination = (value:string) => {
        const new_answers = [...answers];
        new_answers[currentTab].termination.value = value;
        setAnswers(new_answers);
        update_answer_callback(new_answers);
    }

    const update_interface_signature = (value:string) => {
        const new_answers = [...answers];
        new_answers[currentTab].interface.value = value;
        setAnswers(new_answers);
        update_answer_callback(new_answers);
    }

    const update_code = (value:string) => {
        const new_answers = [...answers];
        new_answers[currentTab].code.value = value;
        setAnswers(new_answers);
        update_answer_callback(new_answers);
    }

    return <>
    <Text fontSize={'sm'} fontWeight={'bold'} color={'blackAlpha.800'} mb={2} cursor={'default'}>À toi de jouer !</Text>
    <Box shadow={'md'} bgColor={'#F0F0F0'} rounded={8} pt={2}>
        <Box rounded={8} m={4} p={4} border={'1pt solid #e6e6e6'} bgColor={'white'} mb={5} color={'black'}>
            <LatexText content={body.content} />
        </Box>

        <Divider mb={4} />

        {relatedSp.length > 0 && <>
            <Tabs index={currentTab} variant='soft-rounded' colorScheme='blackAlpha'>
                <TabList ms={4} me={4}>
                    {relatedSp.map((sp, spIndex) => (
                    <Tab 
                        me={2}
                        className="hvr-grow"
                        _selected={{bgColor:sp.color}} 
                        bgColor={'white'} 
                        onClick={() => {setCurrentTab(spIndex);}
                    } key={'sptab_'+spIndex}>{`SP ${spIndex + 1}`}</Tab>
                    ))}
                </TabList>
                <TabPanels>
                    {relatedSp.map((sp, spIndex) => (
                        <TabPanel m={3} bgColor={'white'} border={'1pt solid #e6e6e6'} key={spIndex}>
                            <Text cursor={'default'} fontWeight={'medium'}>Construction du <span style={{fontWeight:'bold', color:sp.color}}>SP {spIndex + 1}</span></Text>
                            <Text mt={2} mb={2} border={'1pt solid #e6e6e6'} rounded={'md'} p={2} fontSize={'sm'}>
                                Ta description : "{sp.description}"
                            </Text>

                            <Text mt={4}>
                                Ce sous-problème...
                            </Text>

                            <RadioGroup value={(answers[spIndex]?.withLoop.value ? 'true' : 'false')} onChange={(e:any) => {update_loop(e == 'true')}}>
                                <Stack spacing={5} direction='row'>
                                    <Radio colorScheme='red' value='true' >
                                    contient une boucle
                                    </Radio>
                                    <Radio colorScheme='green' value='false'>
                                    ne contient pas de boucle
                                    </Radio>
                                </Stack>
                            </RadioGroup>

                            {answers.length > 0 && answers[spIndex].withLoop.seen && answers[spIndex].withLoop.value && <>
                                <Text mt={4} fontWeight={'bold'} color={'purple.500'}>Invariant</Text>

                                <Text mt={2} mb={2}>
                                    Utilise <Link href="https://cafe.uliege.be/glide" target="_blank">GLIDE</Link> pour dessiner l'invariant graphique correspondant à ce sous-problème, puis, entre son code.
                                </Text>

                                <Input maxLength={15} bgColor={'white'} placeholder="Code de l'invariant"
                                    onChange={(e) => {update_gli(e.target.value);}}
                                    defaultValue={answers[spIndex]?.gli.value}
                                />

                                {answers.length > 0 && answers[spIndex].gli.seen && <>
                                    <Text mt={4} fontWeight={'bold'} color={'purple.500'}>Zone 1</Text>
                                    
                                    <Text align={'justify'} mt={2} mb={2}>
                                        Quelle est la valeur d'initialisation des variables associées à ce sous-problème ?
                                    </Text>

                                    <Text ms={2} fontWeight={'bold'} color={'blue.500'}>Inputs</Text>
                                    {sp.inputs.length == 0 && <>
                                        <Text mb={2} mt={2} ms={2} color={'gray'} fontSize={'sm'}>Ce sous-problème ne possède pas de variables d'entrée.</Text>
                                    </>}
                                    {sp.inputs.map((input, inputIndex) => (
                                        <Box ms={2} me={2} mb={2} key={inputIndex}>
                                            <InputGroup>
                                                <Input
                                                    bgColor={'gray.100'}
                                                    roundedRight={0}
                                                    roundedBottomRight={0}
                                                    value={input.type}
                                                    placeholder="type (e.g., int)"
                                                    fontFamily={'Jetbrains Mono'}
                                                    width={'20%'}
                                                    maxLength={100}
                                                    onChange={() => {
                                                        if (Date.now() - lastToastTime > 3000) {
                                                            toast({
                                                                title: 'Action invalide',
                                                                description: "Tu as déjà défini cela dans l'étape de découpe en sous-problèmes.",
                                                                status: 'error',
                                                                duration: 3000,
                                                                isClosable: true,
                                                            });
                                                            setLastToastTime(Date.now());
                                                        }
                                                    }}
                                                />
                                                <Input
                                                    bgColor={'gray.100'}
                                                    roundedLeft={0}
                                                    roundedRight={0}
                                                    roundedBottom={0}
                                                    value={input.id}
                                                    placeholder="identifiant"
                                                    fontFamily={'Jetbrains Mono'}
                                                    width={'30%'}
                                                    maxLength={100}
                                                    onChange={() => {
                                                        if (Date.now() - lastToastTime > 3000) {
                                                            toast({
                                                                title: 'Action invalide',
                                                                description: "Tu as déjà défini cela dans l'étape de découpe en sous-problèmes.",
                                                                status: 'error',
                                                                duration: 3000,
                                                                isClosable: true,
                                                            });
                                                            setLastToastTime(Date.now());
                                                        }
                                                    }}
                                                />
                                                <Input
                                                    roundedLeft={0}
                                                    roundedBottomLeft={0}
                                                    defaultValue={answers[spIndex]?.zone1.inputs[inputIndex]?.value}
                                                    onChange={(e) => {
                                                        update_zone1_inputs(inputIndex, e.target.value);
                                                    }}
                                                    placeholder="valeur initiale"
                                                    fontFamily={'Jetbrains Mono'}
                                                    width={'50%'}
                                                    maxLength={100}
                                                />
                                            </InputGroup>
                                            
                                            <Text mt={2} ms={2} color={'gray'} fontSize={'sm'}><strong>Description :</strong> "{input.description}"</Text>
                                        </Box>
                                    ))}

                                    <Text ms={2} fontWeight={'bold'} color={'green.500'}>Outputs</Text>
                                    {sp.outputs.length == 0 && <>
                                        <Text mt={2} ms={2} color={'gray'} fontSize={'sm'}>Ce sous-problème ne possède pas de variables de sortie.</Text>
                                    </>}
                                    {sp.outputs.map((output, outputIndex) => (
                                        <Box ms={2} me={2} mb={2} key={outputIndex}>
                                        <InputGroup>
                                            <Input
                                                bgColor={'gray.100'}
                                                roundedRight={0}
                                                roundedBottomRight={0}
                                                value={output.type}
                                                placeholder="type (e.g., int)"
                                                fontFamily={'Jetbrains Mono'}
                                                width={'20%'}
                                                maxLength={100}
                                                onChange={() => {
                                                    if (Date.now() - lastToastTime > 3000) {
                                                        toast({
                                                            title: 'Action invalide',
                                                            description: "Tu as déjà défini cela dans l'étape de découpe en sous-problèmes.",
                                                            status: 'error',
                                                            duration: 3000,
                                                            isClosable: true,
                                                        });
                                                        setLastToastTime(Date.now());
                                                    }
                                                }}
                                            />
                                            <Input
                                                bgColor={'gray.100'}
                                                roundedLeft={0}
                                                roundedRight={0}
                                                roundedBottom={0}
                                                value={output.id}
                                                placeholder="identifiant"
                                                fontFamily={'Jetbrains Mono'}
                                                width={'30%'}
                                                maxLength={100}
                                                onChange={() => {
                                                    if (Date.now() - lastToastTime > 3000) {
                                                        toast({
                                                            title: 'Action invalide',
                                                            description: "Tu as déjà défini cela dans l'étape de découpe en sous-problèmes.",
                                                            status: 'error',
                                                            duration: 3000,
                                                            isClosable: true,
                                                        });
                                                        setLastToastTime(Date.now());
                                                    }
                                                }}
                                            />
                                            <Input
                                                roundedLeft={0}
                                                roundedBottomLeft={0}
                                                defaultValue={answers[spIndex]?.zone1.outputs[outputIndex]?.value}
                                                onChange={(e) => {
                                                    update_zone1_outputs(outputIndex, e.target.value);
                                                }}
                                                placeholder="valeur initiale"
                                                fontFamily={'Jetbrains Mono'}
                                                width={'50%'}
                                                maxLength={100}
                                            />
                                        </InputGroup>
                                        
                                        <Text mt={2} ms={2} color={'gray'} fontSize={'sm'}><strong>Description :</strong> "{output.description}"</Text>
                                        </Box>
                                    ))}
                                </>}

                                {answers.length > 0 && answers[spIndex].zone1.seen && <>
                                    <Text mt={4} fontWeight={'bold'} color={'purple.500'}>Gardien de boucle</Text>

                                    <Text mt={2} mb={2}>
                                    Quel est le gardien de boucle de ta boucle ?
                                    </Text>
                                    
                                    <Input maxLength={15} fontFamily={'Jetbrains Mono'} bgColor={'white'} placeholder="Gardien de boucle"
                                    defaultValue={answers[spIndex]?.guardian.value}
                                    onChange={(e) => {update_guardian(e.target.value);}} />
                                </>}
                                
                                {answers.length > 0 && answers[spIndex].guardian.seen && <>
                                    <Text mt={4} fontWeight={'bold'} color={'purple.500'}>Fonction de terminaison</Text>

                                    <Text mt={2} mb={2}>
                                    Quelle est la fonction de terminaison de ta boucle ?
                                    </Text>
                                    
                                    <Input maxLength={15} fontFamily={'Jetbrains Mono'} bgColor={'white'} placeholder="Fonction de terminaison"
                                    defaultValue={answers[spIndex]?.termination.value}
                                    onChange={(e) => {update_termination(e.target.value);}} />
                                </>}
                            </>}

                            {answers.length > 0 && 
                                ((answers[spIndex]?.withLoop.value && answers[spIndex]?.termination.seen)
                                || (!answers[spIndex]?.withLoop.value && answers[spIndex]?.withLoop.seen)) && 
                            <>
                                <Text mt={4} fontWeight={'bold'} color={'purple.500'}>Interface</Text>

                                <Text mt={2} mb={2}>
                                Est-ce que ce sous-problème est associé à une interface de fonction ?
                                </Text>

                                <RadioGroup value={(answers[spIndex]?.withInterface.value ? 'true' : 'false')} defaultValue='1' mb={2} onChange={(e:any) => {update_interface(e == 'true')}}>
                                    <Stack spacing={5} direction='row'>
                                        <Radio colorScheme='red' value='true'>
                                        Oui
                                        </Radio>
                                        <Radio colorScheme='green' value='false'>
                                        Non
                                        </Radio>
                                    </Stack>
                                </RadioGroup>

                                {answers[spIndex]?.withInterface.seen && answers[spIndex]?.withInterface.value && <>
                                <Text mb={2}>
                                    Quelle est la signature de l'interface de fonction associée à ce sous-problème ?
                                </Text>
                                <Box border={'1pt solid #e6e6e6'}>
                                    <AceEditor height={100+"px"} width="100%" mode={'c_cpp'} fontSize={14} 
                                        defaultValue={answers[spIndex]?.interface.value}
                                        minLines={5}
                                        placeholder="// Interface..."
                                        theme={'dracula'}
                                        maxLines={Infinity}
                                        highlightActiveLine={false}
                                        onChange={(e) => {update_interface_signature(e);}}
                                    />
                                </Box>
                                </>}
                                
                                {((answers[spIndex]?.withInterface.seen && answers[spIndex]?.withInterface.value && answers[spIndex]?.interface.seen) || (answers[spIndex]?.withInterface.seen && !answers[spIndex]?.withInterface.value)) && <>
                                    <Text mt={4} fontWeight={'bold'} color={'purple.500'}>Code</Text>

                                    <Text align={'justify'} mt={2} mb={2}>
                                    Quel est le code associé à ce sous-problème ? Cela ne doit pas nécessairement être du code qui compile, mais il doit être cohérent avec ce que tu as défini dans les étapes précédentes.
                                    </Text>

                                    <Box border={'1pt solid #e6e6e6'}>
                                        <AceEditor height={100+"px"} width="100%" mode={'c_cpp'} fontSize={14} 
                                        defaultValue={answers[spIndex]?.interface.value}
                                            minLines={15}
                                            theme={'dracula'}
                                            maxLines={Infinity}
                                            highlightActiveLine={false}
                                            onChange={(e) => {update_code(e);}}
                                            />
                                    </Box>
                                </>}
                            </>}

                            {!everything_seen() &&
                                <Flex mt={2}>
                                    <Spacer />
                                    <Button bgColor={sp.color} onClick={() => {
                                        next_step();
                                    }}>Étape suivante</Button>
                                </Flex>
                            }
                            {everything_seen() &&
                                <Text mt={2} fontWeight={'medium'} color={'green.500'}>
                                    La construction de ce sous-problème est terminée <i className="em-svg em---1"></i>
                                </Text>
                            }
                        </TabPanel>
                    ))}
                </TabPanels>
            </Tabs>
        </>}

        {relatedSp.length == 0 && <>
            <Center mb={2}>
                <FontAwesomeIcon size="xl" color="gray" icon={faLock} />
            </Center>
            <Text ms={4} me={4} align={"center"}>
                Tu dois d'abord répondre à la découpe en sous-problèmes avant de pouvoir répondre à cette étape.
            </Text>
        </>}
        
        <Flex roundedBottomEnd={8} roundedBottomStart={8} mt={4} bgColor={'blackAlpha.100'} p={4}>
            <Tooltip label="Réinitialiser ce sous-problème" p={3} placement='top' hasArrow>
                <Button isDisabled={relatedSp.length == 0} variant={'outline'} colorScheme="darkAlpha" me={2} ><FontAwesomeIcon icon={faRotateRight} /></Button>
            </Tooltip>
            <Spacer />
            <HintBulb display_hint_callback={display_hint_callback} hints_nb={body.hints?.length || 0} />
            {/* <Button isDisabled={relatedSp.length == 0} variant='outline' colorScheme={'green'} size={'md'} rightIcon={<CheckIcon />}
                onClick={
                    () => {
                        check_answer_wrapper();
                    }
                }
            >Enregistrer</Button> */}
        </Flex>
    </Box>
    </>
}

export default CodeConstructFragment;