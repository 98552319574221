import React, { createRef, useEffect, useRef, useState } from "react";
import { Box, Button, Center, Heading, Square, Text, Tooltip } from "@chakra-ui/react";
import Draggable, { DraggableData } from 'react-draggable';
import { faLayerGroup, faLink, faMaximize, faUpRightAndDownLeftFromCenter } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Resizable } from 'react-resizable';

enum BoxType {
    SP = "sp",
    IO = "io",
}

interface IOLink {
    linkName:string;
    anchorSrc: {positionX:number, positionY:number, box_id:number};
    anchorDst: {positionX:number, positionY:number, box_id:number};
}

interface SpBox {
    id: number;
    type: BoxType;
    title: string;
    position: {x:number, y:number};
    size: {width:number, height:number};
    color: string;
}

interface SpBoxProps {
    box:SpBox;
    add_input_callback:Function;
    add_output_callback:Function;
    drag_callback:Function;
    resize_callback:Function;
}

const SpBox: React.FC<SpBoxProps> = (data:SpBoxProps) => {

    const [props, setProps] = useState<SpBox>(data.box);
    const [minSize, setMinSize] = useState<{width:number, height:number}>({width:0,height:0});
    const [handleVisible, setHandleVisible] = useState<boolean>(false);
    const [anchorsVerticalPosition, setAnchorsVerticalPosition] = useState<number>(0);
    const [displayLeftHitbox, setDisplayLeftHitbox] = useState<boolean>(false);
    const [displayRightHitbox, setDisplayRightHitbox] = useState<boolean>(false);
    const [isResizing, setIsResizing] = useState<boolean>(false);
    const [isDragging, setIsDragging] = useState<boolean>(false);

    useEffect(() => {
        // Get the min size of the box, i.e., the size of the content (handle size)
        let min_width = 0;
        let min_height = 0;
        const H_PADDING = 30;
        const V_PADDING = 20;
        const handle = document.getElementById('handle-text');
        if (handle) {
            min_width = handle.clientWidth;
            min_height = handle.clientHeight;
        }

        // Set the min size of the box
        setMinSize({width:min_width + H_PADDING, height:min_height + V_PADDING});
    }, [props]);

    const add_input = (mouseY:number) => {
        const local_position = mouseY - props.position.y;
        data.add_input_callback(local_position);
    }

    const add_output = (mouseY:number) => {
        const local_position = mouseY - props.position.y;
        data.add_output_callback(local_position);
    }

    const drag_callback_wrapper = (e:any, d:DraggableData) => {
        data.drag_callback(d.x,d.y);
    }

    const updateAnchorVerticalPosition = (mouseY:number) => {
        if (isDragging)
            return;
        let new_position = mouseY - props.position.y;
        setAnchorsVerticalPosition(new_position);
    }

    const handleDragStop = (e:any, data:DraggableData) => {
        const new_x = data.x;
        const new_y = data.y;
        const new_position = {x:new_x, y:new_y};
        setProps({...props, position:new_position});
        /**
         * BUG: When the box is released, the position of the mouse and not of the box
         * is used to update the position.
         * We need the mouse relative to the box.
         */
        setIsDragging(false);
    }

    const handleDragStart = (e: any) => {
        setIsDragging(true);
    }

    return <Draggable 
    defaultPosition={props.position} 
    bounds={'parent'}
    handle="#handle"
    onStop={handleDragStop}
    onDrag={drag_callback_wrapper}
  >
    <Resizable 
        width={props.size.width} 
        height={props.size.height} 
        onResize={(event, { node, size, handle }) => {
            setProps({...props, size:size});
            data.resize_callback(size.width, size.height);
        }}
        onResizeStart={() => {
            setIsResizing(true);
        }}
        onResizeStop={() => {
            setIsResizing(false);
        }}
        
        handle={<div
            style={{
            width:"20px", 
            height:"20px",
            position:"absolute",
            right:"-10px",
            bottom:"-10px",
            cursor:"se-resize",
            transform:"rotate(90deg)",
            opacity: (handleVisible || isResizing) ? 0.5 : 0,
        }}><FontAwesomeIcon color="white" icon={faUpRightAndDownLeftFromCenter} /></div>}
        transformScale={1}
        minConstraints={[minSize.width, minSize.height]}>
            
        <Box
            id="box"
            p={3} 
            bgColor={props.color} 
            width={props.size.width + "px"} 
            height={props.size.height + "px"}
            onMouseOver={() => {
                setHandleVisible(true);
            }}
            onMouseOut={() => {
                setHandleVisible(false);
            }}
            position={'absolute'}
            >

            {/* LAYER TOOL */}
            <div
                style={{
                position:"absolute",
                right:"30px",
                bottom:"10px",
                cursor:"pointer",
                opacity: (handleVisible || isResizing) ? 1 : 0,
                }}>
                <Tooltip label="Déplacer en premier plan" placement={'top'}><FontAwesomeIcon color="white" icon={faLayerGroup} /></Tooltip>
            </div>
            

            {/* ADD INPUT ICON */}
            <div style={{position:'relative'}}>
                <div
                    style={{
                    position:"absolute",
                    left:"-10px",
                    top:anchorsVerticalPosition-15+"px",
                    opacity: (!isResizing && displayLeftHitbox) ? 1 : 0,
                    }}>
                    <Tooltip isOpen={displayLeftHitbox} label="Ajouter un input" placement={'top'}><FontAwesomeIcon cursor={'pointer'} color="white" icon={faLink} /></Tooltip>
                </div>
            </div>

            {/* ADD OUTPUT ICON */}
            <div
                style={{
                position:"absolute",
                right:"-10px",
                top:anchorsVerticalPosition-15+"px",
                opacity: (!isResizing && displayRightHitbox) ? 1 : 0,
                }}>
                <Tooltip isOpen={displayRightHitbox} label="Ajouter un output" placement={'top'}><FontAwesomeIcon color="white" icon={faLink} /></Tooltip>
            </div>

            {/* ADD INPUT HITBOX */}
            <div
                onMouseMove={(e) => {
                    updateAnchorVerticalPosition(e.clientY);
                }}
                onMouseOver={() => {setDisplayLeftHitbox(true)}}
                onMouseOut={() => {setDisplayLeftHitbox(false)}}
                onClick={(e:any) => {add_input(e.clientY)}}
                style={{
                position:"absolute",
                left:"-25px",
                top:"0px",
                height: (props.size.height <= 50) ? props.size.height : props.size.height - 30+"px",
                width: "50px",
                backgroundColor:"rgba(255, 0, 0, 0)",
                cursor: (!isResizing && displayLeftHitbox) ? "pointer" : "default",
            }} />

            {/* ADD OUTPUT HITBOX */}
            <div
                onMouseMove={(e) => {
                    updateAnchorVerticalPosition(e.clientY);
                }}
                onMouseOver={() => {setDisplayRightHitbox(true)}}
                onMouseOut={() => {setDisplayRightHitbox(false)}}
                onClick={(e:any) => {add_output(e.clientY)}}
                style={{
                position:"absolute",
                right:"-25px",
                top:"0px",
                height: (props.size.height <= 50) ? props.size.height : props.size.height - 30+"px",
                width: "50px",
                backgroundColor:"rgba(255, 0, 0, 0)",
                cursor: (!isResizing && displayRightHitbox) ? "pointer" : "default",
            }} />
            <Center id="handle" cursor={'grab'}>
            <Text id="handle-text" display={'inline-block'} userSelect={'none'} color={'white'}>
                {props.title}
            </Text>
            </Center>
        </Box>
    </Resizable>
  </Draggable>
}

function Sp() {
    const [boxes, setBoxes] = useState<SpBox[]>([
        {
            id: 0,
            type:BoxType.SP,
            title:"Sous-problème 1",
            position: {x: 50, y: 150},
            size: {width: 300, height: 110},
            color: "blue.800",
        },
        {
            id: 1,
            type:BoxType.SP,
            title:"Sous-problème 2",
            position: {x: 400, y: 280},
            size: {width: 200, height: 90},
            color: "red.800",
        }
    ]);
    const [links, setLinks] = useState<IOLink[]>([
        {
            anchorSrc: {positionX:0, positionY: 20, box_id: 0},
            anchorDst: {positionX:0, positionY: 20, box_id: 1},
            linkName: "int x",
        },
        {
            anchorSrc: {positionX:0, positionY: 60, box_id: 0},
            anchorDst: {positionX:0, positionY: 50, box_id: 1},
            linkName: "int y",
        }
    ]);

    useEffect(() => {
    }, [boxes]);

    const add_input_callback = (boxid:number, mouseY:number) => {
        console.log("add input to box " + boxid + " at y=" + mouseY);
    }

    const add_output_callback = (boxid:number, mouseY:number) => {
        console.log("add output to box " + boxid + " at y=" + mouseY);
    }

    const box_dragged_callback = (boxid:number, x:number, y:number) => {
        // Update the position of the box
        const new_position = {x:x, y:y};
        const new_boxes = boxes.map((box) => {
            if (box.id === boxid) {
                return {...box, position:new_position};
            } else {
                return box;
            }
        });
        setBoxes(new_boxes);
    }

    const box_resize_callback = (boxid:number, width:number, height:number) => {
        // Update the size of the box
        const new_size = {width:width, height:height};
        const new_boxes = boxes.map((box) => {
            if (box.id === boxid) {
                return {...box, size:new_size};
            } else {
                return box;
            }
        });
        setBoxes(new_boxes);

        // If one of the related links is outside of the box, we need to update it
        const new_links = links.map((link) => {
            if (link.anchorSrc.box_id === boxid) {
                if (link.anchorSrc.positionY >= height) {
                    link.anchorSrc.positionY = height;
                }
            }
            if (link.anchorDst.box_id === boxid) {
                if (link.anchorDst.positionY >= height) {
                    link.anchorDst.positionY = height;
                }
            }
            return link;
        });
        setLinks(new_links);
    }
  
    return (
    <>
    <Heading>Hey Polay !</Heading>
    <Heading>Hey Polay !</Heading>
    <Heading>Hey Polay !</Heading>
    <Heading>Hey Polay !</Heading>
    <Box width={'800px'} height={'500px'} border={'1pt solid #111111'} roundedTop={'lg'} position={'relative'}>
        {/* <Box bgColor={'#423f3f'} p={2} roundedTop={'md'}>
            <Text cursor={'default'} color={'white'}>Éditeur de sous-problèmes</Text>
        </Box> */}
        <Box height={'500px'} bgColor={"#191919"}>
            <div style={{width:'100%', height:'100%'}}>
                {boxes.map((box, index) => {
                    // set into parent box
                    return <SpBox key={index} box={box} 
                        add_input_callback={(ypos:number) => {add_input_callback(box.id, ypos)}} 
                        add_output_callback={(ypos:number) => {add_output_callback(box.id, ypos)}}
                        drag_callback={(x:number, y:number) => {box_dragged_callback(box.id, x, y)}}
                        resize_callback={(width:number, height:number) => {box_resize_callback(box.id, width, height)}}
                        />
                })}

                {/* LINKS */}
                {links.map((link, index) => {
                    const related_box_src = boxes.filter((box) => {return box.id === link.anchorSrc.box_id})[0];
                    const related_box_dst = boxes.filter((box) => {return box.id === link.anchorDst.box_id})[0];
                    let x1, y1, x2, y2;

                    if (related_box_src) {
                        // It is coming out of the outputs of this box
                        // we only use the positionY of the anchor, relative to the box
                        x1 = related_box_src.position.x + related_box_src.size.width;
                        y1 = related_box_src.position.y + link.anchorSrc.positionY;
                    } else {
                        // We use the positionX and positionY of the anchor
                        x1 = 0;
                        y1 = 0;
                    }
                    if (related_box_dst) {
                        // It is coming into the inputs of this box
                        // we only use the positionY of the anchor, relative to the box
                        x2 = related_box_dst.position.x;
                        y2 = related_box_dst.position.y + link.anchorDst.positionY + 10;
                    } else {
                        // We use the positionX and positionY of the anchor
                        // it is leading to outside of any box
                        x2 = link.anchorDst.positionX;
                        y2 = link.anchorDst.positionY;
                    }
                    
                    return <Box><svg pointerEvents={"none"} key={index} style={{position:'absolute', top:0, left:0}} width={"100%"} height={"100%"}>
                        {/* Add a dot at the beginning */}
                        <circle cx={x1} cy={y1} r="3" fill="white" />
                        <circle cx={x2} cy={y2} r="3" fill="white" />
                        <line x1={x1} y1={y1} x2={x2} y2={y2} stroke="white" strokeWidth="2" />
                    </svg>
                    <Text pointerEvents={'none'} p={1} bgColor={'rgba(0, 0, 0, 0.5)'} fontFamily={'JetBrains Mono'} textShadow={'sm'} color={'white'} position={'absolute'} top={(y1-10)+'px'} left={(related_box_src.size.width+related_box_src.position.x+10)+'px'} >{link.linkName}</Text>
                    </Box>
                })}
            </div>
        </Box>
    </Box>
    {/* <Button onClick={addBox}>Add box</Button> */}
    </>);
}
export default Sp;