import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Avatar, Box, Button, Center, Divider, Flex, Heading, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Spacer, Square, Tab, TabList, Tabs, Text, Tooltip } from "@chakra-ui/react";
// import Image from chakra but with the name ChakraImage:
import { Image as ChakraImage } from "@chakra-ui/react";

import React, { useEffect, useState } from "react";
import { GCModule, IAnyFragment, IReminder, IResolutionStep, ResolutionStepModule } from "../types";
import { getReminder } from "../API/reminder";
import FragmentsList from "./FragmentsList";
import { THEME_COLORS } from "../config";
import { Link, useParams } from "react-router-dom";
import { getResolutionStep } from "../API/resolution_step";
import Reminders from "./Reminders";
import { getRsModuleProgress, updateModuleProgress, updateRsModuleProgress } from "../API/gcinstance";
import { StarIcon } from "@chakra-ui/icons";
import gamepad_img from '../resources/resolution_step/gamepad.svg';
import write_img from '../resources/resolution_step/write.svg';
import books_img from '../resources/resolution_step/books.svg';
import flipboard_img from '../resources/resolution_step/flipboard.svg';
import papers_img from '../resources/resolution_step/papers.svg';
import toc_mp3 from "../resources/sound/toc.mp3";
import { faCheck, faPenNib } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type ReminderProps = {
    rs_id:string;
};

const ResolutionStep: React.FC<ReminderProps> = ({rs_id}) => {
    const gc_params = useParams();
    const slug = gc_params.slug as string;
    const [currentRs, setCurrentRs] = useState<IResolutionStep>({title: '', gcid:'', statement: [], reminders: [], questions: [], conclusion: [], _id: ''});
    const [step, setStep] = useState<number>(1);
    const [statementStep, setStatementStep] = useState<number>(1);
    const [module, setModule] = useState<ResolutionStepModule>(ResolutionStepModule.STATEMENT);
    const [currentReminderIndex, setCurrentReminderIndex] = useState<number>(0);
    const next_step_sound = new Audio(toc_mp3);

    
    // PRELOAD
    useEffect(() => {
        new Image().src = gamepad_img;
        new Image().src = write_img;
        new Image().src = books_img;
        new Image().src = flipboard_img;
        new Image().src = papers_img;
    }, []);

    useEffect(() => {
        // Get reminder from API
        getResolutionStep(rs_id).then((res) => {
            if (res.data.resolution_step) {
                setCurrentRs(res.data.resolution_step);

                // Get the current step in the module
                getRsModuleProgress(res.data.resolution_step.gcid, res.data.resolution_step._id, module).then((res) => {
                    if (res.data.module_step) {
                        setStep(res.data.module_step);

                        if (module == ResolutionStepModule.STATEMENT) {
                            setStatementStep(res.data.module_step);
                        }
                    }
                });
            }
        });

    }
    , [rs_id]);

    const changeState = (new_module:ResolutionStepModule) => {
        next_step_sound.volume = 1;
        next_step_sound.play();
        setModule(new_module);
        // Get the current step in the module
        getRsModuleProgress(currentRs.gcid, currentRs._id, new_module).then((res) => {
            if (res.data.module_step) {
                setStep(res.data.module_step);
            }
        });
    }

    const nextStep = () => {
        next_step_sound.volume = 1;
        next_step_sound.play();
        setStep(step + 1);

        let current_fragment:string = '';
        switch(module) {
            case ResolutionStepModule.QUESTIONS:
                current_fragment = currentRs.questions[step-1];
                break;
            case ResolutionStepModule.REMINDERS:
                break;
            case ResolutionStepModule.CONCLUSION:
                current_fragment = currentRs.conclusion[step-1];
                break;
            default:
                break;
        }
        updateRsModuleProgress(currentRs.gcid, current_fragment, currentRs._id, module).then((res) => {
            document.dispatchEvent(new Event('PROGRESS_UPDATED'));
            document.dispatchEvent(new Event('RS_PROGRESS_UPDATED'));
        });

        
        // Smoothly scroll the page down to the bottom after 0.5s
        setTimeout(() => {
            window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
        }, 100);
    }

    const nextStatementStep = () => {
        setStatementStep(statementStep + 1);
        next_step_sound.volume = 1;
        next_step_sound.play();

        const current_fragment:string = currentRs.statement[statementStep-1];
        updateRsModuleProgress(currentRs.gcid, current_fragment, currentRs._id, ResolutionStepModule.STATEMENT).then((res) => {
            document.dispatchEvent(new Event('PROGRESS_UPDATED'));
            document.dispatchEvent(new Event('RS_PROGRESS_UPDATED'));
        });

        // Smoothly scroll the page down to the bottom after 0.5s
        setTimeout(() => {
            window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
        }, 100);
    }

    return <>
            {/* <Center> */}
                <Heading size={'lg'} color={THEME_COLORS.RESOLUTION_STEPS} mb={8} mt={2} p={0}>{currentRs.title}</Heading>
            {/* </Center> */}

            {/* STATEMENT */}
            {/* <Box bgColor={'blackAlpha.100'} p={4} rounded={'lg'} shadow={'sm'}> */}
                <Heading size={'md'} color={'gray.700'} >Énoncé de cette étape</Heading>
                <Box bgColor={'white'} border={'1pt solid #ededed'} mt={4} mb={4} p={5} >
                    <FragmentsList fragments_ids={currentRs.statement} step={statementStep} />
                </Box>
            {/* </Box> */}

            {currentRs.statement.length > 0 && statementStep <= currentRs.statement.length &&
                <Button mt={4} colorScheme='teal' onClick={() => nextStatementStep()}>Lire la suite</Button>
            }
            
            {module == ResolutionStepModule.STATEMENT && statementStep > currentRs.statement.length && <>
                <Flex mt={10}>
                    <ChakraImage src={gamepad_img} width={'50px'} />
                    <Box height={'50px'} rounded={'lg'} bgColor={THEME_COLORS.INTRO} textColor={'white'} p={3}  ms={5}>
                        <Text fontWeight={'normal'} fontSize={'11pt'}><strong>À toi de jouer !</strong> Comment veux-tu résoudre cette étape ?</Text>
                    </Box>
                </Flex>
                <Flex mt={5} className="hvr-grow" cursor={'pointer'} onClick={() => changeState(ResolutionStepModule.QUESTIONS)}>
                    <Spacer />
                    <Box height={'50px'} rounded={'lg'} bgColor={THEME_COLORS.STATEMENT} textColor={'white'} p={3} me={5} shadow={'md'}>
                        <Text fontWeight={'medium'} fontSize={'11pt'}>Je veux répondre à la question directement</Text>
                    </Box>
                    <ChakraImage src={write_img} width={'50px'} />
                </Flex>
                <Flex mt={3} className="hvr-grow" cursor={'pointer'} onClick={() => changeState(ResolutionStepModule.REMINDERS)}>
                    <Spacer />
                    <Box height={'50px'} rounded={'lg'} bgColor={THEME_COLORS.REMINDERS} textColor={'white'} p={3} me={5}>
                        <Text fontWeight={'medium'} fontSize={'11pt'}>Je veux d'abord lire des rappels sur cette étape</Text>
                    </Box>
                    <ChakraImage src={books_img} width={'50px'} />
                </Flex>
                <Flex mt={3} className="hvr-grow" cursor={'pointer'} onClick={() => changeState(ResolutionStepModule.CONCLUSION)}>
                    <Spacer />
                    <Box height={'50px'} rounded={'lg'} bgColor={THEME_COLORS.CONCLUSION} textColor={'white'} p={3} me={5}>
                        <Text fontWeight={'medium'} fontSize={'11pt'}>Je veux passer à la conclusion de cette étape</Text>
                    </Box>
                    <ChakraImage src={flipboard_img} width={'50px'} />
                </Flex>
                <Flex mt={3} className="hvr-grow" cursor={'pointer'}>
                    <Spacer />
                    <Link to={'/gamecode/'+slug+'/resolution'}>
                        <Box height={'50px'} rounded={'lg'} bgColor={THEME_COLORS.RESOLUTION_STEPS} textColor={'white'} p={3} me={5}>
                            <Text fontWeight={'medium'} fontSize={'11pt'}>Je veux retourner à la liste des étapes</Text>
                        </Box>
                    </Link>
                    <ChakraImage src={papers_img} width={'50px'} />
                </Flex>
            </>}

            
            {/* REMINDERS */}
            {module == ResolutionStepModule.REMINDERS &&<>
                <Reminders reminders_id={currentRs.reminders} integrated={true} />

                <Divider mb={4} mt={4} />

                <Flex mt={5} className="hvr-grow" cursor={'pointer'} onClick={() => changeState(ResolutionStepModule.QUESTIONS)}>
                    <Spacer />
                    <Box height={'50px'} rounded={'lg'} bgColor={THEME_COLORS.STATEMENT} textColor={'white'} p={3} me={5} shadow={'md'}>
                        <Text fontWeight={'medium'} fontSize={'11pt'}>Maintenant, je veux répondre à la question</Text>
                    </Box>
                    <ChakraImage src={write_img} width={'50px'} />
                </Flex>
                <Flex mt={3} className="hvr-grow" cursor={'pointer'} onClick={() => changeState(ResolutionStepModule.CONCLUSION)}>
                    <Spacer />
                    <Box height={'50px'} rounded={'lg'} bgColor={THEME_COLORS.CONCLUSION} textColor={'white'} p={3} me={5}>
                        <Text fontWeight={'medium'} fontSize={'11pt'}>Je veux passer à la conclusion de cette étape</Text>
                    </Box>
                    <ChakraImage src={flipboard_img} width={'50px'} />
                </Flex>
                <Flex mt={3} className="hvr-grow" cursor={'pointer'}>
                    <Spacer />
                    <Link to={'/gamecode/'+slug+'/resolution'}>
                        <Box height={'50px'} rounded={'lg'} bgColor={THEME_COLORS.RESOLUTION_STEPS} textColor={'white'} p={3} me={5}>
                            <Text fontWeight={'medium'} fontSize={'11pt'}>Je veux retourner à la liste des étapes</Text>
                        </Box>
                    </Link>
                    <ChakraImage src={papers_img} width={'50px'} />
                </Flex>
            </>}

            {/* QUESTIONS */}
            {module == ResolutionStepModule.QUESTIONS &&<>

                <Heading size={'lg'} color={THEME_COLORS.STATEMENT} mb={5} mt={10} p={0} pb={0}>Résolution de l'étape</Heading>

                <FragmentsList fragments_ids={currentRs.questions} step={step} />

                <Divider mb={4} mt={4} />

                {currentRs.questions.length > 0 && step <= currentRs.questions.length &&
                    <Button mt={4} colorScheme='teal' onClick={() => nextStep()}>Lire la suite</Button>
                }
                {currentRs.questions.length > 0 && step > currentRs.questions.length &&
                    <Center mt={5}>
                        <Box p={3} bgColor={"gray.100"}>
                            <Text fontWeight={'medium'} fontSize={'11pt'}><FontAwesomeIcon icon={faCheck} size="lg" style={{marginRight:"5px"}} /> Il n'y a plus rien à lire dans cette partie, bien joué !</Text>
                        </Box>
                    </Center>
                }

                <Flex mt={3} className="hvr-grow" cursor={'pointer'} onClick={() => changeState(ResolutionStepModule.REMINDERS)}>
                    <Spacer />
                    <Box height={'50px'} rounded={'lg'} bgColor={THEME_COLORS.REMINDERS} textColor={'white'} p={3} me={5}>
                        <Text fontWeight={'medium'} fontSize={'11pt'}>Je veux lire des rappels sur cette étape</Text>
                    </Box>
                    <ChakraImage src={books_img} width={'50px'} />
                </Flex>
                <Flex mt={3} className="hvr-grow" cursor={'pointer'} onClick={() => changeState(ResolutionStepModule.CONCLUSION)}>
                    <Spacer />
                    <Box height={'50px'} rounded={'lg'} bgColor={THEME_COLORS.CONCLUSION} textColor={'white'} p={3} me={5}>
                        <Text fontWeight={'medium'} fontSize={'11pt'}>Je veux passer à la conclusion de cette étape</Text>
                    </Box>
                    <ChakraImage src={flipboard_img} width={'50px'} />
                </Flex>
                <Flex mt={3} className="hvr-grow" cursor={'pointer'}>
                    <Spacer />
                    <Link to={'/gamecode/'+slug+'/resolution'}>
                        <Box height={'50px'} rounded={'lg'} bgColor={THEME_COLORS.RESOLUTION_STEPS} textColor={'white'} p={3} me={5}>
                            <Text fontWeight={'medium'} fontSize={'11pt'}>Je veux retourner à la liste des étapes</Text>
                        </Box>
                    </Link>
                    <ChakraImage src={papers_img} width={'50px'} />
                </Flex>
            </>}

            {/* CONCLUSION */}
            {module == ResolutionStepModule.CONCLUSION &&<>

                <Heading size={'lg'} color={THEME_COLORS.CONCLUSION} mb={5} mt={5} p={0}>Conclusion de l'étape</Heading>

                <FragmentsList fragments_ids={currentRs.conclusion} step={step} />
                
                {currentRs.conclusion.length > 0 && step <= currentRs.conclusion.length &&
                    <Button mt={4} colorScheme='teal' onClick={() => nextStep()}>Lire la suite</Button>
                }

                {currentRs.conclusion.length > 0 && step > currentRs.conclusion.length &&
                <Center mt={5}>
                    <Box p={3} bgColor={"gray.100"}>
                        <Text fontWeight={'medium'} fontSize={'11pt'}><FontAwesomeIcon icon={faCheck} size="lg" style={{marginRight:"5px"}} /> Il n'y a plus rien à lire dans cette partie, bien joué !</Text>
                    </Box>
                </Center>
                }

                <Flex mt={5} className="hvr-grow" cursor={'pointer'} onClick={() => changeState(ResolutionStepModule.QUESTIONS)}>
                    <Spacer />
                    <Box height={'50px'} rounded={'lg'} bgColor={THEME_COLORS.STATEMENT} textColor={'white'} p={3} me={5} shadow={'md'}>
                        <Text fontWeight={'medium'} fontSize={'11pt'}>Je veux revenir à ma réponse</Text>
                    </Box>
                    <ChakraImage src={write_img} width={'50px'} />
                </Flex>
                <Flex mt={3} className="hvr-grow" cursor={'pointer'} onClick={() => changeState(ResolutionStepModule.REMINDERS)}>
                    <Spacer />
                    <Box height={'50px'} rounded={'lg'} bgColor={THEME_COLORS.REMINDERS} textColor={'white'} p={3} me={5}>
                        <Text fontWeight={'medium'} fontSize={'11pt'}>Je veux lire des rappels sur cette étape</Text>
                    </Box>
                    <ChakraImage src={books_img} width={'50px'} />
                </Flex>
                <Flex mt={3} className="hvr-grow" cursor={'pointer'}>
                    <Spacer />
                    <Link to={'/gamecode/'+slug+'/resolution'}>
                        <Box height={'50px'} rounded={'lg'} bgColor={THEME_COLORS.RESOLUTION_STEPS} textColor={'white'} p={3} me={5}>
                            <Text fontWeight={'medium'} fontSize={'11pt'}>Je veux retourner à la liste des étapes</Text>
                        </Box>
                    </Link>
                    <ChakraImage src={papers_img} width={'50px'} />
                </Flex>
            </>}
        </>
};


export default ResolutionStep;