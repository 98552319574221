// Rich text editor
import React from "react";
import { Box, Checkbox, Divider, Flex, FormControl, FormLabel, Input, Text } from '@chakra-ui/react';
import { IDynamicFragment } from '../../../types';
import TextEditor from '../TextEditor';

type CodeConstructProps = {
    body: IDynamicFragment;
    update_callback: Function;
};

const CodeConstructFragment: React.FC<CodeConstructProps> = ({body, update_callback}) => {

    const [construct, setConstruct] = React.useState<IDynamicFragment>(body);

    const update_content = (value:string) => {
        const new_frag = {
            ...construct,
            content: value
        };
        setConstruct(new_frag);
        update_fragment(new_frag);
    }

    const update_fragment = (new_frag:IDynamicFragment) => {
        update_callback(new_frag);
    }

    const update_assoc_id = (assoc_id:string) => {
        let new_body:IDynamicFragment = {
            ...construct,
            association_id:assoc_id
        }
        setConstruct(new_body);
        update_callback(new_body);
    }

    //TODO: Verification to see if another fragment with this association_id exists

    return <Box ps={2} pe={2}>
        <Text fontSize={'sm'} fontWeight={'bold'} color={'purple.500'} mb={2} cursor={'default'}>Association</Text>
        
        <Text mb={2} ms={0} mt={3} color={'gray.600'} fontSize={'sm'}>Spécifiez l'identifiant de la découpe en SP associée à cette construction de code.</Text>
        
        <Input errorBorderColor={'red.300'} 
                isInvalid={construct.association_id == undefined || construct.association_id?.length == 0}
                onChange={(e:any) => { update_assoc_id(e.target.value); }}
                ms={0} width={'150px'} 
                defaultValue={construct.association_id}
            placeholder='Identifiant' size='xs' />

        <Divider mb={2} mt={2} />

        <Text fontSize={'sm'} fontWeight={'bold'} color={'purple.500'} mb={2} cursor={'default'}>Énoncé de la construction de code</Text>
        
        <TextEditor content={construct.content} update_callback={update_content} />

        <Text align={'justify'} mt={3} color={'gray.600'} fontSize={'sm'}>Il n'y a aucun champ à remplir pour ce fragment. Si un SP est associé à un invariant, l'étudiant·e remplira le code GLI, la zone 1, le gardien de boucle, et la fonction de terminaison. Sinon, l'étudiant·e remplira juste le code et l'éventuelle interface.</Text>

        </Box>

};


export default CodeConstructFragment;