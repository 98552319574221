import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Box, Button, Center, CircularProgress, Container, Flex, FormControl, FormLabel, Heading, Input, Menu, MenuButton, MenuItem, MenuList, Spacer, Stack, Text, useDisclosure } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { DynamicFragmentType, FragmentType, IReminder, StaticFragmentType } from "../types";
import FragmentsList from "./FragmentsList";
import { AddIcon, CloseIcon, DeleteIcon } from "@chakra-ui/icons";
import { addFragmentToReminder, deleteFragmentFromReminder, deleteReminder, getReminder, updateReminder } from "../API/reminder";
import ReminderEditor from "./ReminderEditor";

type ReminderProps = {
    reminder_id:string;
    remove_reminder:Function;
};

const ReminderAccordion: React.FC<ReminderProps> = ({reminder_id, remove_reminder}) => {
    const [currentReminder, setCurrentReminder] = useState<IReminder>({title: '', content: [], _id: ''});
    const { isOpen, onOpen, onClose } = useDisclosure()
    const cancelRef = React.useRef<null>(null);

    useEffect(() => {
        // Get reminder from API
        getReminder(reminder_id).then((res) => {
            if (res.data.reminder)
                setCurrentReminder(res.data.reminder);
        });
    }
    , [reminder_id]);


    const delete_reminder_callback = () => {
        onOpen();
    }

    const confirm_delete_reminder = () => {
        onClose();
        deleteReminder(reminder_id).then((res) => {
            remove_reminder(reminder_id);
        });
    }

    return <>
        <Accordion allowMultiple p={0}>
            <AccordionItem p={0}>
                { ({ isExpanded }) => (

                    <>
                    
                        <AccordionButton p={4} textColor={'green.500'}>
                        <Box as="span" flex='1' textAlign='left'>
                            <Heading m={0} size={'sm'}>{currentReminder.title}</Heading>
                        </Box>
                        <AccordionIcon />
                        </AccordionButton>
                        { isExpanded &&
                        <AccordionPanel p={2} pb={4} pt={0}>
                            <Flex>
                                <Spacer />
                                <Button mt={2} me={2} size={'sm'} colorScheme='red' onClick={() => {delete_reminder_callback()}} rightIcon={<DeleteIcon />} >Supprimer</Button>
                                <Button mt={2} size={'sm'} colorScheme='blackAlpha' onClick={() => {remove_reminder(reminder_id);}} rightIcon={<CloseIcon />} >Retirer de la liste</Button>
                            </Flex>
                            <ReminderEditor reminder={currentReminder} />
                        </AccordionPanel>
                        }
                    </>
                    )
                }
            </AccordionItem>
        </Accordion>

            
        {/* FRAGMENT DELETION POPUP */}
        <AlertDialog
            isOpen={isOpen}
            leastDestructiveRef={cancelRef}
            onClose={() => {}}
            >
            <AlertDialogOverlay>
                <AlertDialogContent>
                <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                    Supprimer un rappel
                </AlertDialogHeader>
    
                <AlertDialogBody>
                    Attention, le rappel ainsi que tous ses fragments seront définitivement supprimés. <br /><strong>Si ce rappel est présent dans d'autres Gamecodes, il en sera également supprimé.</strong>
                </AlertDialogBody>
    
                <AlertDialogFooter>
                    <Button ref={cancelRef} onClick={onClose}>
                    Annuler
                    </Button>
                    <Button colorScheme='red' onClick={() => {confirm_delete_reminder();}} ml={3}>
                    Supprimer
                    </Button>
                </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
        </>
};


export default ReminderAccordion;