import { Box, Center, Flex, Spacer, Text, Tooltip } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { GCModule } from './types';

export enum ProgressChunkType {
    INTRO=1,
    REMINDERS,
    STATEMENT,
    RESOLUTION,
    CONCLUSION
}

interface IProgressChunk {
    type: ProgressChunkType;
    completion: number;
    focused: boolean;
    slug?: string;
}

const ProgressChunk: React.FC<IProgressChunk> = (props:IProgressChunk) => {
    let color = '';
    let width = '';
    const HEIGHT = '10px';
    const ROUNDED = '20px';
    const COLORS = {
        INTRO: 'blue.500',
        REMINDERS: 'green.500',
        STATEMENT: 'orange.300',
        RESOLUTION_STEPS: 'purple.500',
        CONCLUSION: 'red.400'
    }

    const gc_state = useParams();
    let navigate = useNavigate(); 
    const routeChange = () =>{
        // to={'/gamecode/' + slug + '/' + module}
        let module = '';
        switch(props.type) {
            case ProgressChunkType.INTRO:
                module = 'intro';
                break;
            case ProgressChunkType.REMINDERS:
                module = 'reminders';
                break;
            case ProgressChunkType.STATEMENT:
                module = 'statement';
                break;
            case ProgressChunkType.RESOLUTION:
                module = 'resolution';
                break;
            case ProgressChunkType.CONCLUSION:
                module = 'conclusion';
                break;
        }
        let path = `/gamecode/` + (props.slug ? props.slug : gc_state.slug) + '/' + module; 
        navigate(path);
    }
    const onClick = () => {
        routeChange();
    }

    switch(props.type) {
        case ProgressChunkType.INTRO:
            color = COLORS.INTRO;
            width = '8%';
            break;
        case ProgressChunkType.REMINDERS:
            color = COLORS.REMINDERS;
            width = '22%';
            break;
        case ProgressChunkType.STATEMENT:
            color = COLORS.STATEMENT;
            width = '8%';
            break;
        case ProgressChunkType.RESOLUTION:
            color = COLORS.RESOLUTION_STEPS;
            width = '47%';
            break;
        case ProgressChunkType.CONCLUSION:
            color = COLORS.CONCLUSION;
            width = '8%';
            break;
    }

    // Content of the tooltip
    const tooltip_content = () => {
        let content;
        switch(props.type) {
            case ProgressChunkType.INTRO:
                content = <Box p={2} color={'white'}>Introduction</Box>;
                break;
            case ProgressChunkType.REMINDERS:
                content = <Box p={2}><Center>Rappels Généraux</Center></Box>;
                break;
            case ProgressChunkType.STATEMENT:
                content = <Box p={2}>Énoncé</Box>;
                break;
            case ProgressChunkType.RESOLUTION:
                content = <Box p={2}>Résolution</Box>;
                break;
            case ProgressChunkType.CONCLUSION:
                content = <Box p={2}>Mise en commun</Box>;
                break;
        }

        return content;
    }


    return <Tooltip label={tooltip_content()} hasArrow placement='bottom' shadow={'none'} bgColor={color} rounded={5} offset={[0, 18]}>
                <Box bg={'gray.100'} width={width} height={HEIGHT} rounded={ROUNDED} cursor={'pointer'} onClick={() => onClick()} style={{boxShadow: props.focused ? '0 0 0 2px rgba(255, 255, 255, 1), 0 0 0 4px rgba(0, 0, 0, .2)' : ''}}>
                    <Box bg={color} width={`${props.completion * 100}%`} height={HEIGHT} rounded={ROUNDED} />
                </Box>
            </Tooltip>;
}

interface IProgressLine {
    completion: number[];
    focused_module: GCModule|null;
    slug?: string;
};

export const ProgressLine: React.FC<IProgressLine> = (props) => {
    let focused = null;
    
    switch(props.focused_module) {
        case GCModule.INTRO:
            focused = ProgressChunkType.INTRO;
            break;
        case GCModule.REMINDERS:
            focused = ProgressChunkType.REMINDERS;
            break;
        case GCModule.STATEMENT:
            focused = ProgressChunkType.STATEMENT;
            break;
        case GCModule.RESOLUTION_STEPS:
            focused = ProgressChunkType.RESOLUTION;
            break;
        case GCModule.CONCLUSION:
            focused = ProgressChunkType.CONCLUSION;
            break;
    }

    return (<>
        <Flex width={'580px'} justifyContent={'center'} alignContent={'center'} align={'center'}>
            <ProgressChunk slug={props.slug} focused={focused == ProgressChunkType.INTRO} type={ProgressChunkType.INTRO} completion={props.completion[0]} /><Spacer />

            <ProgressChunk slug={props.slug} focused={focused == ProgressChunkType.REMINDERS} type={ProgressChunkType.REMINDERS} completion={props.completion[1]} /><Spacer />

            <ProgressChunk slug={props.slug} focused={focused == ProgressChunkType.STATEMENT} type={ProgressChunkType.STATEMENT} completion={props.completion[2]} /><Spacer />

            <ProgressChunk slug={props.slug} focused={focused == ProgressChunkType.RESOLUTION} type={ProgressChunkType.RESOLUTION} completion={props.completion[3]} /><Spacer />

            <ProgressChunk slug={props.slug} focused={focused == ProgressChunkType.CONCLUSION} type={ProgressChunkType.CONCLUSION} completion={props.completion[4]} />
        </Flex>
    </>);
}