import { Box, Center, CircularProgress, Skeleton, Stack, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { getFragments, resolveFragmentIds } from "../API/fragment";
import { DynamicFragmentType, FragmentType, IAnyFragment, IDynamicFragment, IStaticFragment, StaticFragmentType } from "../types";
import StaticFragmentComponent from "./fragments/static/StaticFragmentComponent";
import DynamicFragmentComponent from "./fragments/dynamic/DynamicFragmentComponent";

class AnyFragment {
    deleted: boolean = false;

    constructor() {}
    render(withMargin=true) {
        return <></>
    }
}

class StaticFragment extends AnyFragment implements IStaticFragment {
    _id: string;
    type: StaticFragmentType;
    content: any;
    initial_code?: {file:string, before:string, content:string, after:string, secret:boolean}[];
    expected_output?: string;
    body:IStaticFragment;

    constructor(fragment:IStaticFragment) {
        super();
        this._id = fragment._id;
        this.type = fragment.type;
        this.content = fragment.content;
        if (fragment.initial_code)
            this.initial_code = fragment.initial_code;
        if (fragment.expected_output)
            this.expected_output = fragment.expected_output;

        this.body = fragment;
    }

    render(withMargin=true) {
        return <StaticFragmentComponent fragment_id={this._id} type={this.type} content={this.body} withMargin={withMargin} />
    }
}

class DynamicFragment extends AnyFragment implements IDynamicFragment {
    _id: string;
    type: DynamicFragmentType;
    association_id: string;
    cafe_correction: boolean;
    content: string;
    mcq_choices?: {content:string, correct:boolean}[];
    mcq_multiple?: boolean;
    open_solution?: string;
    open_multiline?: boolean;
    initial_code?: {file:string, before:string, content:string, after:string, secret:boolean}[];
    expected_output?: string;
    body:IDynamicFragment;

    constructor(fragment:IDynamicFragment) {
        super();
        this._id = fragment._id;
        this.type = fragment.type;
        this.content = fragment.content;
        this.cafe_correction = fragment.cafe_correction;
        this.association_id = fragment.association_id;
        if (fragment.mcq_choices)
            this.mcq_choices = fragment.mcq_choices;
        if (fragment.mcq_multiple)
            this.mcq_multiple = fragment.mcq_multiple;
        if (fragment.open_solution)
            this.open_solution = fragment.open_solution;
        if (fragment.open_multiline)
            this.open_multiline = fragment.open_multiline;
        if (fragment.initial_code)
            this.initial_code = fragment.initial_code;
        if (fragment.expected_output)
            this.expected_output = fragment.expected_output;
        this.body = fragment;
    }

    render(withMargin=true) {
        return <DynamicFragmentComponent
                    id={this._id}
                    type={this.type}
                    content={this.body}
                    withMargin={withMargin}
                    />
    }
}

type FragmentsListProps = {
    fragments_ids: string[]; // Fragment pointers
    step: number;
};

const FragmentsList: React.FC<FragmentsListProps> = ({fragments_ids, step}) => {
    const [fragments_components, setFragments] = useState<AnyFragment[]>([]);
    const [fetched, setFetched] = useState<boolean>(false);

    /**
     * Fetches fragments from the database and loads them into the editor
     */
    useEffect(() => {
        if (!fragments_ids)
            return;
        const fetchFragments = async () => {
            const fragmentResults = await getFragments(fragments_ids);
            let reordered_fragments = [];
            // Reorder the fragments so they are in the same order as the pointers
            for (let i = 0; i < fragments_ids.length; i++) {
                const fragment_pointer = fragments_ids[i];
                const fragment = fragmentResults.data.fragments.find((fragment) => fragment._id === fragment_pointer);
                if (fragment)
                    reordered_fragments.push(fragment);
            }
            const loaded_fragments:IAnyFragment[] = await resolveFragmentIds(reordered_fragments);
            const fragments = loaded_fragments.map((any_fragment) => {
                switch(any_fragment.type) {
                    case FragmentType.Static:
                        return new StaticFragment(any_fragment.fragment as IStaticFragment);
                    default:
                        return new DynamicFragment(any_fragment.fragment as IDynamicFragment);
                }
            });
            setFragments(fragments);
            setFetched(true);
        };
        fetchFragments();
    }
    , [fragments_ids]);

    return <>
        <Skeleton isLoaded={fetched}>
            {fragments_components.map((fragment, index) => {
                // Render the first (step) fragments
                if (index < step)
                    return <Box key={index}>
                        {fragment.render(index != 0)}
                    </Box>
            })}
        </Skeleton>

        {!fetched && 
            fragments_components.map((fragment, index) => {
                return <Stack key={index}><Skeleton height='10px' /><Skeleton height='10px' /><Skeleton height='10px' /></Stack>
            })
        }
        
        {fetched && fragments_components.length === 0 && <Center><Text mt={3} color={'gray.400'}>Cette partie est vide.</Text></Center>}

    </>
};


export default FragmentsList;