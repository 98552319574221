import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Box, Button, Center, CircularProgress, Container, Divider, Flex, FormControl, FormLabel, Heading, Input, Menu, MenuButton, MenuDivider, MenuItem, MenuList, Spacer, Stack, Text, Tooltip, useDisclosure } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { DynamicFragmentType, FragmentType, GCModule, IReminder, IResolutionStep, RSModule, ResolutionStepModule, StaticFragmentType } from "../types";
import FragmentsList from "./FragmentsList";
import { AddIcon, CloseIcon, CopyIcon, DeleteIcon, EditIcon, LinkIcon } from "@chakra-ui/icons";
import { addFragmentToReminder, addReminder, deleteFragmentFromReminder, deleteReminder, getReminder, updateReminder } from "../API/reminder";
import { addFragmentToRs, deleteFragmentFromRs, deleteResolutionStep, getResolutionStep, removeReminderFromRs, updateResolutionStep } from "../API/resolution_step";
import Reminder from "./Reminder";
import { reorganizeFragments } from "../API/fragment";
import { fa0, fa1, faAlignLeft, faArrowRight, faCircleNodes, faClipboardQuestion, faCode, faGears, faPlusMinus, faTableList, faTerminal } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type ResolutionStepProps = {
    rs_id:string;
    remove_rs:Function;
    open_reminders_table:Function;
    rs_reload:boolean;
};

const ResolutionStep: React.FC<ResolutionStepProps> = ({rs_id, remove_rs, open_reminders_table, rs_reload}) => {
    const [rs, setRs] = useState<IResolutionStep>({_id: '', gcid: '', title: '', statement: [], reminders: [], questions: [], conclusion: []});
    const { isOpen, onOpen, onClose } = useDisclosure()
    const cancelRef = React.useRef<null>(null);

    useEffect(() => {
        getResolutionStep(rs_id).then((res) => {
            if (res.data.resolution_step)
                setRs(res.data.resolution_step);
        });
    } , [rs_id, rs_reload]);

    const update_title = (title:string) => {
        // Update title in database after setting it in state
        setRs({...rs, title: title});
        updateResolutionStep({... rs, title: title});
    }

    const delete_reminder_callback = () => {
        onOpen();
    }
    
    const confirm_delete_rs = () => {
        onClose();
        deleteResolutionStep(rs_id).then((res) => {
            remove_rs(rs_id);
        });
    }

    const remove_reminder = (reminder_id:string) => {
        setRs({...rs, reminders: rs.reminders.filter((r) => {return r !== reminder_id;})});
        removeReminderFromRs(reminder_id, rs_id);
    }

    const new_reminder = () => {
        addReminder('', rs_id).then(({ data: { reminder } }: any) => {
            setRs({...rs, reminders: [...rs.reminders, reminder._id]});
        });
    }

    const add_static_fragment = (type:StaticFragmentType, rs_module:ResolutionStepModule) => {
        addFragmentToRs(FragmentType.Static, type, rs_id, rs_module).then(({ data: { fragment } }: any) => {
            switch(rs_module) {
                case ResolutionStepModule.STATEMENT:
                    setRs({...rs, statement: [...rs.statement, fragment._id]});
                    break;
                case ResolutionStepModule.QUESTIONS:
                    setRs({...rs, questions: [...rs.questions, fragment._id]});
                    break;
                case ResolutionStepModule.CONCLUSION:
                    setRs({...rs, conclusion: [...rs.conclusion, fragment._id]});
                    break;
            }
        });
    }

    const add_dynamic_fragment = (type:DynamicFragmentType, rs_module:ResolutionStepModule) => {
        addFragmentToRs(FragmentType.Dynamic, type, rs_id, rs_module).then(({ data: { fragment } }: any) => {
            switch(rs_module) {
                case ResolutionStepModule.STATEMENT:
                    setRs({...rs, statement: [...rs.statement, fragment._id]});
                    break;
                case ResolutionStepModule.QUESTIONS:
                    setRs({...rs, questions: [...rs.questions, fragment._id]});
                    break;
                case ResolutionStepModule.CONCLUSION:
                    setRs({...rs, conclusion: [...rs.conclusion, fragment._id]});
                    break;
            }
        });
    }

    const delete_fragment_callback = (id:string, module:ResolutionStepModule) => {
        deleteFragmentFromRs(id, rs._id, module).then((res) => {
            getResolutionStep(rs_id).then((res) => {
                if (res.data.resolution_step)
                    setRs(res.data.resolution_step);
            });
        });
    }

    const reorder_fragments = (reorganized_pointers:string[], module:ResolutionStepModule) => {
        const new_rs = {...rs};
        switch(module) {
            case ResolutionStepModule.STATEMENT:
                new_rs.statement = reorganized_pointers;
                break;
            case ResolutionStepModule.QUESTIONS:
                new_rs.questions = reorganized_pointers;
                break;
            case ResolutionStepModule.CONCLUSION:
                new_rs.conclusion = reorganized_pointers;
                break;
        }
        reorganizeFragments(reorganized_pointers, rs.gcid, GCModule.INTRO, rs_id, module)
            .then((res) => {
                setRs(new_rs);
            }
        );
    }

    return <>
        <Flex>
            <Spacer />
            <Button me={2} size={'sm'} colorScheme='blackAlpha' onClick={() => {delete_reminder_callback();}} rightIcon={<DeleteIcon />} >Supprimer l'étape</Button>
        </Flex>
        <FormControl mb={3} mt={3}>
            <FormLabel>Titre de l'étape</FormLabel>
            <Input type='text' value={rs.title} onChange={(e) => { update_title(e.target.value); } } />
        </FormControl>

        {/* STATEMENT */}

        <Flex minWidth='max-content' alignItems='center' gap='2' mt={5} mb={3}>
            <Heading mt={3} mb={2} as={'h3'} size={'md'} color={'purple.500'}>Description</Heading>
            <Divider variant={'dashed'} />
        </Flex>
        <FragmentsList fragments_ids={rs.statement} delete_callback={(id:string) => { delete_fragment_callback(id, ResolutionStepModule.STATEMENT) }} reorder_callback={(fps:string[]) => { reorder_fragments(fps, ResolutionStepModule.STATEMENT); } } />
        <Flex minWidth='max-content' alignItems='center' gap='2' mt={5}>
            <Spacer />
            <Menu>
                <MenuButton colorScheme='teal' as={Button} rightIcon={<AddIcon />} >
                    <Text fontWeight={'semibold'}>Fragment statique</Text>
                </MenuButton>
                <MenuList>
                    <MenuItem icon={<FontAwesomeIcon icon={faAlignLeft} />} onClick={() => {add_static_fragment(StaticFragmentType.Text, ResolutionStepModule.STATEMENT)}}><Text>Texte et images</Text></MenuItem>
                    
                    <MenuItem icon={<FontAwesomeIcon icon={faTerminal} />} onClick={() => {add_static_fragment(StaticFragmentType.Code, ResolutionStepModule.STATEMENT)}}><Text>Code exécutable</Text></MenuItem>

                    <MenuItem icon={<FontAwesomeIcon icon={fa0} />} onClick={() => {add_static_fragment(StaticFragmentType.Binary, ResolutionStepModule.STATEMENT)}}><Text>Nombre binaire</Text></MenuItem>
                    
                </MenuList>
            </Menu>
        </Flex>

        {/* REMINDERS */}

        <Flex minWidth='max-content' alignItems='center' gap='2' mt={5} mb={3}>
            <Heading mt={3} mb={2} as={'h3'} size={'md'} color={'green.500'}>Rappels</Heading>
            <Divider variant={'dashed'} />
        </Flex>
        {rs.reminders.length === 0 &&
            <Flex alignItems='center' justifyContent='center' flexDirection='column'>
                <Text mb={3} color={'gray.400'}>Cette partie est vide.</Text>
            </Flex>
        }
        {rs.reminders.map((rid:string) => (
            <Reminder key={rid} reminder_id={rid} remove_reminder={remove_reminder} />
        ))}
        
        <Flex minWidth='max-content' alignItems='center' gap='2' mt={5}>
            <Spacer />
            <Menu>
                <Button colorScheme='blue' rightIcon={<LinkIcon />} onClick={() => {
                    open_reminders_table(rs_id);
                }} >Importer</Button>
                <Button colorScheme='green' rightIcon={<EditIcon />} onClick={() => {new_reminder()}} >Nouveau</Button>
            </Menu>
        </Flex>

        {/* QUESTIONS */}

        <Flex minWidth='max-content' alignItems='center' gap='2' mt={5} mb={3}>
            <Heading mt={3} mb={2} as={'h3'} size={'md'} color={'orange.300'}>Questions</Heading>
            <Divider variant={'dashed'} />
        </Flex>
        <FragmentsList fragments_ids={rs.questions} delete_callback={(id:string) => { delete_fragment_callback(id, ResolutionStepModule.QUESTIONS) }} reorder_callback={(fps:string[]) => { reorder_fragments(fps, ResolutionStepModule.QUESTIONS); } }/>
        <Flex minWidth='max-content' alignItems='center' gap='2' mt={5}>
            <Spacer />
            <Menu>
                <MenuButton colorScheme='purple' as={Button} rightIcon={<AddIcon />}>
                <Text fontWeight={'semibold'}>Fragment dynamique</Text>
                </MenuButton>
                <MenuList>
                    <MenuItem icon={<FontAwesomeIcon icon={faClipboardQuestion} />} onClick={() => {add_dynamic_fragment(DynamicFragmentType.MCQ, ResolutionStepModule.QUESTIONS)}}><Text>QCM</Text></MenuItem>
                    
                    <MenuItem icon={<FontAwesomeIcon icon={faCode} />} onClick={() => {add_dynamic_fragment(DynamicFragmentType.SimpleCode, ResolutionStepModule.QUESTIONS)}}><Text>Extrait de code</Text></MenuItem>

                    <MenuItem icon={<FontAwesomeIcon icon={faAlignLeft} />} onClick={() => {add_dynamic_fragment(DynamicFragmentType.Textarea, ResolutionStepModule.QUESTIONS)}}><Text>Champ de texte</Text></MenuItem>
                    
                    <MenuItem icon={<FontAwesomeIcon icon={faTerminal} />} onClick={() => {add_dynamic_fragment(DynamicFragmentType.Code, ResolutionStepModule.QUESTIONS)}}><Text>Code à compléter</Text></MenuItem>
                    
                    <MenuItem icon={<FontAwesomeIcon icon={faTableList} />} onClick={() => {add_dynamic_fragment(DynamicFragmentType.Form, ResolutionStepModule.QUESTIONS)}}><Text>Formulaire</Text></MenuItem>
                    
                    <MenuItem icon={<FontAwesomeIcon icon={faCircleNodes} />} onClick={() => {add_dynamic_fragment(DynamicFragmentType.TextualSP, ResolutionStepModule.QUESTIONS)}}><Text>Découpe textuelle en SP</Text></MenuItem>
                    
                    <MenuItem icon={<FontAwesomeIcon icon={faGears} />} onClick={() => {add_dynamic_fragment(DynamicFragmentType.CodeConstruction, ResolutionStepModule.QUESTIONS)}}><Text>Construction de code</Text></MenuItem>
                    
                    <MenuDivider />
                    
                    <MenuItem icon={<FontAwesomeIcon icon={fa0} />} onClick={() => {add_dynamic_fragment(DynamicFragmentType.DecBin, ResolutionStepModule.QUESTIONS)}}><Text>Décimal <FontAwesomeIcon icon={faArrowRight} /> Binaire</Text></MenuItem>
                    
                    <MenuItem icon={<FontAwesomeIcon icon={fa1} />} onClick={() => {add_dynamic_fragment(DynamicFragmentType.BinDec, ResolutionStepModule.QUESTIONS)}}><Text>Binaire <FontAwesomeIcon icon={faArrowRight} /> Décimal</Text></MenuItem>
                    
                    <MenuItem icon={<FontAwesomeIcon icon={faPlusMinus} />} onClick={() => {add_dynamic_fragment(DynamicFragmentType.Calculus, ResolutionStepModule.QUESTIONS)}}><Text>Calcul écrit</Text></MenuItem>
                </MenuList>
            </Menu>
            <Menu>
                <MenuButton colorScheme='teal' as={Button} rightIcon={<AddIcon />} >
                    <Text fontWeight={'semibold'}>Fragment statique</Text>
                </MenuButton>
                <MenuList>
                    <MenuItem icon={<FontAwesomeIcon icon={faAlignLeft} />} onClick={() => {add_static_fragment(StaticFragmentType.Text, ResolutionStepModule.QUESTIONS)}}><Text>Texte et images</Text></MenuItem>
                    
                    <MenuItem icon={<FontAwesomeIcon icon={faTerminal} />} onClick={() => {add_static_fragment(StaticFragmentType.Code, ResolutionStepModule.QUESTIONS)}}><Text>Code exécutable</Text></MenuItem>

                    <MenuItem icon={<FontAwesomeIcon icon={fa0} />} onClick={() => {add_static_fragment(StaticFragmentType.Binary, ResolutionStepModule.QUESTIONS)}}><Text>Nombre binaire</Text></MenuItem>
                    
                </MenuList>
            </Menu>
        </Flex>
        
        {/* ENDING */}

        <Flex minWidth='max-content' alignItems='center' gap='2' mt={5} mb={3}>
            <Heading mt={3} mb={2} as={'h3'} size={'md'} color={'red.400'}>Conclusion</Heading>
            <Divider variant={'dashed'} />
        </Flex>
        <FragmentsList fragments_ids={rs.conclusion} delete_callback={(id:string) => { delete_fragment_callback(id, ResolutionStepModule.CONCLUSION) }} reorder_callback={(fps:string[]) => { reorder_fragments(fps, ResolutionStepModule.CONCLUSION); } }/>
        <Flex minWidth='max-content' alignItems='center' gap='2' mt={5}>
            <Spacer />
            <Menu>
                <MenuButton colorScheme='teal' as={Button} rightIcon={<AddIcon />} >
                    <Text fontWeight={'semibold'}>Fragment statique</Text>
                </MenuButton>
                <MenuList>
                    <MenuItem icon={<FontAwesomeIcon icon={faAlignLeft} />} onClick={() => {add_static_fragment(StaticFragmentType.Text, ResolutionStepModule.CONCLUSION)}}><Text>Texte et images</Text></MenuItem>
                    
                    <MenuItem icon={<FontAwesomeIcon icon={faTerminal} />} onClick={() => {add_static_fragment(StaticFragmentType.Code, ResolutionStepModule.CONCLUSION)}}><Text>Code exécutable</Text></MenuItem>

                    <MenuItem icon={<FontAwesomeIcon icon={fa0} />} onClick={() => {add_static_fragment(StaticFragmentType.Binary, ResolutionStepModule.CONCLUSION)}}><Text>Nombre binaire</Text></MenuItem>
                    
                </MenuList>
            </Menu>
        </Flex>

        <AlertDialog
            isOpen={isOpen}
            leastDestructiveRef={cancelRef}
            onClose={() => {}}
            >
            <AlertDialogOverlay>
                <AlertDialogContent>
                <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                    Supprimer une étape
                </AlertDialogHeader>
    
                <AlertDialogBody>
                    Attention, l'étape ainsi que tous ses modules seront définitivement supprimés.
                </AlertDialogBody>
    
                <AlertDialogFooter>
                    <Button ref={cancelRef} onClick={onClose}>
                    Annuler
                    </Button>
                    <Button colorScheme='red' onClick={() => {confirm_delete_rs();}} ml={3}>
                    Supprimer
                    </Button>
                </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
    </>
};


export default ResolutionStep;