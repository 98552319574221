import axios, { AxiosResponse } from "axios";
import API, { API_URL } from "./params";
import { ApiDataType, FragmentType, IGamecode } from "../types";

export const getServerLogs = async (): Promise<AxiosResponse<ApiDataType>> => {
	try {
		const logs: AxiosResponse<ApiDataType> = await API.get(
			`${API_URL}/logs/server`
		);
		return logs;
	} catch (error) {
		throw error;
	}
};

export const fixServerLog = async (errorid:string, fixed:boolean): Promise<AxiosResponse<ApiDataType>> => {
	try {
		const logs: AxiosResponse<ApiDataType> = await API.post(
			`${API_URL}/logs/server/fix/${errorid}`,
            {fixed: fixed}
        );

		return logs;
	} catch (error) {
		throw error;
	}
};

export const fixEveryServerLog = async (): Promise<AxiosResponse<ApiDataType>> => {
	try {
		const logs: AxiosResponse<ApiDataType> = await API.post(
			`${API_URL}/logs/server/fix/all`
        );

		return logs;
	} catch (error) {
		throw error;
	}
};

export const deleteFixedServerLog = async (): Promise<AxiosResponse<ApiDataType>> => {
	try {
		const logs: AxiosResponse<ApiDataType> = await API.delete(
			`${API_URL}/logs/server/deletefixed`
        );

		return logs;
	} catch (error) {
		throw error;
	}
};

export const sendHeartbeat = async (fragment_type:FragmentType, fragment_id:string, tab_id:string, gcinstance_id:string): Promise<AxiosResponse<ApiDataType>> => {
	try {
		const logs: AxiosResponse<ApiDataType> = await API.post(
			`${API_URL}/logs/heartbeat`,
			{fragment_id: fragment_id, tab_id: tab_id, fragment_type: fragment_type, gcinstance_id: gcinstance_id}
		);

		return logs;
	} catch (error) {
		throw error;
	}
}

export const logEvent = async (gcinstance_id:string, eventType:any, eventData:any): Promise<AxiosResponse<ApiDataType>> => {
	try {
		const logs: AxiosResponse<ApiDataType> = await API.post(
			`${API_URL}/logs/event`,
			{eventType: eventType, eventData: eventData, gcinstance_id: gcinstance_id}
		);

		return logs;
	} catch (error) {
		throw error;
	}
}