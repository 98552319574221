import { Box, Center, Checkbox, Flex, FormControl, FormHelperText, FormLabel, Input, InputGroup, InputRightAddon, PinInput, PinInputField, Select, Text, VStack } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import AceEditor from 'react-ace'
// import mode-<language> , this imports the style and colors for the selected language.
import 'ace-builds/src-noconflict/mode-c_cpp'
import 'ace-builds/src-noconflict/mode-text'
import 'ace-builds/src-noconflict/theme-dracula'
import 'ace-builds/src-noconflict/theme-chrome'
import 'ace-builds/src-noconflict/ext-language_tools'
import 'ace-builds/src-noconflict/ext-beautify'
import { IDynamicFragment } from "../../../types";
import TextEditor from "../TextEditor";
import { Form } from "react-router-dom";


type CalculusFragmentProps = {
    body: IDynamicFragment;
    update_callback: Function;
};

const CalculusFragment: React.FC<CalculusFragmentProps> = ({body, update_callback}) => {

    const [fragment, setFragment] = useState<IDynamicFragment>(body);
    const [nbBits, setNbBits] = useState<number>(body.calculus?.bits || 8);
    const [operation, setOperation] = useState<string>(body.calculus?.operation || 'add');
    const [solution, setSolution] = useState<string>(body.calculus?.solution || '');

    useEffect(() => {
    }, []);

    const update_content = (new_content:string) => {
        if (!fragment.content)
            fragment.content = "";
        let new_body:IDynamicFragment = {
            ...fragment,
            content: new_content
        }
        setFragment(new_body);
        update_callback(new_body);
    }

    const update_operation = (operation:string) => {
        let new_body:IDynamicFragment = fragment;
        new_body.calculus!.operation = operation;
        setFragment(new_body);
        update_callback(new_body);
        setOperation(operation);
    }

    const update_bits = (bits:number) => {
        if (isNaN(bits))
            return;
        if (bits < 1)
            bits = 1;
        if (bits > 16)
            bits = 16;
        let new_body:IDynamicFragment = fragment;
        new_body.calculus!.bits = bits;
        
        // If the solution is too long, we truncate it
        if (solution.length > bits) {
            new_body.calculus!.solution = new_body.calculus!.solution.slice(0, bits);
            setSolution(new_body.calculus!.solution.slice(0, bits));
        }
        setFragment(new_body);
        update_callback(new_body);
        setNbBits(bits);
    }

    const update_solution = (solution:string) => {
        if (isNaN(parseInt(solution)))
            return;
        if (solution.length > nbBits)
            solution = solution.slice(0, nbBits);
        let new_body:IDynamicFragment = fragment;
        new_body.calculus!.solution = solution;
        setFragment(new_body);
        update_callback(new_body);
        setSolution(solution);
    }

    return <>
        
        <Text mt={2} fontSize={'sm'} fontWeight={'bold'} color={'purple.500'} mb={2} cursor={'default'}>Énoncé</Text>

        <TextEditor content={fragment.content} update_callback={update_content} />

        
        <Text mt={3} fontSize={'sm'} fontWeight={'bold'} color={'purple.500'} mb={2} cursor={'default'}>Format de la réponse</Text>

        <Select value={operation} onChange={(event) => update_operation(event.target.value)}>
            <option value='add'>Addition</option>
            <option value='mul'>Multiplication</option>
        </Select>
        
        <FormControl>
            <FormLabel mt={3} fontSize={'sm'} fontWeight={'bold'} color={'purple.500'} mb={2} cursor={'default'}>Nombre de bits</FormLabel>
            <InputGroup ms={2} size='sm' width={"150px"}>
                <Input value={nbBits} type='number' onChange={(event) => update_bits(parseInt(event.target.value))} />
                <InputRightAddon>
                 bits
                </InputRightAddon>
            </InputGroup>
            <FormHelperText ms={2} fontSize={"xs"}>Maximum 16 bits</FormHelperText>
        </FormControl>

        <FormControl>
            <FormLabel mt={3} fontSize={'sm'} fontWeight={'bold'} color={'purple.500'} mb={2} cursor={'default'}>Réponse attendue</FormLabel>

            {nbBits > 16 &&
                <Input size='md' letterSpacing={"0.5rem"} fontFamily={"JetBrains Mono"} type="number" textAlign={"center"} maxLength={nbBits} value={solution} onChange={(event) => update_solution(event.target.value)} />
            }
            {nbBits <= 16 &&
                <Center>
                    <PinInput size='sm' type="number" value={solution} onChange={(value) => update_solution(value)}>
                        {[...Array(nbBits)].map((_, i) => (
                            <PinInputField ms={1} key={i} />
                        ))}
                    </PinInput>
                </Center>
            }

        </FormControl>
        
    </>
};

export default CalculusFragment;