import { Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { createRoot } from "react-dom/client";
import Latex from "react-latex";

type LatexText = {
    content:string;
};

const LatexText: React.FC<LatexText> = ({content}) => {
    const [latex_content, setLatexContent] = useState<string>('');

    useEffect(() => {
        // Replace everything that is between $...$ with a <span class="latex">...</span>
        const latex_regex = /\$([^\$]*)\$/g;
        const matches = content.matchAll(latex_regex);
        let new_content = content;
        for (const match of matches) {
            const latex_text = match[1];
            new_content = new_content.replace(`$${latex_text}$`, `<span class="latex">${latex_text}</span>`);
        }
        setLatexContent(new_content);
    }, [content]);
    useEffect(() => {
        const latex_elements = document.getElementsByClassName('latex');
        for (let i = 0; i < latex_elements.length; i++) {
            // Check if the element has already been parsed
            if (latex_elements[i].classList.contains('latex-parsed'))
                continue;
            const latex_element = latex_elements[i];
            const latex_text = latex_element.innerHTML;
            latex_element.innerHTML = '';
            createRoot(latex_element).render(<Latex>{'$'+latex_text+'$'}</Latex>);
            latex_element.classList.add('latex-parsed');
        }
    }, [latex_content]);


    return <Text dangerouslySetInnerHTML={{__html:latex_content}} />
}

export default LatexText;