import { Box, Button, Center, Flex, Spacer, Spinner, Tab, TabList, Tabs, Text, Tooltip, useToast } from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import AceEditor from 'react-ace'

// import mode-<language> , this imports the style and colors for the selected language.
import 'ace-builds/src-noconflict/mode-c_cpp'
import 'ace-builds/src-noconflict/mode-text'
import 'ace-builds/src-noconflict/mode-assembly_x86'
import 'ace-builds/src-min-noconflict/theme-dracula'
import 'ace-builds/src-noconflict/ext-language_tools'
import 'ace-builds/src-noconflict/ext-beautify'
import { faArrowsUpDown, faBug, faCheck, faClipboard, faGear, faLock, faRotateRight, faTerminal } from "@fortawesome/free-solid-svg-icons";
import { faComment} from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ICodeFile, IDynamicFragment, IGCAnswer, IGCReport, IStaticFragment } from "../../../types";
import LatexText from "../LatexText";
import { compileCode, getReport, setAnswerReport } from "../../../API/corrector";

type CodeFragmentProps = {
    fragment_id:string;
    body: any;
};

const CodeFragment: React.FC<CodeFragmentProps> = ({fragment_id, body}) => {
    const [compiledContent, setCompiledContent] = useState<ICodeFile[]>([]);
    const [lastCompiledContent, setLastCompiledContent] = useState<String>("");
    const [isCompiling, setIsCompiling] = useState(false);
    const [askedToCompile, setAskedToCompile] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [corrected, setCorrected] = useState(false);
    const [correctionError, setCorrectionError] = useState(false);
    const [correctionErrorMsg, setCorrectionErrorMsg] = useState("");
    const [currentFile, setCurrentFile] = useState(0); // index of the current file in [lastCompiledContent]
    const CHARS_DIFFERENCE_THRESHOLD = 20; // to update in database
    const [editorKey, setEditorKey] = useState(0);
    const toast = useToast()
    const REPORT_INVALIDATION_TIME = 1000 * 60 * 5; // 5 minutes
    const REPORT_CHECK_INTERVAL = 500; // 0.5 secondes
    const [report, setReport] = useState<IGCReport|null>(null);

    const correction_error = async () => {
        setCorrectionError(true);
        setCorrected(false);
        setSubmitted(false);
    }

    const get_report = (report_id:string) => {
        getReport(report_id).then((resp) => {
            const report:IGCReport = resp.data.report;
            if (!report.corrected) {
                // if report is still not corrected 5min after its created_at date,
                // then there is an error (set the error to true)
                if (new Date().getTime() - new Date(report.created_at).getTime() > REPORT_INVALIDATION_TIME) {
                    correction_error();
                    return;
                }
                setSubmitted(true);
                setCorrected(false);
                setCorrectionError(false);
                setTimeout(() => {
                    get_report(report_id);
                }, REPORT_CHECK_INTERVAL);
            } else {
                setCorrected(true);
                setIsCompiling(false);
                setReport(report);
            }
        }).catch((e) => {
            // Report not found
            correction_error();
        });
    }

    const compile = () => {
        setReport(null);
        setIsCompiling(true);
        setAskedToCompile(true);
        compileCode(fragment_id, compiledContent).then((resp) => {
            const report_id = resp.data.id;
            get_report(report_id);
        }).catch((e) => {
            const status = e.response.status;
            const error = e.response.data.error;
            toast({
                title: 'Éditeur de code',
                description: "Une erreur est survenue lors de la compilation de ton code. Erreur "+status+": "+error,
                status: 'error',
                duration: 3000,
                isClosable: true,
                position: 'bottom-right',
            })
            setIsCompiling(false);
            setAskedToCompile(false);
        });
    }

    useEffect(() => {
        if (body.initial_code) {
            const initialCodeCopy = body.initial_code.map((file:any) => ({ ...file }));

            // Move the secret files to the end of the array
            const initial_code = initialCodeCopy;
            const secret_files = initial_code.filter((file:any) => file.secret);
            const non_secret_files = initial_code.filter((file:any) => !file.secret);
            setCompiledContent([...non_secret_files, ...secret_files]);
            setLastCompiledContent(non_secret_files[currentFile].content);
        }
    }, []);

    /**
     * Resets the code to its original content.
     */
    const reset_code = () => {
        if (!body.initial_code)
            return;
        const initialCodeCopy = body.initial_code.map((file:any) => ({ ...file }));

        // Reset code for the current file
        setCompiledContent((prev) => {
            const new_content = [...prev];
            new_content[currentFile].content = initialCodeCopy[currentFile].content;
            return new_content;
        });
        setEditorKey(editorKey + 1); // force the editor to re-render
        setLastCompiledContent(initialCodeCopy[currentFile].content);
    }

    /**
     * Updates the answer in the state and in the database if the answer is different from the original 
     * content by more than [threshold] characters.
     * @param answer new answer 
     * @param force force the update (i.e., on blur)
     */
    const update_answer = (answer:any, force:boolean=false) => {
        if (!force) {
            setCompiledContent((prev) => {
                const new_content = [...prev];
                new_content[currentFile].content = answer;
                return new_content;
            });

            // update_answer if the answer is different from the original content by more than 10 characters
            const diff = answer.length - lastCompiledContent.length;
            if (Math.abs(diff) > CHARS_DIFFERENCE_THRESHOLD) {
                setLastCompiledContent(answer);

                let new_files = [...compiledContent];
                new_files[currentFile].content = answer;
            }
        }
        else {
            if (compiledContent)
                setLastCompiledContent(compiledContent[currentFile].content);
        }
    }

    const copy_full_code = () => {
        if (!compiledContent)
            return;
        let full_code = '';
        full_code += compiledContent[currentFile].before;
        full_code += compiledContent[currentFile].content;
        full_code += compiledContent[currentFile].after;
        navigator.clipboard.writeText(full_code);
        toast({
            title: 'Éditeur de code',
            description: "Le code du fichier "+compiledContent[currentFile].file+" a été copié dans le presse-papier.",
            status: 'success',
            duration: 3000,
            isClosable: true,
            position: 'bottom-right',
        })
    }

    // Editor resizing
    const [editorHeight, setEditorHeight] = useState(250); // Initial height
    const handleRef = useRef(null); // Ref for the handle div

    const startDrag = (e:any) => {
        const startY = e.clientY;
        const initialHeight = editorHeight;

        const handleMouseMove = (e:any) => {
            const deltaY = e.clientY - startY;
            if (initialHeight + deltaY > 200)
                setEditorHeight(initialHeight + deltaY);
        };

        const handleMouseUp = () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
        };

        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
    };

    const file_mode = () => {
        const filename:string = compiledContent[currentFile].file;
        if (filename.endsWith(".c") || filename.endsWith(".h"))
            return 'c_cpp';
        else if (filename.endsWith(".txt"))
            return 'text';
        else if (filename.endsWith(".s"))
            return 'assembly_x86';
        return 'c_cpp';
    }

    return <Box>
        <Text fontSize={'sm'} fontWeight={'bold'} color={'blackAlpha.800'} mb={2} cursor={'default'}>Extrait de code</Text>
        {body.content && body.content != "" && <>
            
            {body.content.length > 0 && 
                <Box mb={2} bgColor={'white'} border={'1pt solid #ededed'} p={2}>
                    <LatexText content={body.content} />
                </Box>
            }

            <Flex shadow={'md'} roundedTopEnd={5} roundedTopStart={5} mt={0} border={'1pt solid gray'} borderBottom={0} bgColor={'#3b394a'} p={2}>
                <Text color='white' fontSize={'sm'} fontWeight={'medium'}>Code C</Text>
            </Flex>
        </>}

        <Flex shadow={'md'} mt={0} border={'1pt solid gray'} borderBottom={0} bgColor={'#222226'} p={0}>
            <Tabs variant='enclosed' index={currentFile} onChange={(index) => {
                setCurrentFile(index);
            }}>
                <TabList>
                    {compiledContent.filter((file) => !file.secret).map((file, index) => {
                        if (!file.secret)
                            return <Tab key={index} fontSize={'sm'} fontFamily={'JetBrains Mono'} p={1} color={'gray'} _selected={{ color: 'white' }}><Text>{file.file}</Text></Tab>
                    })}
                </TabList>
            </Tabs>
            {/* <Spacer />
            <Box pe={2} pt={1} cursor={'pointer'}>
                <FontAwesomeIcon color='white' icon={faExpand} />
            </Box> */}
        </Flex>

        <Box
            border="1pt solid gray"
            borderTop={0}
        >
        {compiledContent && compiledContent.length > 0 && currentFile < compiledContent.length && <>

            {compiledContent[currentFile].before != '' &&
                <Tooltip label={<FontAwesomeIcon icon={faLock} />} p={3} placement='right' hasArrow>
                    <Box onClick={() => {
                        toast({
                            title: 'Éditeur de code',
                            description: "Tu ne peux pas modifier cette partie du code.",
                            status: 'warning',
                            duration: 3000,
                            isClosable: true,
                            position: 'bottom-right',
                        })
                    }}>
                        <AceEditor height={100+"px"} width="100%" mode={file_mode()} fontSize={14} 
                            value={compiledContent[currentFile].before}
                            theme={'dracula'}
                            maxLines={Infinity}
                            readOnly={true}
                            highlightActiveLine={false}
                            style={{opacity: 0.9}}
                        />
                    </Box>
                </Tooltip>
            }
            <Box position={'relative'}>
                <Box
                    onMouseDown={startDrag}
                    ref={handleRef}
                    color={'white'}
                    style={{
                        position: 'absolute',
                        bottom: 0,
                        right: 0,
                        width: '20px',
                        height: '20px',
                        cursor: 'nwse-resize',
                        zIndex: 1000,
                    }}
                >
                <Center>
                    <FontAwesomeIcon icon={faArrowsUpDown} />
                </Center>
                </Box>
                    <Box
                        borderTop={"1pt solid gray"} borderBottom={"1pt solid gray"}
                        onClick={() => {
                            if (submitted && !corrected)
                                toast({
                                    title: 'Éditeur de code',
                                    description: "Tu ne peux pas modifier ton code, il est en cours de correction...",
                                    status: 'warning',
                                    duration: 3000,
                                    isClosable: true,
                                    position: 'bottom-right',
                                })
                            if (isCompiling)
                                toast({
                                    title: 'Éditeur de code',
                                    description: "Tu ne peux pas modifier ton code, il est en cours de compilation...",
                                    status: 'warning',
                                    duration: 3000,
                                    isClosable: true,
                                    position: 'bottom-right',
                                })
                        }}
                        style={{ height: editorHeight + 'px' }}>
                        <AceEditor height={editorHeight+"px"} width="100%" mode={file_mode()} fontSize={14}
                            key={editorKey}
                            value={compiledContent[currentFile].content}
                            theme={'dracula'}
                            readOnly={isCompiling || (submitted && !corrected)}
                            style={{opacity: (isCompiling || (submitted && !corrected)) ? 0.8 : 1}}
                            onChange={(value) => {
                                update_answer(value);
                            }}
                            onBlur={(value) => {update_answer(value, true);}}
                        />
                    </Box>
            </Box>
            {compiledContent[currentFile].after != '' &&
                <Tooltip label={<FontAwesomeIcon icon={faLock} />} p={3} placement='right' hasArrow>
                    <Box onClick={() => {
                        toast({
                            title: 'Éditeur de code',
                            description: "Tu ne peux pas modifier cette partie du code.",
                            status: 'warning',
                            duration: 3000,
                            isClosable: true,
                            position: 'bottom-right',
                        })
                    }}>
                        <AceEditor height={100+"px"} width="100%" mode={file_mode()} fontSize={14} 
                            value={compiledContent[currentFile].after}
                            theme={'dracula'}
                            readOnly={true}
                            maxLines={Infinity}
                            highlightActiveLine={false}
                            style={{opacity: 0.9}}
                        />
                    </Box>
                </Tooltip>
            }
        </>}
        </Box>
        <Flex shadow={'md'} roundedBottomEnd={5} roundedBottomStart={5} mt={0} border={'1pt solid gray'} borderTop={0} bgColor={'#F0F0F0'} p={2}>
            <Tooltip label="Réinitialiser ce fichier" p={3} placement='top' hasArrow>
                <Button isDisabled={isCompiling || (submitted && !corrected)} variant='outline' colorScheme={'blackAlpha'} size={'md'}
                    onClick={() => {
                        reset_code();
                    }} me={2}
                ><FontAwesomeIcon icon={faRotateRight} /></Button>
            </Tooltip>
            <Tooltip label="Copier le fichier" p={3} placement='top' hasArrow>
                <Button variant='outline' colorScheme={'blackAlpha'} size={'md'}
                    onClick={() => {
                        copy_full_code();
                    }} me={2}
                ><FontAwesomeIcon icon={faClipboard} /></Button>
            </Tooltip>
            <Spacer />

            {body.compilable && 
                <Button variant={'outline'} isDisabled={isCompiling || (submitted && !corrected)}  colorScheme={'facebook'} size={'md'} rightIcon={isCompiling ? <Spinner size={'sm'} /> : <FontAwesomeIcon icon={faGear} />}
                onClick={() => {compile()}}
                >{isCompiling ? 'Compilation...' : 'Compiler'}</Button>
            }
        </Flex>
        
        {/* {askedToCompile &&
        <>
        <Flex roundedTopEnd={5} border={'1pt solid gray'} borderBottom={0} roundedTopStart={5} mt={2} bgColor={'blackAlpha.100'} p={1}>
            <Text fontSize={'sm'} fontWeight={'medium'}><FontAwesomeIcon icon={faTerminal} style={{marginRight:'5px'}} />Output</Text>
        </Flex>
        <Box minH={50} roundedBottomEnd={5} roundedBottomStart={5} bgColor={'#1f2129'} border={'1pt solid gray'} color={'white'} p={2} className="ace_editor">
            {isCompiling && 
            <Text>
                Compilation en cours...
            </Text>
            }
            {!isCompiling && 
            <Text>
                Hello, World!
            </Text>
            }
        </Box>
        </>} */}

        {!correctionError && submitted &&
        <>
        <Box>
            <Flex roundedTopEnd={5} border={'1pt solid gray'} borderBottom={0} roundedTopStart={5} mt={2} bgColor={'#F0F0F0'} p={1}>
                <Text fontSize={'sm'} fontWeight={'medium'}><FontAwesomeIcon icon={faTerminal} style={{marginRight:'5px'}} />Output</Text>
            </Flex>
            <Box overflowY={"scroll"} maxH={500} minH={50} roundedBottomEnd={5} roundedBottomStart={5} bgColor={'#1f2129'} border={'1pt solid gray'} color={'white'} p={2} className="ace_editor">
                {!corrected && 
                <Text>
                    Compilation en cours...
                </Text>
                }
                {corrected && <>
                    {report?.output?.suspicious && <>
                        <Text color={'orange.400'}>
                            Ton programme a été compilé avec succès, mais une erreur est survenue lors de son exécution.<br/>Voici l'output du correcteur :
                        </Text>
                        
                        <Text dangerouslySetInnerHTML={{__html:report?.output?.compilation_output}}>
                        </Text>
                    </>}
                    {!report?.output?.suspicious && report?.output?.compilation_failed && <>
                        <Text color={'red.400'}>
                            Une erreur est survenue lors de la compilation de ton programme.<br/>Voici l'output du compilateur :
                        </Text>
                        <Text dangerouslySetInnerHTML={{__html:report?.output?.compilation_output}}>
                        </Text>
                    </>}
                    
                    {!report?.output?.suspicious && report?.output?.program_crashed && <>
                        <Text color={'red'}>
                            Une erreur est survenue lors de l'exécution de ton programme.
                        </Text>
                    </>}

                    <Text dangerouslySetInnerHTML={{__html:report?.output?.program_output ?? ""}}>
                    </Text>
                </>
                }
            </Box>
        </Box>
        </>}
        {/* {!correctionError && submitted && corrected && 
            <Box>
                <Flex roundedTopEnd={5} border={'1pt solid gray'} borderBottom={0} roundedTopStart={5} mt={2} bgColor={'#F0F0F0'} p={1}>
                    <Text fontSize={'sm'} fontWeight={'medium'}><FontAwesomeIcon icon={faComment} /> Feedback</Text>
                </Flex>
                <Box minH={50} roundedBottomEnd={5} roundedBottomStart={5} border={'1pt solid gray'} p={2} className="ace_editor" bgColor={'white'}>
                    <Text>
                        {report?.feedback}
                    </Text>
                </Box>
            </Box>
        } */}
        {correctionError &&
            <Box>
                <Flex roundedTopEnd={5} border={'1pt solid gray'} borderBottom={0} roundedTopStart={5} mt={2} bgColor={'blackAlpha.100'} p={1}>
                    <Text fontSize={'sm'} fontWeight={'medium'}><FontAwesomeIcon icon={faBug} /> Erreur</Text>
                </Flex>
                <Box minH={50} roundedBottomEnd={5} roundedBottomStart={5} border={'1pt solid gray'} p={2} className="ace_editor">
                    <Text>
                        Il y a eu une erreur lors de la correction de ton code. <br />Merci de réessayer plus tard :(
                    </Text>
                    {correctionErrorMsg != "" &&
                    <Text mt={2} color={'red'}>
                        CAFÉ a renvoyé l'erreur suivante : {correctionErrorMsg}
                    </Text>
                    }
                </Box>
            </Box>
        } 
    </Box>
};

export default CodeFragment;