import axios, { AxiosResponse } from "axios";
import API, { API_URL } from "./params";
import { ApiDataType, GCModule, ResolutionStepModule } from "../types";

/**
 * GET request to the server to get a given module current step.
 */
export const getModuleProgress = async (gcid:string, module:GCModule): Promise<AxiosResponse<ApiDataType>> => {
    try {
		const gamecodes: AxiosResponse<ApiDataType> = await API.post(
			`${API_URL}/instance/progress/module/${gcid}`,
            {
                module: module
            }
		);
		return gamecodes;
	} catch (error) {
		throw error;
	}
};

/**
 * Updates the progress of a given module.
 * @param gcid ID of the gamecode
 * @param module Module of the gamecode to update
 * @param fragment_id ID of the fragment (pointer, NOT the precise fragment)
 * @returns 
 */
export const updateModuleProgress = async (
	gcid:string, 
	module:GCModule, 
	fragment_id:string
	): Promise<AxiosResponse<ApiDataType>> => {
	try {
		const gamecodes: AxiosResponse<ApiDataType> = await API.post(
			`${API_URL}/instance/progress/module/update/${gcid}`,
			{
				module: module,
				fragment_id: fragment_id
			}
		);
		return gamecodes;
	} catch (error) {
		throw error;
	}
};

export const getRsModuleProgress = async (
	gcid:string, 
	rs_id:string,
	rs_module:ResolutionStepModule): Promise<AxiosResponse<ApiDataType>> => {
	try {
		const gamecodes: AxiosResponse<ApiDataType> = await API.post(
			`${API_URL}/instance/progress/module/${gcid}`,
			{
				module: GCModule.RESOLUTION_STEPS,
				rs_id: rs_id,
				rs_module: rs_module
			}
		);
		return gamecodes;
	} catch (error) {
		throw error;
	}
}

export const updateRsModuleProgress = async (
	gcid:string, 
	fragment_id:string, 
	rs_id:string,
	rs_module:ResolutionStepModule): Promise<AxiosResponse<ApiDataType>> => {
	try {
		const gamecodes: AxiosResponse<ApiDataType> = await API.post(
			`${API_URL}/instance/progress/module/update/${gcid}`,
			{
				module: GCModule.RESOLUTION_STEPS,
				fragment_id: fragment_id,
				rs_id: rs_id,
				rs_module: rs_module
			}
		);
		return gamecodes;
	} catch (error) {
		throw error;
	}
}

export const getInstanceProgress = async (
	gcid:string
	): Promise<AxiosResponse<ApiDataType>> => {
	try {
		const gamecodes: AxiosResponse<ApiDataType> = await API.post(
			`${API_URL}/instance/progress/total/${gcid}`
		);
		return gamecodes;
	} catch (error) {
		throw error;
	}
}

export const getRsProgress = async (
	gc_slug:string
	): Promise<AxiosResponse<ApiDataType>> => {
	try {
		const gamecodes: AxiosResponse<ApiDataType> = await API.post(
			`${API_URL}/instance/progress/rs/${gc_slug}`
		);
		return gamecodes;
	} catch (error) {
		throw error;
	}
}

export const getRightAnswersPerRs = async (
	gc_slug:string
	): Promise<AxiosResponse<ApiDataType>> => {
	try {
		const gamecodes: AxiosResponse<ApiDataType> = await API.get(
			`${API_URL}/instance/progress/rightanswers/${gc_slug}`
		);
		return gamecodes;
	} catch (error) {
		throw error;
	}
}

export const resetInstance = async (
	gcid:string
	): Promise<AxiosResponse<ApiDataType>> => {
	try {
		const gamecodes: AxiosResponse<ApiDataType> = await API.get(
			`${API_URL}/instance/reset/${gcid}`
		);
		return gamecodes;
	} catch (error) {
		throw error;
	}
}

export const getCurrentInstanceId = async (
	slug:string
	): Promise<AxiosResponse<ApiDataType>> => {
	try {
		const gamecodes: AxiosResponse<ApiDataType> = await API.get(
			`${API_URL}/instance/current/${slug}`
		);
		return gamecodes;
	} catch (error) {
		throw error;
	}
}