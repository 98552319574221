import React, { useEffect, useState } from 'react';
import { Box, Center, Container, Divider, Heading, Hide, Input, Text, Tooltip } from '@chakra-ui/react';
import {Image as ChakraImage} from '@chakra-ui/react';
import progress_path from './resources/progress_path.svg'
import pawn from './resources/pawn.svg'
import pawn_blue from './resources/pawn_blue.svg'
import pawn_red from './resources/pawn_red.svg'
import GCListElement, { GCState } from './GCListElement';
import GCListElementLoading from './GCListElementLoading';
import { MainLayout } from './MainLayout';
import { GCModule, IClass, IGamecode } from './types';
import { getGamecodes } from './API/gamecode';
import game_night from './resources/game_together.svg'
import Logger from './Logger';
import PageVisibility, { usePageVisibility } from 'react-page-visibility';
import { useUser } from './UserContext';
import { getClasses } from './API/class';

function littlePawns() {
    return <Center marginBottom={0} paddingBottom={0}>
                <Box width='780px' height={'90px'} bgImage={progress_path} bgRepeat={'no-repeat'} bgSize={'100%'} margin={0}>
                    <Hide below='md'>
                        <Box position={'relative'} left='-20px' top='50px' margin={0} padding={0} zIndex={100}>
                            <Tooltip label="Géraldine" placement='top' bg={'green.500'} hasArrow>
                                <img className='hvr-rotate' src={pawn} width='20px' height='30px'/>
                            </Tooltip>
                        </Box>
                        <Box position={'relative'} right='-60px' top='-10px' margin={0} padding={0} zIndex={100}>
                            <Tooltip label="Lev" placement='top' bg={'blue.500'} hasArrow>
                                <img className='hvr-rotate' src={pawn_blue} width='20px' height='30px' />
                            </Tooltip>
                        </Box>
                        <Box position={'relative'} left='20px' top='10px' margin={0} padding={0} zIndex={100}>
                            <Tooltip label="Benoit" placement='top' bg={'red.500'} hasArrow>
                                <img className='hvr-rotate' src={pawn_red} width='20px' height='30px' />
                            </Tooltip>
                        </Box>
                    </Hide>
                </Box>
            </Center>
}

{/* <Center marginBottom={0} paddingBottom={0}>
    <Box transform={'scaleX(-1);'} width='780px' height={'90px'} bgImage={progress_path} bgRepeat={'no-repeat'} bgSize={'100%'} margin={0}></Box>
</Center> */}

// function useOnScreen(ref: any) {

//     const [isIntersecting, setIntersecting] = useState(false);

//     const observer = new IntersectionObserver(
//         ([entry]) => {
//             setIntersecting(entry.isIntersecting);
//         }
//     );

//     useEffect(() => {
//         observer.observe(ref.current);
//         return () => { observer.disconnect(); };
//     }, []);

//     return isIntersecting;
// }

// const DummyComponent: React.FC = () => {
//     const ref = React.useRef<HTMLDivElement>(null);
//     const isVisible = useOnScreen(ref);

//     useEffect(() => {
//         console.log("isVisible", isVisible);
//     }, [isVisible]);

//     return <PageVisibility onChange={(isVisible) => console.log("page visible:", isVisible)}>
//     <div ref={ref} style={{width:"100%", height: '150px', backgroundColor: isVisible ? 'red' : 'blue'}}></div>
//     </PageVisibility>
// }

const Gamecodes: React.FC = () => {
    const {user, setUser} = useUser();
    const [classes, setClasses] = useState<IClass[]>([]);

    useEffect(() => {
        // Images preload
        new Image().src = progress_path;
        new Image().src = pawn;
        new Image().src = pawn_blue;
        new Image().src = pawn_red;

        new Image().src = game_night;

        getClasses().then(({ data: { classes } }: any) => {
            setClasses(classes);
        }).catch((error) => {});
    }, []);

    const [gamecodes, setGamecodes] = useState<IGamecode[]>([]);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        getGamecodes().then(({ data: { gamecodes } }: any) => {
            setGamecodes(gamecodes);
            setLoading(false);
        });
    }, []);

    const now = new Date();

    return (
        <MainLayout>
        <Container maxWidth={'4xl'} marginBlock={5}>
            <Center mb={3}><Heading as={'h3'} fontWeight={700} size={'2xl'} color={'gray.900'}>Gamecodes</Heading></Center>

            {classes.map((c: IClass) => {
                return <Box mt={10}>
                    <Heading as={'h3'} fontWeight={800} size={'lg'} color={'gray.700'}>{c.name}</Heading>
                    {!loading && gamecodes.filter(
                        (gamecode: IGamecode) => gamecode.class == c.code
                    ).map((gamecode: IGamecode, index: number) => {
                        if ((gamecode.visible && new Date(gamecode.startline) < now) || (user?.rank == "admin"))
                            return <GCListElement
                                key={gamecode._id}
                                visible={gamecode.visible && new Date(gamecode.startline) < now}
                                title={gamecode.title}
                                slug={gamecode.slug}
                                description={gamecode.description}
                                state={gamecode.visible ? GCState.ACCESSIBLE : GCState.LOCKED }
                                alignment={'left'}
                                gcid={gamecode._id}
                                index={index+1}
                                module={GCModule.INTRO}
                            />
                    })}
                </Box>
            })}

            {loading && [1, 2, 3].map((i) => <GCListElementLoading key={i} />)}

            {/* If there are no visible gamecodes, */}
            {!loading && gamecodes.filter((gamecode: IGamecode) => (gamecode.visible && new Date(gamecode.startline) < now) || 
                (user?.rank == "admin")
            ).length == 0 && <>
                <Center m={4}>
                    <ChakraImage height={'160px'} src={game_night} alt='Aucun Gamecode' />
                </Center>

                <Center m={4} mt={8}>
                    <Text align={"center"}>Il n'y a aucun Gamecode à afficher. <br/><br/>Si tu es inscrit·e aux cours "Introduction à la programmation" ou "Organisation des ordinateurs" et que tu ne vois aucun Gamecode apparaitre, rends-toi dans l'onglet « Aide ».</Text>
                </Center>
            </>}

        </Container>

        <Box height={"550px"} />
        </MainLayout>
    );
}

export default Gamecodes;