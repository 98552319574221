import { Box, Collapse, Text, useDisclosure, useToast } from "@chakra-ui/react";
import TextFragment from "../static/TextFragment";
import { DynamicFragmentType, FragmentType, IDynamicFragment, IGCAnswer, IGCReport } from "../../../types";
import MCQFragment from "./MCQFragment";
import { correctAnswer, createAnswer, getLatestAnswer, getReport, sendAnswerToCafe, setAnswerReport, updateAnswer } from "../../../API/corrector";
import CodeFragment from "./CodeFragment";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import JSConfetti from "js-confetti";
import FormFragment from "./FormFragment";
import LatexText from "../LatexText";
import { faLightbulb } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ggSound_mp3 from "../../../resources/sound/gg.mp3"
import wrongSound_mp3 from "../../../resources/sound/wrong.mp3"
import TextualSP from "./TextualSP";
import CodeConstructFragment from "./CodeConstructFragment";
import SimpleCodeFragment from "./SimpleCodeFragment";
import TextareaFragment from "./TextareaFragment";
import HeartbeatController from "../HeartbeatController";
import DecBinFragment from "./DecBinFragment";
import BinDecFragment from "./BinDecFragment";
import CalculusFragment from "./CalculusFragment";
import Logger from "../../../Logger";
import { TabContext } from "../../../TabContext";

type DynamicFragmentProps = {
    id: string;
    type: DynamicFragmentType;
    content?: IDynamicFragment;
    withMargin?: boolean;
  };

const DynamicFragmentComponent: React.FC<DynamicFragmentProps> = ({id, type, content, withMargin=true}) => {
    const gc_params = useParams();
    const toast = useToast();
    const gc_slug = (gc_params.slug || '') as string;
    const jsConfetti = new JSConfetti();

    const [latestAnswer, setLatestAnswer] = useState<IGCAnswer|null>(null);

    const gg_sound = new Audio(ggSound_mp3);
    const wrong_sound = new Audio(wrongSound_mp3);

    /**
     * Check the answer of the user and returns the ID of the report
     */
    const check_answer = async (answer:any) => {
        try {
            if (!content?.cafe_correction) {
                // Send to API and await for correction
                correctAnswer(id, gc_slug, answer).then((resp) => {
                    const db_answer:IGCAnswer = resp.data.answer;
                    if (type != DynamicFragmentType.TextualSP) {
                        if (db_answer.correct) {
                            gg_sound.play();
                            jsConfetti.addConfetti({confettiRadius: 4,});
                        } else {
                            wrong_sound.play();
                        }
                    }
                    setLatestAnswer(db_answer);
                }).catch((e) => {
                });
            } else {
                // Create new answer entry
                /**
                 * If an answer already exists, it will be updated with the new value.
                 */
                const answer_resp = await createAnswer(id, gc_slug, answer);
                const answer_id = answer_resp.data.answer._id;

                // Send to CAFE and await for report
                const cafe_resp = await sendAnswerToCafe(id, answer_id);
                const report_id = cafe_resp.data.id;
                return report_id;
            }
        } catch (error:any) {
            // Handle any errors here, e.g., log them or throw them further
            console.error('[DynFracComp] An error occurred:', error);
            toast({
                title: 'Erreur serveur',
                description: "Une erreur est survenue lors de la correction de votre réponse. Veuillez réessayer plus tard ou bien contactez l'équipe pédagogique. Merci !",
                status: 'error',
                duration: 5000,
                isClosable: true,
                position: 'bottom-right',
            })
            throw error; // Re-throw the error for higher-level error handling
        }
    };

    const update_answer = (answer:any) => {
        updateAnswer(id, gc_slug, answer).then((resp) => {
            const event = new Event('RS_PROGRESS_UPDATED');
            document.dispatchEvent(event);
        }).catch((e) => {});
    }

    // Get the latest answer for this fragment
    useEffect(() => {
        getLatestAnswer(id, gc_slug).then((response) =>{
            if (response.data.answer) {
                const latest_answer:IGCAnswer = response.data.answer;
                setLatestAnswer(latest_answer);
            }
        });
    }, []);

    const [displayedHintsCount, setDisplayedHintsCount] = useState<number>(0);
    const currentTab = useContext(TabContext).currentTab;
    const display_hint = () => {
        if (displayedHintsCount < (content?.hints?.length || 0)) {
            Logger.logEvent(Logger.EventType.DISPLAY_HINT, {dynamic_fragment_id: id, hint_id:displayedHintsCount+1, slug: gc_slug, tab_id: currentTab});
            setDisplayedHintsCount(displayedHintsCount + 1);
        }
    }

    let fragment:JSX.Element;
    switch (type) {
        case DynamicFragmentType.MCQ:
            fragment = <MCQFragment 
                            body={content!} 
                            check_answer={check_answer} 
                            display_hint_callback={display_hint}
                            latest_answer={latestAnswer}
                            />;
            break;
        case DynamicFragmentType.Form:
            fragment = <FormFragment 
                            body={content!} 
                            check_answer={check_answer} 
                            display_hint_callback={display_hint} 
                            update_answer_callback={update_answer} 
                            latest_answer={latestAnswer} />;
            break;
        case DynamicFragmentType.Code:
            fragment = <CodeFragment 
                            body={content!} 
                            display_hint_callback={display_hint} 
                            latest_answer={latestAnswer}
                            update_answer_callback={update_answer} 
                            check_answer_callback={check_answer} />;
            break;
        case DynamicFragmentType.TextualSP:
            fragment = <TextualSP 
                            body={content!} 
                            display_hint_callback={display_hint} 
                            latest_answer={latestAnswer}
                            update_answer_callback={update_answer}
                            check_answer={check_answer} />;
            break;
        case DynamicFragmentType.CodeConstruction:
            fragment = <CodeConstructFragment 
                            body={content!} 
                            check_answer={check_answer} 
                            display_hint_callback={display_hint} 
                            update_answer_callback={update_answer} 
                            latest_answer={latestAnswer} />;
            break;
        case DynamicFragmentType.SimpleCode:
            fragment = <SimpleCodeFragment
                            body={content!}
                            display_hint_callback={display_hint} 
                            latest_answer={latestAnswer}
                            update_answer_callback={update_answer} 
                        />;
            break;
        case DynamicFragmentType.Textarea:
            fragment = <TextareaFragment
                            body={content!}
                            display_hint_callback={display_hint} 
                            latest_answer={latestAnswer}
                            update_answer_callback={update_answer} 
                        />;
            break;
        case DynamicFragmentType.DecBin:
            fragment = <DecBinFragment
                            body={content!}
                            update_answer_callback={update_answer}
                            display_hint_callback = {display_hint}
                            latest_answer={latestAnswer}
                            check_answer={check_answer} 
                        />;
            break;
        case DynamicFragmentType.BinDec:
                            fragment = <BinDecFragment
                            body={content!}
                            update_answer_callback={update_answer}
                            display_hint_callback = {display_hint}
                            latest_answer={latestAnswer}
                            check_answer={check_answer} 
                        />;
            break;
        case DynamicFragmentType.Calculus:
                            fragment = <CalculusFragment
                            body={content!}
                            update_answer_callback={update_answer}
                            display_hint_callback = {display_hint}
                            latest_answer={latestAnswer}
                            check_answer={check_answer} 
                        />;
            break;
        default:
            fragment = <TextFragment content={"FRAGMENT INCONNU"} />;
            break;
    }

    return <>
        <HeartbeatController fragment_id={id} fragment_type={FragmentType.Dynamic}>
            <Box mt={withMargin ? 10 : 0}>{fragment}</Box>
            {content?.hints && content?.hints.map((hint, index) => {
                if (index < displayedHintsCount) {
                    return <Box key={index} mt={5} mb={5} p={5} pt={2} bgColor={'green.100'} rounded={6}>
                        <Text ps={2} pt={2} pb={1} fontWeight={'bold'} fontSize={'sm'} color={'green.800'} mb={2}><FontAwesomeIcon icon={faLightbulb} /> {hint.title}</Text>
                        <Box border={"1pt solid #e6e6e6"} bgColor={'white'} textAlign={"justify"} p={3} rounded={5}>
                            <LatexText content={hint.content} />
                        </Box>
                    </Box>;
                }
            })}
        </HeartbeatController>
    </>;
};


export default DynamicFragmentComponent;