import React, { useEffect, useState } from "react";
import { Box, Button, Flex, Image, Input, Spacer, Stack, Text, Tooltip, useDisclosure } from '@chakra-ui/react';
import { CheckIcon } from '@chakra-ui/icons';
import { IDynamicFragment, IGCAnswer } from '../../../types';
import LatexText from "../LatexText";
import HintBulb from "./HintBulb";
import { updateAnswer } from "../../../API/corrector";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestion } from "@fortawesome/free-solid-svg-icons";

type FormFragmentProps = {
    body: IDynamicFragment;
    check_answer: Function;
    display_hint_callback: Function;
    update_answer_callback: Function;
    latest_answer: IGCAnswer | null;
};

const FormFragment: React.FC<FormFragmentProps> = ({body, check_answer, display_hint_callback, update_answer_callback, latest_answer}) => {
    const gg:string[] = [
        "Bien joué !",
        "Bravo !",
        "Génial !",
        "Parfait !",
        "Super !",
        "Très bien !",
    ];
    const gg_index = Math.floor(Math.random() * gg.length);
    const bad:string[] = [
        "Mauvaise réponse...",
        "Oups...",
        "Dommage..."
    ];
    const bad_index = Math.floor(Math.random() * bad.length);
    const [fb_sentence, setFbSentence] = useState<string>('');
    
    const [submitted, setSubmitted] = useState<boolean>(false);
    const [correct, setCorrect] = useState<boolean>(false);
    const [corrected, setCorrected] = useState<boolean>(false);
    const [form, setForm] = React.useState<IDynamicFragment>(body);

    // create body.content.length answers with "" as default value
    const [answers, setAnswers] = React.useState<string[]>(Array.from({
        length: body.form_entries ? body.form_entries.length : 0}, 
        () => ""
    ));
    const [previousAnswers, setPreviousAnswers] = React.useState<string[]>(Array.from({
        length: body.form_entries ? body.form_entries.length : 0}, 
        () => ""
    ));

    useEffect(() => {
        if (latest_answer) {
            setAnswers(latest_answer.answer);
            setPreviousAnswers(latest_answer.answer);
            setSubmitted(latest_answer.submitted);
            setCorrect(latest_answer.correct);
            if (latest_answer.submitted)
                setCorrected(true);

            if (latest_answer.correct) {
                setFbSentence(gg[gg_index]);
            } else {
                setFbSentence(bad[bad_index]);
            }
        }
    }, [latest_answer]);

    const check_answer_wrapper = () => {
        // if at least one answer has been checked, check the answer
        // const checked_answers = answers.filter((answer) => answer.checked);
        if (!submitted) {
            check_answer(answers);
            setSubmitted(true);
        }
    }

    const update_entry = (index:number, value:string) => {
        if (value === previousAnswers[index]) {
            return;
        }
        const new_answers = [...answers];
        new_answers[index] = value;
        setAnswers(new_answers);
        update_answer_callback(new_answers);
    }

    return <>
    <Text fontSize={'sm'} fontWeight={'bold'} color={'blackAlpha.800'} mb={2} cursor={'default'}>À toi de jouer !</Text>
    <Box shadow={'md'} bgColor={'#F0F0F0'} rounded={8} pt={2}>
        <Box rounded={8} m={4} p={4} bgColor={'white'} border={'1pt solid #e6e6e6'} mb={5} color={'black'}>
            <LatexText content={body.content} />
        </Box>
        <Stack spacing={5} direction={'column'} ps={7} pe={7}>
            {!submitted && form.form_entries?.map((entry, index) => {
                return <Flex key={index}>
                    <Text width={'50%'} fontFamily={'Jetbrains Mono'}>{entry}</Text>
                    <Input
                        focusBorderColor='blue.200'
                        width={'50%'}
                        defaultValue={answers[index]}
                        bgColor={'white'}
                        fontFamily={'Jetbrains Mono'}
                        onBlur={(e:any) => { update_entry(index, e.target.value); }}
                        onChange={(e:any) => { 
                            const new_answers = [...answers];
                            new_answers[index] = e.target.value;
                            setAnswers(new_answers);
                        }}
                    />
                </Flex>
            })}
            {submitted && form.form_entries?.map((entry, index) => {
                return <Flex key={index}>
                <Text width={'50%'} fontFamily={'Jetbrains Mono'}>{entry}</Text>
                <Input
                    borderColor={(answers[index] == (body.form_solutions ? body.form_solutions[index] : "")) ? 'green.500' : 'red.500'}
                    focusBorderColor='blue.200'
                    width={'50%'}
                    defaultValue={answers[index]}
                    bgColor={'white'}
                    isReadOnly={true}
                    fontFamily={'Jetbrains Mono'}
                />
            </Flex>
            })}
        </Stack>

        <Flex roundedBottomEnd={8} roundedBottomStart={8} mt={4} bgColor={'blackAlpha.100'} p={4}>
            {submitted && corrected &&
                <Text mt={2} 
                    cursor={'default'} fontSize={'md'} fontWeight={600} 
                    color={correct ? 'green.500' : 'red.500'}>
                    <i style={{marginBottom:'5px', marginRight:'5px'}} className={correct ? "em em-svg em-star-struck" : "em em-svg em-disappointed"}></i>
                    {fb_sentence}</Text>
            }

            <Spacer />

            {/* <Tooltip p={3} hasArrow label={submitted ? "" : "Tu ne peux plus répondre à la question si tu cliques sur ce bouton"} placement="top">
                <Button isDisabled={submitted && !corrected} me={2} variant='outline' colorScheme={'blackAlpha'} size={'md'} rightIcon={<FontAwesomeIcon icon={faQuestion} />} onClick={() => {}}>Voir l'explication</Button>
            </Tooltip> */}

            <HintBulb display_hint_callback={display_hint_callback} hints_nb={body.hints?.length || 0} />
            
            <Button isDisabled={submitted} variant='outline' colorScheme={'green'} size={'md'} rightIcon={<CheckIcon />}
                onClick={
                    () => {
                        check_answer_wrapper();
                    }
                }
            >Corriger</Button>
        </Flex>
    </Box>
    </>

};


export default FormFragment;