import React from "react";
import TextEditor from '../TextEditor';
import { Box, FormControl, FormLabel, Input, InputGroup, InputRightAddon, Select, Text } from "@chakra-ui/react";

type BinaryFragmentProps = {
    content?: any;
    update_callback: Function;
};

const BinaryFragment: React.FC<BinaryFragmentProps> = ({content, update_callback}) => {
    const [format, setFormat] = React.useState<string>(content.binary.format || 'unsigned');
    const [number, setNumber] = React.useState<string>(content.binary.number || '');

    const update_format = (format:string) => {
        setFormat(format);
        update_callback({binary: {
            format: format,
            number: number
        }});
    }

    const update_number = (number:string) => {
        if (number.length > 64) {
            number = number.slice(0, 64);
        }
        if (format == "ieee" && number.length > 32)
            number = number.slice(0, 32);
        setNumber(number);
        update_callback({binary: {
            format: format,
            number: number
        }});
    }

    return <Box>
        <Text fontSize={'sm'} fontWeight={'bold'} color={'purple.500'} mb={2} cursor={'default'}>Format du nombre binaire</Text>

        <Select value={format} onChange={(event) => update_format(event.target.value)}>
            <option value='unsigned'>Binaire non signé</option>
            <option value='signed'>Binaire signé</option>
            <option value='c1'>Complément à 1</option>
            <option value='c2'>Complément à 2</option>
            <option value='ieee'>IEEE 754 simple précision</option>
        </Select>
        
        <FormControl>
            <FormLabel mt={3} fontSize={'sm'} fontWeight={'bold'} color={'purple.500'} mb={2} cursor={'default'}>Nombre binaire</FormLabel>
            <Input size='md' letterSpacing={"0.5rem"} fontFamily={"JetBrains Mono"} type="number" textAlign={"center"} value={number} onChange={(event) => update_number(event.target.value)} />
        </FormControl>
    </Box>
};


export default BinaryFragment;