import { useContext, useEffect, useRef, useState } from "react";
import VisibilityObserver from "../../VisibilityObserver";
import { TabContext } from "../../TabContext";
import PageVisibility from "react-page-visibility";
import { Box } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { getCurrentInstanceId } from "../../API/gcinstance";
import { sendHeartbeat } from "../../API/logs";
import { FragmentType } from "../../types";


interface Props {
	children: React.ReactNode;
    fragment_id: string;
    fragment_type: FragmentType;
}

const HeartbeatController: React.FC<Props> = (props: Props) => {
    // Visibility observer
    const ref = useRef<HTMLDivElement>(null);
    const isVisibleOnScreen = VisibilityObserver(ref);
    const [pageVisible, setPageVisible] = useState(true);
    const currentHeartbeat = useRef<any>(0);
    const HEARTBEAT_INTERVAL = 1000 * 15; // 15 seconds
    const currentTab = useContext(TabContext).currentTab;

    const gc_params = useParams();
    const gc_slug = (gc_params.slug || '') as string;
    const [instanceId, setInstanceId] = useState('');

    useEffect(() => {
        if (!gc_slug)
            return;

        if (instanceId === '') {
            getCurrentInstanceId(gc_slug).then((response) => {
                setInstanceId(response.data.id);
            });
        }
        else {
            if (pageVisible && isVisibleOnScreen) {
                start_heartbeat();
            } else {
                stop_heartbeat();
            }
        }

        return () => {
            stop_heartbeat();
        };
    }, [pageVisible, isVisibleOnScreen, instanceId]);

    const start_heartbeat = () => {
        stop_heartbeat();
        
        send_heartbeat();
        currentHeartbeat.current = setInterval(() => {
            send_heartbeat();
        }, HEARTBEAT_INTERVAL);
    };

    const stop_heartbeat = () => {
        clearInterval(currentHeartbeat.current);
    };

    const send_heartbeat = () => {
        sendHeartbeat(props.fragment_type, props.fragment_id, currentTab, instanceId);
    }

    return <PageVisibility onChange={(isPageVisible) => setPageVisible(isPageVisible) }>
        <Box ref={ref}>{props.children}</Box>
    </PageVisibility>
};

export default HeartbeatController;