import React, { useEffect, useState } from 'react';
import { AbsoluteCenter, Box, Button, Card, CardBody, Center, Container, Flex, Heading, Spacer, Spinner, Text } from '@chakra-ui/react';
import { Image as ChakraImage } from '@chakra-ui/react';
import login_img from './resources/login.svg'

const Login: React.FC = () => {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Set loading to false after 0.5 second
        setTimeout(() => {
            setLoading(false);
        }, 500);

        // Preload the image
        const img = new Image();
        img.src = login_img;
    }, []);

    return (<>
    
        {loading &&
            <AbsoluteCenter mt={"300px"}>
                <Spinner
                    thickness='6px'
                    speed='0.65s'
                    emptyColor='gray.200'
                    color='blue.500'
                    size='xl'
                />
            </AbsoluteCenter>
        }

        {!loading &&
            <Box>

            <Center mt={20} mb={2}>
                <ChakraImage src={login_img} width={'200px'} className='noselect' />
            </Center>
            
            <Center>
                <Heading mt={2} as={'h3'} size={'xl'} color={'gray.900'}>Bonjour ?</Heading>
            </Center>

            <Center ms={4} me={4} mb={5} mt={5}>
                <Text>Tu dois te connecter à ton compte ULiège pour accéder aux Gamecodes.</Text>
            </Center>

            <Center>
                <Button mt={4} colorScheme='teal' onClick={() => window.location.href = 'https://cafe.uliege.be/login?redirect_gamecode'}>Me connecter</Button>
            </Center>
        </Box>
      }
    </>);
}

export default Login;