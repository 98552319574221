import React, { useEffect, useState } from "react";
import { IReminder } from "../types";
import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Box, Button, Center, Flex, Heading, Image, List, ListItem, Spacer, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tooltip, Tr, UnorderedList, useDisclosure, useToast } from "@chakra-ui/react";
import { addReminder, deleteReminder, getAllReminders, getGamecodesByReminders, getReminder, getResolutionStepsByReminders } from "../API/reminder";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import { THEME_COLORS } from "../config";
import ReminderEditor from "./ReminderEditor";
import bulb from '../resources/bulb.svg'

type RemindersEditorProps = {
};

const RemindersEditor: React.FC<RemindersEditorProps> = ({}) => {
    const [reminders, setReminders] = useState<IReminder[]>([]);
    const [remindersGamecodes, setRemindersGamecodes] = useState<{reminder_id:string, gc_title:string}[]>([]);
    const [remindersResolutionSteps, setRemindersResolutionSteps] = useState<{reminder_id:string, gc_title:string, rs_title:string}[]>([]);
    const [currentReminder, setCurrentReminder] = useState<IReminder | null>(null);
    const { isOpen, onOpen, onClose } = useDisclosure()
    const cancelRef = React.useRef<null>(null);
    const [selectedReminder, setSelectedReminder] = useState<IReminder | null>(null);
    const toast = useToast()

    useEffect(() => {
        load_reminders();
    }, []);

    const load_reminders = () => {
        getAllReminders().then((res) => {
            if (res.data.reminders) {
                setReminders(res.data.reminders);
                getGamecodesByReminders(res.data.reminders.map((reminder:IReminder) => reminder._id))
                .then((res) => {
                    if (res.data.reminders_gcs) {
                        setRemindersGamecodes(res.data.reminders_gcs);
                    }
                });
                getResolutionStepsByReminders(res.data.reminders.map((reminder:IReminder) => reminder._id))
                .then((res) => {
                    if (res.data.reminders_rs) {
                        setRemindersResolutionSteps(res.data.reminders_rs);
                    }
                });
            }
        });    }

    const load_reminder = (reminder_id:string) => {
        getReminder(reminder_id).then((res) => {
            if (res.data.reminder) {
                setCurrentReminder(res.data.reminder);
            }
        });
    }

    const confirm_delete_reminder = () => {
        onClose();
        if (selectedReminder)
            deleteReminder(selectedReminder._id).then((res) => {
                setReminders(reminders.filter((reminder) => reminder._id != selectedReminder?._id));
            });
    }

    const new_reminder = () => {
        addReminder('').then(({ data: { reminder } }: any) => {
            setReminders([...reminders, reminder]);
            setCurrentReminder(reminder);
        });
    }

    function ShowToast() {
        toast({
            title: 'Rappel supprimé',
            description: "Le rappel a bien été supprimé.",
            status: 'success',
            duration: 3000,
            isClosable: true,
        })
    }

    return <>
    
    <Heading mt={3} mb={4} as={'h3'} size={'lg'} color={'green.500'}>Rappels</Heading>

    {!currentReminder &&<>
    <TableContainer>
        <Table variant='simple' size={'sm'}>
            <Thead>
            <Tr>
                <Th>Titre</Th>
                <Th>Utilisé dans...</Th>
                <Th></Th>
            </Tr>
            </Thead>
            <Tbody>
                {reminders.map((reminder:IReminder) => {
                    return <Tr key={reminder._id}>
                        <Td>{(reminder.title.length > 30) ? reminder.title.substring(0, 30)+"..." : reminder.title}</Td>
                        <Td fontSize={'sm'}>
                            {remindersGamecodes.length > 0 && <>
                                {remindersGamecodes.filter((reminder_gc) => reminder_gc.reminder_id == reminder._id).map((reminder_gc) => {
                                    return <Text key={reminder_gc.reminder_id + reminder_gc.gc_title} fontWeight={'500'} color={THEME_COLORS.INTRO}>{`GC "${reminder_gc.gc_title}"`.substring(0, 30)}</Text>
                                })}
                            </>}

                            {remindersResolutionSteps.length > 0 && <>
                            {remindersResolutionSteps.filter((reminder_rs) => reminder_rs.reminder_id == reminder._id).map((reminder_rs) => {
                                return <Text key={reminder_rs.reminder_id + reminder_rs.gc_title + reminder_rs.rs_title} fontWeight={'500'} color={THEME_COLORS.RESOLUTION_STEPS}>{`RS "${reminder_rs.rs_title}" (${reminder_rs.gc_title})`.substring(
                                    0,
                                    40
                                )}...</Text>
                            })}
                            </>}
                        </Td>
                        <Td align="right">
                            <Flex>
                                <Spacer />
                                <Tooltip bgColor={'blue.500'} label="Éditer le rappel" aria-label="Éditer le rappel">
                                    <Button me={3} onClick={() => {load_reminder(reminder._id);}} colorScheme='blue' size='sm'>
                                        <FontAwesomeIcon icon={faPen} />
                                    </Button>
                                </Tooltip>
                                <Tooltip bgColor={'red.500'} label="Supprimer le rappel" aria-label="Supprimer le rappel">
                                    <Button colorScheme='red' size='sm'
                                        onClick={() => {
                                            setSelectedReminder(reminder);
                                            onOpen();
                                        }}>
                                        <FontAwesomeIcon icon={faTrash} />
                                    </Button>
                                </Tooltip>
                            </Flex>
                        </Td>
                    </Tr>
                })}
            </Tbody>
        </Table>
    </TableContainer>
    <Flex>
        <Spacer />
        <Button me={2} mt={4} size={'sm'} colorScheme='green' onClick={() => {new_reminder()}}>Nouveau rappel</Button>
    </Flex>
    </>
    }
    {currentReminder &&
        <>
            <Button mb={3} size={'sm'} colorScheme='blackAlpha' onClick={() => {setCurrentReminder(null); load_reminders();}}>Retour à la liste</Button>
            <ReminderEditor reminder={currentReminder} />
        </>
    }
    
    {reminders.length == 0 &&
        <Center>
            <Text>
                Il n'y a aucun rappel pour le moment.
            </Text>
        </Center>
    }

    <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={() => {}}
        >
        <AlertDialogOverlay>
            <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                Supprimer un rappel
            </AlertDialogHeader>

            <AlertDialogBody>
                Attention, le rappel ainsi que tous ses fragments seront définitivement supprimés. <br /><strong>Si ce rappel est présent dans d'autres Gamecodes, il en sera également supprimé.</strong>
            </AlertDialogBody>

            <AlertDialogFooter>
                <Button ref={cancelRef} onClick={onClose}>
                Annuler
                </Button>
                <Button colorScheme='red' onClick={() => {confirm_delete_reminder(); ShowToast();}} ml={3}>
                Supprimer
                </Button>
            </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialogOverlay>
    </AlertDialog>
    </>;
}

export default RemindersEditor;