import { Box, Center, Checkbox, Flex, FormControl, FormHelperText, FormLabel, Input, InputGroup, InputRightAddon, PinInput, PinInputField, Select, Text, VStack } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import AceEditor from 'react-ace'
// import mode-<language> , this imports the style and colors for the selected language.
import 'ace-builds/src-noconflict/mode-c_cpp'
import 'ace-builds/src-noconflict/mode-text'
import 'ace-builds/src-noconflict/theme-dracula'
import 'ace-builds/src-noconflict/theme-chrome'
import 'ace-builds/src-noconflict/ext-language_tools'
import 'ace-builds/src-noconflict/ext-beautify'
import { IDynamicFragment } from "../../../types";
import TextEditor from "../TextEditor";


type BinDecFragmentProps = {
    body: IDynamicFragment;
    update_callback: Function;
};

const BinDecFragment: React.FC<BinDecFragmentProps> = ({body, update_callback}) => {

    const [fragment, setFragment] = useState<IDynamicFragment>(body);
    const [binaryNumber, setBinaryNumber] = useState<string>(body.bindec?.binary || "");
    const [format, setFormat] = useState<string>(body.bindec?.format || 'unsigned');
    const [solution, setSolution] = useState<string>(body.bindec?.solution.toString() || "");

    useEffect(() => {
    }, []);

    const update_content = (new_content:string) => {
        if (!fragment.content)
            fragment.content = "";
        let new_body:IDynamicFragment = {
            ...fragment,
            content: new_content
        }
        setFragment(new_body);
        update_callback(new_body);
    }

    const update_hint = (hint:boolean) => {
        let new_body:IDynamicFragment = fragment;
        new_body.bindec!.hint = hint;
        setFragment(new_body);
        update_callback(new_body);
    }

    const update_format = (format:string) => {
        let new_body:IDynamicFragment = fragment;
        new_body.bindec!.format = format;

        setFragment(new_body);
        update_callback(new_body);
        setFormat(format);
    }

    const update_binary = (binary:string) => {
        if (isNaN(parseInt(binary)))
            return;
        let new_body:IDynamicFragment = fragment;
        new_body.bindec!.binary = binary;

        setFragment(new_body);
        update_callback(new_body);
        setBinaryNumber(binary);
    }

    const update_solution = (solution:string) => {
        let new_body:IDynamicFragment = fragment;
        let s:number;
        if (format === 'ieee') {
            s = parseFloat(solution);
        } else {
            s = parseInt(solution);
        }
        new_body.bindec!.solution = s;
        setFragment(new_body);
        update_callback(new_body);
        setSolution(solution);
    }

    return <>
        
        <Text mt={2} fontSize={'sm'} fontWeight={'bold'} color={'purple.500'} mb={2} cursor={'default'}>Énoncé</Text>

        <TextEditor content={fragment.content} update_callback={update_content} />

        
        <Text mt={3} fontSize={'sm'} fontWeight={'bold'} color={'purple.500'} mb={2} cursor={'default'}>Format du nombre binaire</Text>

        <Select value={format} onChange={(event) => update_format(event.target.value)}>
            <option value='unsigned'>Binaire non signé</option>
            <option value='signed'>Binaire signé</option>
            <option value='c1'>Complément à 1</option>
            <option value='c2'>Complément à 2</option>
            <option value='ieee'>IEEE 754 simple précision</option>
        </Select>

        <FormControl>
            <FormLabel mt={3} fontSize={'sm'} fontWeight={'bold'} color={'purple.500'} mb={2} cursor={'default'}>Nombre binaire à convertir</FormLabel>

            <Input size='md' letterSpacing={"0.5rem"} fontFamily={"JetBrains Mono"} type="number" textAlign={"center"} value={binaryNumber} onChange={(event) => update_binary(event.target.value)} />

            {format === 'ieee' && binaryNumber.length != 32 && <FormHelperText fontSize={"sm"} color={"red.500"}>Veuillez entrer exactement 32 bits</FormHelperText>}

        </FormControl>

        <FormControl>
            <FormLabel mt={3} fontSize={'sm'} fontWeight={'bold'} color={'purple.500'} mb={2} cursor={'default'}>Réponse décimale attendue</FormLabel>

            <Input size='md' letterSpacing={"0.5rem"} fontFamily={"JetBrains Mono"} type="text" textAlign={"center"} value={solution} onChange={(event) => update_solution(event.target.value)} />

        </FormControl>
        
        <FormControl>
            <FormLabel mt={3} fontSize={'sm'} fontWeight={'bold'} color={'purple.500'} mb={2} cursor={'default'}>Options</FormLabel>
            <Checkbox
                ms={2}
                isChecked={fragment.large_area}
                onChange={(event) => { update_hint(event.target.checked) }}
            >Avec indice de couleur</Checkbox>
        </FormControl>
        
    </>
};

export default BinDecFragment;