import { Box, Center, Text, Tooltip, useDisclosure } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import Latex from "react-latex";
import ReactDOM from 'react-dom';
import { createRoot } from "react-dom/client";

type BinaryFragmentProps = {
    content: any;
};

const BinaryFragment: React.FC<BinaryFragmentProps> = ({content}) => {
    const format = content.binary.format || 'unsigned';
    const number = content.binary.number || '';
    const mantisse_disclosure = useDisclosure();

    const has_sign = format == "signed" || format == "c1" || format == "c2";

    return <Box p={3} bgColor={"white"} border={"1pt solid #ededed"}>
        {format == "ieee" &&
            <Center>
            <Text letterSpacing={"0.2rem"} ps={4} fontFamily={"Jetbrains Mono"} cursor={"pointer"} fontSize={"lg"}>
                <Tooltip me={2} placement="left" hasArrow bgColor={"#399fa1"} label="Bit signe" key={0} p={3}>
                    <Text className="hvr-float" as={"span"} color={"#399fa1"}>{number[0]}</Text>
                </Tooltip>

                <Tooltip placement={"bottom"} hasArrow bgColor={"#348757"} label="Exposant" key={1} p={3}>
                    <Text className="hvr-float" as={"span"} color={"#348757"} ms={2}>{number.slice(1, 9)}</Text>
                </Tooltip>

                {number.length > 9 &&
                <Tooltip ms={2} placement="right" hasArrow isOpen={mantisse_disclosure.isOpen} bgColor={"#805ad5"} label="Mantisse" key={2} p={3}>

                    <Text ms={2} as={"span"} onMouseOver={mantisse_disclosure.onOpen} onMouseOut={mantisse_disclosure.onClose}>
                    {[...Array(23)].map((_, i) => (
                    <Tooltip bgColor={"#805ad5"} fontSize={"xl"} label={<Latex>{`$\\frac{1}{2^{${i+1}}}$`}</Latex>} placement={"top"} key={i} p={3} hasArrow>
                        <Text className="hvr-float" as={"span"} color={"#805ad5"} ms={1}>{number[9+i]}</Text>
                    </Tooltip>
                    ))}
                    </Text>
                </Tooltip>
                }
            </Text>
        </Center>
        }
        {format != "ieee" &&<>
            <Center>
            <Text letterSpacing={"0.2rem"} ps={4} fontFamily={"Jetbrains Mono"} cursor={"pointer"} fontSize={"lg"}>

                {has_sign &&
                <Tooltip me={2} placement="bottom" hasArrow bgColor={"gray"} label="Bit signe" key={0} p={3}>
                    <Text fontWeight={500} as={"span"} color={"#5f5f5f"}>{number[0]}</Text>
                </Tooltip>
                }

                <Text as={"span"} color={"#131313"}>{number.slice(has_sign ? 1 : 0, number.length)}</Text>
            </Text>
        </Center>
        </>}
        </Box>
};


export default BinaryFragment;