import React, { useEffect, useState } from "react";
import { Box, Button, Checkbox, Flex, Spacer, Stack, Text, Tooltip } from '@chakra-ui/react';
import { IDynamicFragment, IGCAnswer } from '../../../types';
import LatexText from "../LatexText";
import HintBulb from "./HintBulb";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faQuestion } from "@fortawesome/free-solid-svg-icons";

type MCQFragmentProps = {
    body: IDynamicFragment;
    check_answer: Function;
    display_hint_callback: Function;
    latest_answer: IGCAnswer|null;
};

const MCQFragment: React.FC<MCQFragmentProps> = ({body, check_answer, display_hint_callback, latest_answer}) => {
    const gg:string[] = [
        "Bien joué !",
        "Bravo !",
        "Génial !",
        "Parfait !",
        "Super !",
        "Très bien !",
        "Tu as tout bon !",
    ];
    const gg_index = Math.floor(Math.random() * gg.length);
    const bad:string[] = [
        "Mauvaise réponse...",
        "Oups...",
        "Dommage..."
    ];
    const bad_index = Math.floor(Math.random() * bad.length);
    const [fb_sentence, setFbSentence] = useState<string>('');
    const [submitted, setSubmitted] = useState<boolean>(false);

    // MCQ LOGIC
    const [mcq, setMcq] = React.useState<IDynamicFragment>(body);
    const [answers, setAnswers] = React.useState<{index:number, checked:boolean}[]>([]);
    const [corrected, setCorrected] = useState<boolean>(false);
    const [correct, setCorrect] = useState<boolean>(false);

    useEffect(() => {
        // Report linked to the latest answer
        if (latest_answer != null) {
            setAnswers(latest_answer.answer);
            setSubmitted(true);
            setCorrected(true);
            setCorrect(latest_answer.correct);
            if (latest_answer.correct) {
                setFbSentence(gg[gg_index]);
            } else {
                setFbSentence(bad[bad_index]);
            }
        }
    }, [latest_answer]);

    useEffect(() => {
        if (!mcq.mcq_choices)
            return;
        // Add every answer as unchecked in the answers array
        const new_answers = [];
        for (let i = 0; i < mcq.mcq_choices.length; i++) {
            new_answers.push({index:i, checked:false});
        }
        setAnswers(new_answers);
    }, [mcq]);

    const handleAnswer = (index:number) => {
        const new_answers = [...answers];
        const answer_index = new_answers.findIndex((answer) => answer.index === index);
        if (answer_index === -1) {
            new_answers.push({index:index, checked:true});
        } else {
            new_answers[answer_index].checked = !new_answers[answer_index].checked;
        }
        if (!mcq.mcq_multiple) {
            for (let i = 0; i < new_answers.length; i++) {
                if (new_answers[i].index !== index) {
                    new_answers[i].checked = false;
                }
            }
        }
        setAnswers(new_answers);
    }

    const check_answer_wrapper = async () => {
        // if at least one answer has been checked, check the answer
        const checked_answers = answers.filter((answer) => answer.checked);
        if (!submitted && checked_answers.length > 0) {
            setSubmitted(true);
            try {
                await check_answer(answers);
            } catch (e) {
                console.error("[MCQ Error]:"+e);
            }
        }
    }

    return <>
    <Text fontSize={'sm'} fontWeight={'bold'} color={'blackAlpha.800'} mb={2} cursor={'default'}>À toi de jouer !</Text>
    <Box shadow={'md'} bgColor={'#F0F0F0'} rounded={8} pt={2}>
            <Box rounded={8} m={4} p={4} bgColor={'white'} border={'1pt solid #e6e6e6'} mb={5} color={'black'}>
                <LatexText content={body.content} />
            </Box>
            <Stack spacing={5} direction={'column'} ps={7}>
            {!submitted && mcq.mcq_choices?.map((choice, index) => {
                return <Checkbox 
                            size={'lg'}
                            mb={0} 
                            pb={0}
                            isChecked={answers.find((answer) => answer.index === index)?.checked}
                            onChange={() => handleAnswer(index)}
                            borderColor={'blackAlpha.600'} key={index} fontSize={'11pt'} color={'black'} colorScheme='green'>
                            <Text mb={0} pb={0} fontSize={'11pt'}>{choice.content}</Text>
                    </Checkbox>
            })}
            {submitted && !corrected && mcq.mcq_choices?.map((choice, index) => {
                return <Checkbox 
                            cursor={'default'}
                            size={'lg'}
                            isChecked={answers.find((answer) => answer.index === index)?.checked}
                            borderColor={'blackAlpha.600'} mb={0} pb={0} key={index} fontSize={'11pt'} color={'black'} colorScheme='green'>
                            <Text mb={0} pb={0} fontSize={'11pt'} 
                            fontWeight={(choice.correct) ? '500' : '300'} >{choice.content}</Text>
                    </Checkbox>
            })}
            {submitted && corrected && mcq.mcq_choices?.map((choice, index) => {
                return <Checkbox 
                            cursor={'default'}
                            size={'lg'}
                            isChecked={answers.find((answer) => answer.index === index)?.checked}
                            borderColor={'blackAlpha.600'} mb={0} pb={0} key={index} fontSize={'11pt'} color={'black'} colorScheme='green'>
                            <Text mb={0} pb={0} fontSize={'11pt'} color={(choice.correct) ? 'green.500' : 'red.500'} 
                            fontWeight={(choice.correct) ? '500' : '300'} >{choice.content}</Text>
                    </Checkbox>
            })}
        </Stack>
        <Flex roundedBottomEnd={8} roundedBottomStart={8} mt={4} bgColor={'blackAlpha.100'} p={4}>
            {submitted && corrected &&
                <Text mt={2} 
                    cursor={'default'} fontSize={'md'} fontWeight={600} 
                    color={correct ? 'green.500' : 'red.500'}>
                    <i style={{marginBottom:'5px', marginRight:'5px'}} className={correct ? "em em-svg em-star-struck" : "em em-svg em-disappointed"}></i>
                    {fb_sentence}</Text>
            }
            <Spacer />
            <HintBulb display_hint_callback={display_hint_callback} hints_nb={body.hints?.length || 0} />
            
            {/* <Tooltip p={3} hasArrow label={submitted ? "" : "Tu ne peux plus répondre à la question si tu cliques sur ce bouton"} placement="top">
                <Button isDisabled={submitted && !corrected} me={2} variant='outline' colorScheme={'blackAlpha'} size={'md'} rightIcon={<FontAwesomeIcon icon={faQuestion} />} onClick={() => {}}>Voir l'explication</Button>
            </Tooltip> */}
            <Button isDisabled={submitted} variant='outline' colorScheme={'green'} size={'md'} rightIcon={<FontAwesomeIcon icon={faCheck} />}
                onClick={
                    () => {
                        check_answer_wrapper();
                    }
                }
            >Corriger</Button>
        </Flex>
    </Box>
    </>

};


export default MCQFragment;