import { Box, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import Latex from "react-latex";
import ReactDOM from 'react-dom';
import { createRoot } from "react-dom/client";

type TextFragmentProps = {
    content: any;
};

const TextFragment: React.FC<TextFragmentProps> = ({content}) => {
    const [latex_content, setLatexContent] = useState<string>('');

    // Once the page is rendered, convert all the strings that are between $...$ to latex components
    useEffect(() => {
        // Replace everything that is between $...$ with a <span class="latex">...</span>
        const latex_regex = /\$([^\$]*)\$/g;
        const matches = content.content.matchAll(latex_regex);
        let new_content = content.content;
        for (const match of matches) {
            const latex_text = match[1];
            new_content = new_content.replace(`$${latex_text}$`, `<span class="latex">${latex_text}</span>`);
        }
        setLatexContent(new_content);
    }, [content]);

    // Set every table element to have a 100% width and a white background
    useEffect(() => {
        const tables = document.getElementsByTagName('table');
        for (let i = 0; i < tables.length; i++) {
            tables[i].style.width = '100%';
            tables[i].style.backgroundColor = 'white';
        }
    }, [latex_content]);

    // Highlight callout blocks
    useEffect(() => {
        // Get every element that has the class "highlight-block"
        const highlight_blocks = document.getElementsByClassName('highlight-block');
        // Insert a title in it with the text "Alerte !"
        for (let i = 0; i < highlight_blocks.length; i++) {
            if (!highlight_blocks[i].classList.contains('alert-inserted')) {
                const highlight_block = highlight_blocks[i];
                const title = document.createElement('h3');
                // title.innerHTML = 'Alerte !';
                // give it a bottom margin of 0
                title.style.marginBottom = '0';
                highlight_block.insertBefore(title, highlight_block.firstChild);
                // Add the class alert-inserted to the parent element
                highlight_block.classList.add('alert-inserted');
            }
        }
    }, [latex_content]);

    useEffect(() => {
        const latex_elements = document.getElementsByClassName('latex');
        for (let i = 0; i < latex_elements.length; i++) {
            // Check if the element has already been parsed
            if (latex_elements[i].classList.contains('latex-parsed'))
                continue;
            const latex_element = latex_elements[i];
            const latex_text = latex_element.innerHTML;
            latex_element.innerHTML = '';
            createRoot(latex_element).render(<Latex>{'$'+latex_text+'$'}</Latex>);
            latex_element.classList.add('latex-parsed');
        }
    }, [latex_content]);

    return <Box className="text-fragment">
            <Text align={'justify'} dangerouslySetInnerHTML={{__html: latex_content}}></Text>
        </Box>
};


export default TextFragment;