import axios, { AxiosResponse } from "axios";
import { ApiDataType, DynamicFragmentType, FragmentType, GCModule, IAnyFragment, IDynamicFragment, IFragment, IStaticFragment, RSModule, ResolutionStepModule, StaticFragmentType } from "../types";
import API, { API_URL } from "./params";

// FRAGMENTS

/**
 * GET request to the server to get all fragments which id's are in the array.
 */
export const getFragments = async ( ids: string[] ): Promise<AxiosResponse<ApiDataType>> => {
    try {
        const fragments: AxiosResponse<ApiDataType> = await API.get(
            `${API_URL}/fragments`,
            { params: { ids: ids } }
        );
        return fragments;
    } catch (error) {
        throw error;
    }
}

/**
 * GET request to the server to get the fragment with the given id.
 */
export const getFragment = async ( id: string ): Promise<AxiosResponse<ApiDataType>> => {
    try {
        const fragment: AxiosResponse<ApiDataType> = await API.get(
            `${API_URL}/fragments/${id}`
        );
        return fragment;
    } catch (error) {
        throw error;
    }
}

/**
 * PUT request to add a fragment to the database.
 */
export const addFragment = async ( data: IFragment ): Promise<AxiosResponse<ApiDataType>> => {
    try {
        const fragment: Omit<IFragment, "_id"> = {
            type: data.type,
            fragment_id: data.fragment_id,
        };
        const saveFragment: AxiosResponse<ApiDataType> = await API.put(
            `${API_URL}/fragments`,
            fragment
        );
        return saveFragment;
    } catch (error) {
        throw error;
    }
}

export const deleteFragment = async ( id: string ): Promise<AxiosResponse<ApiDataType>> => {
    try {
        const deletedFragment: AxiosResponse<ApiDataType> = await API.delete(
            `${API_URL}/fragments/${id}`
        );
        return deletedFragment;
    } catch (error) {
        throw error;
    }
}

/**
 * Takes a list of fragment pointers (IFragment[]) and returns a list of the fragments they point to.
 * (either static or dynamic fragments)
 */
export const resolveFragmentIds = async ( fragments: IFragment[] ): Promise<IAnyFragment[]> => {
    try {
        // Send the list of fragments through an API.get request to the server.
        const resolved_fragments: AxiosResponse<ApiDataType> = await API.post(
            `${API_URL}/fragments/resolve`,
            fragments
        );
        // Return the list of fragments.
        return resolved_fragments.data.any_fragments;
    }
    catch (error) {
        throw error;
    }
}

// STATIC FRAGMENTS
/**
 * GET request to get the static fragment with the given id.
 */
export const getStaticFragment = async ( id: string ): Promise<IStaticFragment> => {
    try {
        const fragment: AxiosResponse<ApiDataType> = await API.get(
            `${API_URL}/fragments/static/${id}`
        );
        if (fragment.data.static_fragment)
            return fragment.data.static_fragment;
        else
            throw new Error("Fragment not found");
    } catch (error) {
        throw error;
    }
}

/**
 * PUT request to add a static fragment to the database.
 */
export const addStaticFragment = async ( fragment_type: StaticFragmentType, gcid:string, module:GCModule): Promise<AxiosResponse<ApiDataType>> => {
    try {
        const new_fragment = {
            precise_type: fragment_type,
            gcid: gcid,
            module: module
        };
        const saveFragment: AxiosResponse<ApiDataType> = await API.put(
            `${API_URL}/fragments/static`,
            new_fragment
        );

        return saveFragment;
    } catch (error) {
        throw error;
    }
}

export const reorganizeFragments = async ( new_list:string[], gcid:string, module:GCModule, rsid:string='', rsmodule:ResolutionStepModule=ResolutionStepModule.STATEMENT, reminder_id:string='' ): Promise<AxiosResponse<ApiDataType>> => {
    try {
        const reorganize_req: AxiosResponse<ApiDataType> = await API.post(
            `${API_URL}/fragments/reorganize`,
            {
                new_list:new_list, 
                gcid: gcid, 
                module: module, 
                rsid: rsid, 
                rsmodule: rsmodule,
                reminder_id: reminder_id
            }
        );

        return reorganize_req;
    } catch (error) {
        throw error;
    }
}

/**
 * POST request to update the static fragment with the given id.
 */
export const updateStaticFragment = async ( id: string, new_content:string ): Promise<AxiosResponse<ApiDataType>> => {
    try {
        const updated_fragment = {
            id: id,
            new_content: new_content
        };
        const updateFragment: AxiosResponse<ApiDataType> = await API.post(
            `${API_URL}/fragments/static`,
            updated_fragment
        );
        return updateFragment;
    }
    catch (error) {
        throw error;
    }
}

export const deleteStaticFragment = async ( id:string, gcid:string, module:GCModule, rsid:string='', rsmodule:RSModule=RSModule.STATEMENT ): Promise<AxiosResponse<ApiDataType>> => {
    try {
        const deletedFragment: AxiosResponse<ApiDataType> = await API.delete(
            `${API_URL}/fragments/static/delete/${id}`,
            { data: { gcid: gcid, module: module, rsid: rsid, rsmodule: rsmodule } }
        );
        return deletedFragment;
    } catch (error) {
        throw error;
    }
}


/**
 * PUT request to add a dynamic fragment to the database.
 */
export const addDynamicFragment = async ( fragment_type: DynamicFragmentType, gcid:string, module:GCModule): Promise<AxiosResponse<ApiDataType>> => {
    try {
        const new_fragment = {
            precise_type: fragment_type,
            gcid: gcid,
            module: module
        };
        const saveFragment: AxiosResponse<ApiDataType> = await API.put(
            `${API_URL}/fragments/dynamic`,
            new_fragment
        );

        return saveFragment;
    } catch (error) {
        throw error;
    }
}

/**
 * POST request to update the dynamic fragment with the given id.
 */
export const updateDynamicFragment = async ( id: string, new_content:IDynamicFragment ): Promise<AxiosResponse<ApiDataType>> => {
    try {
        const updated_fragment = {
            id: id,
            new_content: new_content
        };
        const updateFragment: AxiosResponse<ApiDataType> = await API.post(
            `${API_URL}/fragments/dynamic`,
            updated_fragment
        );
        return updateFragment;
    }
    catch (error) {
        throw error;
    }
}

/**
 * POST request to update the dynamic fragment with the given id.
 */
export const updateDynamicFragmentHints = async ( id: string, new_hints:any[] ): Promise<AxiosResponse<ApiDataType>> => {
    try {
        const updateFragment: AxiosResponse<ApiDataType> = await API.post(
            `${API_URL}/fragments/dynamic/hints`,
            {
                id: id,
                new_hints: new_hints
            }
        );
        return updateFragment;
    }
    catch (error) {
        throw error;
    }
}