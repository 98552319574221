import React, { useEffect, useState } from "react";
import { Box, Button, Center, Flex, Image, Input, PinInput, PinInputField, Spacer, Stack, Text, Textarea, Tooltip, useDisclosure } from '@chakra-ui/react';
import { CheckIcon } from '@chakra-ui/icons';
import { IDynamicFragment, IGCAnswer } from '../../../types';
import LatexText from "../LatexText";
import HintBulb from "./HintBulb";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import Latex from "react-latex";

type DecBinFragmentProps = {
    body: IDynamicFragment;
    display_hint_callback: Function;
    update_answer_callback: Function;
    check_answer: Function;
    latest_answer: IGCAnswer | null;
};

const DecBinFragment: React.FC<DecBinFragmentProps> = ({body, display_hint_callback, update_answer_callback, latest_answer, check_answer}) => {

    const [answer, setAnswer] = React.useState<string>(latest_answer ? latest_answer.answer : "");
    const [bitsNb, setBitsNb] = React.useState<number>(body.decbin?.bits || 8);
    const [format, setFormat] = React.useState<string>(body.decbin?.format || 'unsigned');
    const [colorHint, setColorHint] = React.useState<boolean>(body.decbin?.hint || false);
    const [submitted, setSubmitted] = React.useState<boolean>(false);
    const [corrected, setCorrected] = React.useState<boolean>(false);
    const [correct, setCorrect] = React.useState<boolean>(false);
    const mantisse_disclosure = useDisclosure();
    const gg:string[] = [
        "Bien joué !",
        "Bravo !",
        "Génial !",
        "Parfait !",
        "Super !",
        "Très bien !",
        "Tu as tout bon !",
    ];
    const gg_index = Math.floor(Math.random() * gg.length);
    const bad:string[] = [
        "Mauvaise réponse...",
        "Oups... Mauvaise réponse...",
        "Mauvaise réponse..."
    ];
    const bad_index = Math.floor(Math.random() * bad.length);
    const [fb_sentence, setFbSentence] = useState<string>('');

    useEffect(() => {
        if (latest_answer) {
            setAnswer(latest_answer.answer);
            setSubmitted(latest_answer.submitted);
            setCorrect(latest_answer.correct);
            if (latest_answer.correct) {
                setFbSentence(gg[gg_index]);
            } else {
                setFbSentence(bad[bad_index]);
            }
            setCorrected(latest_answer.submitted);
        }
    }, [latest_answer]);

    const update_answer = (ans:string) => {
        if (ans.length > bitsNb)
            ans = ans.slice(0, bitsNb);
        setAnswer(ans);

        if (ans.length === bitsNb)
            update_answer_callback(ans);
    }

    const check_answer_callback = () => {
        setSubmitted(true);
        check_answer(answer);
    }

    const has_sign_bit = () => {
        return format !== "ieee" && format !== "unsigned";
    }

    return <>
    <Text fontSize={'sm'} fontWeight={'bold'} color={'blackAlpha.800'} mb={2} cursor={'default'}>À toi de jouer !</Text>
    <Box shadow={'md'} bgColor={'#F0F0F0'} rounded={8} pt={2}>
        <Box rounded={8} m={4} p={4} bgColor={'white'} border={'1pt solid #e6e6e6'} mb={5} color={'black'}>
            <LatexText content={body.content} />
        </Box>

        {bitsNb <= 16 && format != "ieee" &&
            <Center p={5}>
                <PinInput isDisabled={submitted} size='sm' type="number" value={answer} onChange={(value) => update_answer(value)}>
                        {[...Array(bitsNb)].map((_, i) => (
                            (has_sign_bit() && i === 0 && colorHint) ?
                            <Tooltip hasArrow bgColor={"#2c7a7b"} label="Bit signe" key={i} p={3}>
                                <PinInputField textAlign={"center"} width={"32px"} height={"32px"} border={"2pt solid #399fa1"} _focus={{outline:"none"}} cursor={"pointer"} bgColor={"white"} fontWeight={500} fontFamily={"Jetbrains Mono"} ms={1} />
                            </Tooltip>
                             :
                             (colorHint) ? 
                             <Tooltip hasArrow placement="top" bgColor={"#2c7a7b"} fontSize={"xl"} label={<Latex>{`$2^{${(bitsNb-i-1)}}$`}</Latex>} key={i} p={3}>
                                <PinInputField textAlign={"center"} width={"32px"} height={"32px"} border={"2pt solid gray"} _focus={{outline:"none"}} cursor={"pointer"} bgColor={"white"} fontWeight={500} fontFamily={"Jetbrains Mono"} ms={1} key={i} />
                            </Tooltip> :
                            <PinInputField textAlign={"center"} width={"32px"} height={"32px"} border={"2pt solid gray"} _focus={{outline:"none"}} cursor={"pointer"} bgColor={"white"} fontWeight={500} fontFamily={"Jetbrains Mono"} ms={1} key={i} />
                        ))}
                </PinInput>
            </Center>
        }

        {bitsNb > 16 && format != "ieee" &&
            <Center>
                <Input isDisabled={submitted} m={4} bgColor={"white"} size={bitsNb <= 32 ? 'md' : "sm"} letterSpacing={"0.5rem"} fontFamily={"JetBrains Mono"} type="number" textAlign={"center"} maxLength={bitsNb} value={answer} onChange={(event) => update_answer(event.target.value)} />
            </Center>
        }

        {format == "ieee" &&<>
            {answer.length > 0 && colorHint && <>
            <Text ms={5} mb={2} color={"gray.700"} fontSize={"sm"} fontWeight={700}>Représentation graphique :</Text>
            <Center>
                <Text letterSpacing={"0.2rem"} ps={4} fontFamily={"Jetbrains Mono"} cursor={"pointer"}>
                    <Tooltip me={2} placement="left" hasArrow bgColor={"#399fa1"} label="Bit signe" key={0} p={3}>
                        <Text className="hvr-float" as={"span"} color={"#399fa1"}>{answer[0]}</Text>
                    </Tooltip>

                    <Tooltip placement={"bottom"} hasArrow bgColor={"#348757"} label="Exposant" key={1} p={3}>
                        <Text className="hvr-float" as={"span"} color={"#348757"} ms={2}>{answer.slice(1, 9)}</Text>
                    </Tooltip>

                    {answer.length > 9 &&
                    <Tooltip ms={2} placement="right" hasArrow isOpen={mantisse_disclosure.isOpen} bgColor={"#805ad5"} label="Mantisse" key={2} p={3}>

                        <Text ms={2} as={"span"} onMouseOver={mantisse_disclosure.onOpen} onMouseOut={mantisse_disclosure.onClose}>
                        {[...Array(23)].map((_, i) => (
                        <Tooltip bgColor={"#805ad5"} fontSize={"xl"} label={<Latex>{`$\\frac{1}{2^{${i+1}}}$`}</Latex>} placement={"top"} key={i} p={3} hasArrow>
                            <Text className="hvr-float" as={"span"} color={"#805ad5"} ms={1}>{answer[9+i]}</Text>
                        </Tooltip>
                        ))}
                        </Text>
                    </Tooltip>
                    }
                </Text>
            </Center>
            </>}
            <Center>
                <Input isDisabled={submitted} p={3} m={4} bgColor={"white"} size={bitsNb <= 32 ? 'md' : "sm"} letterSpacing={"0.5rem"} fontFamily={"JetBrains Mono"} type="text" textAlign={"center"} maxLength={bitsNb} value={answer} onChange={(event) => { setAnswer(event.target.value || "")}} onBlur={(event) => update_answer(event.target.value)} />
            </Center>
        </>}

        <Flex roundedBottomEnd={8} roundedBottomStart={8} mt={4} bgColor={'blackAlpha.100'} p={4}>
            {corrected && submitted &&
                <Text mt={2} 
                    cursor={'default'} fontSize={'md'} fontWeight={600} 
                    color={correct ? 'green.500' : 'red.500'}>
                    <i style={{marginBottom:'5px', marginRight:'5px'}} className={correct ? "em em-svg em-star-struck" : "em em-svg em-disappointed"}></i>
                    {fb_sentence}</Text>
            }
            <Spacer />
            <HintBulb display_hint_callback={display_hint_callback} hints_nb={body.hints?.length || 0} />
            <Button isDisabled={submitted} variant='outline' colorScheme={'green'} size={'md'} rightIcon={<FontAwesomeIcon icon={faCheck} />}
                onClick={
                    () => {
                        check_answer_callback();
                    }
                }
            >Corriger</Button>
        </Flex>
    </Box>
    </>

};


export default DecBinFragment;