import { useEffect, useState } from "react";

export default function VisibilityObserver(ref: any) {

    const [isIntersecting, setIntersecting] = useState(false);

    const observer = new IntersectionObserver(
        ([entry]) => {
            setIntersecting(entry.isIntersecting);
        }
    );

    useEffect(() => {
        observer.observe(ref.current);
        return () => { observer.disconnect(); };
    }, []);

    return isIntersecting;
}