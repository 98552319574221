import { L10n } from '@syncfusion/ej2-base';
// Rich text editor
import { FontColorModel, HtmlEditor, Image, Inject, Link, QuickToolbar, RichTextEditorComponent, Table, Toolbar, ToolbarType } from '@syncfusion/ej2-react-richtexteditor';
import '../../texteditor.css';
import React from "react";
import { Text } from '@chakra-ui/react';

type TextEditorProps = {
    content?: string;
    update_callback: Function;
};

const TextEditor: React.FC<TextEditorProps> = ({content, update_callback}) => {
    L10n.load({
        'fr': {
        'richtexteditor': {
            alignments: 'Alignement',
            justifyLeft: 'Gauche',
            justifyCenter: 'Centre',
            justifyRight: 'Droite',
            justifyFull: 'Justifié',
            fontName: 'Nom de la police',
            fontSize: 'Taille de la police',
            fontColor: 'Couleur de la police',
            backgroundColor: 'Couleur de fond',
            bold: 'GRAS',
            italic: 'Italique',
            underline: 'Souligné',
            strikethrough: 'Barré',
            clearFormat: 'Effacer le formatage',
            clearAll: 'Tout effacer',
            cut: 'Couper',
            copy: 'Copier',
            paste: 'Coller',
            unorderedList: 'Liste à puces',
            orderedList: 'Liste numérotée',
            indent: 'Augmenter le retrait',
            outdent: 'Diminuer le retrait',
            undo: 'Annuler',
            redo: 'Rétablir',
            superscript: 'Exposant',
            subscript: 'Indice',
            createLink: 'Insérer un lien',
            openLink: 'Ouvrir un lien',
            editLink: 'Modifier le lien',
            removeLink: 'Supprimer le lien',
            image: 'Insérer une image',
            replace: 'Remplacer',
            align: 'Aligner',
            caption: 'Légende de l\'image',
            remove: 'Supprimer',
            insertLink: 'Insérer un lien',
            display: 'Affichage',
            altText: 'Texte alternatif',
            dimension: 'Modifier la taille',
            fullscreen: 'Plein écran',
            maximize: 'Maximiser',
            minimize: 'Minimiser',
            lowerCase: 'Minuscules',
            upperCase: 'Majuscules',
            print: 'Imprimer',
            formats: 'Formats',
            sourcecode: 'Afficher le code',
            preview: 'Aperçu',
            viewside: 'Afficher le côté',
            insertCode: 'Insérer du code',
            linkText: 'Texte à afficher',
            linkTooltipLabel: 'Titre',
            linkWebUrl: 'Adresse web',
            linkTitle: 'Titre',
            linkurl: 'http://example.com',
            linkOpenInNewWindow: 'Ouvrir dans une nouvelle fenêtre',
            linkHeader: 'Insérer un lien',
            dialogInsert: 'Insérer',
            dialogCancel: 'Annuler',
            dialogUpdate: 'Mettre à jour',
            imageHeader: 'Insérer une image',
            imageLinkHeader: 'Vous pouvez également fournir un lien depuis le web',
            mdimageLink: 'Veuillez fournir une URL pour votre image',
            imageUploadMessage: 'Déposez l\'image ici ou parcourez pour télécharger',
            imageDeviceUploadMessage: 'Cliquez ici pour télécharger',
            imageAlternateText: 'Texte alternatif',
            alternateHeader: 'Texte alternatif',
            browse: 'Parcourir',
            imageUrl: 'http://example.com/image.png',
            imageCaption: 'Légende',
            imageSizeHeader: 'Taille de l\'image',
            imageHeight: 'Hauteur',
            imageWidth: 'Largeur',
            textPlaceholder: 'Saisir du texte',
            inserttablebtn: 'Insérer un tableau',
            tabledialogHeader: 'Insérer un tableau',
            tableWidth: 'Largeur',
            cellpadding: 'Marge intérieure',
            cellspacing: 'Espacement des cellules',
            columns: 'Nombre de colonnes',
            rows: 'Nombre de lignes',
            tableRows: 'Lignes du tableau',
            tableColumns: 'Colonnes du tableau',
            tableCellHorizontalAlign: 'Alignement horizontal de la cellule',
            tableCellVerticalAlign: 'Alignement vertical de la cellule',
            createTable: 'Créer un tableau',
            removeTable: 'Supprimer le tableau',
            tableHeader: 'En-tête du tableau',
            tableRemove: 'Supprimer le tableau',
            tableCellBackground: 'Couleur de fond de la cellule',
            tableEditProperties: 'Modifier les propriétés du tableau',
            styles: 'Styles',
            insertColumnLeft: 'Insérer une colonne à gauche',
            insertColumnRight: 'Insérer une colonne à droite',
            deleteColumn: 'Supprimer la colonne',
            insertRowBefore: 'Insérer une ligne avant',
            insertRowAfter: 'Insérer une ligne après',
            deleteRow: 'Supprimer la ligne',
            tableEditHeader: 'Modifier le tableau',
            TableHeadingText: 'En-tête',
            TableColText: 'Colonne',
            imageInsertLinkHeader: 'Insérer un lien',
            editImageHeader: 'Modifier l\'image',
            alignmentsDropDownLeft: 'Gauche',
            alignmentsDropDownCenter: 'Centre',
            alignmentsDropDownRight: 'Droite',
            alignmentsDropDownJustify: 'Justifié',
            imageDisplayDropDownInline: 'Inline',
            imageDisplayDropDownBreak: 'Saut de ligne',
            tableInsertRowDropDownBefore: 'Insérer une ligne avant',
            tableInsertRowDropDownAfter: 'Insérer une ligne après',
            tableInsertRowDropDownDelete: 'Supprimer la ligne',
            tableVerticalAlignDropDownTop: 'Aligner en haut',
            tableVerticalAlignDropDownMiddle: 'Aligner au centre',
            tableVerticalAlignDropDownBottom: 'Aligner en bas',
            tableStylesDropDownDashedBorder: 'Bordure en pointillés',
            tableStylesDropDownAlternateRows: 'Lignes alternées',
            pasteFormat: 'Coller le format',
            pasteFormatContent: 'Choisissez l\'action de formatage',
            plainText: 'Texte brut',
            cleanFormat: 'Nettoyer',
            keepFormat: 'Conserver',
            pasteDialogOk: 'OK',
            pasteDialogCancel: 'Annuler',
            fileManager: 'Gestionnaire de fichiers',
            fileDialogHeader: 'Navigateur de fichiers',
            formatsDropDownParagraph: 'Paragraphe',
            formatsDropDownCode: 'Code',
            formatsDropDownQuotation: 'Citation',
            formatsDropDownHeading1: 'Titre 1',
            formatsDropDownHeading2: 'Titre 2',
            formatsDropDownHeading3: 'Titre 3',
            formatsDropDownHeading4: 'Titre 4',
            fontNameSegoeUI: 'Segoe UI',
            fontNameArial: 'Arial',
            fontNameGeorgia: 'Georgia',
            fontNameImpact: 'Impact',
            fontNameTahoma: 'Tahoma',
            fontNameTimesNewRoman: 'Times New Roman',
            fontNameVerdana: 'Verdana',
            numberFormatListNumber: 'Numérotation',
            numberFormatListLowerAlpha: 'Minuscules alphabétiques',
            numberFormatListUpperAlpha: 'Majuscules alphabétiques',
            numberFormatListLowerRoman: 'Chiffres romains en minuscules',
            numberFormatListUpperRoman: 'Chiffres romains en majuscules',
            numberFormatListLowerGreek: 'Minuscules grecques',
            bulletFormatListDisc: 'Disque',
            bulletFormatListCircle: 'Cercle',
            bulletFormatListSquare: 'Carré',
            numberFormatListNone: 'Aucun',
            bulletFormatListNone: 'Aucun'
        }
    }
    });

    const fontColors: FontColorModel = {
        default: '#000000',
        mode: 'Palette',
        columns: 10,
        colorCode: {'Custom' : ['#000000', '#e7e6e6', '#44546a', '#4472c4', '#ed7d31', '#a5a5a5', '#ffc000', '#70ad47', '#ff0000','#f2f2f2', '#808080', '#cfcdcd', '#d5dce4', '#d9e2f3', '#fbe4d5', '#ededed', '#fff2cc', '#e2efd9', '#ffcccc','#d9d9d9', '#595959', '#aeaaaa', '#acb9ca', '#b4c6e7', '#f7caac', '#dbdbdb', '#ffe599', '#c5e0b3', '#ff8080','#bfbfbf', '#404040', '#747070', '#8496b0', '#8eaadb', '#f4b083', '#c9c9c9', '#ffd966', '#a8d08d', '#ff3333','#a6a6a6', '#262626', '#3b3838', '#323e4f', '#2f5496', '#c45911', '#7b7b7b', '#bf8f00', '#538135', '#b30000','#7f7f7f', '#0d0d0d', '#161616', '#212934', '#1f3763', '#823b0b', '#525252', '#7f5f00', '#375623', '#660000', '#4c4c4c', 
        '#a9a9a9', '#ff9300','#b22cb2','#941751','#cc1e00','#929000','#0328cc','#005c00','#0078cc', '#181818']}
    }

    let items = ['Bold', 'Italic' ,'Underline', 'StrikeThrough',
        'FontSize', 'Formats', '|' , 'FontColor',
        {
            template: '<button class="e-tbar-btn e-btn" tabindex="-1" id="custom_tbar"  style="width:100%"><div class="e-tbar-btn-text" style="font-weight: 500;"><i class="fa-solid fa-code"></i></div></button>',
            undo: true,
            click: codify.bind(this),
            tooltipText: 'Code',
            name: 'codify'
        },
        {
            template: '<button class="e-tbar-btn e-btn" tabindex="-1" id="custom_tbar"  style="width:100%"><div class="e-tbar-btn-text" style="font-weight: 500;"><i class="fa-solid fa-highlighter"></i></div></button>',
            undo: true,
            click: highlight.bind(this),
            tooltipText: 'Mise en évidence',
            name: 'highlight'
        },
        {
            template: '<button class="e-tbar-btn e-btn" tabindex="-1" id="custom_tbar"  style="width:100%"><div class="e-tbar-btn-text" style="font-weight: 500;"><i class="fa-solid fa-circle-exclamation"></i></div></button>',
            undo: true,
            click: block_highlight.bind(this),
            tooltipText: 'Bloc en évidence',
            name: 'highlight-block'
        },
        'Alignments', 'OrderedList', 'UnorderedList',
        'Outdent', 'Indent',
        'CreateLink', 'CreateTable', 'Image', '|', 'ClearFormat'
        , '|', 'Undo', 'Redo'
    ];
    function codify() { 
        // Set the current text selection in span with class codechunk
        let selection = document.getSelection();
        if (!selection)
            return;
        // Check if it is codechunk
        let range = selection.getRangeAt(0);
        let parentElement = range.commonAncestorContainer.parentElement;
        if (parentElement && parentElement.classList.contains("code-inline")) {
            // Remove codechunk
            let text = parentElement.textContent;
            if (!text)
                return;
            let textNode = document.createTextNode(text);
            if (!parentElement.parentElement)
                return;
            parentElement.parentElement.insertBefore(textNode, parentElement);
            parentElement.parentElement.removeChild(parentElement);
        } else {
            let selectedText = range.toString();
            let codeElement = document.createElement("span");
            codeElement.className = "code-inline";
            codeElement.textContent = selectedText;
            range.deleteContents();
            range.insertNode(codeElement);
        }
    }
    function highlight() { 
        // Set the current text selection in span with class codechunk
        let selection = document.getSelection();
        if (!selection)
            return;
        // Check if it is codechunk
        let range = selection.getRangeAt(0);
        let parentElement = range.commonAncestorContainer.parentElement;
        if (parentElement && parentElement.classList.contains("highlight-inline")) {
            // Remove codechunk
            let text = parentElement.textContent;
            if (!text)
                return;
            let textNode = document.createTextNode(text);
            if (!parentElement.parentElement)
                return;
            parentElement.parentElement.insertBefore(textNode, parentElement);
            parentElement.parentElement.removeChild(parentElement);
        } else {
            let selectedText = range.toString();
            let codeElement = document.createElement("span");
            codeElement.className = "highlight-inline";
            codeElement.textContent = selectedText;
            range.deleteContents();
            range.insertNode(codeElement);
        }
    }
    function block_highlight() { 
        // Set the current text selection in div with class
        let selection = document.getSelection();
        if (!selection)
            return;
        // Check if it is already in block
        let range = selection.getRangeAt(0);
        let parentElement = range.commonAncestorContainer.parentElement;
        if (parentElement && parentElement.classList.contains("highlight-block")) {
            // Remove it
            let text = parentElement.textContent;
            if (!text)
                return;
            let textNode = document.createTextNode(text);
            if (!parentElement.parentElement)
                return;
            parentElement.parentElement.insertBefore(textNode, parentElement);
            parentElement.parentElement.removeChild(parentElement);
        } else {
            let selectedText = range.toString();
            let codeElement = document.createElement("div");
            codeElement.className = "highlight-block";
            codeElement.textContent = selectedText;
            range.deleteContents();
            range.insertNode(codeElement);
        }
    }

    const update_callback_wrapper = (e:any) => {
        update_callback(e);
    }

    return <><RichTextEditorComponent
                locale="fr"
                value={content || ''}
                fontColor={fontColors}
                backgroundColor={fontColors}
                change={(e:any) => update_callback_wrapper(e.value)}
                autoSaveOnIdle={true} 
                saveInterval={100}
                fontSize={{default:'16px', width:'70px',items: [{text:'Petit', value:'13px'}, {text:'Normal', value:'16px'}]}}
                
                toolbarSettings={{
                    items: items,
                    type: ToolbarType.Expand
                }}
                
                insertImageSettings={{
                    saveFormat: 'Base64',
                }}
                
                >
            <Inject services={[Toolbar, Image, Link, HtmlEditor, QuickToolbar, Table]} />
        </RichTextEditorComponent>
        <Text fontSize={'xs'}>Note : pour éviter de coller du texte avec son formattage, utilisez ctrl+shift+v.</Text>
        </>
}

export default TextEditor;