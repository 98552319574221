import { Box, Button, Center, Collapse, Divider, Flex, Input, Spacer, Text, Tooltip, useDisclosure } from "@chakra-ui/react";
import { faCirclePlus, faLightbulb as darkBulb } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TextEditor from "../TextEditor";
import { DeleteIcon } from "@chakra-ui/icons";
import { useEffect, useState } from "react";
import { updateDynamicFragmentHints } from "../../../API/fragment";
import { faLightbulb } from "@fortawesome/free-regular-svg-icons";

type HintsCollapseProps = {
    hints: {title:string, content:string}[];
    dynamic_fragment_id: string;
};

const HintsCollapse: React.FC<HintsCollapseProps> = ({hints, dynamic_fragment_id}) => {
    const { isOpen, onToggle } = useDisclosure()
    const [hints_list, setHintsList] = useState<{id:number, title:string, content:string}[]>([]);

    useEffect(() => {
        // complete the hints_list with an increasing id
        let new_list:{id:number, title:string, content:string}[] = [];
        hints.forEach((hint, i) => {
            new_list.push({id:i, title:hint.title, content:hint.content});
        });
        setHintsList(new_list);
    }, [hints]);

    const new_hint = () => {
        let new_list = [...hints_list, {id: hints_list.filter(hint => hint.id !== undefined).reduce((max, hint) => hint.id! > max ? hint.id! : max, 0) + 1
            , title: '', content: ''}];
        setHintsList(new_list);
        // Update db with the new list but without the id
        updateDynamicFragmentHints(dynamic_fragment_id, new_list.map((hint) => {
            return {title: hint.title, content: hint.content};
        }));
    }

    const delete_hint = (id:number) => {
        let new_list:{id:number, title:string, content:string}[] = [];
        hints_list.forEach((hint, i) => {
            if (hint.id !== id) {
                new_list.push(hint);
            }
        });
        setHintsList(new_list);
        // delete in DB
        updateDynamicFragmentHints(dynamic_fragment_id, new_list.map((hint) => {
            return {title: hint.title, content: hint.content};
        }));
    }

    const update_hint_title = (id:number, title:string) => {
        let new_list = hints_list.map((hint, i) => {
            if (hint.id === id) {
                hint.title = title;
            }
            return hint;
        });
        setHintsList(new_list);
        // update in DB
        updateDynamicFragmentHints(dynamic_fragment_id, new_list.map((hint) => {
            return {title: hint.title, content: hint.content};
        }));
    }

    const update_hint_content = (id:number, content:string) => {
        let new_list = hints_list.map((hint, i) => {
            if (hint.id === id) {
                hint.content = content;
            }
            return hint;
        });
        setHintsList(new_list);
        // update in DB
        updateDynamicFragmentHints(dynamic_fragment_id, new_list.map((hint) => {
            return {title: hint.title, content: hint.content};
        }));
    }
      
    return (
      <Box p={2}>
        <Flex>
            <Spacer />
            <Tooltip label={isOpen ? 'Cacher les indices' : 'Afficher les indices'} bgColor={'blue.500'} placement={'bottom'} hasArrow>
                <Button mt={2} size={'sm'} onClick={onToggle} colorScheme={'telegram'} leftIcon={<FontAwesomeIcon icon={isOpen ? darkBulb : faLightbulb} />}>{hints_list.length == 0 ? 'Indices' : 'Indices ('+hints_list.length+')'}</Button>
            </Tooltip>
        </Flex>
        <Collapse in={isOpen} animateOpacity>
            <Divider mt={2} mb={2} />

            <Text fontSize={'sm'} fontWeight={'bold'} color={'blue.500'} mb={2} cursor={'default'}>Indices du fragment</Text>

            <Text fontSize={'sm'} mb={2} color={'gray'}>Ces indices apparaissent les uns après les autres lorsque l'étudiant clique sur le bouton "Voir un indice".</Text>

            {hints_list.length == 0 &&
                <Center>
                    <Text fontSize={"sm"}>Aucun indice</Text>
                </Center>
            }

            {hints_list.map((hint) => {
                return <Box key={hint.id} bgColor={'#fafaff'} p={3} border={'1pt solid #abacc9'} rounded={'xl'} mb={2}>
                    <Flex mb={2}>
                        <Text me={2} mt={1}>Titre de l'indice : </Text>
                        <Input
                            onChange={(e:any) => { update_hint_title(hint.id, e.target.value); }}
                            maxWidth={'400px'}
                            defaultValue={hint.title}
                            bgColor={'white'}
                            placeholder="Titre" size='sm' />
                        <Spacer />
                        <Tooltip label={'Supprimer l\'indice'} bgColor={'blue.500'} placement={'left'} hasArrow>
                            <Button ms={2} size={'sm'} colorScheme={'blue'} onClick={() => {delete_hint(hint.id)}}><DeleteIcon /></Button>
                        </Tooltip>
                    </Flex>
                    <TextEditor update_callback={(newcontent:any) => {update_hint_content(hint.id, newcontent)}} content={hint.content} key={hint.id} />
                </Box>
            })}

            <Box>
                <Flex>
                    {/* <Spacer /> */}
                    <Button size={'sm'} onClick={() => {new_hint()}} variant='outline' colorScheme={'telegram'} leftIcon={<FontAwesomeIcon icon={faCirclePlus} />}>Nouvel indice</Button>
                </Flex>
            </Box>
        </Collapse>
      </Box>
    )
}

export default HintsCollapse;