import { AbsoluteCenter, Box, Button, Checkbox, Divider, Flex, Image, Input, Spacer, Tab, TabList, Tabs, Text, Tooltip } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import AceEditor from 'react-ace'

// import mode-<language> , this imports the style and colors for the selected language.
import 'ace-builds/src-noconflict/mode-c_cpp'
import 'ace-builds/src-noconflict/mode-assembly_x86'
import 'ace-builds/src-noconflict/mode-text'
import 'ace-builds/src-noconflict/theme-dracula'
import 'ace-builds/src-noconflict/theme-chrome'
import 'ace-builds/src-noconflict/ext-language_tools'
import 'ace-builds/src-noconflict/ext-beautify'
import TextEditor from "../TextEditor";
import { faFileCirclePlus, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ViewOffIcon } from "@chakra-ui/icons";


type CodeFragmentProps = {
    body: any;
    update_callback: Function;
};

const CodeFragment: React.FC<CodeFragmentProps> = ({body, update_callback}) => {

    const [code, setCode] = useState<any>({
        content: '',
        initial_code: [{
            file: 'file.c',
            before: '',
            content: '',
            after: '',
            secret:false
        }],
        compilable:true
    });
    const [currentFile, setCurrentFile] = useState<number>(0);
    const [filenameChange, setFilenameChange] = useState<boolean>(false);

    useEffect(() => {
        if (body == null) {
            // Create initial file.c with empty content
            setCode({
                content: '',
                initial_code: [{
                    file: 'main.c',
                    before: '',
                    content: '',
                    after: '',
                    secret:false
                }]
            });
        } else {
            setCode(body);
            if (!body.compilable) {
                update_compilable(false);
            }
        }
    }, [body]);

    useEffect(() => {
        // Add drop event to every component which classname is ace-editor
        const ace_editors = document.getElementsByClassName("ace_content");
        for (let i = 0; i < ace_editors.length; i++) {
            const element = ace_editors[i];
            element.addEventListener("drop", (event: any) => {
                event.preventDefault();
                event.stopPropagation();
                return false;
            });
        }

        // enter key
        document.addEventListener("keydown", (event) => {
            if (event.key == "Enter") {
                setFilenameChange(false);
            }
        });
    }, []);

    const update_code_content = (section:string, new_code:string) => {
        if (!code.initial_code)
            code.initial_code = [];
        let new_body:any = {
            ...code,
            initial_code: code.initial_code.map((file:any, index:number) => {
                if (index == currentFile) {
                    return {
                        ...file,
                        [section]: new_code
                    }
                }
                return file;
            })
        }
        setCode(new_body);
        update_callback(new_body);
    }

    const update_compilable = (new_compilable:boolean) => {
        let new_body:any = {
            ...code,
            compilable:new_compilable
        }
        setCode(new_body);
        update_callback(new_body);
    }

    const update_statement = (new_statement:string) => {
        let new_body:any = {
            ...code,
            content:new_statement
        }
        setCode(new_body);
        update_callback(new_body);
    }

    const update_filename = (index:number, new_file:string) => {
        if (!code.initial_code)
            code.initial_code = [];
        if (new_file == '')
            new_file = 'new_file.c';
        let new_body:any = {
            ...code,
            initial_code: code.initial_code.map((file:any, i:number) => {
                if (i == index) {
                    return {
                        ...file,
                        file:new_file
                    }
                }
                return file;
            })
        }
        setCode(new_body);
        update_callback(new_body);
    }

    const create_file = () => {
        if (!code.initial_code)
            code.initial_code = [];
        let new_body:any = {
            ...code,
            initial_code: [...code.initial_code, {
                file: 'new_file.c',
                before: '',
                content: '',
                after: '',
                secret:false
            }]
        }
        setCode(new_body);
        update_callback(new_body);
    }

    const delete_file = (index:number) => {
        if (!code.initial_code)
            code.initial_code = [];
        let new_body:any = {
            ...code,
            initial_code: code.initial_code.filter((_:any, i:number) => i !== index)
        }
        setCode(new_body);
        update_callback(new_body);
        if (currentFile >= index)
            setCurrentFile(0);
    }

    const file_mode = () => {
        const filename:string = code.initial_code[currentFile].file;
        if (filename.endsWith(".c") || filename.endsWith(".h"))
            return 'c_cpp';
        else if (filename.endsWith(".txt"))
            return 'text';
        else if (filename.endsWith(".s"))
            return 'assembly_x86';
        return 'c_cpp';
    }

    return <>
        <Text fontSize={'sm'} fontWeight={'bold'} color={'purple.500'} mb={2} cursor={'default'}>Texte explicatif (facultatif)</Text>
        
        <TextEditor content={code.content} update_callback={update_statement} />

        <Checkbox ms={2} mt={3} isChecked={code.compilable} onChange={(e:any) => { update_compilable(e.target.checked); }}>Peut être compilé</Checkbox>
        
        <Text mt={2} fontSize={'sm'} fontWeight={'bold'} color={'purple.500'} mb={2} cursor={'default'}>Code initial (facultatif)</Text>

        <Flex mt={0} p={1} mb={2}>
            <Text fontSize={'sm'} me={2}>Nom du fichier : </Text>
            <Input errorBorderColor={'red.300'} 
                isInvalid={code.initial_code && code.initial_code[currentFile].file == ''}
                onChange={(e:any) => { update_filename(currentFile, e.target.value); }}
                value={code.initial_code ? code.initial_code[currentFile].file : 'file.c'}
                width={'150px'}
                placeholder='Nom du fichier'
                size='xs' />
            <Spacer />
                <Checkbox isChecked={code.initial_code && code.initial_code[currentFile].secret} 
                        onChange={
                            (e:any) => { update_code_content("secret", e.target.checked); }
                        }
                        ms={3} size={'sm'} colorScheme="red" position={'relative'} top={'2px'}
                ><Tooltip label="Fichier invisible mais utilisé dans la compilation" placement="top" hasArrow><Text>Fichier invisible pour l'étudiant</Text></Tooltip></Checkbox>
        </Flex>
        
        
        <Flex mt={0} border={'1pt solid gray'} borderBottom={0} bgColor={'#222226'} p={0} style={{ overflowX: 'auto' }}>
            <Tabs variant='enclosed' index={currentFile} onChange={(index) => {
                setCurrentFile(index);
            }}>
                <TabList>
                    {code.initial_code && code.initial_code.length > 0 && code.initial_code.map((file:any, index:number) => {
                        return <Tab key={index} fontSize={'sm'} fontFamily={'JetBrains Mono'} p={1} color={'gray'} _selected={{ color: 'white' }} onDoubleClick={() =>{
                            setFilenameChange(!filenameChange);
                        }}>
                            {!filenameChange &&
                                <Text>{file.file} {file.secret ? <ViewOffIcon /> : null}</Text>
                            }
                            {filenameChange &&
                                <Input errorBorderColor={'red.300'} 
                                    isInvalid={file.file == ''}
                                    onChange={(e:any) => { update_filename(index, e.target.value); }}
                                    defaultValue={file.file}
                                    width={'100px'}
                                    placeholder='Nom du fichier' size='xs' />
                            }
                            {code.initial_code && code.initial_code.length > 1 &&
                                <Tooltip label="Supprimer le fichier" placement="top" hasArrow>
                                    <Box ms={2} p={1} pt={0} pb={0} rounded={'md'} _hover={{bgColor:'#3c3c42'}} onClick={() => {delete_file(index)}}><FontAwesomeIcon icon={faXmark} /></Box>
                                </Tooltip>
                            }
                        </Tab>
                    })}
                </TabList>
            </Tabs>
            
            <Tooltip label="Ajouter un fichier" placement="top" hasArrow>
                <Box ms={2} mt={1} color="gray" cursor={'pointer'} _hover={{color:'white'}} onClick={() => {create_file()}}>
                    <FontAwesomeIcon icon={faFileCirclePlus} />
                </Box>
            </Tooltip>
        </Flex>
        
        {!code.initial_code[currentFile].secret && <>
            <Flex mt={0} border={'1pt solid gray'} borderBottom={0} bgColor={'#222226'} p={0}>
                <Text p={1} fontSize={'sm'} fontWeight={'bold'} color={'purple.300'} cursor={'default'}>Partie supérieure</Text>
            </Flex>
            <Box shadow={'sm'} border={'1pt solid gray'}>
                <AceEditor height="250px" width="100%" mode={file_mode()} fontSize={14} 
                onChange={
                    (new_content) => { update_code_content("before", new_content); }
                } 
                defaultValue={code.initial_code && currentFile < code.initial_code.length && code.initial_code.length > 0 ? code.initial_code[currentFile].before : ''}
                value={code.initial_code && currentFile < code.initial_code.length && code.initial_code.length > 0 ? code.initial_code[currentFile].before : ''}
                theme={'dracula'}
                />
            </Box>
        </>}

        <Flex mt={0} border={'1pt solid gray'} borderBottom={0} bgColor={'#222226'} p={0}>
            <Text p={1} fontSize={'sm'} fontWeight={'bold'} color={'purple.300'} cursor={'default'}>{!code.initial_code[currentFile].secret ? "Partie modifiable par l'étudiant" : "Contenu du fichier"}</Text>
        </Flex>
        <Box shadow={'sm'} border={'1pt solid gray'}>
            <AceEditor height="250px" width="100%" mode={file_mode()} fontSize={14} 
            onChange={
                (new_content) => { update_code_content("content", new_content); }
            } 
            defaultValue={code.initial_code && currentFile < code.initial_code.length && code.initial_code.length > 0 ? code.initial_code[currentFile].content : ''}
            value={code.initial_code && currentFile < code.initial_code.length && code.initial_code.length > 0 ? code.initial_code[currentFile].content : ''}
            theme={'dracula'}
            />
        </Box>


        {!code.initial_code[currentFile].secret && <>
            <Flex mt={0} border={'1pt solid gray'} borderBottom={0} bgColor={'#222226'} p={0}>
                <Text p={1} fontSize={'sm'} fontWeight={'bold'} color={'purple.300'} cursor={'default'}>Partie inférieure</Text>
            </Flex>
            <Box shadow={'sm'} border={'1pt solid gray'}>
                <AceEditor height="250px" width="100%" mode={file_mode()} fontSize={14} 
                onChange={
                    (new_content) => { update_code_content("after", new_content); }
                } 
                defaultValue={code.initial_code && currentFile < code.initial_code.length && code.initial_code.length > 0 ? code.initial_code[currentFile].after : ''}
                value={code.initial_code && currentFile < code.initial_code.length && code.initial_code.length > 0 ? code.initial_code[currentFile].after : ''}
                theme={'dracula'}
                />
            </Box>
        </>}
    </>
};

export default CodeFragment;