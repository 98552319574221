import React, { useEffect } from "react";
import JSConfetti from "js-confetti";

const Kodami:React.FC = () => {
    const jsConfetti = new JSConfetti();
    useEffect(() => {
        // easter egg when the user types Konami Code
        const konamiCode = ['ArrowUp', 'ArrowUp', 'ArrowDown', 'ArrowDown', 'ArrowLeft', 'ArrowRight', 'ArrowLeft', 'ArrowRight', 'b', 'a'];
        let konamiCodePosition = 0;
        document.addEventListener('keydown', (e) => {
            const key = e.key;
            const requiredKey = konamiCode[konamiCodePosition];
            if (key == requiredKey) {
                konamiCodePosition++;
                if (konamiCodePosition == konamiCode.length) {
                    jsConfetti.addConfetti({
                        emojis: ['🖥️', '⚡️', '💾', '✨', '💿', '🖱️'],
                        emojiSize: 40,
                        confettiNumber: 100,
                    })

                    konamiCodePosition = 0;
                }
            } else {
                konamiCodePosition = 0;
            }
        }
        );
    }, []);

    return (
        <></>
    );
}

export default Kodami;