// Rich text editor
import React from "react";
import { Box, Checkbox, Divider, Input, Text } from '@chakra-ui/react';
import { IDynamicFragment } from '../../../types';
import TextEditor from '../TextEditor';

type TextualSPProps = {
    body: IDynamicFragment;
    update_callback: Function;
};

const TextualSP: React.FC<TextualSPProps> = ({body, update_callback}) => {

    const [textSp, setTextSp] = React.useState<IDynamicFragment>(body);

    const update_content = (value:string) => {
        const new_textSp = {
            ...textSp,
            content: value
        };
        setTextSp(new_textSp);
        update_textSp(new_textSp);
    }

    const update_textSp = (new_textSp:IDynamicFragment) => {
        update_callback(new_textSp);
    }

    const update_assoc_id = (assoc_id:string) => {
        let new_body:IDynamicFragment = {
            ...textSp,
            association_id:assoc_id
        }
        setTextSp(new_body);
        update_callback(new_body);
    }

    return <Box ps={2} pe={2}>
        <Text fontSize={'sm'} fontWeight={'bold'} color={'purple.500'} mb={2} cursor={'default'}>Association</Text>
        
        <Text mb={2} ms={0} mt={3} color={'gray.600'} fontSize={'sm'}>Spécifiez l'identifiant de cette découpe en SP, s'il est nécessaire de la lier à une construction de code.</Text>
        
        <Input errorBorderColor={'red.300'} 
                onChange={(e:any) => { update_assoc_id(e.target.value); }}
                ms={0} width={'150px'} 
                defaultValue={textSp.association_id}
            placeholder='Identifiant' size='xs' />

        <Divider mb={2} mt={2} />

        <Text fontSize={'sm'} fontWeight={'bold'} color={'purple.500'} mb={2} cursor={'default'}>Énoncé de la découpe en SPs</Text>
        
        <TextEditor content={textSp.content} update_callback={update_content} />

        <Text mt={3} color={'gray.600'} fontSize={'sm'}>Il n'y a aucun champ à remplir pour ce fragment. L'étudiant·e définira le nombre de sous-problèmes et leur contenu.</Text>

        </Box>

};


export default TextualSP;