// Rich text editor
import React from "react";
import { Box, Button, Checkbox, Flex, IconButton, Input, InputGroup, InputLeftAddon, InputRightAddon, Stack, Text, Tooltip } from '@chakra-ui/react';
import { DeleteIcon, PlusSquareIcon } from '@chakra-ui/icons';
import { IDynamicFragment } from '../../../types';
import TextEditor from '../TextEditor';

type MCQFragmentProps = {
    body: IDynamicFragment;
    update_callback: Function;
};

const MCQFragment: React.FC<MCQFragmentProps> = ({body, update_callback}) => {

    const [mcq, setMcq] = React.useState<IDynamicFragment>(body);

    const new_choice = () => {
        const new_mcq = {
            ...mcq,
            mcq_choices: [
                ...mcq.mcq_choices ?? [],
                {
                    content: '',
                    correct: false
                }
            ]
        };
        setMcq(new_mcq);
        update_mcq(new_mcq);
    }

    const delete_choice = (index:number) => {
        let new_mcq = {
            ...mcq,
            mcq_choices: mcq.mcq_choices?.filter((choice, i) => i !== index)
        };
        // At least one choice must be correct
        if (!new_mcq.mcq_choices?.some(choice => choice.correct)) {
            new_mcq = {
                ...new_mcq,
                mcq_choices: new_mcq.mcq_choices?.map((choice, i) => {
                    if (i === 0) {
                        choice.correct = true;
                    }
                    return choice;
                })
            };
        }
        setMcq(new_mcq);
        update_mcq(new_mcq);
    }

    const update_choice = (index:number, value:string) => {
        const new_mcq = {
            ...mcq,
            mcq_choices: mcq.mcq_choices?.map((choice, i) => {
                if (i === index) {
                    choice.content = value;
                }
                return choice;
            })
        };
        setMcq(new_mcq);
        update_mcq(new_mcq);
    }

    const update_correct_choice = (index:number, value:boolean) => {
        let new_mcq = {
            ...mcq,
            mcq_choices: mcq.mcq_choices?.map((choice, i) => {
                if (i === index) {
                    choice.correct = value;
                } else {
                    if (!mcq.mcq_multiple)
                        choice.correct = false;
                }
                return choice;
            })
        };
        // At least one choice must be correct
        if (!new_mcq.mcq_choices?.some(choice => choice.correct)) {
            new_mcq = {
                ...new_mcq,
                mcq_choices: new_mcq.mcq_choices?.map((choice, i) => {
                    if (i === 0) {
                        choice.correct = true;
                    }
                    return choice;
                })
            };
        }
        setMcq(new_mcq);
        update_mcq(new_mcq);
    }

    const update_multiple = (value:boolean) => {
        let new_mcq = {
            ...mcq,
            mcq_multiple: value
        };
        // Only one choice can be correct if the question is not multiple
        if (!value) {
            // Only leave the first correct choice
            new_mcq = {
                ...new_mcq,
                mcq_choices: new_mcq.mcq_choices?.map((choice, i) => {
                    if (i === 0) {
                        choice.correct = true;
                    } else {
                        choice.correct = false;
                    }
                    return choice;
                })
            };
        }
        setMcq(new_mcq);
        update_mcq(new_mcq);
    }

    const update_mcq = (new_mcq:IDynamicFragment) => {
        update_callback(new_mcq);
    }

    const update_content = (value:string) => {
        const new_mcq = {
            ...mcq,
            content: value
        };
        setMcq(new_mcq);
        update_mcq(new_mcq);
    }

    return <Box ps={2} pe={2}>
        <Text fontSize={'sm'} fontWeight={'bold'} color={'purple.500'} mb={2} cursor={'default'}>Énoncé du QCM</Text>
        <TextEditor content={mcq.content} update_callback={update_content} />
        <Text fontSize={'sm'} fontWeight={'bold'} color={'purple.500'} mb={2} mt={2} cursor={'default'}>Réponses</Text>
        <Stack spacing={4}>
            {mcq.mcq_choices?.map((choice:any, index:number) => {
                return  <InputGroup key={index}>
                            <InputLeftAddon bgColor={'blackAlpha.100'}><Checkbox isChecked={choice.correct} onChange={
                                (e:any) => { update_correct_choice(index, e.target.checked); }
                            } colorScheme='green' borderColor={'blackAlpha.300'}>Correct</Checkbox></InputLeftAddon>
                            <Input 
                                value={choice.content}
                                onChange={(e:any) => { update_choice(index, e.target.value); }}
                             />
                            <InputRightAddon>
                            
                                <Tooltip label={'Supprimer la réponse'}>
                                    <IconButton isDisabled={mcq.mcq_choices?.length == 1}
                                                aria-label="Delete" 
                                                onClick={() => { delete_choice(index); }}
                                                icon={<DeleteIcon />} />
                                </Tooltip>
                            </InputRightAddon>
                        </InputGroup>
            })}
        </Stack>
        <Flex>
        <Button mt={2} size={'sm'} onClick={ () => { new_choice(); }} colorScheme={'purple'} leftIcon={<PlusSquareIcon />}>Ajouter une réponse</Button>
        <Checkbox mt={1} ms={2} size={'md'} isChecked={mcq.mcq_multiple} onChange={ (e:any) => {
            update_multiple(e.target.checked);
        } } colorScheme='purple'>Plusieurs bonnes réponses</Checkbox>
        </Flex>
        </Box>

};


export default MCQFragment;