import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Button, Center, Heading, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { IReminder } from "../types";
import { getReminder } from "../API/reminder";
import FragmentsList from "./FragmentsList";
import { THEME_COLORS } from "../config";
import { useParams } from "react-router-dom";
import { getReminderProgress, updateReminderProgress } from "../API/reminderinstance";
import toc_mp3 from "../resources/sound/toc.mp3";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type ReminderProps = {
    reminder_id:string;
};

const Reminder: React.FC<ReminderProps> = ({reminder_id}) => {
    const [currentReminder, setCurrentReminder] = useState<IReminder>({title: '', content: [], _id: ''});
    const [step, setStep] = useState<number>(1);
    const next_step_sound = new Audio(toc_mp3);

    useEffect(() => {
        // Get reminder from API
        getReminder(reminder_id).then((res) => {
            if (res.data.reminder) {
                setCurrentReminder(res.data.reminder);
                getReminderProgress(reminder_id).then((res) => {
                    setStep(res.data.reminder_step);
                });
            }
        });
    }
    , [reminder_id]);

    const nextStep = () => {
        const old_step = step;
        setStep(step + 1);
        next_step_sound.volume = 1;
        next_step_sound.play();

        const current_fragment = currentReminder.content[old_step-1];
        updateReminderProgress(reminder_id, current_fragment).then((res) => {
            document.dispatchEvent(new Event('PROGRESS_UPDATED'));
        });

        // Smoothly scroll the page down to the bottom after 0.5s
        setTimeout(() => {
            window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
        }, 100);
    }

    return <Box>
        <Center>
            <Heading size={'xl'} color={THEME_COLORS.REMINDERS} mt={5} p={0}>{currentReminder.title}</Heading>
        </Center>

        <FragmentsList fragments_ids={currentReminder.content} step={step} />
        
        {currentReminder.content.length > 0 && step <= currentReminder.content.length &&
            <Button mt={4} colorScheme='teal' onClick={() => nextStep()}>Lire la suite</Button>
        }
        
        {currentReminder.content.length > 0 && step > currentReminder.content.length &&
            <Center mt={5}>
                <Box p={3} bgColor={"gray.100"}>
                    <Text fontWeight={'medium'} fontSize={'11pt'}><FontAwesomeIcon icon={faCheck} size="lg" style={{marginRight:"5px"}} /> Il n'y a plus rien à lire dans cette partie, bien joué !</Text>
                </Box>
            </Center>
        }
        </Box>
};


export default Reminder;