import { Box, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Tooltip, useDisclosure } from "@chakra-ui/react";
import reminder_book from '../resources/reminder_book.svg';
import map from '../resources/map.png';
import Reminders from "./Reminders";
import { useEffect, useState } from "react";
import { getFavoriteReminders } from "../API/userparams";

interface RemindersModalProps {
    isOpen:any;
    onClose:any;
}
const RemindersModal: React.FC<RemindersModalProps> = ({isOpen, onClose}) => {
    const [favoriteReminders, setFavoriteReminders] = useState<string[]>([]);

    useEffect(() => {
        getFavoriteReminders().then((res) => {
            setFavoriteReminders(res.data.ids);
        });
    }, []);

    return <Modal size={"4xl"} isOpen={isOpen} onClose={onClose}>
                <ModalOverlay  
                    bg='none'
                    backdropFilter='auto'
                    backdropInvert='80%'
                    backdropBlur='2px'
                />
                <ModalContent>
                    <ModalHeader fontWeight={700}>Mes rappels préférés</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody p={5}>
                        <Reminders reminders_id={favoriteReminders} integrated={true} withTitle={false} withPicture={false} />
                    </ModalBody>
                </ModalContent>
            </Modal>
}

const Toolbox: React.FC = () => {
    const reminders_disclosure = useDisclosure();
    const map_disclosure = useDisclosure();

    return <>
        <RemindersModal isOpen={reminders_disclosure.isOpen} onClose={reminders_disclosure.onClose} />

        <Box p={5} style={{position:'fixed', bottom:0, right:0, zIndex:1000}}>
            <Tooltip label="Mes rappels préférés" p={2} placement='left' bg={'green.500'} hasArrow>
                <Box onClick={reminders_disclosure.onOpen} cursor={'pointer'} className='hvr-grow' me={2} w={35} h={35} bgImage={reminder_book} bgSize={'contain'} bgRepeat={'no-repeat'} bgPosition={'center'} />
            </Tooltip>
            {/* <Tooltip label="Carte" p={2} placement='left' bg={'blue.500'} hasArrow>
                <Box cursor={'pointer'} className='hvr-grow' me={2} w={35} h={35} bgImage={map} bgSize={'contain'} bgRepeat={'no-repeat'} bgPosition={'center'} />
            </Tooltip> */}
        </Box>
    </>
}

export default Toolbox;