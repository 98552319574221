import React, { useEffect, useState } from "react";
import { Box, Button, Center, Flex, Grid, Image, Input, PinInput, PinInputField, Spacer, Stack, Text, Textarea, Tooltip, useDisclosure } from '@chakra-ui/react';
import { CheckIcon } from '@chakra-ui/icons';
import { IDynamicFragment, IGCAnswer } from '../../../types';
import LatexText from "../LatexText";
import HintBulb from "./HintBulb";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faMinus, faPlus, faXmark } from "@fortawesome/free-solid-svg-icons";
import Latex from "react-latex";

type CalculusFragmentProps = {
    body: IDynamicFragment;
    display_hint_callback: Function;
    update_answer_callback: Function;
    check_answer: Function;
    latest_answer: IGCAnswer | null;
};

const CalculusFragment: React.FC<CalculusFragmentProps> = ({body, display_hint_callback, update_answer_callback, latest_answer, check_answer}) => {
    const operation:string = body.calculus?.operation || "add";
    const bitsNb:number = body.calculus?.bits || 8;

    const [format, setFormat] = React.useState<string>(body.decbin?.format || 'unsigned');
    const [colorHint, setColorHint] = React.useState<boolean>(body.decbin?.hint || false);
    const [submitted, setSubmitted] = React.useState<boolean>(false);
    const [corrected, setCorrected] = React.useState<boolean>(false);
    const [correct, setCorrect] = React.useState<boolean>(false);
    const mantisse_disclosure = useDisclosure();
    const gg:string[] = [
        "Bien joué !",
        "Bravo !",
        "Génial !",
        "Parfait !",
        "Super !",
        "Très bien !",
        "Tu as tout bon !",
    ];
    const gg_index = Math.floor(Math.random() * gg.length);
    const bad:string[] = [
        "Mauvaise réponse...",
        "Oups... Mauvaise réponse...",
        "Mauvaise réponse..."
    ];
    const bad_index = Math.floor(Math.random() * bad.length);
    const [fb_sentence, setFbSentence] = useState<string>('');
    
    const [answer, setAnswer] = React.useState<{
        nb1: string[];
        nb2: string[];
        carries: {bit:string,visible:boolean,editing:boolean}[][];
        steps: string[][];
        result: string[];
    }>({
        nb1: [...Array(bitsNb)].map(() => ""),
        nb2: [...Array(bitsNb)].map(() => ""),
        carries: [[...Array(bitsNb)].map(() => {return {bit:"",visible:false,editing:false}})],
        steps: [...Array(bitsNb)].map(() => [...Array(bitsNb)].map(() => "")),
        result: [...Array(bitsNb)].map(() => ""),
    });

    const update_result = (index:number, ans:string) => {
        if (!ans.includes("0") && !ans.includes("1")) {
            ans = "";
        }
        if (ans.length > 1) {
            let previous_bit = answer.result[index];
            if (previous_bit == "1" && ans.includes("0"))
                ans = "0";
            else if (previous_bit == "0" && ans.includes("1"))
                ans = "1";
            else
                ans = ans.slice(0, 1);
        }
        setAnswer(prevAnswer => {
            const newAnswer = {...prevAnswer};
            newAnswer.result[index] = ans;
            update_answer(newAnswer);
            return newAnswer;
        });
    }

    useEffect(() => {
        if (latest_answer) {
            setAnswer(latest_answer.answer);
            setSubmitted(latest_answer.submitted);
            setCorrect(latest_answer.correct);
            if (latest_answer.correct) {
                setFbSentence(gg[gg_index]);
            } else {
                setFbSentence(bad[bad_index]);
            }
            setCorrected(latest_answer.submitted);
        }
    }, [latest_answer]);

    const check_answer_callback = () => {
        setSubmitted(true);
        check_answer(answer);
    }

    const update_answer = (newAnswer:any) => {
        update_answer_callback(newAnswer);
    }

    const [dividerWidth, setDividerWidth] = React.useState<number>(0);
    useEffect(() => {
        // set the divider width to the width of nb1_cells
        const nb1_cells = document.getElementById("nb1_cells");
        if (nb1_cells) {
            setDividerWidth(nb1_cells.clientWidth);
        }
    }, []);
    
    const update_carry = (i:number, j:number, value:string) => {
        if (value.length > 1) {
            value = value.slice(0, 1);
        }

        // Set new bit value
        // Set visibility to true if the bit is not empty
        let newCarries = answer.carries.slice();
        let newCarriesCopy = newCarries.slice();
        newCarriesCopy[i][j].bit = value;
        newCarriesCopy[i][j].visible = value != "";

        // If one row is empty, remove it (if length > 1)
        if (newCarriesCopy.length > 1) {
            for (let k = 0; k < newCarriesCopy.length; k++) {
                let row_filled = false;
                for (let l = 0; l < newCarriesCopy[k].length; l++) {
                    if (newCarriesCopy[k][l].bit != "") {
                        row_filled = true;
                    }
                }
                if (!row_filled) {
                    newCarriesCopy.splice(k, 1);
                }
            }
        }

        // If every row of carries has at least one non empty bit, add a new row
        let all_rows_filled = true;
        for (let k = 0; k < newCarriesCopy.length; k++) {
            let row_filled = false;
            for (let l = 0; l < newCarriesCopy[k].length; l++) {
                if (newCarriesCopy[k][l].bit != "") {
                    row_filled = true;
                }
            }
            if (!row_filled) {
                all_rows_filled = false;
            }
        }
        if (all_rows_filled) {
            newCarriesCopy.unshift([...Array(bitsNb)].map(() => {return {bit:"",visible:false,editing:false}}));
        }

        // If one of the carry bits is empty, set its visibility to false
        for (let k = 0; k < newCarriesCopy.length; k++) {
            for (let l = 0; l < newCarriesCopy[k].length; l++) {
                if (newCarriesCopy[k][l].bit == "") {
                    newCarriesCopy[k][l].visible = false;
                }
            }
        }

        setAnswer(prevAnswer => {
            const newAnswer = {...prevAnswer};
            newAnswer.carries = newCarriesCopy;
            update_answer(newAnswer);
            return newAnswer;
        });
    }

    const update_nb1 = (i:number, value:string) => {
        if (!value.includes("0") && !value.includes("1")) {
            value = "";
        }
        if (value.length > 1) {
            let previous_bit = answer.nb1[i];
            if (previous_bit == "1" && value.includes("0"))
                value = "0";
            else if (previous_bit == "0" && value.includes("1"))
                value = "1";
            else
                value = value.slice(0, 1);
        }
        let prevNb1 = answer.nb1.slice();
        const newNb1 = [...prevNb1];
        newNb1[i] = value;
        setAnswer(prevAnswer => {
            const newAnswer = {...prevAnswer};
            newAnswer.nb1 = newNb1;
            update_answer(newAnswer);
            return newAnswer;
        });
    }
    const update_nb2 = (i:number, value:string) => {
        if (!value.includes("0") && !value.includes("1")) {
            value = "";
        }
        if (value.length > 1) {
            let previous_bit = answer.nb2[i];
            if (previous_bit == "1" && value.includes("0"))
                value = "0";
            else if (previous_bit == "0" && value.includes("1"))
                value = "1";
            else
                value = value.slice(0, 1);
        }
        let prevNb2 = answer.nb2.slice();
        const newNb2 = [...prevNb2];
        newNb2[i] = value;
        setAnswer(prevAnswer => {
            const newAnswer = {...prevAnswer};
            newAnswer.nb2 = newNb2;
            update_answer(newAnswer);
            return newAnswer;
        });
    }

    // Steps rows
    const update_steps_row = (i:number, j:number, value:string) => {
        if (value.length > 1) {
            value = value.slice(0, 1);
        }

        // Set new bit value
        // Set visibility to true if the bit is not empty
        let newStepsRows = answer.steps.slice();
        let newStepsRowsCopy = newStepsRows.slice();
        newStepsRowsCopy[i][j] = value;
        setAnswer(prevAnswer => {
            const newAnswer = {...prevAnswer};
            newAnswer.steps = newStepsRowsCopy;
            update_answer(newAnswer);
            return newAnswer;
        });
    }

    return <>
    <Text fontSize={'sm'} fontWeight={'bold'} color={'blackAlpha.800'} mb={2} cursor={'default'}>À toi de jouer !</Text>
    <Box shadow={'md'} bgColor={'#F0F0F0'} rounded={8} pt={2}>
        <Box rounded={8} m={4} p={4} bgColor={'white'} border={'1pt solid #e6e6e6'} mb={5} color={'black'}>
            <LatexText content={body.content} />
        </Box>

        {operation === "add" &&
        <Box p={5} pt={0}>
            {answer.carries.map((carry, i) => (
                <Center mb={0} key={i}>
                    {/* CARRIES */}
                    <Box bgColor={"white"} width={"32px"} height={"32px"} opacity={0}></Box>
                    {carry.map((carry_bit, j) => (
                        <Input
                        isDisabled={submitted}
                        p={0}
                        value={carry_bit.bit}
                        color={"black"}
                        rounded={"none"}
                        onChange={(e) => {
                            update_carry(i, j, e.target.value);
                        }}
                        onMouseOver={() => {
                            let newCarries = answer.carries.slice();
                            let newCarriesCopy = newCarries.slice();
                            newCarriesCopy[i][j].visible = true;
                            setAnswer(prevAnswer => {
                                const newAnswer = {...prevAnswer};
                                newAnswer.carries = newCarriesCopy;
                                return newAnswer;
                            });
                        }}
                        onMouseOut={() => {
                            if (!carry_bit.editing) {
                                let newCarries = answer.carries.slice();
                                let newCarriesCopy = newCarries.slice();
                                newCarriesCopy[i][j].visible = false;
                                setAnswer(prevAnswer => {
                                    const newAnswer = {...prevAnswer};
                                    newAnswer.carries = newCarriesCopy;
                                    return newAnswer;
                                });
                            }
                        }}
                        onClick={() => {
                            // set carry edition
                            let newCarries = answer.carries.slice();
                            let newCarriesCopy = newCarries.slice();
                            if (newCarriesCopy[i][j].bit == "0")
                                update_carry(i, j, "");
                            else if (newCarriesCopy[i][j].bit == "1")
                                update_carry(i, j, "");
                            else
                                update_carry(i, j, "1");
                        }}
                        onBlur={() => {
                            let newCarries = answer.carries.slice();
                            let newCarriesCopy = newCarries.slice();
                            newCarriesCopy[i][j].editing = false;
                            newCarriesCopy[i][j].visible = newCarries[i][j].bit != "";
                        }}
                        opacity={carry_bit.visible ? 1 : (carry_bit.bit != "" ? 1 : 0)} key={j+"-"+i} mb={1} textAlign={"center"} width={"32px"} height={"32px"} border={"2pt solid #48bb78"} _focus={{outline:"none"}} cursor={"pointer"} bgColor={"white"} fontWeight={500} fontFamily={"Jetbrains Mono"} ms={1} />
                    ))}
                </Center>
            ))}

            {/* NUMBER 1 */}
            <Center>
                <Box bgColor={"white"} width={"32px"} height={"32px"} opacity={0}></Box>
                    {[...Array(bitsNb)].map((_, i) => (
                        <Input
                        isDisabled={submitted}
                        rounded={0}
                        value={answer.nb1[i]}
                        p={0}
                        onChange={(e) => {
                        update_nb1(i, e.target.value);
                        }}
                        // prevent from typing other characters than 0 or 1
                        onKeyDown={(e) => {
                            if (e.key !== "0" && e.key !== "1" && e.key !== "Backspace" && e.key !== "Delete") {
                                e.preventDefault();
                            }
                        }}
                        onClick={() => {
                            if (answer.nb1[i] == "0")
                                update_nb1(i, "1");
                            else if (answer.nb1[i] == "1")
                                update_nb1(i, "");
                            else
                                update_nb1(i, "0");
                        }}
                        textAlign={"center"} width={"32px"} height={"32px"} border={"2pt solid gray"} _focus={{outline:"none"}} cursor={"pointer"} bgColor={"white"} fontWeight={500} fontFamily={"Jetbrains Mono"} ms={1} key={i} />
                    ))}
            </Center>

            {/* NUMBER 2 */}
            <Center mt={1}>
                <Box textAlign={"center"} verticalAlign={"middle"} width={"32px"} height={"32px"}>
                    <Text fontFamily={"Jetbrains Mono"} fontSize={"2xl"}><FontAwesomeIcon icon={(body.calculus?.operation == "mul") ? faXmark : faPlus} /></Text>
                </Box>
                    {[...Array(bitsNb)].map((_, i) => (
                        <Input
                        isDisabled={submitted}
                        rounded={0}
                        value={answer.nb2[i]}
                        p={0}
                        onChange={(e) => {
                        update_nb2(i, e.target.value);
                        }}
                        // prevent from typing other characters than 0 or 1
                        onKeyDown={(e) => {
                            if (e.key !== "0" && e.key !== "1" && e.key !== "Backspace" && e.key !== "Delete") {
                                e.preventDefault();
                            }
                        }}
                        onClick={() => {
                            if (answer.nb2[i] == "0")
                                update_nb2(i, "1");
                            else if (answer.nb2[i] == "1")
                                update_nb2(i, "");
                            else
                                update_nb2(i, "0");
                        }}
                        textAlign={"center"} width={"32px"} height={"32px"} border={"2pt solid gray"} _focus={{outline:"none"}} cursor={"pointer"} bgColor={"white"} fontWeight={500} fontFamily={"Jetbrains Mono"} ms={1} key={i} />
                    ))}
            </Center>

            <Center>
                <Box rounded={"lg"} mt={4} mb={4} width={32*bitsNb+64+"px"} height={"2px"} bgColor={"gray"}></Box>
            </Center>
            
            <Center>
                <Box bgColor={"white"} width={"32px"} height={"32px"} opacity={0}></Box>
                    {[...Array(bitsNb)].map((_, i) => (
                        <Input
                        isDisabled={submitted}
                        rounded={0}
                        value={answer.result[i]}
                        p={0}
                        onChange={(e) => {
                            update_result(i, e.target.value);
                        }}
                        // prevent from typing other characters than 0 or 1
                        onKeyDown={(e) => {
                            if (e.key !== "0" && e.key !== "1" && e.key !== "Backspace" && e.key !== "Delete") {
                                e.preventDefault();
                            }
                        }}
                        onClick={() => {
                            if (answer.result[i] == "0")
                                update_result(i, "1");
                            else if (answer.result[i] == "1")
                                update_result(i, "");
                            else
                                update_result(i, "0");
                        }}
                        textAlign={"center"} width={"32px"} height={"32px"} border={"2pt solid gray"} _focus={{outline:"none"}} cursor={"pointer"} bgColor={"white"} fontWeight={500} fontFamily={"Jetbrains Mono"} ms={1} key={i} />
                    ))}
            </Center>
        </Box>
        }

        {operation === "mul" &&
        <Box p={5} pt={0}>
            {/* NUMBER 1 */}
            <Center>
                <Box bgColor={"white"} width={"32px"} height={"32px"} opacity={0}></Box>
                    {[...Array(bitsNb)].map((_, i) => (
                        <Input
                        isDisabled={submitted}
                        rounded={0}
                        value={answer.nb1[i]}
                        p={0}
                        onChange={(e) => {
                        update_nb1(i, e.target.value);
                        }}
                        // prevent from typing other characters than 0 or 1
                        onKeyDown={(e) => {
                            if (e.key !== "0" && e.key !== "1" && e.key !== "Backspace" && e.key !== "Delete") {
                                e.preventDefault();
                            }
                        }}
                        onClick={() => {
                            if (answer.nb1[i] == "0")
                                update_nb1(i, "1");
                            else if (answer.nb1[i] == "1")
                                update_nb1(i, "");
                            else
                                update_nb1(i, "0");
                        }}
                        textAlign={"center"} width={"32px"} height={"32px"} border={"2pt solid gray"} _focus={{outline:"none"}} cursor={"pointer"} bgColor={"white"} fontWeight={500} fontFamily={"Jetbrains Mono"} ms={1} key={i} />
                    ))}
            </Center>

            {/* NUMBER 2 */}
            <Center mt={1}>
                <Box textAlign={"center"} verticalAlign={"middle"} width={"32px"} height={"32px"}>
                    <Text fontFamily={"Jetbrains Mono"} fontSize={"2xl"}><FontAwesomeIcon icon={(body.calculus?.operation == "mul") ? faXmark : faPlus} /></Text>
                </Box>
                    {[...Array(bitsNb)].map((_, i) => (
                        <Input
                        isDisabled={submitted}
                        rounded={0}
                        value={answer.nb2[i]}
                        p={0}
                        onChange={(e) => {
                        update_nb2(i, e.target.value);
                        }}
                        // prevent from typing other characters than 0 or 1
                        onKeyDown={(e) => {
                            if (e.key !== "0" && e.key !== "1" && e.key !== "Backspace" && e.key !== "Delete") {
                                e.preventDefault();
                            }
                        }}
                        onClick={() => {
                            if (answer.nb2[i] == "0")
                                update_nb2(i, "1");
                            else if (answer.nb2[i] == "1")
                                update_nb2(i, "");
                            else
                                update_nb2(i, "0");
                        }}
                        textAlign={"center"} width={"32px"} height={"32px"} border={"2pt solid gray"} _focus={{outline:"none"}} cursor={"pointer"} bgColor={"white"} fontWeight={500} fontFamily={"Jetbrains Mono"} ms={1} key={i} />
                    ))}
            </Center>

            {/* ------------------ */}
            <Center>
                <Box rounded={"lg"} mt={4} mb={4} width={"100%"} height={"2px"} bgColor={"gray"}></Box>
            </Center>

            {answer.carries.map((carry, i) => (
                <Center mb={0} key={i}>
                    {/* CARRIES */}
                    <Box bgColor={"white"} width={"32px"} height={"32px"} opacity={0}></Box>
                    {carry.map((carry_bit, j) => (
                        <Input
                        p={0}
                        isDisabled={submitted}
                        value={carry_bit.bit}
                        color={"black"}
                        rounded={"none"}
                        onChange={(e) => {
                            update_carry(i, j, e.target.value);
                        }}
                        onMouseOver={() => {
                            let newCarries = answer.carries.slice();
                            let newCarriesCopy = newCarries.slice();
                            newCarriesCopy[i][j].visible = true;
                            setAnswer(prevAnswer => {
                                const newAnswer = {...prevAnswer};
                                newAnswer.carries = newCarriesCopy;
                                return newAnswer;
                            });
                        }}
                        onMouseOut={() => {
                            if (!carry_bit.editing) {
                                let newCarries = answer.carries.slice();
                                let newCarriesCopy = newCarries.slice();
                                newCarriesCopy[i][j].visible = false;
                                setAnswer(prevAnswer => {
                                    const newAnswer = {...prevAnswer};
                                    newAnswer.carries = newCarriesCopy;
                                    return newAnswer;
                                });
                            }
                        }}
                        onClick={() => {
                            // set carry edition
                            let newCarries = answer.carries.slice();
                            let newCarriesCopy = newCarries.slice();
                            if (newCarriesCopy[i][j].bit == "0")
                                update_carry(i, j, "");
                            else if (newCarriesCopy[i][j].bit == "1")
                                update_carry(i, j, "");
                            else
                                update_carry(i, j, "1");
                        }}
                        onBlur={() => {
                            let newCarries = answer.carries.slice();
                            let newCarriesCopy = newCarries.slice();
                            newCarriesCopy[i][j].editing = false;
                            newCarriesCopy[i][j].visible = newCarries[i][j].bit != "";
                            setAnswer(prevAnswer => {
                                const newAnswer = {...prevAnswer};
                                newAnswer.carries = newCarriesCopy;
                                return newAnswer;
                            });
                        }}
                        opacity={carry_bit.visible ? 1 : (carry_bit.bit != "" ? 1 : 0)} key={j+"-"+i} mb={1} textAlign={"center"} width={"32px"} height={"32px"} border={"2pt solid #48bb78"} _focus={{outline:"none"}} cursor={"pointer"} bgColor={"white"} fontWeight={500} fontFamily={"Jetbrains Mono"} ms={1} />
                    ))}
                </Center>
            ))}
            
            {answer.steps.map((stepRow, i) => (
                <Center mb={1} key={i}>
                    {i == bitsNb-1 && 
                        <Box textAlign={"center"} verticalAlign={"middle"} width={"32px"} height={"32px"}>
                        <Text fontFamily={"Jetbrains Mono"} fontSize={"2xl"}><FontAwesomeIcon icon={faPlus} /></Text>
                        </Box>
                    }
                    {i != bitsNb-1 &&
                        <Box bgColor={"white"} width={"32px"} height={"32px"} opacity={0}></Box>
                    }
                    {stepRow.map((bit, j) => (
                        <Input
                        isDisabled={(j > bitsNb-i-1) || submitted}
                        rounded={0}
                        value={bit}
                        p={0}
                        onChange={(e) => {
                            update_steps_row(i, j, e.target.value);
                        }}
                        // prevent from typing other characters than 0 or 1
                        onKeyDown={(e) => {
                            if (e.key !== "0" && e.key !== "1" && e.key !== "Backspace" && e.key !== "Delete") {
                                e.preventDefault();
                            }
                        }}
                        onClick={() => {
                            if (answer.steps[i][j] == "0")
                                update_steps_row(i, j, "1");
                            else if (answer.steps[i][j] == "1")
                                update_steps_row(i, j, "0");
                            else
                                update_steps_row(i, j, "0");
                        }}
                        textAlign={"center"} width={"32px"} height={"32px"} border={"2pt solid gray"} _focus={{outline:"none"}} cursor={"pointer"} bgColor={"white"} fontWeight={500} fontFamily={"Jetbrains Mono"} ms={1} key={i+"-"+j} />
                    ))}
                </Center>
            ))}
            
            {/* ------------------ */}
            <Center>
                <Box rounded={"lg"} mt={4} mb={4} width={"100%"} height={"2px"} bgColor={"gray"}></Box>
            </Center>
            
            <Center>
                <Box bgColor={"white"} width={"32px"} height={"32px"} opacity={0}></Box>
                    {[...Array(bitsNb)].map((_, i) => (
                        <Input
                        rounded={0}
                        isDisabled={submitted}
                        value={answer.result[i]}
                        p={0}
                        onChange={(e) => {
                        update_result(i, e.target.value);
                        }}
                        // prevent from typing other characters than 0 or 1
                        onKeyDown={(e) => {
                            if (e.key !== "0" && e.key !== "1" && e.key !== "Backspace" && e.key !== "Delete") {
                                e.preventDefault();
                            }
                        }}
                        onClick={() => {
                            if (answer.result[i] == "0")
                                update_result(i, "1");
                            else if (answer.result[i] == "1")
                                update_result(i, "0");
                            else
                                update_result(i, "0");
                        }}
                        textAlign={"center"} width={"32px"} height={"32px"} border={"2pt solid gray"} _focus={{outline:"none"}} cursor={"pointer"} bgColor={"white"} fontWeight={500} fontFamily={"Jetbrains Mono"} ms={1} key={i} />
                    ))}
            </Center>
        </Box>
        }

        <Flex roundedBottomEnd={8} roundedBottomStart={8} mt={4} bgColor={'blackAlpha.100'} p={4}>
            {corrected && submitted &&
                <Text mt={2} 
                    cursor={'default'} fontSize={'md'} fontWeight={600} 
                    color={correct ? 'green.500' : 'red.500'}>
                    <i style={{marginBottom:'5px', marginRight:'5px'}} className={correct ? "em em-svg em-star-struck" : "em em-svg em-disappointed"}></i>
                    {fb_sentence}</Text>
            }
            <Spacer />
            <HintBulb display_hint_callback={display_hint_callback} hints_nb={body.hints?.length || 0} />
            <Button isDisabled={submitted} variant='outline' colorScheme={'green'} size={'md'} rightIcon={<FontAwesomeIcon icon={faCheck} />}
                onClick={
                    () => {
                        check_answer_callback();
                    }
                }
            >Corriger</Button>
        </Flex>
    </Box>
    </>

};


export default CalculusFragment;