// CSS
import './index.css';
import "hover.css";
import "@fortawesome/fontawesome-free/css/all.min.css";

// PACKAGES
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { registerLicense } from '@syncfusion/ej2-base';
import Login from './Login';
import Gamecodes from './Gamecodes';
import Gamecode from './Gamecode';
import { useEffect, useState } from 'react';
import Admin from './admin/Admin';
import Editor from './admin/Editor';
import Kodami from './kodami';
import { UserProvider, useUser } from './UserContext';
import GamecodeHistory from './GamecodeHistory';
import Sp from './Sp';
import { TabProvider } from './TabContext';
import Cookies from 'js-cookie';
import SetUser from './SetUser';
import Help from './Help';

if (process.env.REACT_APP_SYNCFUSION_LICENSE_KEY)
  registerLicense(process.env.REACT_APP_SYNCFUSION_LICENSE_KEY);
else
  console.error("No Syncfusion license key found. Please set the REACT_APP_SYNCFUSION_LICENSE_KEY environment variable.");
  
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const theme = extendTheme({
  // styles: {
  //   global: {
  //   },
  // },
  fonts: {
    body: `'Sora', sans-serif`,
    heading: `'Sora', sans-serif`,
  },
  colors: {
    gray: {
      900: '#212427'
    }
  }
});


const App = () => {
  const { user, setUser } = useUser();
  const [tokenError, setTokenError] = useState(true);

  useEffect(() => {
      const matToken = Cookies.get('jwt_cafe');
      if (matToken) {
        setTokenError(false);
      }
      else {
        setTokenError(true);
      }
      
      const tokenErrorListener = (e: any) => {
        console.log("TOKEN_ERROR received");
        setTokenError(true);
      }
      document.addEventListener("TOKEN_ERROR", tokenErrorListener);
  }, []);

  return (
    <ChakraProvider theme={theme}>
      <Kodami />
      <BrowserRouter>
      {tokenError &&
        <Login />
      }
      {!tokenError && <>
        {user &&
            <SmoothRoutes />
        }
        {!user &&
          <SetUser />
        }
        </>
      }
      </BrowserRouter>
    </ChakraProvider>
  );
};

const SmoothRoutes = () => {
  const location = useLocation();
  const [displayLocation, setDisplayLocation] = useState(location);
  const [transitionStage, setTransistionStage] = useState("fadeIn");

  useEffect(() => {
    if (location !== displayLocation) {
      setTransistionStage("fadeOut");
    }
  }, [location, displayLocation]);

  return (
    <div
      className={`${transitionStage}`}
      onAnimationEnd={() => {
        if (transitionStage === "fadeOut") {
          setTransistionStage("fadeIn");
          setDisplayLocation(location);
          window.scrollTo({ top: 0});
        }
      }}
    >
      <Routes location={displayLocation}>
        <Route index element={<Gamecodes />} />
        <Route path="/login" element={<Login />} />
        <Route path="/gamecode/:slug/:module/:index?" element={<Gamecode />} />
        {/* <Route path="/history/:slug" element={<GamecodeHistory />} /> */}
        <Route path="/admin" element={<Admin />} />
        <Route path="/sp" element={<Sp />} />
        <Route path="/help" element={<Help />} />
        <Route path="/admin/edit/gamecode/:id" element={<Editor />} />

        {/* DEFAULT ROUTE */}
        <Route path="*" element={<Gamecodes />} />
      </Routes>
    </div>
  )
}

root.render(
  <UserProvider initialUser={{rank:"user", firstname:"", lastname:"", matricule:""}}>
    <TabProvider>
      <App />
    </TabProvider>
  </UserProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
