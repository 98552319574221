import { DeleteIcon } from "@chakra-ui/icons";
import { Box, Button, Center, Collapse, Divider, Flex, Spacer, Text, Tooltip, useDisclosure } from "@chakra-ui/react";
import { useState } from "react";
import TextFragment from "../static/TextFragment";
import { DynamicFragmentType, FragmentType, IDynamicFragment, StaticFragmentType } from "../../../types";
import MCQFragment from "./MCQFragment";
import { updateDynamicFragment } from "../../../API/fragment";
import CodeFragment from "./CodeFragment";
import { DraggableProvidedDragHandleProps } from "react-beautiful-dnd";
import FormFragment from "./FormFragment";
import { faLightbulb } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import HintsCollapse from "./HintsCollapse";
import TextualSP from "./TextualSP";
import CodeConstructFragment from "./CodeConstructFragment";
import SimpleCodeFragment from "./SimpleCodeFragment";
import TextareaFragment from "./TextareaFragment";
import DecBinFragment from "./DecBinFragment";
import BinDecFragment from "./BinDecFragment";
import CalculusFragment from "./CalculusFragment";

type DynamicFragmentProps = {
    id: string;
    type: DynamicFragmentType;
    delete_callback: Function;
    content?: IDynamicFragment;
};

const DynamicFragmentComponent: React.FC<DynamicFragmentProps> = ({id, type, delete_callback, content}) => {

    let title:string = '';
    switch (type) {
        case DynamicFragmentType.MCQ:
            title = 'QCM';
            break;
        case DynamicFragmentType.Form:
            title = 'Formulaire';
            break;
        case DynamicFragmentType.Code:
            title = 'Code à compléter';
            break;
        case DynamicFragmentType.TextualSP:
            title = 'Découpe en SP textuelle';
            break;
        case DynamicFragmentType.CodeConstruction:
            title = 'Construction de code';
            break;
        case DynamicFragmentType.SimpleCode:
            title = 'Extrait de code';
            break;
        case DynamicFragmentType.Textarea:
            title = 'Champ de texte';
            break;
        case DynamicFragmentType.DecBin:
            title = 'Conversion décimal-binaire';
            break;
        case DynamicFragmentType.BinDec:
            title = 'Conversion binaire-décimal';
            break;
        case DynamicFragmentType.Calculus:
            title = 'Calcul écrit';
            break;
        default:
            break;
    }

    const update_callback = (new_content:IDynamicFragment) => {
        updateDynamicFragment(id, new_content);
    }

    let fragment:JSX.Element;
    switch (type) {
        case DynamicFragmentType.MCQ:
            fragment = <MCQFragment 
                            body = {content as IDynamicFragment}
                            update_callback={update_callback}
                        />;
            break;
        case DynamicFragmentType.Form:
            fragment = <FormFragment body={content as IDynamicFragment} update_callback={update_callback} />;
            break;
        case DynamicFragmentType.Code:
            fragment = <CodeFragment body={content as IDynamicFragment} update_callback={update_callback} />;
            break;
        case DynamicFragmentType.TextualSP:
            fragment = <TextualSP body={content as IDynamicFragment} update_callback={update_callback} />;
            break;
        case DynamicFragmentType.CodeConstruction:
            fragment = <CodeConstructFragment body={content as IDynamicFragment} update_callback={update_callback} />;
            break;
        case DynamicFragmentType.SimpleCode:
            fragment = <SimpleCodeFragment body={content as IDynamicFragment} update_callback={update_callback} />;
            break;
        case DynamicFragmentType.Textarea:
            fragment = <TextareaFragment body={content as IDynamicFragment} update_callback={update_callback} />;
            break;
        case DynamicFragmentType.DecBin:
            fragment = <DecBinFragment body={content as IDynamicFragment} update_callback={update_callback} />;
            break;
        case DynamicFragmentType.BinDec:
            fragment = <BinDecFragment body={content as IDynamicFragment} update_callback={update_callback} />;
            break;
        case DynamicFragmentType.Calculus:
            fragment = <CalculusFragment body={content as IDynamicFragment} update_callback={update_callback} />;
            break;
        default:
            fragment = <TextFragment content={"FRAGMENT INCONNU !!!"} update_callback={() => {}} />;
            break;
    }

    return <Box mt={3} shadow={'md'}>
        <Flex className="drag-handle" minWidth='max-content' alignItems='center' gap='2' mt={5} cursor={'grab'} bgColor={'#ededed'} p={2} ps={3} pe={3} roundedTop={'md'}>
            <Text fontWeight={'500'} colorScheme="red.500">{title}</Text>
            <Spacer />
            
            <Tooltip label={'Supprimer le fragment'}>
            <Button size={'sm'} colorScheme={'red'} onClick={() => delete_callback(id, FragmentType.Dynamic)}><DeleteIcon /></Button>
            </Tooltip>
        </Flex>
        <Box border={'1pt solid #ededed'} p={3} bgColor={'white'}>
            { fragment }
            <HintsCollapse hints={content?.hints ? content?.hints : []} dynamic_fragment_id={content?._id ? content?._id : ''} />
        </Box>
    </Box>
};


export default DynamicFragmentComponent;