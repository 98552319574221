import React from "react";
import TextEditor from '../TextEditor';
import { Box } from "@chakra-ui/react";

type TextFragmentProps = {
    content?: any;
    update_callback: Function;
};

const TextFragment: React.FC<TextFragmentProps> = ({content, update_callback}) => {

    const update_wrapper = (new_content:string) => {
        update_callback({content: new_content});
    }

    return <Box><TextEditor update_callback={update_wrapper} content={content.content} /></Box>
};


export default TextFragment;