import { Box, Checkbox, Flex, Select, Text, VStack } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import AceEditor from 'react-ace'
// import mode-<language> , this imports the style and colors for the selected language.
import 'ace-builds/src-noconflict/mode-c_cpp'
import 'ace-builds/src-noconflict/mode-text'
import 'ace-builds/src-noconflict/theme-dracula'
import 'ace-builds/src-noconflict/theme-chrome'
import 'ace-builds/src-noconflict/ext-language_tools'
import 'ace-builds/src-noconflict/ext-beautify'
import { IDynamicFragment } from "../../../types";
import TextEditor from "../TextEditor";


type CodeFragmentProps = {
    body: IDynamicFragment;
    update_callback: Function;
};

const TextareaFragment: React.FC<CodeFragmentProps> = ({body, update_callback}) => {

    const [fragment, setFragment] = useState<IDynamicFragment>(body);

    useEffect(() => {
    }, []);

    const update_content = (new_content:string) => {
        if (!fragment.content)
            fragment.content = "";
        let new_body:IDynamicFragment = {
            ...fragment,
            content: new_content
        }
        setFragment(new_body);
        update_callback(new_body);
    }

    const update_size = (big_size:boolean) => {
        let new_body:IDynamicFragment = {
            ...fragment,
            large_area: big_size
        }
        setFragment(new_body);
        update_callback(new_body);
    }

    const update_codify = (codify:boolean) => {
        let new_body:IDynamicFragment = {
            ...fragment,
            codify: codify
        }
        setFragment(new_body);
        update_callback(new_body);
    }

    return <>
        
        <Text mt={2} fontSize={'sm'} fontWeight={'bold'} color={'purple.500'} mb={2} cursor={'default'}>Énoncé</Text>

        <TextEditor content={fragment.content} update_callback={update_content} />

        <VStack mt={4} mb={4} ms={2} align={'start'}>
            <Checkbox
                isChecked={fragment.large_area}
                onChange={(event) => { update_size(event.target.checked) }}
            >Grand champ de texte</Checkbox>

            <Checkbox
                isChecked={fragment.codify}
                onChange={(event) => { update_codify(event.target.checked) }}
            >Police de texte prévue pour insérer du code ou une fonction</Checkbox>
        </VStack>
        
    </>
};

export default TextareaFragment;