import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Box, Button, Center, CircularProgress, Container, Flex, FormControl, FormLabel, Heading, Input, Menu, MenuButton, MenuDivider, MenuItem, MenuList, Spacer, Stack, Text, useDisclosure } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { DynamicFragmentType, FragmentType, GCModule, IReminder, ResolutionStepModule, StaticFragmentType } from "../types";
import FragmentsList from "./FragmentsList";
import { AddIcon, CloseIcon, DeleteIcon } from "@chakra-ui/icons";
import { addFragmentToReminder, deleteFragmentFromReminder, deleteReminder, getReminder, updateReminder } from "../API/reminder";
import { reorganizeFragments } from "../API/fragment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fa0, faAlignLeft, faTerminal } from "@fortawesome/free-solid-svg-icons";
import { fa1, faArrowRight, faCircleNodes, faClipboardQuestion, faCode, faGears, faPlusMinus, faTableList } from "@fortawesome/free-solid-svg-icons";

type ReminderEditorProps = {
    reminder:IReminder;
};

const ReminderEditor: React.FC<ReminderEditorProps> = ({reminder}) => {
    const [currentReminder, setCurrentReminder] = useState<IReminder>(reminder);

    useEffect(() => {
        setCurrentReminder(reminder);
    }, [reminder]);
    
    const delete_fragment_callback = (id:string, type:FragmentType) => {
        deleteFragmentFromReminder(id, currentReminder._id).then((res) => {
            getReminder(currentReminder._id).then((res) => {
                if (res.data.reminder)
                    setCurrentReminder(res.data.reminder);
            });
        });
    }

    const reorder_fragments = (reorganized_pointers:string[]) => {
        const new_reminder = {...currentReminder, content: reorganized_pointers};
        reorganizeFragments(reorganized_pointers, '', GCModule.INTRO, '', ResolutionStepModule.STATEMENT, currentReminder._id)
            .then((res) => {
                setCurrentReminder(new_reminder);
            }
        );
    }

    const add_static_fragment = (type:StaticFragmentType) => {
        addFragmentToReminder(FragmentType.Static, type, currentReminder._id).then((res) => {
            setCurrentReminder({...currentReminder, content: [...currentReminder.content, res.data.fragment._id]});
        });
    }

    const add_dynamic_fragment = (type:DynamicFragmentType) => {
        addFragmentToReminder(FragmentType.Dynamic, type, currentReminder._id).then((res) => {
            setCurrentReminder({...currentReminder, content: [...currentReminder.content, res.data.fragment._id]});
        });
    }

    const update_title = (title:string) => {
        // Update title in database after setting it in state
        setCurrentReminder({...currentReminder, title: title});
        updateReminder({... currentReminder, title: title});
    }

    return <><FormControl mb={3} mt={3}>
        <FormLabel>Titre du rappel</FormLabel>
        <Input type='text' value={currentReminder.title} onChange={(e) => { update_title(e.target.value); } } />
    </FormControl>

    <FragmentsList fragments_ids={currentReminder.content} delete_callback={delete_fragment_callback} reorder_callback={reorder_fragments}/>
    
    <Flex minWidth='max-content' alignItems='center' gap='2' mt={5}>
        <Spacer />
        <Menu>
            <MenuButton colorScheme='purple' as={Button} rightIcon={<AddIcon />}>
            <Text fontWeight={'semibold'}>Fragment dynamique</Text>
            </MenuButton>
            <MenuList>
                <MenuItem icon={<FontAwesomeIcon icon={faClipboardQuestion} />} onClick={() => {add_dynamic_fragment(DynamicFragmentType.MCQ)}}><Text>QCM</Text></MenuItem>
                
                <MenuItem icon={<FontAwesomeIcon icon={faCode} />} onClick={() => {add_dynamic_fragment(DynamicFragmentType.SimpleCode)}}><Text>Extrait de code</Text></MenuItem>

                <MenuItem icon={<FontAwesomeIcon icon={faAlignLeft} />} onClick={() => {add_dynamic_fragment(DynamicFragmentType.Textarea)}}><Text>Champ de texte</Text></MenuItem>
                
                <MenuItem icon={<FontAwesomeIcon icon={faTerminal} />} onClick={() => {add_dynamic_fragment(DynamicFragmentType.Code)}}><Text>Code à compléter</Text></MenuItem>
                
                <MenuItem icon={<FontAwesomeIcon icon={faTableList} />} onClick={() => {add_dynamic_fragment(DynamicFragmentType.Form)}}><Text>Formulaire</Text></MenuItem>
                
                <MenuItem icon={<FontAwesomeIcon icon={faCircleNodes} />} onClick={() => {add_dynamic_fragment(DynamicFragmentType.TextualSP)}}><Text>Découpe textuelle en SP</Text></MenuItem>
                
                <MenuItem icon={<FontAwesomeIcon icon={faGears} />} onClick={() => {add_dynamic_fragment(DynamicFragmentType.CodeConstruction)}}><Text>Construction de code</Text></MenuItem>
                
                <MenuDivider />
                
                <MenuItem icon={<FontAwesomeIcon icon={fa0} />} onClick={() => {add_dynamic_fragment(DynamicFragmentType.DecBin)}}><Text>Décimal <FontAwesomeIcon icon={faArrowRight} /> Binaire</Text></MenuItem>
                
                <MenuItem icon={<FontAwesomeIcon icon={fa1} />} onClick={() => {add_dynamic_fragment(DynamicFragmentType.BinDec)}}><Text>Binaire <FontAwesomeIcon icon={faArrowRight} /> Décimal</Text></MenuItem>
                
                <MenuItem icon={<FontAwesomeIcon icon={faPlusMinus} />} onClick={() => {add_dynamic_fragment(DynamicFragmentType.Calculus)}}><Text>Calcul écrit</Text></MenuItem>
            </MenuList>
        </Menu>
        <Menu>
            <MenuButton colorScheme='teal' as={Button} rightIcon={<AddIcon />}>
                <Text fontWeight={'semibold'}>Fragment statique</Text>
            </MenuButton>

            <MenuList>
                <MenuItem icon={<FontAwesomeIcon icon={faAlignLeft} />} onClick={() => {add_static_fragment(StaticFragmentType.Text)}}><Text>Texte et images</Text></MenuItem>
                
                <MenuItem icon={<FontAwesomeIcon icon={faTerminal} />} onClick={() => {add_static_fragment(StaticFragmentType.Code)}}><Text>Code exécutable</Text></MenuItem>

                <MenuItem icon={<FontAwesomeIcon icon={fa0} />} onClick={() => {add_static_fragment(StaticFragmentType.Binary)}}><Text>Nombre binaire</Text></MenuItem>
                
            </MenuList>
        </Menu>
    </Flex>
    </>;
}

export default ReminderEditor;