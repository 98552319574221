import React, { useContext, useEffect, useRef, useState } from 'react';
import { Box, Button, Center, Container, Flex, Heading, List, ListIcon, ListItem, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Spacer, Text, Tooltip, useDisclosure } from '@chakra-ui/react';
import { useNavigate, useParams } from 'react-router-dom';
import { MainLayout } from './MainLayout';
import { ProgressLine } from './ProgressLine';
import { ArrowUpIcon } from '@chakra-ui/icons';
import { GCModule, GamecodeDifficulty, IGamecode } from './types';
import { getGamecode, getGamecodeBySlug } from './API/gamecode';
import FragmentsList from './gamecode/FragmentsList';
import { getCurrentInstanceId, getInstanceProgress, getModuleProgress, updateModuleProgress } from './API/gcinstance';
import Reminders from './gamecode/Reminders';
import ResolutionSteps from './gamecode/ResolutionSteps';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fa1, faHandPointLeft, faHandPointRight } from '@fortawesome/free-solid-svg-icons';
import { THEME_COLORS } from './config';
import Logger from './Logger';
import toc_mp3 from "./resources/sound/toc.mp3";
import finish_mp3 from "./resources/sound/finish.mp3";
import man_hello from './resources/man_hello.svg';
import woman_stem from './resources/woman_stem.svg';
import lady_drawing from './resources/lady_drawing.svg';
import women_highlight from './resources/women_highlight.svg';
import highfive from './resources/highfive.svg';
import { TabContext } from './TabContext';
import Toolbox from './gamecode/Toolbox';
import { useUser } from './UserContext';

const Gamecode: React.FC = () => {
    const gc_params = useParams();
    const current_module = gc_params.module as GCModule;
    const [fragments, setFragments] = useState<string[]>([]);
    const gc_slug = (gc_params.slug || '') as string;
    const [step, setStep] = useState<number>(1);
    const next_step_sound = new Audio(toc_mp3);
    const finish_sound = new Audio(finish_mp3);
    const { isOpen, onOpen, onClose } = useDisclosure(); // End modal
    const [gc, setGc] = useState<IGamecode>({
        _id: '',
        title: '',
        slug: '',
        class: '',
        startline: new Date(),
        visible: false,
        description: '',
        introduction: [],
        reminders: [],
        statement: [],
        resolution_steps: [],
        conclusion: [],
        difficulty: GamecodeDifficulty.Easy
    });
    const [instanceProgress, setInstanceProgress] = useState<number[]>([0,0,0,0,0]);
    const currentTab = useContext(TabContext).currentTab;
    const navigate = useNavigate();
    const { user, setUser } = useUser();

    const fetchInstanceProgress = () => {
        if (gc._id == '') return;
        getInstanceProgress(gc._id).then(({ data: { instance_progress } }: any) => {
            setInstanceProgress(instance_progress);
        });
    }

    const open_end_modal = () => {
        onOpen();
    }
    
    useEffect(() => {
        fetchInstanceProgress();

        // Create a listener on document to the event PROGRESS_UPDATED
        document.addEventListener('PROGRESS_UPDATED', fetchInstanceProgress);

        // Remove the listener when the component is unmounted
        return () => {
            document.removeEventListener('PROGRESS_UPDATED', fetchInstanceProgress);
        }
    }, [gc]);

    useEffect(() => {
        getGamecodeBySlug(gc_slug).then(({ data: { gamecode } }: any) => {
            if (!gamecode.visible && (user?.rank != "admin"))
                navigate('/');

            setGc(gamecode);
            switch(current_module) {
                case GCModule.INTRO:
                    setFragments(gamecode.introduction);
                    break;
                case GCModule.REMINDERS:
                    setFragments(gamecode.reminders);
                    break;
                case GCModule.STATEMENT:
                    setFragments(gamecode.statement);
                    break;
                case GCModule.RESOLUTION_STEPS:
                    setFragments(gamecode.resolution_steps);
                    // set height of spacer_box to window.innerHeight*0.2 + 'px'
                    document.getElementById('spacer_box')?.setAttribute('style', 'height:' + window.innerHeight*0.2 + 'px');
                    break;
                case GCModule.CONCLUSION:
                    setFragments(gamecode.conclusion);
                    break;
                default:
                    navigate('/');
                    break;
            }
            if (current_module != GCModule.RESOLUTION_STEPS) {
                document.getElementById('spacer_box')?.setAttribute('style', 'height:' + window.innerHeight*0.4 + 'px');
            }
            // Get the current step in the module
            if (current_module != GCModule.RESOLUTION_STEPS)
                getModuleProgress(gamecode._id, current_module).then(({ data: { module_step } }: any) => {
                    setStep(module_step);
                });
        }).catch((err) => {
            navigate('/');
        });

    }, [gc_slug, gc_params.module]);

    useEffect(() => {
        Logger.logEvent(Logger.EventType.MODULE_CHANGE, {module: gc_params.module, slug: gc_slug, tab_id: currentTab});
    }, [gc_params.module]);

    const nextStep = () => {
        const old_step = step;
        next_step_sound.volume = 1;
        next_step_sound.play();

        // Update progress for the current module in DB
        updateModuleProgress(gc._id, current_module, fragments[old_step - 1]).then(() => {
            setStep(step + 1);
            fetchInstanceProgress();
        });

        // Smoothly scroll the page down to the bottom after 0.5s
        setTimeout(() => {
            window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
        }, 100);
    }

    const nextModule = () => {
        // Go to the next module
        let next_module:string = '';
        switch(current_module) {
            case GCModule.INTRO:
                next_module = 'reminders';
                break;
            case GCModule.REMINDERS:
                next_module = 'statement';
                break;
            case GCModule.STATEMENT:
                next_module = 'resolution';
                break;
        }
        navigate('/gamecode/' + gc_slug + '/' + next_module);
    }

    
    const previousModule = () => {
        // Go to the previous module
        let previous_module:string = '';
        switch(current_module) {
            case GCModule.REMINDERS:
                previous_module = 'intro';
                break;
            case GCModule.STATEMENT:
                previous_module = 'reminders';
                break;
            case GCModule.RESOLUTION_STEPS:
                previous_module = 'statement';
                break;
        }
        navigate('/gamecode/' + gc_slug + '/' + previous_module);
    }

    useEffect(() => {
        new Image().src = man_hello;
        new Image().src = woman_stem;
        new Image().src = lady_drawing;
        new Image().src = women_highlight;
        new Image().src = highfive;
    }, []);

    return (
        <MainLayout>
            <Box id='main_box'>
                {window.scrollY > 50 &&
                    <Box position={'fixed'} bottom={'20px'} left={'20px'} width={'100%'} opacity={.5}><Button colorScheme='gray' onClick={() => window.scrollTo({ top: 0, behavior:'smooth'})}><ArrowUpIcon /></Button></Box>
                }
                <Container maxWidth={'2xl'} marginBlock={5}>
                    <Center mb={4}><Heading as={'h3'} size={'2xl'} color={'gray.900'}>{gc?.title}</Heading></Center>

                    {/* PROGRESS BAR */}
                    <Center mb={12}><ProgressLine completion={instanceProgress} focused_module={current_module} /></Center>

                    {current_module == GCModule.INTRO &&
                        <>
                        <Heading mb={8} fontSize={'4xl'} color={THEME_COLORS.INTRO}>Introduction</Heading>

                        <Flex mb={10} position={'relative'} left={'50px'}>
                            <Spacer />
                            <img draggable='false' src={man_hello} style={{width:'400px'}} />
                        </Flex>
                        </>
                    }
                    {current_module == GCModule.STATEMENT &&    <>
                        <Heading mb={8} fontSize={'4xl'} color={THEME_COLORS.STATEMENT}>Énoncé général</Heading>
                        
                        <Flex mb={4} position={'relative'} left={'40px'}>
                            <Spacer />
                            <img draggable='false' src={woman_stem} style={{width:'300px'}} />
                        </Flex>
                    </>}
                    {current_module == GCModule.CONCLUSION && <>
                        <Heading mb={8} fontSize={'4xl'} color={THEME_COLORS.CONCLUSION}>Mise en commun</Heading>

                        
                        <Flex mb={4} position={'relative'} right={'60px'}>
                            {/* <Spacer /> */}
                            <img draggable='false' src={women_highlight} style={{width:'400px'}} />
                        </Flex>
                    </>}

                    {(current_module == GCModule.INTRO || current_module == GCModule.STATEMENT || current_module == GCModule.CONCLUSION) &&
                        <>
                        <FragmentsList fragments_ids={fragments} step={step} />
                        </>
                    }

                    {current_module == GCModule.CONCLUSION && step > fragments.length && <>
                        <Center>
                            <Button mt={8} _hover={{bgColor:'black'}} color={'white'} bgColor='black' size='lg' onClick={() => {finish_sound.play(); open_end_modal();}}>
                            Terminer ce Gamecode
                            </Button>
                        </Center>

                        <Modal onClose={onClose} size={'full'} isOpen={isOpen}>
                            <ModalOverlay />
                            <ModalContent>
                            <ModalHeader>
                                {/* <Center> */}
                                
                                {/* </Center> */}
                            </ModalHeader>
                            {/* <ModalCloseButton /> */}
                            <ModalBody>
                                <Container maxWidth={'2xl'} marginBlock={5}>
                                <Box textAlign={'justify'}>
                                    <Heading mt={8} mb={8} fontSize={'4xl'} color={THEME_COLORS.CONCLUSION} bgColor={"white"} p={1}>Gamecode terminé</Heading>
                                    <Text bgColor={"white"} p={3}>
                                        <strong>Bien joué !</strong> Tu as terminé ce Gamecode. Tu peux maintenant revenir à la page d'accueil pour en choisir un autre, ou bien continuer à explorer ce Gamecode pour revoir les rappels et les étapes de résolution.
                                    </Text>
                                    
                                    <Flex mt={4}>
                                        <Spacer />
                                        <Button _hover={{bgColor:THEME_COLORS.CONCLUSION}} color={'white'} bgColor={THEME_COLORS.CONCLUSION} size='lg' onClick={() => {onClose(); navigate('/')}}>
                                        Continuer
                                        </Button>
                                    </Flex>
                                </Box>
                                <Flex mb={4} 
                                    position={'absolute'} 
                                    bottom={'60px'}
                                    right={'50px'}
                                    zIndex={-1}
                                    >
                                    <Spacer />
                                    <img draggable='false' src={highfive} style={{width:'1000px'}} />
                                </Flex>
                                </Container>
                            </ModalBody>
                            <ModalFooter>
                            </ModalFooter>
                            </ModalContent>
                        </Modal>
                    </>}

                    {current_module == GCModule.REMINDERS && 
                    <>
                        <Reminders reminders_id={gc.reminders} />
                    </>}

                    {current_module == GCModule.RESOLUTION_STEPS && 
                        <>
                            <ResolutionSteps rs_ids={gc.resolution_steps} />
                        </>
                    }

                    {current_module != GCModule.REMINDERS && current_module != GCModule.RESOLUTION_STEPS && fragments.length > 0 && step <= fragments.length &&
                        <Button mt={4} _hover={{bgColor:'black'}} color={'white'} bgColor={'black'} onClick={() => nextStep()}>Lire la suite</Button>
                    }

                    {current_module == GCModule.STATEMENT && fragments.length > 0 && step > fragments.length &&
                        <Flex>
                            <Button mt={4} _hover={{bgColor: THEME_COLORS.REMINDERS}} color={'white'} bgColor={THEME_COLORS.REMINDERS} onClick={() => previousModule()} leftIcon={<FontAwesomeIcon icon={faHandPointLeft} />}>Retour aux rappels</Button>
                            <Spacer />
                            <Button mt={4} _hover={{bgColor: THEME_COLORS.RESOLUTION_STEPS}} color={'white'} bgColor={THEME_COLORS.RESOLUTION_STEPS} onClick={() => nextModule()} rightIcon={<FontAwesomeIcon icon={faHandPointRight} />}>Passer à la résolution</Button>
                        </Flex>
                    }


                    {current_module == GCModule.INTRO && fragments.length > 0 && step > fragments.length &&
                        <Flex>
                            <Spacer />
                            <Button mt={4} _hover={{bgColor: THEME_COLORS.REMINDERS}} color={'white'} bgColor={THEME_COLORS.REMINDERS} onClick={() => nextModule()} rightIcon={<FontAwesomeIcon icon={faHandPointRight} />}>Passer aux rappels</Button>
                        </Flex>
                    }
                </Container>

                {/* Space for better visibility */}
                <Box height={window.innerHeight*0.4 + 'px'} width={'100%'} id='spacer_box'></Box>

                {/* Toolbox */}
                <Toolbox />
            </Box>
        </MainLayout>
    );
}

export default Gamecode;