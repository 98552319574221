import { Box, Button, Center, Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Spacer, Spinner, Tab, TabList, Tabs, Text, Tooltip, useDisclosure, useToast } from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import AceEditor from 'react-ace'

// import mode-<language> , this imports the style and colors for the selected language.
import 'ace-builds/src-noconflict/mode-c_cpp'
import 'ace-builds/src-noconflict/mode-assembly_x86'
import 'ace-builds/src-noconflict/mode-text'
import 'ace-builds/src-min-noconflict/theme-dracula'
import 'ace-builds/src-noconflict/ext-language_tools'
import 'ace-builds/src-noconflict/ext-beautify'
import { faArrowsUpDown, faBug, faCheck, faClipboard, faGear, faLock, faRotateRight, faTerminal } from "@fortawesome/free-solid-svg-icons";
import { faComment} from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IDynamicFragment, IGCAnswer, IGCReport } from "../../../types";
import LatexText from "../LatexText";
import HintBulb from "./HintBulb";
import { compileCode, getReport } from "../../../API/corrector";

type CodeFragmentProps = {
    body: IDynamicFragment;
    display_hint_callback: Function;
    update_answer_callback: Function;
    latest_answer?: IGCAnswer|null;
};

const SimpleCodeFragment: React.FC<CodeFragmentProps> = ({body, display_hint_callback, update_answer_callback, latest_answer}) => {
    const [compiledContent, setCompiledContent] = useState<string>("");
    const [lastCompiledContent, setLastCompiledContent] = useState<String>("");
    const [isCompiling, setIsCompiling] = useState(false);
    const [askedToCompile, setAskedToCompile] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [corrected, setCorrected] = useState(false);
    const [correctionError, setCorrectionError] = useState(false);
    const CHARS_DIFFERENCE_THRESHOLD = 20; // to update in database
    const [editorKey, setEditorKey] = useState(0);
    const toast = useToast()
    const REPORT_INVALIDATION_TIME = 1000 * 60 * 5; // 5 minutes
    const REPORT_CHECK_INTERVAL = 500; // 0.5 secondes
    const [report, setReport] = useState<IGCReport|null>(null);
    const [compilationReport, setCompilationReport] = useState<IGCReport|null>(null);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [waitingCorrection, setWaitingCorrection] = useState<boolean>(false);
    
    useEffect(() => {
        if (latest_answer) {
            setCompiledContent(latest_answer.answer || " ");
        } else
            setCompiledContent(body.content || " ");

    }, [latest_answer]);

    const correction_error = async () => {
        setCorrectionError(true);
        setCorrected(false);
        setSubmitted(false);
    }

    const get_report = (report_id:string, compilation=false) => {
        getReport(report_id).then((resp) => {
            const db_report:IGCReport = resp.data.report;
            if (!db_report.corrected) {
                // if report is still not corrected 5min after its created_at date,
                // then there is an error (set the error to true)
                if (new Date().getTime() - new Date(db_report.created_at).getTime() > REPORT_INVALIDATION_TIME) {
                    correction_error();
                    return;
                }
                if (!compilation) {
                    setSubmitted(true);   
                    setCorrected(false);
                    setCorrectionError(false);
                }
                else {
                    setIsCompiling(true);
                }
                setTimeout(() => {
                    get_report(report_id, compilation);
                }, REPORT_CHECK_INTERVAL);
            } else {
                if (!compilation) {
                    setCorrected(true);
                    setReport(db_report);
                    if (waitingCorrection) {
                        setWaitingCorrection(false);
                    }
                }
                else {
                    setIsCompiling(false);
                    setCompilationReport(db_report);
                }
            }
        }).catch((e) => {
            // Report not found
            correction_error();
        });
    }

    const compile = () => {
        setIsCompiling(true);
        setCompilationReport(null);
        compileCode(body._id, 
            [{file:"main.c", before:"", content:compiledContent, after:"", secret:false}]
        ).then((resp) => {
            const report_id = resp.data.id;
            get_report(report_id, true);
        }).catch((e) => {
            if (e.response && e.response.status && e.response.data.error) {
                const status = e.response.status;
                const error = e.response.data.error;
                toast({
                    title: 'Éditeur de code',
                    description: "Une erreur est survenue lors de la compilation de ton code. Erreur "+status+": "+error,
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                    position: 'bottom-right',
                })
            } else {
                toast({
                    title: 'Éditeur de code',
                    description: "Une erreur est survenue lors de la compilation de ton code. Réessaye plus tard ou bien contacte l'équipe pédagogique, merci !",
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                    position: 'bottom-right',
                })
            }
            setIsCompiling(false);
        });
    }

    /**
     * Resets the code to its original content.
     */
    const reset_code = () => {
        onOpen();
    }

    const reset_code_confirm = () => {
        if (!body.content)
            return;
        setCompiledContent(body.content);
        setEditorKey(editorKey + 1); // force the editor to re-render
    }

    /**
     * Updates the answer in the state and in the database if the answer is different from the original 
     * content by more than [threshold] characters.
     * @param answer new answer 
     * @param force force the update (i.e., on blur)
     */
    const update_answer = (answer:any, force:boolean=false) => {
        if (!force) {
            setCompiledContent(answer);

            // update_answer if the answer is different from the original content by more than 10 characters
            const diff = answer.length - lastCompiledContent.length;
            if (Math.abs(diff) > CHARS_DIFFERENCE_THRESHOLD) {
                setLastCompiledContent(answer);
                update_answer_callback(answer);
            }
        }
        else {
            // if (compiledContent)
            //     setLastCompiledContent(compiledContent[currentFile].content);
            update_answer_callback(compiledContent);
        }
    }

    // Editor resizing
    const [editorHeight, setEditorHeight] = useState(250); // Initial height
    const handleRef = useRef(null); // Ref for the handle div

    const startDrag = (e:any) => {
        const startY = e.clientY;
        const initialHeight = editorHeight;

        const handleMouseMove = (e:any) => {
            const deltaY = e.clientY - startY;
            if (initialHeight + deltaY > 200) {
                setEditorHeight(initialHeight + deltaY);
            }
        };

        const handleMouseUp = () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
        };

        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
    };
    
    const file_mode = () => {
        if (body.language == 'c')
            return 'c_cpp';
        else if (body.language == 'text')
            return 'text';
        else if (body.language == 'assembly')
            return 'assembly_x86';
        return 'c_cpp';
    }

    return <Box>
        <Flex shadow={'md'} roundedTopEnd={5} roundedTopStart={5} mt={0} border={'1pt solid gray'} borderBottom={0} bgColor={'#3b394a'} p={2}>
            <Text color='white' fontSize={'sm'} fontWeight={'medium'}>Extrait de code</Text>
        </Flex>

        <Box
            border="1pt solid gray"
            borderTop={0}
        >
        {compiledContent && compiledContent.length > 0 && <>
            <Box position={'relative'}>
                <Box
                    onMouseDown={startDrag}
                    ref={handleRef}
                    color={'white'}
                    style={{
                        position: 'absolute',
                        bottom: 0,
                        right: 0,
                        width: '20px',
                        height: '20px',
                        cursor: 'nwse-resize',
                        zIndex: 1000,
                    }}
                >
                <Center>
                    <FontAwesomeIcon icon={faArrowsUpDown} />
                </Center>
                </Box>
                    <Box
                        borderTop={"1pt solid gray"} borderBottom={"1pt solid gray"}
                        onClick={() => {
                            if (submitted && !corrected)
                                toast({
                                    title: 'Éditeur de code',
                                    description: "Tu ne peux pas modifier ton code, il est en cours de correction...",
                                    status: 'warning',
                                    duration: 3000,
                                    isClosable: true,
                                    position: 'bottom-right',
                                })
                            if (isCompiling)
                                toast({
                                    title: 'Éditeur de code',
                                    description: "Tu ne peux pas modifier ton code, il est en cours de compilation...",
                                    status: 'warning',
                                    duration: 3000,
                                    isClosable: true,
                                    position: 'bottom-right',
                                })
                        }}
                        style={{ height: editorHeight + 'px' }}>
                        <AceEditor height={editorHeight+"px"} width="100%" mode={file_mode()} fontSize={14}
                            key={editorKey}
                            value={compiledContent}
                            theme={'dracula'}
                            readOnly={isCompiling || (submitted && !corrected)}
                            style={{opacity: (isCompiling || (submitted && !corrected)) ? 0.8 : 1}}
                            minLines={5}
                            onChange={(value) => {
                                update_answer(value);
                            }}
                            onBlur={(value) => {update_answer(value, true);}}
                        />
                    </Box>
            </Box>
        </>}
        </Box>
        <Flex shadow={'md'} roundedBottomEnd={5} roundedBottomStart={5} mt={0} border={'1pt solid gray'} borderTop={0} bgColor={'#F0F0F0'} p={2}>
            <Tooltip label="Réinitialiser ce contenu" p={3} placement='top' hasArrow>
                <Button isDisabled={isCompiling || (submitted && !corrected)} variant='outline' colorScheme={'blackAlpha'} size={'md'}
                    onClick={() => {
                        reset_code();
                    }} me={2}
                ><FontAwesomeIcon icon={faRotateRight} /></Button>
            </Tooltip>

            <Spacer />
            <HintBulb display_hint_callback={display_hint_callback} hints_nb={body.hints?.length || 0} />
            
            
            {body.compilable &&
                <Button variant={'outline'} isDisabled={isCompiling || (submitted && !corrected)}  colorScheme={'facebook'} size={'md'} rightIcon={isCompiling ? <Spinner size={'sm'} /> : <FontAwesomeIcon icon={faGear} />}
                    onClick={() => {compile()}}
                >{isCompiling ? 'Compilation...' : 'Compiler'}</Button>
            }
        </Flex>
        
        {askedToCompile &&
        <>
        <Flex roundedTopEnd={5} border={'1pt solid gray'} borderBottom={0} roundedTopStart={5} mt={2} bgColor={'blackAlpha.100'} p={1}>
            <Text fontSize={'sm'} fontWeight={'medium'}><FontAwesomeIcon icon={faTerminal} style={{marginRight:'5px'}} />Output</Text>
        </Flex>
        <Box minH={50} roundedBottomEnd={5} roundedBottomStart={5} bgColor={'#1f2129'} border={'1pt solid gray'} color={'white'} p={2} className="ace_editor">
            {isCompiling && 
            <Text>
                Compilation en cours...
            </Text>
            }
            {!isCompiling && 
            <Text>
                Hello, World!
            </Text>
            }
        </Box>
        </>}

        {compilationReport != null && <>
            <Box>
                <Flex roundedTopEnd={5} border={'1pt solid gray'} borderBottom={0} roundedTopStart={5} mt={2} bgColor={'blackAlpha.100'} p={1}>
                    <Text fontSize={'sm'} fontWeight={'medium'}><FontAwesomeIcon icon={faTerminal} style={{marginRight:'5px'}} />Output de compilation</Text>
                </Flex>
                <Box overflowY={"scroll"} maxH={500} minH={50} roundedBottomEnd={5} roundedBottomStart={5} bgColor={'#1f2129'} border={'1pt solid gray'} color={'white'} p={2} className="ace_editor">
                    {compilationReport?.output?.program_crashed &&<>
                        <Text color={'red.400'}>
                        Une erreur est survenue lors de l'exécution de ton programme.
                        </Text>
                    </>}
                    {compilationReport?.output?.compilation_failed &&<>
                        <Text color={'red.400'}>
                            Une erreur est survenue lors de la compilation de ton programme.<br/>Voici l'output du compilateur :
                        </Text>
                        <Text>
                            {compilationReport?.output?.compilation_output}
                        </Text>
                    </>}
                    {!compilationReport?.output?.compilation_failed &&
                        <Text>
                            {compilationReport?.output?.program_output}
                        </Text>
                    }
                </Box>
            </Box>
        </>}

        {!correctionError && submitted &&
        <>
        <Box>
            <Flex roundedTopEnd={5} border={'1pt solid gray'} borderBottom={0} roundedTopStart={5} mt={2} bgColor={'blackAlpha.100'} p={1}>
                <Text fontSize={'sm'} fontWeight={'medium'}><FontAwesomeIcon icon={faTerminal} style={{marginRight:'5px'}} />Output de ta réponse</Text>
            </Flex>
            <Box overflowY={"scroll"} maxH={500} minH={50} roundedBottomEnd={5} roundedBottomStart={5} bgColor={'#1f2129'} border={'1pt solid gray'} color={'white'} p={2} className="ace_editor">
                {!corrected && 
                <Text>
                    Compilation en cours...
                </Text>
                }
                {corrected && 
                <Text>
                    {report?.output?.program_output}
                </Text>
                }
            </Box>
        </Box>
        </>}

        <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Réinitialiser le fichier ?</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Es-tu sûr·e de vouloir effacer ce que tu as écrit et réinitialiser le fichier ?
          </ModalBody>

          <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={() => {reset_code_confirm(); onClose(); }}>
              Oui
            </Button>
            <Button variant='ghost' onClick={onClose}>Non</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
};

export default SimpleCodeFragment;