import { Box, Button, Center, Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Spacer, Spinner, Tab, TabList, Tabs, Text, Tooltip, useDisclosure, useToast } from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import AceEditor from 'react-ace'

// import mode-<language> , this imports the style and colors for the selected language.
import 'ace-builds/src-noconflict/mode-c_cpp'
import 'ace-builds/src-noconflict/mode-assembly_x86'
import 'ace-builds/src-noconflict/mode-text'
import 'ace-builds/src-min-noconflict/theme-dracula'
import 'ace-builds/src-noconflict/ext-language_tools'
import 'ace-builds/src-noconflict/ext-beautify'
import { faArrowsUpDown, faBug, faCheck, faClipboard, faGear, faLock, faRotateRight, faTerminal } from "@fortawesome/free-solid-svg-icons";
import { faComment} from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ICodeFile, IDynamicFragment, IGCAnswer, IGCReport } from "../../../types";
import LatexText from "../LatexText";
import HintBulb from "./HintBulb";
import { compileCode, getReport, setAnswerReport } from "../../../API/corrector";
import JSConfetti from "js-confetti";
import ggSound_mp3 from "../../../resources/sound/gg.mp3"
import wrongSound_mp3 from "../../../resources/sound/wrong.mp3"

type CodeFragmentProps = {
    body: IDynamicFragment;
    display_hint_callback: Function;
    update_answer_callback: Function;
    check_answer_callback: Function;
    latest_answer?: IGCAnswer|null;
};

const CodeFragment: React.FC<CodeFragmentProps> = ({body, display_hint_callback, update_answer_callback, check_answer_callback, latest_answer}) => {
    const [compiledContent, setCompiledContent] = useState<ICodeFile[]>([]);
    const [lastCompiledContent, setLastCompiledContent] = useState<String>("");
    const [isCompiling, setIsCompiling] = useState(false);
    const [askedToCompile, setAskedToCompile] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [corrected, setCorrected] = useState(false);
    const [correctionError, setCorrectionError] = useState(false);
    const [correctionErrorMsg, setCorrectionErrorMsg] = useState("");
    const [currentFile, setCurrentFile] = useState(0); // index of the current file in [lastCompiledContent]
    const CHARS_DIFFERENCE_THRESHOLD = 20; // to update in database
    const [editorKey, setEditorKey] = useState(0);
    const toast = useToast()
    const REPORT_INVALIDATION_TIME = 1000 * 60 * 5; // 5 minutes
    const REPORT_CHECK_INTERVAL = 500; // 0.5 secondes
    const [report, setReport] = useState<IGCReport|null>(null);
    const [compilationReport, setCompilationReport] = useState<IGCReport|null>(null);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const jsConfetti = new JSConfetti();
    const gg_sound = new Audio(ggSound_mp3);
    const wrong_sound = new Audio(wrongSound_mp3);
    const gg:string[] = [
        "Bien joué !",
        "Bravo !",
        "Génial !",
        "Parfait !",
        "Super !",
        "Très bien !",
        "Tu as tout bon !",
    ];
    const gg_index = Math.floor(Math.random() * gg.length);
    const bad:string[] = [
        "Mauvaise réponse..."
    ];
    const bad_index = Math.floor(Math.random() * bad.length);
    const [fb_sentence, setFbSentence] = useState<string>('');
    const [waitingCorrection, setWaitingCorrection] = useState<boolean>(false);
    
    useEffect(() => {
        if (body.initial_code) {
            const initialCodeCopy = body.initial_code.map((file) => ({ ...file }));

            // Move the secret files to the end of the array
            const initial_code = initialCodeCopy;
            const secret_files = initial_code.filter((file) => file.secret);
            const non_secret_files = initial_code.filter((file) => !file.secret);
            setCompiledContent([...non_secret_files, ...secret_files]);
            setLastCompiledContent(non_secret_files[currentFile].content);
        }

        if (latest_answer) {
            const initialCodeCopy = latest_answer.answer.map((file:ICodeFile) => ({ ...file }));
            const initial_code = initialCodeCopy;
            const secret_files = initial_code.filter((file:ICodeFile) => file.secret);
            const non_secret_files = initial_code.filter((file:ICodeFile) => !file.secret);
            setCompiledContent([...non_secret_files, ...secret_files]);
            setLastCompiledContent(non_secret_files[currentFile].content);
            repair_answer();

            // Put feedback if the answer has been corrected
            if (latest_answer.submitted && latest_answer.report_id != "") {
                setSubmitted(true);
                get_report(latest_answer.report_id, false, true);
            }
            if (latest_answer.correct) {
                setFbSentence(gg[gg_index]);
            } else {
                setFbSentence(bad[bad_index]);
            }
        }
    }, [latest_answer]);

    /**
     * Checks if the format of the answer is the same as the format of the initial code
     * for the "before", "after" and "secret" files.
     * If it is not the same, change the format of the answer to match the format of the initial code,
     * without touching the content of the files.
     * This could happen if the structure of the code is changed in the database.
     */
    const repair_answer = () => {
        if (!body.initial_code || !latest_answer)
            return;
        const initialCodeCopy = body.initial_code.map((file) => ({ ...file }));
        const initial_code = initialCodeCopy;

        for (let i = 0; latest_answer && i < latest_answer.answer.length; i++) {
            // iterate through the files by their filename (f.file) and not by their index (i)
            let initial_code_file = initial_code.find((f) => f.file == latest_answer.answer[i].file);
            let answer_code_file = latest_answer.answer[i];
            if (!initial_code_file || !answer_code_file) {
                // Delete this file from the answer
                const new_files = [...latest_answer.answer];
                new_files.splice(i, 1);
                setCompiledContent(new_files);
                return;
            }
            if (initial_code_file.before != latest_answer.answer[i].before) {
                const new_files = [...latest_answer.answer];
                new_files[i].before = initial_code_file.before;
                setCompiledContent(new_files);
            }
            if (initial_code_file.after != latest_answer.answer[i].after) {
                const new_files = [...latest_answer.answer];
                new_files[i].after = initial_code_file.after;
                setCompiledContent(new_files);
            }
            if (initial_code_file.secret != latest_answer.answer[i].secret) {
                const new_files = [...latest_answer.answer];
                new_files[i].secret = initial_code_file.secret;
                setCompiledContent(new_files);
            }
        }

        // Check if there are missing files in the answer
        for (let i = 0; latest_answer && i < initial_code.length; i++) {
            let initial_code_file = initial_code[i];
            let answer_code_file = latest_answer.answer.find((f:ICodeFile) => f.file == initial_code_file.file);
            if (!answer_code_file) {
                const new_files = [...latest_answer.answer];
                new_files.push(initial_code_file);
                setCompiledContent(new_files);
            }
        }
    }

    const submit = async () => {
        setCompilationReport(null);
        setWaitingCorrection(true);
        setSubmitted(true);
        setCorrected(false);
        setCorrectionError(false);
        toast({
            title: 'Code envoyé',
            description: "Ton code a été envoyé à CAFE pour correction.",
            status: 'success',
            duration: 3000,
            isClosable: true,
            position: 'bottom-right',
        })
        // Send the code to CAFE
        try {
            const report_id = await check_answer_callback(compiledContent);
            get_report(report_id);
        } catch (e:any) {
            const error = e.response.data.error;
            if (error != undefined)
                setCorrectionErrorMsg(error);
            correction_error();
        }
    }

    const correction_error = async () => {
        setCorrectionError(true);
        setCorrected(false);
        setSubmitted(false);
    }

    const get_report = (report_id:string, compilation=false, initial=false) => {
        getReport(report_id).then((resp) => {
            const db_report:IGCReport = resp.data.report;
            if (!db_report.corrected) {
                // if report is still not corrected 5min after its created_at date,
                // then there is an error (set the error to true)
                if (new Date().getTime() - new Date(db_report.created_at).getTime() > REPORT_INVALIDATION_TIME) {
                    correction_error();
                    return;
                }
                if (!compilation) {
                    setSubmitted(true);   
                    setCorrected(false);
                    setCorrectionError(false);
                }
                else {
                    setIsCompiling(true);
                }
                setTimeout(() => {
                    get_report(report_id, compilation);
                }, REPORT_CHECK_INTERVAL);
            } else {
                if (!compilation) {
                    setCorrected(true);
                    setReport(db_report);
                    if (db_report.correct) {
                        setFbSentence(gg[gg_index]);
                        if (!initial) {
                            gg_sound.play();
                            jsConfetti.addConfetti({confettiRadius: 4,});
                        }
                    } else {
                        setFbSentence(bad[bad_index]);
                        if (!initial)
                            wrong_sound.play();
                    }
                }
                else {
                    setIsCompiling(false);
                    setCompilationReport(db_report);
                }
            }
        }).catch((e) => {
            // Report not found
            correction_error();
        });
    }

    const compile = () => {
        setIsCompiling(true);
        setCompilationReport(null);
        compileCode(body._id, compiledContent).then((resp) => {
            const report_id = resp.data.id;
            get_report(report_id, true);
        }).catch((e) => {
            const status = e.response.status;
            const error = e.response.data.error;
            toast({
                title: 'Éditeur de code',
                description: "Une erreur est survenue lors de la compilation de ton code. Erreur "+status+": "+error,
                status: 'error',
                duration: 3000,
                isClosable: true,
                position: 'bottom-right',
            })
            setIsCompiling(false);
        });
    }

    /**
     * Resets the code to its original content.
     */
    const reset_code = () => {
        onOpen();
    }

    const reset_code_confirm = () => {
        if (!body.initial_code)
            return;
        const initialCodeCopy = body.initial_code.map((file) => ({ ...file }));

        // Reset code for the current file
        setCompiledContent((prev) => {
            const new_content = [...prev];
            new_content[currentFile].content = initialCodeCopy[currentFile].content;
            return new_content;
        });
        setEditorKey(editorKey + 1); // force the editor to re-render
        setLastCompiledContent(initialCodeCopy[currentFile].content);
    }

    /**
     * Updates the answer in the state and in the database if the answer is different from the original 
     * content by more than [threshold] characters.
     * @param answer new answer 
     * @param force force the update (i.e., on blur)
     */
    const update_answer = (answer:any, force:boolean=false) => {
        if (!force) {
            setCompiledContent((prev) => {
                const new_content = [...prev];
                new_content[currentFile].content = answer;
                return new_content;
            });

            // update_answer if the answer is different from the original content by more than 10 characters
            const diff = answer.length - lastCompiledContent.length;
            if (Math.abs(diff) > CHARS_DIFFERENCE_THRESHOLD) {
                setLastCompiledContent(answer);

                let new_files = [...compiledContent];
                new_files[currentFile].content = answer;
                update_answer_callback(new_files);
            }
        }
        else {
            if (compiledContent)
                setLastCompiledContent(compiledContent[currentFile].content);
            update_answer_callback(compiledContent);
        }
    }

    const copy_full_code = () => {
        if (!compiledContent)
            return;
        let full_code = '';
        full_code += compiledContent[currentFile].before;
        full_code += compiledContent[currentFile].content;
        full_code += compiledContent[currentFile].after;
        navigator.clipboard.writeText(full_code);
        toast({
            title: 'Éditeur de code',
            description: "Le code du fichier "+compiledContent[currentFile].file+" a été copié dans le presse-papier.",
            status: 'success',
            duration: 3000,
            isClosable: true,
            position: 'bottom-right',
        })
    }

    // Editor resizing
    const [editorHeight, setEditorHeight] = useState(250); // Initial height
    const handleRef = useRef(null); // Ref for the handle div

    const startDrag = (e:any) => {
        const startY = e.clientY;
        const initialHeight = editorHeight;

        const handleMouseMove = (e:any) => {
            const deltaY = e.clientY - startY;
            if (initialHeight + deltaY > 200)
                setEditorHeight(initialHeight + deltaY);
        };

        const handleMouseUp = () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
        };

        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
    };
    
    const file_mode = () => {
        const filename:string = compiledContent[currentFile].file;
        if (filename.endsWith(".c") || filename.endsWith(".h"))
            return 'c_cpp';
        else if (filename.endsWith(".txt"))
            return 'text';
        else if (filename.endsWith(".s"))
            return 'assembly_x86';
        return 'c_cpp';
    }

    return <Box>
        <Text fontSize={'sm'} fontWeight={'bold'} color={'blackAlpha.800'} mb={2} cursor={'default'}>À toi de jouer !</Text>
        
        <Box mb={2}>
            <LatexText content={body.content} />
        </Box>

        <Flex shadow={'md'} roundedTopEnd={5} roundedTopStart={5} mt={0} border={'1pt solid gray'} borderBottom={0} bgColor={'#3b394a'} p={2}>
            <Text color='white' fontSize={'sm'} fontWeight={'medium'}>Code C</Text>
        </Flex>

        <Flex shadow={'md'} mt={0} border={'1pt solid gray'} borderBottom={0} bgColor={'#222226'} p={0}>
            <Tabs variant='enclosed' index={currentFile} onChange={(index) => {
                setCurrentFile(index);
            }}>
                <TabList>
                    {compiledContent.filter((file) => !file.secret).map((file, index) => {
                        if (!file.secret)
                            return <Tab key={index} fontSize={'sm'} fontFamily={'JetBrains Mono'} p={1} color={'gray'} _selected={{ color: 'white' }}><Text>{file.file}</Text></Tab>
                    })}
                </TabList>
            </Tabs>
            {/* <Spacer />
            <Box pe={2} pt={1} cursor={'pointer'}>
                <FontAwesomeIcon color='white' icon={faExpand} />
            </Box> */}
        </Flex>

        <Box
            border="1pt solid gray"
            borderTop={0}
        >
        {compiledContent && compiledContent.length > 0 && currentFile < compiledContent.length && <>

            {compiledContent[currentFile].before != '' &&
                <Tooltip label={<FontAwesomeIcon icon={faLock} />} p={3} placement='right' hasArrow>
                    <Box onClick={() => {
                        toast({
                            title: 'Éditeur de code',
                            description: "Tu ne peux pas modifier cette partie du code.",
                            status: 'warning',
                            duration: 3000,
                            isClosable: true,
                            position: 'bottom-right',
                        })
                    }}>
                        <AceEditor height={100+"px"} width="100%" mode={file_mode()} fontSize={14} 
                            value={compiledContent[currentFile].before}
                            theme={'dracula'}
                            maxLines={Infinity}
                            readOnly={true}
                            highlightActiveLine={false}
                            style={{opacity: 0.9}}
                        />
                    </Box>
                </Tooltip>
            }
            <Box position={'relative'}>
                <Box
                    onMouseDown={startDrag}
                    ref={handleRef}
                    color={'white'}
                    style={{
                        position: 'absolute',
                        bottom: 0,
                        right: 0,
                        width: '20px',
                        height: '20px',
                        cursor: 'nwse-resize',
                        zIndex: 1000,
                    }}
                >
                <Center>
                    <FontAwesomeIcon icon={faArrowsUpDown} />
                </Center>
                </Box>
                    <Box
                        borderTop={"1pt solid gray"} borderBottom={"1pt solid gray"}
                        onClick={() => {
                            if (submitted && !corrected)
                                toast({
                                    title: 'Éditeur de code',
                                    description: "Tu ne peux pas modifier ton code, il est en cours de correction...",
                                    status: 'warning',
                                    duration: 3000,
                                    isClosable: true,
                                    position: 'bottom-right',
                                })
                            if (isCompiling)
                                toast({
                                    title: 'Éditeur de code',
                                    description: "Tu ne peux pas modifier ton code, il est en cours de compilation...",
                                    status: 'warning',
                                    duration: 3000,
                                    isClosable: true,
                                    position: 'bottom-right',
                                })
                        }}
                        style={{ height: editorHeight + 'px' }}>
                        <AceEditor height={editorHeight+"px"} width="100%" mode={file_mode()} fontSize={14}
                            key={editorKey}
                            value={compiledContent[currentFile].content}
                            theme={'dracula'}
                            readOnly={isCompiling || (submitted && !corrected)}
                            style={{opacity: (isCompiling || (submitted && !corrected)) ? 0.8 : 1}}
                            onChange={(value) => {
                                update_answer(value);
                            }}
                            onBlur={(value) => {update_answer(value, true);}}
                        />
                    </Box>
            </Box>
            {compiledContent[currentFile].after != '' &&
                <Tooltip label={<FontAwesomeIcon icon={faLock} />} p={3} placement='right' hasArrow>
                    <Box onClick={() => {
                        toast({
                            title: 'Éditeur de code',
                            description: "Tu ne peux pas modifier cette partie du code.",
                            status: 'warning',
                            duration: 3000,
                            isClosable: true,
                            position: 'bottom-right',
                        })
                    }}>
                        <AceEditor height={100+"px"} width="100%" mode={file_mode()} fontSize={14} 
                            value={compiledContent[currentFile].after}
                            theme={'dracula'}
                            readOnly={true}
                            maxLines={Infinity}
                            highlightActiveLine={false}
                            style={{opacity: 0.9}}
                        />
                    </Box>
                </Tooltip>
            }
        </>}
        </Box>
        <Flex shadow={'md'} roundedBottomEnd={5} roundedBottomStart={5} mt={0} border={'1pt solid gray'} borderTop={0} bgColor={'#F0F0F0'} p={2}>
            <Tooltip label="Réinitialiser ce fichier" p={3} placement='top' hasArrow>
                <Button isDisabled={isCompiling || (submitted && !corrected)} variant='outline' colorScheme={'blackAlpha'} size={'md'}
                    onClick={() => {
                        reset_code();
                    }} me={2}
                ><FontAwesomeIcon icon={faRotateRight} /></Button>
            </Tooltip>
            <Tooltip label="Copier le fichier" p={3} placement='top' hasArrow>
                <Button variant='outline' colorScheme={'blackAlpha'} size={'md'}
                    onClick={() => {
                        copy_full_code();
                    }} me={2}
                ><FontAwesomeIcon icon={faClipboard} /></Button>
            </Tooltip>
            
            {submitted && corrected &&
                <Text mt={2} 
                    cursor={'default'} fontSize={'md'} fontWeight={600} 
                    color={report?.correct ? 'green.500' : 'red.500'}>
                    <i style={{marginBottom:'5px', marginRight:'5px'}} className={report?.correct ? "em em-svg em-star-struck" : "em em-svg em-disappointed"}></i>
                    {fb_sentence}
                </Text>
            }

            <Spacer />
            <HintBulb display_hint_callback={display_hint_callback} hints_nb={body.hints?.length || 0} />
            <Button variant={'outline'} isDisabled={isCompiling || (submitted && !corrected)}  colorScheme={'green'} size={'md'} rightIcon={(submitted && !corrected) ? <Spinner size={'sm'} /> : <FontAwesomeIcon icon={faCheck} />} me={2}
                onClick={() => {submit()}}
            >{submitted && !corrected ? 'Correction...' : 'Corriger'}</Button>
            <Button variant={'outline'} isDisabled={isCompiling || (submitted && !corrected)}  colorScheme={'facebook'} size={'md'} rightIcon={isCompiling ? <Spinner size={'sm'} /> : <FontAwesomeIcon icon={faGear} />}
                onClick={() => {compile()}}
            >{isCompiling ? 'Compilation...' : 'Compiler'}</Button>
        </Flex>
        
        {askedToCompile &&
        <>
        <Flex roundedTopEnd={5} border={'1pt solid gray'} borderBottom={0} roundedTopStart={5} mt={2} bgColor={'blackAlpha.100'} p={1}>
            <Text fontSize={'sm'} fontWeight={'medium'}><FontAwesomeIcon icon={faTerminal} style={{marginRight:'5px'}} />Output</Text>
        </Flex>
        <Box minH={50} roundedBottomEnd={5} roundedBottomStart={5} bgColor={'#1f2129'} border={'1pt solid gray'} color={'white'} p={2} className="ace_editor">
            {isCompiling && 
            <Text>
                Compilation en cours...
            </Text>
            }
            {!isCompiling && 
            <Text>
                Hello, World!
            </Text>
            }
        </Box>
        </>}

        {compilationReport != null && <>
            <Box>
                <Flex roundedTopEnd={5} border={'1pt solid gray'} borderBottom={0} roundedTopStart={5} mt={2} bgColor={'blackAlpha.100'} p={1}>
                    <Text fontSize={'sm'} fontWeight={'medium'}><FontAwesomeIcon icon={faTerminal} style={{marginRight:'5px'}} />Output de compilation</Text>
                </Flex>
                <Box overflowY={"scroll"} maxH={500} minH={50} roundedBottomEnd={5} roundedBottomStart={5} bgColor={'#1f2129'} border={'1pt solid gray'} color={'white'} p={2} className="ace_editor">
                    {compilationReport?.output?.program_crashed &&<>
                        <Text color={'red.400'}>
                        Une erreur est survenue lors de l'exécution de ton programme.
                        </Text>
                    </>}
                    {compilationReport?.output?.compilation_failed &&<>
                        <Text color={'red.400'}>
                            Une erreur est survenue lors de la compilation de ton programme.<br/>Voici l'output du compilateur :
                        </Text>
                        <Text>
                            {compilationReport?.output?.compilation_output}
                        </Text>
                    </>}
                    {!compilationReport?.output?.compilation_failed &&
                        <Text>
                            {compilationReport?.output?.program_output}
                        </Text>
                    }
                </Box>
            </Box>
        </>}

        {!correctionError && submitted &&
        <>
        <Box>
            <Flex roundedTopEnd={5} border={'1pt solid gray'} borderBottom={0} roundedTopStart={5} mt={2} bgColor={'blackAlpha.100'} p={1}>
                <Text fontSize={'sm'} fontWeight={'medium'}><FontAwesomeIcon icon={faTerminal} style={{marginRight:'5px'}} />Output de ta réponse</Text>
            </Flex>
            <Box overflowY={"scroll"} maxH={500} minH={50} roundedBottomEnd={5} roundedBottomStart={5} bgColor={'#1f2129'} border={'1pt solid gray'} color={'white'} p={2} className="ace_editor">
                {!corrected && 
                <Text>
                    Compilation en cours...
                </Text>
                }
                {corrected && 
                <Text>
                    {report?.output?.program_output}
                </Text>
                }
            </Box>
        </Box>
        </>}
        {!correctionError && submitted && corrected && 
            <Box>
                <Flex roundedTopEnd={5} border={'1pt solid gray'} borderBottom={0} roundedTopStart={5} mt={2} bgColor={'blackAlpha.100'} p={1}>
                    <Text fontSize={'sm'} fontWeight={'medium'}><FontAwesomeIcon icon={faComment} /> Feedback</Text>
                </Flex>
                <Box minH={50} roundedBottomEnd={5} roundedBottomStart={5} border={'1pt solid gray'} p={2} className="ace_editor" bgColor={"white"}>
                    <Text dangerouslySetInnerHTML={{__html: report?.feedback || ""}}
                    ></Text>
                </Box>
            </Box>
        }
        {correctionError &&
            <Box>
                <Flex roundedTopEnd={5} border={'1pt solid gray'} borderBottom={0} roundedTopStart={5} mt={2} bgColor={'blackAlpha.100'} p={1}>
                    <Text fontSize={'sm'} fontWeight={'medium'}><FontAwesomeIcon icon={faBug} /> Erreur</Text>
                </Flex>
                <Box minH={50} roundedBottomEnd={5} roundedBottomStart={5} border={'1pt solid gray'} p={2} className="ace_editor">
                    <Text>
                        Il y a eu une erreur lors de la correction de ton code. <br />Merci de réessayer plus tard :(
                    </Text>
                    {correctionErrorMsg != "" &&
                    <Text mt={2} color={'red'}>
                        CAFÉ a renvoyé l'erreur suivante : {correctionErrorMsg}
                    </Text>
                    }
                </Box>
            </Box>
        }

        <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Réinitialiser le fichier ?</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Es-tu sûr de vouloir effacer ce que tu as écrit et réinitialiser le fichier ?
          </ModalBody>

          <ModalFooter>
            <Button colorScheme='blue' mr={3} onClick={() => {reset_code_confirm(); onClose(); }}>
              Oui
            </Button>
            <Button variant='ghost' onClick={onClose}>Non</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
};

export default CodeFragment;