import { type } from "os";

// Gamecode
export enum GamecodeDifficulty {
    VeryEasy = 1,
    Easy = 2,
    Normal = 3,
    Hard = 4,
    VeryHard = 5,
};
export interface IGamecode {
	_id: string;
    title: string;
    slug: string;
    class: string;
    startline: Date;
    visible: boolean;
    description: string;
    introduction: string[]; // Fragment pointers
    reminders: string[]; // Fragment pointers
    statement: string[]; // Fragment pointers
    resolution_steps: string[]; // Fragment pointers
    conclusion: string[]; // Fragment pointers
    difficulty: GamecodeDifficulty;
}

export enum GCModule {
    INTRO = 'intro',
    REMINDERS = 'reminders',
    STATEMENT = 'statement',
    RESOLUTION_STEPS = 'resolution',
    CONCLUSION = 'conclusion'
}

export enum RSModule {
    STATEMENT = 'statement',
    REMINDERS = 'reminders',
    HINTS = 'hints',
    QUESTIONS = 'questions',
    CONCLUSION = 'ending'
}

// Fragments
export enum FragmentType {
    Static = 1,
    Dynamic = 2
};

export enum StaticFragmentType {
    Text = 1,
    Binary = 2,
    Code = 3,
};

export enum DynamicFragmentType {
    MCQ = 1,
    Form = 2,
    Code = 3,
    TextualSP = 4,
    Interface = 5,
    CodeConstruction = 6,
    SimpleCode = 7,
    Textarea = 8,
    DecBin = 9,
    BinDec = 10,
    Calculus = 11,
};

export interface IFragment {
	_id: string;
    type: FragmentType;
    fragment_id: string;
};

export interface IStaticFragment {
	_id: string;
    type: StaticFragmentType;
    content: any;
    initial_code?: {file:string, before:string, content:string, after:string, secret:boolean}[];
    expected_output?: string;
    binary?: {format:string, number:string};
    compilable?: boolean;
};

export interface ITextualSPIO {
    type: string;
    id: string;
    description: string;
}

export interface ITextualSP {
    description: string;
    inputs: ITextualSPIO[];
    outputs: ITextualSPIO[];
    color: string;
}

export interface IDynamicFragment {
    _id: string;
    type: DynamicFragmentType;
    cafe_correction: boolean;
    association_id:string;
    content: string;
    hints?: {content:string, title:string}[];
    mcq_choices?: {content:string, correct:boolean}[];
    mcq_multiple?: boolean;
    form_entries?: string[];
    form_solutions?: string[];
    open_solution?: string;
    open_multiline?: boolean;
    language?: string;
    large_area?: boolean;
    compilable?: boolean;
    codify?: boolean;
    initial_code?: {file:string, before:string, content:string, after:string, secret:boolean}[];
    decbin?: {format:string, bits:number, hint:boolean, solution:string};
    bindec?: {format:string, binary:string, hint:boolean, solution:number};
    calculus?: {operation:string, bits:number, solution:string};
    expected_output?: string;
};

export interface IGCAnswer {
    _id: string;
    gcid: string;
    matricule: string;
    correct: boolean;
    fragment_id: string; // Fragment pointer
    answer:any;
    report_id: string;
    reports_history: string[];
    submitted: boolean;
    history: any[];
    subproblems?: ITextualSP[];
}

export interface IGCReportOutput {
    compilation_failed: boolean;
    compilation_output: string;
    program_crashed: boolean;
    program_output: string;
    suspicious?: boolean;
}
export interface IGCReport {
    _id: string;
    matricule:string;
    output?:IGCReportOutput;
    erroneous:boolean;
    error_reason:string;
    answer:any;
    feedback:any;
    corrected:boolean;
    correct:boolean;
    timestamp:number;
    created_at:Date;
}

export interface ICodeFile {
    file: string;
    before: string;
    content: string;
    after: string;
    secret: boolean;
}

export interface IAnyFragment {
    type: FragmentType;
    fragment: IStaticFragment | IDynamicFragment;
};

// Reminders
export interface IReminder {
    _id: string;
    title: string;
    content: string[];
}

// Resolution Steps
export interface IResolutionStep {
    _id: string;
    gcid: string;
    title: string;
    statement: string[];
    reminders: string[];
    questions: string[];
    conclusion: string[];
}

export enum ResolutionStepModule {
    STATEMENT = 'statement',
    REMINDERS = 'reminders',
    QUESTIONS = 'questions',
    CONCLUSION = 'conclusion'
}

export interface IErrorLog {
    _id: string,
    timestamp: Date,
    level: string,
    message: string
    fixed: boolean
}

export interface IClass {
    _id: string,
    name: string;
    code: string;
    students: string[];
}

export type ApiDataType = {
	message: string;
	status: number;
	gamecode?: IGamecode;
	gamecodes?: IGamecode[];
    fragment: IFragment;
    fragments: IFragment[];
    static_fragment?: IStaticFragment;
    static_fragments?: IStaticFragment[];
    dynamic_fragment?: IDynamicFragment;
    dynamic_fragments?: IDynamicFragment[];
    any_fragment: IAnyFragment;
    any_fragments: IAnyFragment[];
    reminder?: IReminder;
    reminders?: IReminder[];
    reminders_gcs?: {reminder_id:string, gc_title:string}[];
    reminders_rs?: {reminder_id:string, gc_title:string, rs_title:string}[];
    resolution_step?: IResolutionStep;
    resolution_steps?: IResolutionStep[];
    module_step: number;
    reminder_step: number;
    is_correct: boolean;
    feedback: any;
    answer: any;
    serverlog: IErrorLog;
    serverlogs: IErrorLog[];
    ids: string[];

    // CAFE responses
    id:string;
    report:IGCReport;
};