import axios, { AxiosResponse } from "axios";
import API, { API_URL } from "./params";
import { ApiDataType, GCModule } from "../types";

/**
 * GET request to the server to get a given module current step.
 */
export const getReminderProgress = async (reminder_id:string): Promise<AxiosResponse<ApiDataType>> => {
    try {
		const gamecodes: AxiosResponse<ApiDataType> = await API.post(
			`${API_URL}/instance/reminder/progress/${reminder_id}`
		);
		return gamecodes;
	} catch (error) {
		throw error;
	}
};

/**
 * Updates the progress of a given module.
 * @param gcid ID of the gamecode
 * @param module Module of the gamecode to update
 * @param fragment_id ID of the fragment (pointer, NOT the precise fragment)
 * @returns 
 */
export const updateReminderProgress = async (reminder_id:string, fragment_id:string): Promise<AxiosResponse<ApiDataType>> => {
	try {
		const gamecodes: AxiosResponse<ApiDataType> = await API.post(
			`${API_URL}/instance/reminder/progress/update/${reminder_id}`,
			{
				fragment_id: fragment_id
			}
		);
		return gamecodes;
	} catch (error) {
		throw error;
	}
};