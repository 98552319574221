import axios, { AxiosResponse } from "axios";
import { ApiDataType, DynamicFragmentType } from "../types";
import API, { API_URL, CAFE_URL } from "./params";

/*
 * POST request to the server to check the answer of a given fragment.
**/
export const correctAnswer = async ( fragment_id:string, gc_slug:string, answer:any ): Promise<AxiosResponse<ApiDataType>> => {
   try {
       const fragments: AxiosResponse<ApiDataType> = await API.post(
           `${API_URL}/corrector/answer`,
            { answer:answer, fragment_id:fragment_id, gc_slug:gc_slug }
         );
       return fragments;
   } catch (error) {
       throw error;
   }
}

/*
 * POST request to the server to check the answer of a given fragment.
**/
export const sendAnswerToCafe = async ( fragment_id:string, answer_id:string ): Promise<AxiosResponse<ApiDataType>> => {
   try {
       const feedback: AxiosResponse<ApiDataType> = await API.post(
           `${CAFE_URL}/gamecode/submit`,
            { 
               fragment_id:fragment_id,
               answer_id:answer_id
            }
         );
       return feedback;
   } catch (error) {
       throw error;
   }
}

/*
 * POST request to the server to check the answer of a given fragment.
**/
export const compileCode = async ( fragment_id:string, files:any[] ): Promise<AxiosResponse<ApiDataType>> => {
   try {
       const feedback: AxiosResponse<ApiDataType> = await API.post(
           `${CAFE_URL}/gamecode/compiler`,
            { 
               fragment_id:fragment_id,
               files:files
            }
         );
       return feedback;
   } catch (error) {
       throw error;
   }
}

export const getAssociatedAnswer = async ( fragment_id:string, association_id:string, gc_slug:string ): Promise<AxiosResponse<ApiDataType>> => {
     try {
          const fragments: AxiosResponse<ApiDataType> = await API.post(
               `${API_URL}/corrector/answer/get_associated`,
               { fragment_id:fragment_id, association_id:association_id, gc_slug:gc_slug }
             );
          return fragments;
     } catch (error) {
          throw error;
     }
 }

 export const getLatestAnswer = async ( fragment_id:string, gc_slug:string ): Promise<AxiosResponse<ApiDataType>> => {
     try {
          const fragments: AxiosResponse<ApiDataType> = await API.get(
               `${API_URL}/corrector/latest/${fragment_id}/${gc_slug}`
             );
          return fragments;
     } catch (error) {
          throw error;
     }
 }

export const createAnswer = async ( fragment_id:string, gc_slug:string, answer:any ): Promise<AxiosResponse<ApiDataType>> => {
     try {
          const fragments: AxiosResponse<ApiDataType> = await API.post(
               `${API_URL}/corrector/answer/new`,
               { answer:answer, fragment_id:fragment_id, gc_slug:gc_slug }
             );
          return fragments;
     } catch (error) {
          throw error;
     }
 }

 export const updateAnswer = async ( fragment_id:string, gc_slug:string, answer:any ): Promise<AxiosResponse<ApiDataType>> => {
     try {
          const fragments: AxiosResponse<ApiDataType> = await API.post(
               `${API_URL}/corrector/update`,
               { answer:answer, fragment_id:fragment_id, gc_slug:gc_slug }
             );
          return fragments;
     } catch (error) {
          throw error;
     }
 }

export const setAnswerReport = async ( answer_id:string, report_id:string ): Promise<AxiosResponse<ApiDataType>> => {
    try {
         const fragments: AxiosResponse<ApiDataType> = await API.post(
              `${API_URL}/corrector/report`,
              { answer_id:answer_id, report_id:report_id }
            );
         return fragments;
    } catch (error) {
         throw error;
    }
}

export const getReport = async ( report_id:string ): Promise<AxiosResponse<ApiDataType>> => {
    try {
         const report: AxiosResponse<ApiDataType> = await API.get(
              `${API_URL}/corrector/report/${report_id}`
            );
         return report;
    } catch (error) {
         throw error;
    }
}