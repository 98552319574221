import React, { useEffect, useState } from "react";
import { Box, Button, Center, Divider, Flex, IconButton, Image, Input, InputGroup, InputLeftAddon, InputRightAddon, Select, Spacer, Stack, Tab, TabList, TabPanel, TabPanels, Tabs, Text, Textarea, Tooltip, position, useDisclosure, useToast } from '@chakra-ui/react';
import { CheckIcon, DeleteIcon } from '@chakra-ui/icons';
import { IDynamicFragment, IGCAnswer, ITextualSP, ITextualSPIO } from '../../../types';
import LatexText from "../LatexText";
import HintBulb from "./HintBulb";
import { updateAnswer } from "../../../API/corrector";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightFromBracket, faCheck, faRightToBracket, faSquarePlus } from "@fortawesome/free-solid-svg-icons";
import { CirclePicker } from "react-color";

class ColorPicker extends React.Component<any, any> {
    state = {
      displayColorPicker: false,
    };
  
    handleClick = () => {
      this.setState({ displayColorPicker: !this.state.displayColorPicker })
    };
  
    handleClose = () => {
      this.setState({ displayColorPicker: false })
    };

    handleChange = (color:any) => {
        if (this.props.onChangeColor) {
            const hexcolor = "rgb("+color.rgb.r+","+color.rgb.g+","+color.rgb.b+", 0.8)";
            this.props.onChangeColor(hexcolor);
            this.handleClose();
        }
    };
  
    render() {
      return (
        <Box>
            <Button size={'sm'} onClick={ this.handleClick }>Couleur</Button>
            {this.state.displayColorPicker ?
            <Box zIndex={2} position={'absolute'} bgColor={'white'} padding={4} shadow={'lg'}>
                <Box onClick={ this.handleClose } style={{
                    position: 'fixed',
                    top: '0px',
                    right: '0px',
                    bottom: '0px',
                    left: '0px',
                }} />
                <CirclePicker 
                    onChangeComplete={ this.handleChange }
                />
            </Box>
            : null}
        </Box>
      )
    }
}

type TextualSPProps = {
    body: IDynamicFragment;
    check_answer: Function;
    display_hint_callback: Function;
    update_answer_callback: Function;
    latest_answer: IGCAnswer|null;
};

const TextualSP: React.FC<TextualSPProps> = ({body, check_answer, display_hint_callback, update_answer_callback, latest_answer}) => {
    const [textualSp, setTextualSp] = React.useState<IDynamicFragment>(body);
    const [answer, setAnswer] = React.useState<ITextualSP[]>([{
        description: '',
        inputs: [] as ITextualSPIO[],
        outputs: [] as ITextualSPIO[],
        color: 'rgb(63,81,181, 0.5)'
    }] as ITextualSP[]);

    // Keeps track of the changes made to the answer to know
    // if it has to be saved or not
    const [answerChanged, setAnswerChanged] = React.useState<any[]>([{
        description: false,
        inputs: [],
        outputs: [],
        color: false
    }]);
    const MAX_SP_NUMBER = 8;
    const [currentTab, setCurrentTab] = React.useState<number>(0);
    const toast = useToast()

    // Answer checking
    const [submitted, setSubmitted] = useState<boolean>(false);
    const [unsaved, setUnsaved] = useState<boolean>(false);

    useEffect(() => {
        if (latest_answer) {
            setAnswer(latest_answer.answer);
            // Set answerChanged to false for every field
            // by using the fields from the latest answer
            const new_answer_changed = [...answerChanged];
            for (let i = 0; i < latest_answer.answer.length; i++) {
                new_answer_changed[i] = {
                    description: false,
                    inputs: [],
                    outputs: [],
                    color: false
                };

                // Set the inputs and outputs to false
                for (let j = 0; j < latest_answer.answer[i].inputs.length; j++) {
                    new_answer_changed[i].inputs.push(false);
                }
                for (let j = 0; j < latest_answer.answer[i].outputs.length; j++) {
                    new_answer_changed[i].outputs.push(false);
                }
            }
            setAnswerChanged(new_answer_changed);
            setSubmitted(latest_answer.submitted);
        }
    }, [latest_answer]);

    const check_answer_wrapper = () => {
        setSubmitted(true);
        check_answer(answer);
        setUnsaved(false);
    }

    const notify_change = () => {
        setSubmitted(false);
        setUnsaved(true);
    }

    const add_sp = () => {
        if (answer.length >= MAX_SP_NUMBER) {
            toast({
                title: 'Trop de sous-problèmes',
                description: "Limite de SPs atteinte...",
                status: 'error',
                duration: 3000,
                isClosable: true,
            })
            return;
        }
        const new_answer = [...answer];
        new_answer.push({
            description: '',
            inputs: [],
            outputs: [],
            color: 'rgb(63,81,181, 0.5)'
        });
        setAnswer(new_answer);
        update_answer_callback(new_answer);

        // Set the current tab to the newly created SP
        setCurrentTab(new_answer.length - 1);

        notify_change();

        // Add the new SP to the answerChanged array
        const new_answer_changed = [...answerChanged];
        new_answer_changed.push({
            description: false,
            inputs: [],
            outputs: [],
            color: false
        });
        setAnswerChanged(new_answer_changed);
    }

    const delete_sp = (index:number) => {
        const new_answer = [...answer];
        new_answer.splice(index, 1);
        setAnswer(new_answer);
        update_answer_callback(new_answer);

        // If the deleted SP was the last one, we need to update the current tab
        if (index == currentTab) {
            if (index > 0)
                setCurrentTab(index - 1);
        }

        notify_change();

        // Delete the SP from the answerChanged array
        const new_answer_changed = [...answerChanged];
        new_answer_changed.splice(index, 1);
        setAnswerChanged(new_answer_changed);
    }

    const update_sp_description = (index:number, value:string, notify=false) => {
        if (answer[index].description != value) {
            answerChanged[index].description = true;
        }

        const new_answer = [...answer];
        new_answer[index].description = value;
        setAnswer(new_answer);
        
        if (notify && answerChanged[index].description) {
            update_answer_callback(new_answer);
            notify_change();
            answerChanged[index].description = false;
        }
    }

    const update_sp_input_type = (sp_index:number, input_index:number, value:string, notify=false) => {
        if (answer[sp_index].inputs[input_index].type != value) {
            answerChanged[sp_index].inputs[input_index] = true;
        }
        const new_answer = [...answer];
        new_answer[sp_index].inputs[input_index].type = value;
        setAnswer(new_answer);
        if (notify && answerChanged[sp_index].inputs[input_index]) {
            update_answer_callback(new_answer);
            notify_change();
            answerChanged[sp_index].inputs[input_index] = false;
        }
    }

    const update_sp_input_id = (sp_index:number, input_index:number, value:string, notify=false) => {
        if (answer[sp_index].inputs[input_index].id != value) {
            answerChanged[sp_index].inputs[input_index] = true;
        }
        const new_answer = [...answer];
        new_answer[sp_index].inputs[input_index].id = value;
        setAnswer(new_answer);
        if (notify && answerChanged[sp_index].inputs[input_index]) {
            update_answer_callback(new_answer);
            notify_change();
            answerChanged[sp_index].inputs[input_index] = false;
        }
    }

    const update_sp_input_description = (sp_index:number, input_index:number, value:string, notify=false) => {
        if (answer[sp_index].inputs[input_index].description != value) {
            answerChanged[sp_index].inputs[input_index] = true;
        }
        const new_answer = [...answer];
        new_answer[sp_index].inputs[input_index].description = value;
        setAnswer(new_answer);
        if (notify && answerChanged[sp_index].inputs[input_index]) {
            update_answer_callback(new_answer);
            notify_change();
            answerChanged[sp_index].inputs[input_index] = false;
        }
    }

    const update_sp_output_type = (sp_index:number, output_index:number, value:string, notify=false) => {
        if (answer[sp_index].outputs[output_index].type != value) {
            answerChanged[sp_index].outputs[output_index] = true;
        }
        const new_answer = [...answer];
        new_answer[sp_index].outputs[output_index].type = value;
        setAnswer(new_answer);
        if (notify && answerChanged[sp_index].outputs[output_index]) {
            update_answer_callback(new_answer);
            notify_change();
            answerChanged[sp_index].outputs[output_index] = false;
        }
    }

    const update_sp_output_id = (sp_index:number, output_index:number, value:string, notify=false) => {
        if (answer[sp_index].outputs[output_index].id != value) {
            answerChanged[sp_index].outputs[output_index] = true;
        }
        const new_answer = [...answer];
        new_answer[sp_index].outputs[output_index].id = value;
        setAnswer(new_answer);
        if (notify && answerChanged[sp_index].outputs[output_index]) {
            update_answer_callback(new_answer);
            notify_change();
            answerChanged[sp_index].outputs[output_index] = false;
        }
    }

    const update_sp_output_description = (sp_index:number, output_index:number, value:string, notify=false) => {
        if (answer[sp_index].outputs[output_index].description != value) {
            answerChanged[sp_index].outputs[output_index] = true;
        }
        const new_answer = [...answer];
        new_answer[sp_index].outputs[output_index].description = value;
        setAnswer(new_answer);
        if (notify && answerChanged[sp_index].outputs[output_index]) {
            update_answer_callback(new_answer);
            notify_change();
            answerChanged[sp_index].outputs[output_index] = false;
        }
    }

    const add_sp_input = (sp_index:number) => {
        const new_answer = [...answer];
        new_answer[sp_index].inputs.push({type: '', id: '', description: ''});
        setAnswer(new_answer);
        update_answer_callback(new_answer);
        notify_change();
    }

    const add_sp_output = (sp_index:number) => {
        const new_answer = [...answer];
        new_answer[sp_index].outputs.push({type: '', id: '', description: ''});
        setAnswer(new_answer);
        update_answer_callback(new_answer);
        notify_change();
    }

    const delete_sp_input = (sp_index:number, input_index:number) => {
        const new_answer = [...answer];
        new_answer[sp_index].inputs.splice(input_index, 1);
        setAnswer(new_answer);
        update_answer_callback(new_answer);
        notify_change();
    }

    const delete_sp_output = (sp_index:number, output_index:number) => {
        const new_answer = [...answer];
        new_answer[sp_index].outputs.splice(output_index, 1);
        setAnswer(new_answer);
        update_answer_callback(new_answer);
        notify_change();
    }

    const update_sp_color = (sp_index:number, color:string) => {
        const new_answer = [...answer];
        new_answer[sp_index].color = color;
        setAnswer(new_answer);
        update_answer_callback(new_answer);
        notify_change();
    }

    return (
        <>
            <Text fontSize={'sm'} fontWeight={'bold'} color={'blackAlpha.800'} mb={2} cursor={'default'}>À toi de jouer !</Text>
            
            <Box shadow={'md'} bgColor={'#F0F0F0'} rounded={8} pt={2}>
                
                <Box rounded={8} m={4} p={4} bgColor={'white'} border={'1pt solid #e6e6e6'} mb={5} color={'black'}>
                    <LatexText content={textualSp.content} />
                </Box>
                {answer.length == 0 &&
                <>
                    <Center>
                        <Text color={"gray"}>Aucun sous-problème.</Text>
                    </Center>
                </>
                }

                <Tabs index={currentTab} variant='soft-rounded'>
                    <TabList ms={4}>
                        {answer.map((sp, spIndex) => (
                        <Tab me={2} _selected={{bgColor:sp.color}} color='black' onClick={
                            () => {
                                setCurrentTab(spIndex);
                            }
                        
                        } key={'sptab_'+spIndex}>{`SP ${spIndex + 1}`}</Tab>
                        ))}
                    </TabList>
                    <TabPanels>
                        {answer.map((sp, spIndex) => (
                        <TabPanel key={spIndex}>
                            <Box rounded={8} bgColor={'white'} mb={5} color={'black'} shadow={'lg'}>
                            <Flex fontSize={'sm'} mb={2} bgColor={sp.color} roundedTop={'md'} ps={3} pe={3} pb={2}>
                                <Text mt={3} fontSize={'sm'} fontWeight={'bold'} color={'black'} cursor={'default'}>{`Sous-problème ${spIndex + 1}`}</Text>
                                <Spacer />
                                <Tooltip label={'Supprimer le SP'}>
                                    <IconButton
                                        mt={2}
                                        aria-label="Delete"
                                        size={'sm'}
                                        bgColor={sp.color ? sp.color.replace('0.5', '1') : 'pink.50'}
                                        onClick={() => delete_sp(spIndex)}
                                        icon={<DeleteIcon />}
                                    />
                                </Tooltip>
                            </Flex>

                            <Box p={4} pt={0}>
                                {/* COLOR PICKER */}
                                <ColorPicker onChangeColor={(col:string) => {
                                    update_sp_color(spIndex, col);
                                }} />

                                {/* DESCRIPTION */}
                                <Textarea
                                    mt={2}
                                    placeholder='Description du SP'
                                    value={sp.description}
                                    onBlur={(e) => {update_sp_description(spIndex, e.target.value, true);}}
                                    onChange={(e) => update_sp_description(spIndex, e.target.value)}
                                    maxLength={1000}
                                />

                                {/* IO BUTTONS */}
                                <Flex>
                                    <Spacer />
                                    <Button
                                        mt={2}
                                        me={2}
                                        aria-label="AddSp"
                                        size={'sm'}
                                        colorScheme="blue"
                                        onClick={() => add_sp_input(spIndex)}
                                        variant={'outline'}
                                        leftIcon={<FontAwesomeIcon icon={faRightToBracket} />}
                                    >
                                        Ajouter un input
                                    </Button>
                                    <Button
                                        mt={2}
                                        aria-label="AddSp"
                                        size={'sm'}
                                        colorScheme="green"
                                        variant={'outline'}
                                        onClick={() => add_sp_output(spIndex)}
                                        leftIcon={<FontAwesomeIcon icon={faArrowRightFromBracket} />}
                                    >
                                        Ajouter un output
                                    </Button>
                                </Flex>

                                {/* INPUTS */}
                                <Flex>
                                    <Text mb={2} mt={3} fontSize={'sm'} fontWeight={'bold'} color={'blue.500'} cursor={'default'}>Inputs</Text>
                                    <Divider ms={2} mt={5} />
                                </Flex>
                                {sp.inputs.map((input, inputIndex) => (
                                    <Box mt={inputIndex > 0 ? 3 : 0} key={inputIndex}><InputGroup>
                                        <Input
                                            bgColor={'gray.100'}
                                            roundedRight={0}
                                            roundedBottom={0}
                                            value={input.type}
                                            onBlur={(e) => {update_sp_input_type(spIndex, inputIndex, e.target.value, true);}}
                                            onChange={(e) => update_sp_input_type(spIndex, inputIndex, e.target.value)}
                                            placeholder="type (e.g., int)"
                                            fontFamily={'Jetbrains Mono'}
                                            width={'40%'}
                                            maxLength={100}
                                        />
                                        <Input
                                            roundedLeft={0}
                                            value={input.id}
                                            onBlur={(e) => {update_sp_input_id(spIndex, inputIndex, e.target.value, true);}}
                                            onChange={(e) => update_sp_input_id(spIndex, inputIndex, e.target.value)}
                                            placeholder="identifiant"
                                            fontFamily={'Jetbrains Mono'}
                                            width={'60%'}
                                            maxLength={100}
                                        />
                                        <InputRightAddon roundedBottom={0}>
                                            <Tooltip label={"Supprimer l'input"}>
                                                <IconButton
                                                    aria-label="Delete"
                                                    onClick={() => delete_sp_input(spIndex, inputIndex)}
                                                    roundedBottom={0}
                                                    icon={<DeleteIcon />}
                                                />
                                            </Tooltip>
                                        </InputRightAddon>
                                    </InputGroup>
                                    
                                    <Textarea 
                                        maxLength={1000} 
                                        roundedTop={0} 
                                        placeholder="Description de l'input" 
                                        value={input.description} 
                                        onBlur={(e) => {update_sp_input_description(spIndex, inputIndex, e.target.value, true);}}
                                        onChange={(e) => update_sp_input_description(spIndex, inputIndex, e.target.value)} 
                                    />
                                </Box>
                                ))}
                                {sp.inputs.length == 0 &&
                                <Center>
                                    <Text color={"gray"}>
                                        Aucun input
                                    </Text>
                                </Center>
                                }

                                {/* OUTPUTS */}
                                <Flex>
                                    <Text mb={2} mt={3} fontSize={'sm'} fontWeight={'bold'} color={'green.500'} cursor={'default'}>Outputs</Text>
                                    <Divider ms={2} mt={5} />
                                </Flex>
                                {sp.outputs.map((output, outputIndex) => (
                                    <Box key={outputIndex} mt={outputIndex > 0 ? 3 : 0}>
                                        <InputGroup>
                                            <Input
                                                bgColor={'gray.100'}
                                                roundedRight={0}
                                                roundedBottom={0}
                                                value={output.type}
                                                onBlur={(e) => {update_sp_output_type(spIndex, outputIndex, e.target.value, true);}}
                                                onChange={(e) => update_sp_output_type(spIndex, outputIndex, e.target.value)}
                                                placeholder="type (e.g., int)"
                                                fontFamily={'Jetbrains Mono'}
                                                width={'40%'}
                                                maxLength={100}
                                            />
                                            <Input
                                                roundedLeft={0}
                                                value={output.id}
                                                onBlur={(e) => {update_sp_output_id(spIndex, outputIndex, e.target.value, true);}}
                                                onChange={(e) => update_sp_output_id(spIndex, outputIndex, e.target.value)}
                                                placeholder="identifiant"
                                                fontFamily={'Jetbrains Mono'}
                                                width={'60%'}
                                                maxLength={100}
                                            />
                                            <InputRightAddon roundedBottom={0}>
                                                <Tooltip label={"Supprimer l'output"}>
                                                    <IconButton
                                                        aria-label="Delete"
                                                        onClick={() => delete_sp_output(spIndex, outputIndex)}
                                                        roundedBottom={0}
                                                        icon={<DeleteIcon />}
                                                    />
                                                </Tooltip>
                                            </InputRightAddon>
                                        </InputGroup>
                                        <Textarea 
                                            maxLength={1000} 
                                            roundedTop={0} 
                                            placeholder="Description de l'output" 
                                            value={output.description} 
                                            onBlur={(e) => {update_sp_output_description(spIndex, outputIndex, e.target.value, true);}}
                                            onChange={(e) => update_sp_output_description(spIndex, outputIndex, e.target.value)} 
                                        />
                                    </Box>
                                ))}

                                {sp.outputs.length == 0 &&
                                <Center>
                                    <Text color={"gray"}>
                                        Aucun output
                                    </Text>
                                </Center>
                                }
                            </Box>
                        </Box>
                        </TabPanel>
                        ))}
                    </TabPanels>
                </Tabs>

                <Flex m={4} mt={2}>
                    <Spacer />
                    <Button
                        className="hvr-grow"
                        colorScheme={'pink'}
                        size={'sm'}
                        rightIcon={<FontAwesomeIcon icon={faSquarePlus} />}
                        onClick={add_sp}
                    >
                        Ajouter un SP
                    </Button>
                </Flex>

                {unsaved &&<>
                    <Text p={2} color={'red.600'} bgColor={'orange.100'} ms={4} me={4} fontSize={'sm'} fontWeight={'500'}>
                    <i style={{marginBottom:'5px', marginRight:'5px'}} className="em em-svg em-warning"></i>Tes dernières modifications n'ont pas été enregistrées.
                    </Text>

                    <Text p={2} pt={0} color={'red.600'} bgColor={'orange.100'} ms={4} me={4} fontSize={'sm'} fontWeight={'500'}><strong>Attention !</strong> Si tu as déjà rempli la construction de code associée dans une étape de résolution, elle sera réinitialisée.</Text>
                </>}

                <Flex roundedBottomEnd={8} roundedBottomStart={8} mt={4} bgColor={'blackAlpha.100'} p={4}>
                    {submitted && !unsaved &&
                        <Text mt={2} 
                            cursor={'default'} fontSize={'md'} fontWeight={600} 
                            color={'green.500'}>
                            <i style={{marginBottom:'5px', marginRight:'5px'}} className={"em em-svg em-star-struck"}></i>
                            Réponse enregistrée</Text>
                    }
                    <Spacer />
                    <HintBulb display_hint_callback={display_hint_callback} hints_nb={body.hints?.length || 0} />
                    <Tooltip closeDelay={500} p={4} label={"Aucune correction automatique n'est prévue pour cet exercice. Ta découpe en sous-problème sera réutilisée dans la construction du code."} >
                        <Button isDisabled={submitted} variant='outline' colorScheme={'green'} size={'md'} rightIcon={<FontAwesomeIcon icon={faCheck} />}
                            onClick={
                                () => {
                                    check_answer_wrapper();
                                }
                            }
                        >Enregistrer</Button>

                    </Tooltip>
                </Flex>
            </Box>
        </>
    );
};

export default TextualSP;