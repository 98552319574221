import React, { useEffect, useState } from 'react';
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Center, Container, Flex, Heading, Hide, Input, Spacer, Text, Tooltip } from '@chakra-ui/react';
import {Image as ChakraImage} from '@chakra-ui/react';
import progress_path from './resources/progress_path.svg'
import pawn from './resources/pawn.svg'
import pawn_blue from './resources/pawn_blue.svg'
import pawn_red from './resources/pawn_red.svg'
import GCListElement, { GCState } from './GCListElement';
import GCListElementLoading from './GCListElementLoading';
import { MainLayout } from './MainLayout';
import { GCModule, IGamecode } from './types';
import { getGamecodes } from './API/gamecode';
import help from './resources/help.svg'
import Logger from './Logger';
import PageVisibility, { usePageVisibility } from 'react-page-visibility';

const Help: React.FC = () => {

    return (
        <MainLayout>
        <Container maxWidth={'4xl'} marginBlock={5}>
            <Heading mb={5} as={'h3'} size={'xl'} color={'gray.900'}>Aide</Heading>

            <Flex mt={10} me={10} mb={5}>
                <Spacer />
                <ChakraImage height={'350px'} src={help} alt='Aide' />
            </Flex>

            <Accordion allowToggle bgColor={"white"}>
                <AccordionItem>
                    <AccordionButton>
                        <Box fontWeight={500} as="span" flex='1' textAlign='left'>
                        C'est quoi les Gamecodes ?
                        </Box>
                        <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel pb={4} textAlign={"justify"} p={5}>
                    Les Gamecodes sont basés sur l'idée des <Text onClick={() => {
                        window.open("https://fr.wikipedia.org/wiki/Livre-jeu", "_blank");
                    }} as={"span"} color={"gray.500"} decoration={'underline'} cursor={'pointer'}>Gamebooks</Text>. Dans un Gamebook, le lecteur ou la lectrice doit faire des choix à la fin de chaque section, et ces choix déterminent la suite de l'histoire. (e.g., <Text as={"span"} fontStyle={"italic"}>« Pour combattre le dragon, rends-toi à la page 50. Si tu veux essayer de quitter cette salle du donjon, rends-toi à la page 114 »</Text>).<br />
                    Dans le cadre des Gamecodes, tu es amené·e à résoudre des exercices de programmation et d'algorithmique, et chaque exercice résolu lui permet de passer à la section suivante. Tu es également libre de naviguer entre les rappels théoriques, les mises en commun, et l'énoncé, comme bon te semble.<br />
                    En contrepartie, certaines données sont collectées sur ta participation aux gamecodes (e.g., exercices résolus, erreurs, bonnes réponses, etc.), et me serviront de base dans le cadre de mon travail de fin d'étude. Toutes les données récoltées sont bien sûr anonymisées et traitées à des fins de recherche uniquement.
                    <br />
                    Un grand merci d'avance pour ta participation ! :)<br />
                    <Flex mt={3}>
                        <Spacer />
                        <Text fontSize={"sm"} as={"span"} color={"gray.500"} align={"end"}>- Lev Malcev, étudiant de Master 2 en Computer systems security</Text>
                    </Flex>
                    </AccordionPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionButton>
                        <Box fontWeight={500} as="span" flex='1' textAlign='left'>
                        C'est quoi CAFÉ ?
                        </Box>
                        <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel pb={4} textAlign={"justify"} p={5}>
                    CAFÉ est l'acronyme de <Text as={"span"} color={"gray.500"}>« Correction Automatique & Feedback des étudiant·e·s »</Text>, qui est un projet de recherche dirigé par <Text onClick={() => {
                        window.open("https://www.uliege.be/cms/c_9054334/fr/repertoire?uid=u216357", "_blank");
                    }} as={"span"} color={"gray.500"} decoration={'underline'} cursor={'pointer'}>Pr. Benoit Donnet</Text>. Le but de ce projet est de mener une étude en développant une plateforme de correction automatique d'exercices de programmation et d'algorithmique, ainsi qu'une expérience personnalisée d'apprentissage, et de fournir des feedbacks automatiques aux étudiant·e·s. <br /><br />

                    Les Gamecodes sont une partie de ce projet, et sont développés par Lev Malcev, étudiant de Master 2 en <Text as={"span"} fontStyle={"italic"}>Computer systems security</Text>, dans le cadre de son travail de fin d'étude.
                    </AccordionPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionButton>
                        <Box fontWeight={500} as="span" flex='1' textAlign='left'>
                        Je ne vois aucun Gamecode apparaitre
                        </Box>
                        <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel pb={4} textAlign={"justify"} p={5}>
                    Les Gamecodes sont actuellement disponibles pour les étudiant·e·s des cours <Text as={"span"} color={"gray.500"}>"INFO0946 - Introduction à la programmation"</Text> et <Text as={"span"} color={"gray.500"}>"INFO0061 - Organisation des ordinateurs"</Text> uniquement. <br />
                    
                    Si tu fais partie de ces cours et que tu ne vois pas de Gamecode, tu peux me contacter par e-mail pour que je t'y ajoute : <Text as={"span"} fontWeight={"400"} fontFamily={"Jetbrains Mono"}>l.malcev@student.uliege.be</Text>
                    </AccordionPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionButton>
                        <Box fontWeight={500} as="span" flex='1' textAlign='left'>
                        J'ai trouvé un bug ou bien j'ai une suggestion
                        </Box>
                        <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel pb={4} textAlign={"justify"} p={5}>
                    Si tu as trouvé un bug, ou que tu as une suggestion pour améliorer les Gamecodes, tu peux me contacter par e-mail pour me le signaler : <Text as={"span"} fontWeight={"400"} fontFamily={"Jetbrains Mono"}>l.malcev@student.uliege.be</Text>. Un grand merci d'avance !
                    </AccordionPanel>
                </AccordionItem>

                <AccordionItem>
                    <AccordionButton>
                        <Box fontWeight={500} as="span" flex='1' textAlign='left'>
                        J'ai une autre question
                        </Box>
                        <AccordionIcon />
                    </AccordionButton>
                    <AccordionPanel pb={4} textAlign={"justify"} p={5}>
                    Si tu as une autre question, tu peux me contacter par e-mail : <Text as={"span"} fontWeight={"400"} fontFamily={"Jetbrains Mono"}>l.malcev@student.uliege.be</Text>
                    </AccordionPanel>
                </AccordionItem>
            </Accordion>

            <Box height={"750px"} />
        </Container>

        </MainLayout>
    );
}

export default Help;