import { DeleteIcon } from "@chakra-ui/icons";
import { Box, Button, Center, Divider, Flex, Spacer, Text, Tooltip } from "@chakra-ui/react";
import { useState } from "react";
import TextFragment from "./TextFragment";
import { FragmentType, StaticFragmentType } from "../../../types";
import { updateStaticFragment } from "../../../API/fragment";
import CodeFragment from "./CodeFragment";
import { DraggableProvidedDragHandleProps } from "react-beautiful-dnd";
import BinaryFragment from "./BinaryFragment";

type FragmentProps = {
    id: string;
    type: StaticFragmentType;
    delete_callback: Function;
    content?: any;
};

const StaticFragmentComponent: React.FC<FragmentProps> = ({id, type, delete_callback, content=null}) => {

    const update_callback = (new_content:any) => {
        updateStaticFragment(id, new_content);
    }

    const delete_callback_wrapper = () => {
        delete_callback(id, FragmentType.Static);
    }

    let title:string = '';
    switch (type) {
        case StaticFragmentType.Text:
            title = 'Fragment textuel';
            break;
        case StaticFragmentType.Code:
            title = 'Code';
            break;
        case StaticFragmentType.Binary:
            title = 'Nombre binaire';
            break;
        default:
            break;
    }

    let fragment:JSX.Element;
    switch (type) {
        case StaticFragmentType.Text:
            fragment = <TextFragment content={content} update_callback={update_callback} />;
            break;
        case StaticFragmentType.Code:
            fragment = <CodeFragment body={content} update_callback={update_callback} />;
            break;
        case StaticFragmentType.Binary:
            fragment = <BinaryFragment content={content} update_callback={update_callback} />;
            break;
        default:
            fragment = <TextFragment content={'Fragment inconnu'} update_callback={update_callback} />;
            break;
    }

    return <Box mt={3} shadow={'md'}>
    <Flex className="drag-handle" minWidth='max-content' alignItems='center' gap='2' mt={5} cursor={'grab'} bgColor={'#ededed'} p={2} ps={3} pe={3} roundedTop={'md'}>
        <Text fontWeight={'500'} colorScheme="red.500" width={'20%'}>{title}</Text>
        <Spacer />
        
        <Tooltip label={'Supprimer le fragment'}>
        <Button size={'sm'} colorScheme={'red'} onClick={() => delete_callback_wrapper()}><DeleteIcon /></Button>
        </Tooltip>
    </Flex>
    <Box border={'1pt solid #ededed'} p={3} bgColor={'white'}>
    { fragment }</Box>
</Box>
};


export default StaticFragmentComponent;