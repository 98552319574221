import React, { useEffect, useState } from 'react';
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Badge, Box, Button, Center, Container, Flex, FormControl, FormLabel, Heading, Input, Menu, MenuButton, MenuItem, MenuList, ScaleFade, Select, Spacer, Spinner, Switch, Tab, TabList, TabPanel, TabPanels, Table, TableContainer, Tabs, Tbody, Td, Text, Textarea, Th, Thead, Tooltip, Tr, useDisclosure } from '@chakra-ui/react';
import { MainLayout } from '../MainLayout';
import { useNavigate, useParams } from 'react-router-dom';

// Date time picker
import "../../node_modules/@syncfusion/ej2-base/styles/material.css";
import "../../node_modules/@syncfusion/ej2-buttons/styles/material.css";
import "../../node_modules/@syncfusion/ej2-lists/styles/material.css";
import "../../node_modules/@syncfusion/ej2-inputs/styles/material.css";
import "../../node_modules/@syncfusion/ej2-popups/styles/material.css";
import "../../node_modules/@syncfusion/ej2-react-calendars/styles/material.css";
import { DateTimePickerComponent } from '@syncfusion/ej2-react-calendars';
import { loadCldr } from '@syncfusion/ej2-base';
import { L10n } from '@syncfusion/ej2-base';
import * as gregorian from 'cldr-data/main/fr/ca-gregorian.json';
import * as numbers from 'cldr-data/main/fr/numbers.json';
import * as timeZoneNames from 'cldr-data/main/fr/timeZoneNames.json';
import * as numberingSystems from 'cldr-data/supplemental/numberingSystems.json';
import * as weekData from 'cldr-data/supplemental/weekData.json';
import { FragmentType, GCModule, IGamecode, IReminder, StaticFragmentType } from '../types';
import { getGamecode, updateGamecode } from '../API/gamecode';
import FragmentsList from './FragmentsList';
import { AddIcon, EditIcon, LinkIcon, SearchIcon, WarningTwoIcon } from '@chakra-ui/icons';
import {  addStaticFragment, deleteStaticFragment, reorganizeFragments } from '../API/fragment';
import ReminderAccordion from './Reminder';
import { addReminder, getAllReminders, importReminder, removeReminderFromGamecode } from '../API/reminder';
import ResolutionStep from './ResolutionStep';
import { addResolutionStep, removeRsFromGamecode } from '../API/resolution_step';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fa0, faAlignLeft, faTerminal } from '@fortawesome/free-solid-svg-icons';

const VerticalSpacer = () => {
    useEffect(() => {
      const setVerticalSpacerHeight = () => {
        const verticalSpacer = document.getElementById('vertical_spacer');
        if (verticalSpacer) {
          const screenHeight = window.innerHeight;
          const halfScreenHeight = screenHeight / 2;
          verticalSpacer.style.height = `${halfScreenHeight}px`;
        }
      };
  
      // Call the function when the component is mounted
      setVerticalSpacerHeight();
  
      // Cleanup function
      return () => {
        // If needed, remove event listeners or perform any cleanup
      };
    }, []); // Empty dependency array ensures that the effect runs only once on mount
  
    return <div id="vertical_spacer"></div>;
};

const Editor: React.FC = () => {
    const gc_state = useParams();
    const gc_id = (gc_state.id || "") as string;
    const [currentModule, setCurrentModule] = useState<GCModule>(GCModule.INTRO);
    const [currentDeletedFragment, setCurrentDeletedFragment] = useState<string>(""); // id of the fragment to delete
    const [currentDeletedFragmentType, setCurrentDeletedFragmentType] = useState<FragmentType>(FragmentType.Static); // type of the fragment to delete
    const { isOpen, onOpen, onClose } = useDisclosure()
    const cancelRef = React.useRef<null>(null);
    const [modalContent, setModalContent] = useState<JSX.Element>(<></>);
    const [modalTitle, setModalTitle] = useState<string>('');
    const [modalCallback, setModalCallback] = useState<Function>(() => {});
    const [allReminders, setAllReminders] = useState<IReminder[]>([]); // All reminders in the database
    const [rsReload, setRsReload] = useState<boolean>(false); // Used to reload the resolution step when a reminder is imported
    const navigate = useNavigate();


    // Fetch gamecode data
    const [gamecode, setGamecode] = useState<IGamecode>({
        _id: "",
        title: '',
        slug: '',
        class: '',
        startline: new Date(),
        visible: false,
        description: '',
        introduction: [],
        reminders: [],
        statement: [],
        resolution_steps: [],
        conclusion: [],
        difficulty: 1,
    });
    useEffect(() => {
        getGamecode(gc_id).then(({ data: { gamecode } }: IGamecode | any) => {
            setGamecode(gamecode);
        }).catch((err) => {
            navigate('/admin');
        });
    }, [gc_id]);

    const set_modal = (title:string, content:JSX.Element, callback:Function) => {
        setModalTitle(title);
        setModalContent(content);
        setModalCallback(callback);
    }
    
    // Load date time picker
    loadCldr(numberingSystems, gregorian, numbers, timeZoneNames, weekData);
    L10n.load({
        'fr': {
        'datetimepicker': {
            placeholder: 'Date et heure',
            today:"aujourd'hui"
        }
    }
    });

    const delete_callback = (fragment_id: string, fragment_type:FragmentType) => {
        if (fragment_type === FragmentType.Static)
            deleteStaticFragment(fragment_id, gamecode._id, currentModule).then(({ data: { static_fragment } }: any) => {
                getGamecode(gamecode._id).then(({ data: { gamecode } }: IGamecode | any) => {
                    setGamecode(gamecode);
                });
        });
    }

    const [adding, setAdding] = useState<boolean>(false);
    const add_static_fragment = (type:StaticFragmentType) => {
        setAdding(true);
        addStaticFragment(type, gamecode._id, currentModule).then(({ data: { fragment } }: any) => {
            switch(currentModule) {
                case GCModule.INTRO:
                    setGamecode({...gamecode, introduction: [...gamecode.introduction, fragment._id]});
                    break;
                case GCModule.STATEMENT:
                    setGamecode({...gamecode, statement: [...gamecode.statement, fragment._id]});
                    break;
                case GCModule.CONCLUSION:
                    setGamecode({...gamecode, conclusion: [...gamecode.conclusion, fragment._id]});
                    break;
                default:
                    break;
            }
        }).finally(() => {
            setAdding(false);
        });
    }

    const new_reminder = () => {
        addReminder(gamecode._id).then(({ data: { reminder } }: any) => {
            setGamecode({...gamecode, reminders: [...gamecode.reminders, reminder._id]});
        });
    }

    const remove_reminder = (reminder_id:string) => {
        setGamecode({...gamecode, reminders: gamecode.reminders.filter((rid:string) => rid !== reminder_id)});
        removeReminderFromGamecode(reminder_id, gamecode._id);
    }

    const remove_resolution_step = (resolution_step_id:string) => {
        setGamecode({...gamecode, resolution_steps: gamecode.resolution_steps.filter((rsid:string) => rsid !== resolution_step_id)});
        removeRsFromGamecode(resolution_step_id, gamecode._id);
    }

    const new_resolution_step = () => {
        addResolutionStep(gamecode._id).then(({ data: { resolution_step } }: any) => {
            setGamecode({...gamecode, resolution_steps: [...gamecode.resolution_steps, resolution_step._id]});
        });
    }

    const switch_module = (index:number) => {
        switch(index) {
            case 0:
                setCurrentModule(GCModule.INTRO);
                break;
            case 1:
                setCurrentModule(GCModule.REMINDERS);
                break;
            case 2:
                setCurrentModule(GCModule.STATEMENT);
                break;
            case 3:
                setCurrentModule(GCModule.RESOLUTION_STEPS);
                break;
            case 4:
                setCurrentModule(GCModule.CONCLUSION);
                break;
            default:
                break;
        }
    }

    const updateVisibility = () => {
        const new_visibility:boolean = !gamecode.visible;
        updateGamecode({...gamecode, visible: new_visibility}).then((res) => {
            // Update the gamecode in the state
            setGamecode({...gamecode, visible: new_visibility});
        });
    }

    const title_ref = React.createRef<HTMLInputElement>();
    const desc_ref = React.createRef<HTMLTextAreaElement>();
    const updateTitle = () => {
        const new_title = title_ref.current?.value || '';
        setGamecode({...gamecode, title: new_title});
        if (new_title === '')
            return;
        updateGamecode({...gamecode, title: new_title}).then((res) => {
            // Update slug
            if (res.data.gamecode)
                setGamecode({...gamecode, title: new_title, slug: res.data.gamecode.slug});
        });
    }

    const updateStartline = (new_startline:Date) => {
        // transform to format 2023-06-27T18:00:00.000Z
        setGamecode({...gamecode, startline: new_startline});
        updateGamecode({...gamecode, startline: new_startline});
    }

    const updateDescription = () => {
        const new_description = desc_ref.current?.value || '';
        setGamecode({...gamecode, description: new_description});
        updateGamecode({...gamecode, description: new_description});
    }

    const updateClass = (new_class:string) => {
        setGamecode({...gamecode, class: new_class});
        updateGamecode({...gamecode, class: new_class});
    }

    const reorder_fragments = (reorganized_pointers:string[]) => {
        // Reorganize the right array (introduction, statement or conclusion)
        const new_gamecode:IGamecode = {...gamecode};
        switch(currentModule) {
            case GCModule.INTRO:
                new_gamecode.introduction = reorganized_pointers;
                break;
            case GCModule.STATEMENT:
                new_gamecode.statement = reorganized_pointers;
                break;
            case GCModule.CONCLUSION:
                new_gamecode.conclusion = reorganized_pointers;
                break;
            default:
                break;
        }

        reorganizeFragments(reorganized_pointers, gamecode._id, currentModule)
            .then((res) => {
                setGamecode(new_gamecode);
            }
        );
    }

    const open_reminders_table = (rsid:string='') => {
        setSelectReminderForRs(rsid);
        getAllReminders().then((res) => {
            if (res.data.reminders) {
                setAllReminders(res.data.reminders);
            }
        });
        onOpen();
    }
    const [selectReminderForRs, setSelectReminderForRs] = useState<string>('');

    useEffect(() => {
        const reminders_table = <TableContainer>
            <Table variant='simple' size={'sm'}>
                <Thead>
                <Tr>
                    <Th>Titre</Th>
                </Tr>
                </Thead>
                <Tbody>
                    {allReminders.map((reminder:IReminder) => {
                        return <Tr onClick={() => {import_reminder(reminder._id)}} _hover={{bgColor:'gray.100'}} cursor={'pointer'} key={reminder._id}>
                            <Td>{reminder.title}</Td>
                        </Tr>
                    })}
                </Tbody>
            </Table>
        </TableContainer>;
        
        set_modal('Rappels', reminders_table, () => {});
    }, [allReminders]);

    const import_reminder = (reminder_id:string) => {
        if (selectReminderForRs !== '')
            importReminder(reminder_id, gamecode._id, selectReminderForRs).then((res) => {
                // Reload the resolution step
                setRsReload(!rsReload);
            });
        else
            importReminder(reminder_id, gamecode._id).then((res) => {
                // Reload the gamecode
                getGamecode(gamecode._id).then(({ data: { gamecode } }: IGamecode | any) => {
                    setGamecode(gamecode);
                });
            });
        onClose();
    }

    const [ currentRsTab, setCurrentRsTab ] = useState<number>(0);
    
    return (
        <MainLayout>
            <Container maxWidth={'4xl'} marginBlock={5}>
                <Heading mb={5} as={'h3'} size={'xl'} color={'gray.900'}>Édition du Gamecode</Heading>

                <Container maxWidth={'100%'}>
                    <FormControl mb={3}>
                        <FormLabel>Titre du Gamecode</FormLabel>
                        <Input type='text' value={gamecode.title} ref={title_ref} onChange={(e) => {updateTitle()} } />
                    </FormControl>
                    <FormControl mb={3}>
                        <FormLabel>Description</FormLabel>
                        <Textarea ref={desc_ref} value={gamecode.description} onChange={(e) => {updateDescription()} } placeholder='En quelques mots...' />
                    </FormControl>

                    <FormControl mb={3}>
                        <FormLabel>Date de publication</FormLabel>
                        <DateTimePickerComponent 
                            scrollTo={new Date()} 
                            id="datetimepicker" 
                            locale='fr' 
                            format="dd/MM/yy à HH:mm"
                            value={new Date(gamecode.startline)}
                            onChange={(e:any) => {updateStartline(new Date(e.value))}}
                            />
                    </FormControl>

                    <Flex mb={3} mt={3}>
                        <FormLabel>Visible par les étudiants</FormLabel>
                        <Switch isChecked={gamecode.visible} onChange={() => {updateVisibility()}} size='md' />
                    </Flex>

                    <Flex mb={3} mt={3}>
                        <FormLabel mt={2}>Cours associé</FormLabel>
                        <Select width={"450px"} value={gamecode.class} onChange={(e) => {updateClass(e.target.value)}}>
                            <option value='info0946'>INFO0946 - Introduction à la programmation</option>
                            <option value='info0061'>INFO0061 - Organisation des ordinateurs</option>
                        </Select>
                    </Flex>
                </Container>

                <Container maxWidth={'100%'} mt={5}>
                    <Center>
                    <Tabs variant='soft-rounded' colorScheme='blue' mb={5} onChange={(index) => {switch_module(index)}}>
                        <TabList>
                            <Tab color={'blue.500'}>Introduction</Tab>
                            <Tab color={'green.500'}>Rappels</Tab>
                            <Tab color={'orange.300'}>Énoncé</Tab>
                            <Tab color={'purple.500'}>Étapes de résolution</Tab>
                            <Tab color={'red.400'}>Conclusion</Tab>
                        </TabList>
                    </Tabs>
                    </Center>

                    {/* TITLES */}
                    
                    {currentModule === GCModule.INTRO && 
                        <>
                            <Heading mt={3} mb={2} as={'h3'} size={'lg'} color={'blue.500'}>Introduction</Heading>
                            <Text p={2} pb={0}>L'introduction d'un Gamecode est individuelle à celui-ci et composée de fragments statiques.</Text>
                        </>
                    }
                    {currentModule === GCModule.REMINDERS &&
                        <>
                            <Heading mt={3} mb={2} as={'h3'} size={'lg'} color={'green.500'}>Rappels</Heading>
                            <Text p={2} pb={3} align={'justify'}>Un rappel est une liste de fragments, et un Gamecode contient une liste de rappels. Vous pouvez les importer d'un Gamecode à l'autre. <br /><strong>Attention : </strong>Les modifications d'un rappel sont répercutées sur tous les Gamecodes qui l'utilisent.</Text>
                        </>
                    }
                    {currentModule === GCModule.STATEMENT &&
                        <>
                            <Heading mt={3} mb={2} as={'h3'} size={'lg'} color={'orange.300'}>Énoncé</Heading>
                            <Text p={2} pb={0}>L'énoncé d'un Gamecode est individuel à celui-ci et composée de fragments statiques.</Text>
                        </>
                    }
                    {currentModule === GCModule.RESOLUTION_STEPS &&
                        <Heading mt={3} mb={2} as={'h3'} size={'lg'} color={'purple.500'}>Étapes de résolution</Heading>
                    }
                    {currentModule === GCModule.CONCLUSION &&
                        <>
                        <Heading mt={3} mb={2} as={'h3'} size={'lg'} color={'red.400'}>Conclusion</Heading>
                        <Text p={2} pb={0}>La conclusion d'un Gamecode est individuelle à celui-ci et composée de fragments statiques.</Text>
                        </>
                    }

                    {/* RESOLUTION STEPS */}
                    {currentModule === GCModule.RESOLUTION_STEPS && <>
                    <Button size={'sm'} m={2} mb={4} colorScheme='purple' onClick={() => {new_resolution_step()}} >Ajouter une étape</Button>
                    {gamecode.resolution_steps.length > 0 &&
                        <Tabs isFitted variant='enclosed' isLazy>
                            <TabList mb='1em'>
                            {gamecode.resolution_steps.map((step, index) => (
                                <Tab key={index} onClick={() => {setCurrentRsTab(index);}}>
                                    <Text>Étape {index+1}</Text>
                                </Tab>
                            ))}
                            </TabList>
                            <TabPanels>
                                {gamecode.resolution_steps.map((step_id, index) => (
                                    <TabPanel key={index} p={1}>
                                        <ResolutionStep 
                                            rs_id={step_id} 
                                            remove_rs={remove_resolution_step} 
                                            open_reminders_table={open_reminders_table}
                                            rs_reload={rsReload}
                                            />
                                    </TabPanel>
                                ))}
                            </TabPanels>
                        </Tabs>
                    }
                    {gamecode.resolution_steps.length === 0 &&
                        <Flex alignItems='center' justifyContent='center' flexDirection='column'>
                            <Text mb={3} color={'gray.400'}>Aucune étape pour le moment</Text>
                        </Flex>
                    }
                    </>}

                    {/* FRAGMENTS */}
                    
                    {currentModule === GCModule.INTRO && <>
                        <FragmentsList fragments_ids={gamecode.introduction} delete_callback={delete_callback} reorder_callback={reorder_fragments} />

                        {adding && 
                            <Center mt={5} mb={5}>
                                <Spinner color='blue.300' thickness='4px' size={"xl"} />
                            </Center>
                        }
                    </>}
                    {currentModule === GCModule.REMINDERS && gamecode.reminders.length > 0 && 
                        // Foreach reminder, display a card <Reminder />
                        <>
                        {gamecode.reminders.map((rid:string) => (
                            <ReminderAccordion key={rid} reminder_id={rid} remove_reminder={remove_reminder} />
                        ))}
                        </>
                    }
                    {currentModule === GCModule.REMINDERS && gamecode.reminders.length === 0 &&
                        <Flex alignItems='center' justifyContent='center' flexDirection='column'>
                            <Text mb={3} color={'gray.400'}>Aucun rappel pour le moment</Text>
                        </Flex>
                    }
                    {currentModule === GCModule.STATEMENT &&
                        <FragmentsList fragments_ids={gamecode.statement} delete_callback={delete_callback} reorder_callback={reorder_fragments} />
                    }
                    {currentModule === GCModule.CONCLUSION &&
                        <FragmentsList fragments_ids={gamecode.conclusion} delete_callback={delete_callback} reorder_callback={reorder_fragments} />
                    }

                    <Flex minWidth='max-content' alignItems='center' gap='2' mt={5}>
                        <Spacer />
                        {(currentModule === GCModule.INTRO || currentModule === GCModule.STATEMENT || currentModule === GCModule.CONCLUSION) &&
                        <Menu>
                            <MenuButton isDisabled={adding} colorScheme='teal' as={Button} rightIcon={<AddIcon />}>
                                <Text fontWeight={'semibold'}>Fragment statique</Text>
                            </MenuButton>

                            <MenuList>
                                <MenuItem icon={<FontAwesomeIcon icon={faAlignLeft} />} onClick={() => {add_static_fragment(StaticFragmentType.Text)}}><Text>Texte et images</Text></MenuItem>
                                
                                <MenuItem icon={<FontAwesomeIcon icon={faTerminal} />} onClick={() => {add_static_fragment(StaticFragmentType.Code)}}><Text>Code exécutable</Text></MenuItem>

                                <MenuItem icon={<FontAwesomeIcon icon={fa0} />} onClick={() => {add_static_fragment(StaticFragmentType.Binary)}}><Text>Nombre binaire</Text></MenuItem>
                                
                            </MenuList>
                        </Menu>
                        }
                        
                        {currentModule === GCModule.REMINDERS &&
                        <Menu>
                            <Button colorScheme='blue' rightIcon={<LinkIcon />} onClick={() => {open_reminders_table()}} >Importer</Button>
                            <Button colorScheme='green' rightIcon={<EditIcon />} onClick={() => {new_reminder()}} >Nouveau</Button>
                        </Menu>
                        }
                    </Flex>

                    {/* FRAGMENT DELETION POPUP */}
                    <AlertDialog
                        isOpen={isOpen}
                        leastDestructiveRef={cancelRef}
                        onClose={() => {}}
                        >
                        <AlertDialogOverlay>
                            <AlertDialogContent>
                            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                                {modalTitle}
                            </AlertDialogHeader>
                
                            <AlertDialogBody>
                                {modalContent}
                            </AlertDialogBody>
                
                            <AlertDialogFooter>
                                <Button ref={cancelRef} onClick={onClose}>
                                Retour
                                </Button>
                                <Button colorScheme='blue' onClick={() => {if (modalCallback != undefined) modalCallback(); onClose();}} ml={3}>
                                OK
                                </Button>
                            </AlertDialogFooter>
                            </AlertDialogContent>
                        </AlertDialogOverlay>
                    </AlertDialog>

                    <VerticalSpacer />

                </Container>

            </Container>
        </MainLayout>
    );
}

export default Editor;