import { Box, Button, Center, Circle, Collapse, Flex, Heading, Spacer, Text, Tooltip, useDisclosure } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { GCModule, IReminder } from "../types";
import { getReminder } from "../API/reminder";
import { ArrowLeftIcon, StarIcon } from "@chakra-ui/icons";
import Reminder from "./Reminder";
import { Link, useNavigate, useParams } from "react-router-dom";
import reminder_book from '../resources/reminder_book.svg';
import lady_reading from '../resources/lady_reading.svg';
import { THEME_COLORS } from "../config";
import { faHandPointLeft, faHandPointRight, faHeart } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getFavoriteReminders, updateFavoriteReminders } from "../API/userparams";

type ReminderProps = {
    reminders_id:string[];
    integrated?:boolean;
    withTitle?:boolean;
    withPicture?:boolean;
};

const Reminders: React.FC<ReminderProps> = ({reminders_id, integrated=false, withTitle=true, withPicture=true}) => {
    const [reminders, setReminders] = useState<IReminder[]>([]);
    const [index, setIndex] = useState<number>(-1);
    const navigate = useNavigate();
    const gc_params = useParams();
    const gc_slug = (gc_params.slug || '') as string;
    const [favoriteReminders, setFavoriteReminders] = useState<string[]>([]);
    const [loaded, setLoaded] = useState<boolean>(false);

    useEffect(() => {
        const fetchReminders = async () => {
            const promises = reminders_id.map((id) => getReminder(id));
            const responses = await Promise.all(promises);
            const fetchedReminders = responses.map((res) => res.data.reminder).filter(Boolean);
            setReminders(fetchedReminders.map((reminder: any) => {
                    return reminder as IReminder;
                }));
            getFavoriteReminders().then((res) => {
                setFavoriteReminders(res.data.ids);
            });
            setLoaded(true);
        };
      
        fetchReminders();
    }, [reminders_id]);

    // IMAGES PRELOAD
    useEffect(() => {
        new Image().src = reminder_book;
        new Image().src = lady_reading;
    }, []);

    const add_favorite = () => {
        updateFavoriteReminders(reminders[index]._id).then((res) => {
            getFavoriteReminders().then((res) => {
                setFavoriteReminders(res.data.ids);
            });
        });
    }

    const set_index_wrapper = (index:number) => {
        setIndex(index);
        getFavoriteReminders().then((res) => {
            setFavoriteReminders(res.data.ids);
        });
    }

    return <>
        {index == -1 &&
            <>  
                {withTitle &&
                <Heading mb={8} fontSize={'4xl'} color={THEME_COLORS.REMINDERS}>Rappels</Heading>
                }

                <Flex mt={8} mb={8}>
                    <Spacer />
                    <Box>
                        {loaded && reminders.map((reminder, index) => {
                            return <Box mb={8} key={'box'+index}>
                            <Flex mb={1}>
                                <Box onClick={() => { set_index_wrapper(index); }} width={'50px'} height={'50px'} cursor={'pointer'}>
                                    <img className="hvr-grow" src={reminder_book} style={{width:'50px'}} />
                                    {favoriteReminders.includes(reminder._id) &&
                                        <Text cursor={'pointer'} mt={0.5} color={'red.500'} position={"relative"} bottom={4} right={-10}>
                                            <FontAwesomeIcon icon={faHeart} />
                                        </Text>
                                    }
                                </Box>

                                <Box ms={10} pt={1}>
                                    <Box height={'50%'} mt={1}>
                                        <Text onClick={() => { set_index_wrapper(index); }} cursor={'pointer'} fontWeight={'bold'} fontSize={'xl'}>{reminder.title}</Text>
                                    </Box>
                                </Box>
                            </Flex>
                        </Box>
                        })}
                        {loaded && reminders.length == 0 &&
                            <Center>
                                <Text color={'gray'}>Aucun rappel à afficher.</Text>
                            </Center>
                        }
                        {!loaded &&
                            <Center>
                                <Text color={'gray'}>Chargement des rappels...</Text>
                            </Center>
                        }
                    </Box>
                </Flex>

                {withPicture && 
                <Box position={'relative'} right={'50px'} mb={4}>
                    <img draggable='false' src={lady_reading} style={{width:'300px'}} />
                </Box>
                }

                {!withPicture && 
                <Box position={'relative'} mb={8}>
                    <img draggable='false' src={lady_reading} style={{width:'300px'}} />
                </Box>
                }

                {!integrated &&
                    <Flex>
                        <Button mt={4} _hover={{bgColor: THEME_COLORS.INTRO}} color={'white'} bgColor={THEME_COLORS.INTRO} onClick={() => {navigate('/gamecode/' + gc_slug + '/intro');}} leftIcon={<FontAwesomeIcon icon={faHandPointLeft} />}>Retour à l'intro</Button>
                        <Spacer />
                        <Button mt={4} _hover={{bgColor: THEME_COLORS.STATEMENT}} color={'white'} bgColor={THEME_COLORS.STATEMENT} onClick={() => {navigate('/gamecode/' + gc_slug + '/statement');}} rightIcon={<FontAwesomeIcon icon={faHandPointRight} />}>Passer à l'énoncé</Button>
                    </Flex>
                }
            </>
        }
        {index != -1 && index <= reminders.length &&
            <>
                <Flex>
                    <Text onClick={add_favorite} cursor={'pointer'} mt={0.5} 
                        // if reminders[index]._id is in favoriteReminders, then color is red, otherwise THEME_COLORS.STATEMENT
                        color={favoriteReminders.includes(reminders[index]._id) ? 'red.500' : THEME_COLORS.STATEMENT}>
                        <FontAwesomeIcon icon={faHeart} />
                    </Text>
                    <Text onClick={add_favorite} cursor={'pointer'} fontSize={'sm'} fontWeight={'bold'} ms={2} mt={1}>
                        {favoriteReminders.includes(reminders[index]._id) ? 'Retirer des favoris' : 'Ajouter aux favoris'}
                    </Text>
                    <Spacer />
                    <img onClick={() => { set_index_wrapper(-1); }} src={reminder_book} style={{width:'20px', cursor:'pointer'}} />
                    <Text onClick={() => { set_index_wrapper(-1); }} cursor={'pointer'} fontSize={'sm'} fontWeight={'bold'} ms={2} mt={1}>Fermer le rappel</Text>
                </Flex>
                <Reminder reminder_id={reminders[index]._id} />
            </>
        }
    </>
};


export default Reminders;