import { ReactNode, useEffect, useState } from 'react';
import {
  Box,
  Flex,
  Avatar,
  HStack,
  IconButton,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  useDisclosure,
  useColorModeValue,
  Stack,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Center,
} from '@chakra-ui/react';
import { Image as ChakraImage } from '@chakra-ui/react';
import { Link as ReactLink} from 'react-router-dom';
import { HamburgerIcon, CloseIcon } from '@chakra-ui/icons';
import './navbar.css';
import { useUser } from './UserContext';
import Cookies from 'js-cookie';
import internet from './resources/internet.svg'

function connectionLostModal (isOpen: boolean, onClose: () => void) {

  return <Modal isCentered size={"xl"} closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
          <ModalOverlay
            backdropFilter='blur(8px) hue-rotate(90deg)'
            
           />
          <ModalContent>
            <ModalHeader>Connexion perdue</ModalHeader>
            {/* <ModalCloseButton /> */}
            <ModalBody p={5} pt={0}>
              <Center p={5}>
                <ChakraImage height={'200px'} src={internet} alt='Internet' />
              </Center>
              <Text align={"center"}>
                Aïe... Il semble que tu as perdu ta connexion internet.<br/> Vérifie que tu es bien connecté à un réseau et recharge la page.
              </Text>
            </ModalBody>
          </ModalContent>
        </Modal>
}

const Links:{name:string, route:string}[] = [{name: 'Gamecodes', route: '/'}, {name: 'Administration', route: '/admin'}, {name: "Aide", route: '/help'}];

const NavLink = ({ children }: { children: ReactNode }) => (
  <Box
    px={2}
    py={1}
    rounded={'md'}
    _hover={{
      textDecoration: 'none',
      bg: useColorModeValue('gray.200', 'gray.700'),
    }}>
    {children}
  </Box>
);

interface Props {
	children: React.ReactNode;
}
export const MainLayout: React.FunctionComponent<Props> = (props: Props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { user, setUser } = useUser();
  const [connectionLost, setConnectionLost] = useState(false);
  const connection_diclosure = useDisclosure();

  const disconnect = () => {
    Cookies.remove('jwt_cafe');
    setUser({
      matricule: '',
      firstname: '',
      lastname: '',
      rank: '',
    });
    // Reload the page to update the navbar
    window.location.reload();
  }

  useEffect(() => {
    // Preload the image
    const img = new Image();
    img.src = internet;

    const handleConnectionChange = () => {
      const condition = navigator.onLine ? "online" : "offline";
      if (condition === "online") {
        connection_diclosure.onClose();
      } else {
        connection_diclosure.onOpen();
      }
    }

    window.addEventListener('online', handleConnectionChange);
    window.addEventListener('offline', handleConnectionChange);

    return () => {
      window.removeEventListener('online', handleConnectionChange);
      window.removeEventListener('offline', handleConnectionChange);
    }
  }, []);

  return (
    <>
    {connectionLostModal(connection_diclosure.isOpen, connection_diclosure.onClose)}
    <Box>
      <Flex zIndex={1} as="header" position="fixed" w="100%" bg={"white"} px={4} shadow={"lg"}>
        <Box w='100%'>
          <Flex h={16} alignItems={'center'} justifyContent={'space-between'}>
            <IconButton
              size={'md'}
              icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
              aria-label={'Open Menu'}
              display={{ md: 'none' }}
              onClick={isOpen ? onClose : onOpen}
            />
            <HStack spacing={8} alignItems={'center'}>
              <ReactLink to={'https://gamecodes.cafe.uliege.be/'}>
                <Box>CAFÉ 2.0</Box>
              </ReactLink>
              <HStack
                as={'nav'}
                spacing={4}
                display={{ base: 'none', md: 'flex' }}>
                {Links.map((link) => (
                  (link.name === 'Administration') ? (
                    (user?.rank === 'admin') ?
                    <ReactLink to={link.route} key={link.name}>
                      <NavLink>
                        <Text color={'red.600'}>{link.name}</Text>
                      </NavLink>
                    </ReactLink> :
                    <Box key={link.name}></Box>
                  ) : (
                    // Render other links here (non-admin links)
                    <ReactLink to={link.route} key={link.name}>
                      <NavLink>{link.name}</NavLink>
                    </ReactLink>
                  )
                ))}
              </HStack>
            </HStack>
            <Flex alignItems={'center'}>
              <Menu>
                <MenuButton
                  as={Button}
                  rounded={'full'}
                  variant={'link'}
                  cursor={'pointer'}
                  minW={0}>
                  <Avatar
                    size={'sm'}
                    src={
                      'https://css.montefiore.uliege.be/public/img/profile.jpg'
                    }
                  />
                </MenuButton>
                <MenuList>
                  <ReactLink to={'https://cafe.uliege.be/'} target='_blank'><MenuItem className='navbar-item'>Retour sur CAFÉ</MenuItem></ReactLink>
                  <MenuDivider />
                  <MenuItem className='navbar-item' onClick={() => { disconnect() }}>Déconnexion</MenuItem>
                </MenuList>
              </Menu>
            </Flex>
          </Flex>

          {isOpen ? (
            <Box pb={4} display={{ md: 'none' }}>
              <Stack as={'nav'} spacing={4}>
                {Links.map((link) => (
                  <ReactLink to={link.route}key={link.name}><NavLink key={link.name}>{link.name}</NavLink></ReactLink>
                ))}
              </Stack>
            </Box>
          ) : null}
        </Box>
      </Flex>
      
      <Box zIndex={0} position={"relative"} top={"80px"} p={4}>{props.children}</Box>
    </Box>
    </>
  );
}