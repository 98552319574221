import { Image, Tooltip } from "@chakra-ui/react";
import bulb from '../../../resources/resolution_step/bulb.svg';
import { useEffect, useState } from "react";
import hintSound_mp3 from "../../../resources/sound/hint.mp3"

type HintBulbProps = {
    hints_nb: number;
    display_hint_callback: Function;
};

const HintBulb: React.FC<HintBulbProps> = ({hints_nb, display_hint_callback}) => {

    const [displayedHintsCount, setDisplayedHintsCount] = useState<number>(0);
    const [hintBulb, setHintBulb] = useState<any>(<></>);
    const hintSound = new Audio(hintSound_mp3);
    useEffect(() => {
        if (hints_nb > 0)
            if (!allHintsDisplayed)
                setHintBulb(<Tooltip p={3} placement="top" hasArrow label={'Voir un indice'}>
                                <Image src={bulb} width={'30px'} me={3} cursor={'pointer'} className={"hvr-pop"} onClick={() => display_hint()} />
                            </Tooltip>
                )
            else
                setHintBulb(<Tooltip p={3} placement="top" hasArrow label={'Tous les indices sont affichés'}>
                                <Image opacity={0.2} src={bulb} width={'30px'} me={3} />
                            </Tooltip>
                )
        
        
        // Preload sound
        hintSound.volume = 0;
        hintSound.play();
    }, [displayedHintsCount]);
    const allHintsDisplayed = displayedHintsCount >= hints_nb;

    const display_hint = () => {
        if (displayedHintsCount < hints_nb) {
            hintSound.currentTime = 0;
            hintSound.volume = 1;
            hintSound.play();
            setDisplayedHintsCount(displayedHintsCount + 1);
            display_hint_callback();
        }
    }

    return hintBulb;
}

export default HintBulb;