import React from 'react';
import { Box, Center, Container, Flex, Heading, Hide, Skeleton, SkeletonText, Spacer, Square, Stack, Text, Tooltip } from '@chakra-ui/react';
import button_activated from './resources/button_activated.svg';
import button_locked from './resources/button_locked.svg';
import progress from './resources/progress.svg'
import progress_empty from './resources/progress_empty.svg'
import { Link } from 'react-router-dom';
import { GCState } from './GCListElement';

const GCListElementLoading: React.FC = () => {
    return (
        <Container boxShadow={'5px 5px rgba(0, 0, 0, .1)'} maxWidth={'5xl'} marginBlock={5} marginBottom={2} paddingBlock={5} backgroundColor={'#F2F2F2'}>
            <Flex marginBottom={5}>
                <Square bg='white' minWidth={'80px'} minHeight={'80px'} maxHeight={'80px'} marginBlock={5} marginTop={1} rounded={25}>
                    <Text fontWeight={'extrabold'} fontSize={'4xl'}></Text>
                </Square>
                <Box flex='1' marginBlock={5} marginStart={5} marginBottom={0} marginTop={1}>
                    <Skeleton height='20px' width='300px' marginBottom={2} startColor={'gray.500'} endColor={'gray.500'}/>
                    <SkeletonText mt='4' noOfLines={4} spacing='4' skeletonHeight='2' startColor={'gray.500'} endColor={'gray.500'} />
                </Box>
            </Flex>
            <Flex color='white' alignItems={'center'}>
                <Box w={['65%','65%','50%']}>
                    <Flex>
                    <Spacer />
                    </Flex>
                </Box>
                <Box flex='1'>
                    <Flex alignItems={'center'}>
                        <Spacer />
                        <img className='playbutton' src={button_locked} style={{height:'68px'}} />
                    </Flex>
                </Box>
            </Flex>
        </Container>
    );
}

export default GCListElementLoading;