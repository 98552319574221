import axios, { AxiosResponse } from "axios";
import { ApiDataType, DynamicFragmentType, FragmentType, IResolutionStep, ResolutionStepModule, StaticFragmentType } from "../types";
import API, { API_URL } from "./params";

export const getResolutionStep = async ( id: string ): Promise<AxiosResponse<ApiDataType>> => {
    try {
        const rs: AxiosResponse<ApiDataType> = await API.get(
            `${API_URL}/rs/${id}`
        );
        return rs;
    } catch (error) {
        throw error;
    }
}

export const addResolutionStep = async ( gcid:string ): Promise<AxiosResponse<ApiDataType>> => {
    try {
        const saveRs: AxiosResponse<ApiDataType> = await API.put(
            `${API_URL}/rs`,
            {gcid: gcid}
        );
        return saveRs;
    } catch (error) {
        throw error;
    }
}

export const removeRsFromGamecode = async ( rs_id: string, gcid:string ): Promise<AxiosResponse<ApiDataType>> => {
    try {
        const deletedRs: AxiosResponse<ApiDataType> = await API.delete(
            `${API_URL}/gamecode/rs`,
            { data: { gcid:gcid, rs_id: rs_id } }
        );
        return deletedRs;
    } catch (error) {
        throw error;
    }
}

export const removeReminderFromRs = async ( reminder_id: string, rs_id:string ): Promise<AxiosResponse<ApiDataType>> => {
    try {
        const deletedReminder: AxiosResponse<ApiDataType> = await API.delete(
            `${API_URL}/rs/reminder`,
            { data: { rs_id: rs_id, reminder_id: reminder_id } }
        );
        return deletedReminder;
    } catch (error) {
        throw error;
    }
}

export const updateResolutionStep = async ( rs:IResolutionStep ): Promise<AxiosResponse<ApiDataType>> => {
    try {
        const saveRs: AxiosResponse<ApiDataType> = await API.put(
            `${API_URL}/rs/`+rs._id,
            rs
        );
        return saveRs;
    } catch (error) {
        throw error;
    }
}

export const deleteResolutionStep = async ( id: string ): Promise<AxiosResponse<ApiDataType>> => {
    try {
        const deletedRs: AxiosResponse<ApiDataType> = await API.delete(
            `${API_URL}/rs/${id}`
        );
        return deletedRs;
    } catch (error) {
        throw error;
    }
}

export const addFragmentToRs = async ( 
        fragment_type: FragmentType, 
        precise_type:StaticFragmentType|DynamicFragmentType, 
        rs_id:string,
        module:ResolutionStepModule 
    ): Promise<AxiosResponse<ApiDataType>> => {
    try {
        const new_fragment = {
            fragment_type: fragment_type,
            precise_type: precise_type,
            rs_id: rs_id,
            module: module
        };
        const saveFragment: AxiosResponse<ApiDataType> = await API.post(
            `${API_URL}/rs/fragments`,
            new_fragment
        );

        return saveFragment;
    } catch (error) {
        throw error;
    }
}

export const deleteFragmentFromRs = async ( specific_fragment_id: string, rs_id:string, module:ResolutionStepModule ): Promise<AxiosResponse<ApiDataType>> => {
    try {
        const deletedFragment: AxiosResponse<ApiDataType> = await API.delete(
            `${API_URL}/rs/fragments/`,
            { data: { 
                specific_fragment_id: specific_fragment_id, 
                rs_id: rs_id,
                module: module } }
        );
        return deletedFragment;
    } catch (error) {
        throw error;
    }
}