import { AxiosResponse } from "axios";
import API, { API_URL } from "./params";
import { ApiDataType } from "../types";

export const getFavoriteReminders = async (): Promise<AxiosResponse<ApiDataType>> => {
	try {
		const reminders_ids: AxiosResponse<ApiDataType> = await API.get(
			`${API_URL}/userparams/favoritereminders`
		);
		return reminders_ids;
	} catch (error) {
		throw error;
	}
};

export const updateFavoriteReminders = async (reminder_id:string): Promise<AxiosResponse<ApiDataType>> => {
	try {
		const ret: AxiosResponse<ApiDataType> = await API.get(
			`${API_URL}/userparams/favoritereminders/update/${reminder_id}`
        );

		return ret;
	} catch (error) {
		throw error;
	}
};