import axios, { AxiosResponse } from "axios";
import { ApiDataType, DynamicFragmentType, FragmentType, IReminder, StaticFragmentType } from "../types";
import API, { API_URL } from "./params";

export const getReminder = async ( id: string ): Promise<AxiosResponse<ApiDataType>> => {
    try {
        const reminder: AxiosResponse<ApiDataType> = await API.get(
            `${API_URL}/reminder/${id}`
        );
        return reminder;
    } catch (error) {
        throw error;
    }
}

export const getAllReminders = async (): Promise<AxiosResponse<ApiDataType>> => {
    try {
        const reminder: AxiosResponse<ApiDataType> = await API.get(
            `${API_URL}/reminders`
        );
        return reminder;
    } catch (error) {
        throw error;
    }
}

export const getGamecodesByReminders = async ( reminder_ids: string[] ): Promise<AxiosResponse<ApiDataType>> => {
    try {
        const reminder: AxiosResponse<ApiDataType> = await API.post(
            `${API_URL}/reminders/gamecodes`,
            {reminder_ids: reminder_ids}
        );
        return reminder;
    } catch (error) {
        throw error;
    }
}

export const getResolutionStepsByReminders = async ( reminder_ids: string[] ): Promise<AxiosResponse<ApiDataType>> => {
    try {
        const reminder: AxiosResponse<ApiDataType> = await API.post(
            `${API_URL}/reminders/rs`,
            {reminder_ids: reminder_ids}
        );
        return reminder;
    } catch (error) {
        throw error;
    }
}

export const addReminder = async ( gcid:string, rsid:string='' ): Promise<AxiosResponse<ApiDataType>> => {
    try {
        const saveReminder: AxiosResponse<ApiDataType> = await API.put(
            `${API_URL}/reminders`,
            {gcid: gcid, rsid: rsid}
        );
        return saveReminder;
    } catch (error) {
        throw error;
    }
}

export const updateReminder = async ( reminder:IReminder ): Promise<AxiosResponse<ApiDataType>> => {
    try {
        const saveReminder: AxiosResponse<ApiDataType> = await API.put(
            `${API_URL}/reminder/`+reminder._id,
            reminder
        );
        return saveReminder;
    } catch (error) {
        throw error;
    }
}

export const deleteReminder = async ( id: string ): Promise<AxiosResponse<ApiDataType>> => {
    try {
        const deletedReminder: AxiosResponse<ApiDataType> = await API.delete(
            `${API_URL}/reminders/${id}`
        );
        return deletedReminder;
    } catch (error) {
        throw error;
    }
}

export const removeReminderFromGamecode = async ( reminder_id: string, gcid:string ): Promise<AxiosResponse<ApiDataType>> => {
    try {
        const deletedReminder: AxiosResponse<ApiDataType> = await API.delete(
            `${API_URL}/gamecode/reminder`,
            { data: { gcid:gcid, reminder_id: reminder_id } }
        );
        return deletedReminder;
    } catch (error) {
        throw error;
    }
}

export const addFragmentToReminder = async ( fragment_type: FragmentType, precise_type:StaticFragmentType|DynamicFragmentType, reminder_id:string ): Promise<AxiosResponse<ApiDataType>> => {
    try {
        const new_fragment = {
            fragment_type: fragment_type,
            precise_type: precise_type,
            reminder_id: reminder_id
        };
        const saveFragment: AxiosResponse<ApiDataType> = await API.post(
            `${API_URL}/reminder/fragments`,
            new_fragment
        );

        return saveFragment;
    } catch (error) {
        throw error;
    }
}

export const deleteFragmentFromReminder = async ( specific_fragment_id: string, reminder_id:string ): Promise<AxiosResponse<ApiDataType>> => {
    try {
        const deletedFragment: AxiosResponse<ApiDataType> = await API.delete(
            `${API_URL}/reminder/fragments/`,
            { data: { specific_fragment_id: specific_fragment_id, reminder_id: reminder_id } }
        );
        return deletedFragment;
    } catch (error) {
        throw error;
    }
}

export const importReminder = async ( reminder_id:string, gcid:string, rsid:string='' ): Promise<AxiosResponse<ApiDataType>> => {
    try {
        const import_data = {
            reminder_id: reminder_id,
            gcid: gcid,
            rsid: rsid
        };
        const import_req: AxiosResponse<ApiDataType> = await API.post(
            `${API_URL}/reminder/import`,
            import_data
        );

        return import_req;
    } catch (error) {
        throw error;
    }
}