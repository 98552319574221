import React, { useEffect, useState } from 'react';
import { Box, Button, Center, Container, Flex, HStack, Heading, Hide, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Skeleton, Spacer, Square, Text, Tooltip, VStack, useDisclosure, useToast } from '@chakra-ui/react';
import button_activated from './resources/button_activated.svg';
import button_locked from './resources/button_locked.svg';
import progress from './resources/progress.svg'
import progress_empty from './resources/progress_empty.svg'
import { Link } from 'react-router-dom';
import { GCModule } from './types';
import { getInstanceProgress, getRsProgress, resetInstance } from './API/gcinstance';
import { ProgressLine } from './ProgressLine';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClockRotateLeft, faEraser, faEyeSlash, faGamepad } from '@fortawesome/free-solid-svg-icons';

function RestartModal(isOpen:any, onClose:any, restart_callback:Function) {
    return (
        <Modal size={"xl"} isOpen={isOpen} onClose={onClose} isCentered>
            <ModalOverlay  
                bg='none'
                backdropFilter='auto'
                // backdropInvert='80%'
                backdropBlur='5px'
            />
            <ModalContent>
                <ModalHeader fontWeight={700}>Réinitialisation</ModalHeader>
                <ModalCloseButton />
                <ModalBody p={5}>
                        <Center mb={5}>
                            <Text align={'center'}>Es-tu sûr·e de vouloir réinitialiser ce Gamecode ?</Text>
                        </Center>
                        <Flex>
                            <Spacer />
                            <Button colorScheme='red' onClick={() => {restart_callback(); onClose();}}>Oui</Button>
                            <Button ms={2} colorScheme='green' onClick={() => {onClose();}}>J'ai changé d'avis</Button>
                        </Flex>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
}

export enum GCState {
    ACCESSIBLE,
    LOCKED
};

export interface GCListElementProps {
    title:string;
    slug:string;
    visible:boolean;
    description:string;
    state: GCState;
    alignment:string;
    gcid:string;
    index:number;
    module:GCModule;
};

const GCListElement: React.FC<GCListElementProps> = ({title, slug, visible, description, state, alignment, gcid, index, module, }) => {
    const title_slug:string = title.replace(/\s+/g, '-').toLowerCase();
    const [progression, setProgression] = useState<number>(0);
    const [loaded, setLoaded] = useState<boolean>(false);
    const [instanceProgress, setInstanceProgress] = useState<number[]>([]);
    const toast = useToast();
    const restart_dis = useDisclosure();

    // IMAGES PRELOAD
    useEffect(() => {
        new Image().src = button_activated;
        new Image().src = button_locked;
        new Image().src = progress;
        new Image().src = progress_empty;
    }, []);
    
    useEffect(() => {
        getRsProgress(slug).then(({ data: { rs_progress } }: any) => {
            setLoaded(true);
            const progression = rs_progress.reduce((a:number, b:number) => a + b, 0) / rs_progress.length;
            if (rs_progress.length == 0) setProgression(0);
            else
                setProgression(Math.round(progression * 100));
        });
        fetchInstanceProgress();
    }, []);

    const fetchInstanceProgress = () => {
        if (gcid == '') return;
        getInstanceProgress(gcid).then(({ data: { instance_progress } }: any) => {
            setInstanceProgress(instance_progress);
        });
    }

    const resetInstance_callback = () => {
        resetInstance(gcid).then(() => {
            fetchInstanceProgress();
            toast({
                title: 'Gamecode réinitialisé',
                description: "Le Gamecode a bien été rénitialisé. Tu peux maintenant le recommencer :)",
                status: 'success',
                duration: 3000,
                isClosable: true,
            })
        });
    }

    return (<>
        {RestartModal(restart_dis.isOpen, restart_dis.onClose, resetInstance_callback)}
        <Skeleton isLoaded={loaded}>
            <Container boxShadow={'5px 5px rgba(0, 0, 0, .1)'} maxWidth={'5xl'} marginBlock={5} marginBottom={2} paddingBlock={5} backgroundColor={'#F2F2F2'}>
                <Flex marginBottom={5}>
                    {alignment == 'left' && (<>
                        <Square bg='white' minWidth={'80px'} minHeight={'80px'} maxHeight={'80px'} marginBlock={5} marginTop={1} rounded={25}>
                            <Text fontWeight={'extrabold'} fontSize={'4xl'}>{index}</Text>
                        </Square>
                        <Box flex='1' marginBlock={5} marginStart={5} marginBottom={0} marginTop={1}>
                            <Heading size={'md'} color={visible ? "#131313" : "red.600"} marginBottom={2}>{!visible && <Tooltip p={3} bgColor={"red.600"} placement='top' hasArrow label={"Invisible pour les étudiant·e·s"}><Text as={"span"} color={"red.600"} me={2}><FontAwesomeIcon icon={faEyeSlash} /></Text></Tooltip>}{title}</Heading>
                            <Text align={'justify'}>{description}</Text>
                        </Box>
                    </>)}
                    {alignment == 'right' && (<>
                        <Box flex='1' marginBlock={5}  marginBottom={0} marginTop={1}>
                            <Heading size={'md'} color={visible ? "#131313" : "red.600"} marginBottom={2}>{!visible && <Tooltip p={3} bgColor={"red.600"} placement='top' hasArrow label={"Invisible pour les étudiant·e·s"}><Text as={"span"} color={"red.600"} me={2}><FontAwesomeIcon icon={faEyeSlash} /></Text></Tooltip>}{title}</Heading>
                            <Text align={'justify'}>{description}</Text>
                        </Box>
                        <Square marginStart={5} bg='white' minWidth={'80px'} minHeight={'80px'} maxHeight={'80px'} marginBlock={5} marginTop={1} rounded={25}>
                            <Text fontWeight={'extrabold'} fontSize={'4xl'}>{index}</Text>
                        </Square>
                    </>)}
                </Flex>
                <Flex color='white' alignItems={'center'}>
                    <Hide below='lg'>
                        <VStack spacing={0}>
                            <Box bgColor={'white'} p={3} rounded={'3xl'} border={'1pt solid #dedcdc'}>
                                <ProgressLine completion={instanceProgress} focused_module={null} slug={slug} />
                            </Box>
                        </VStack>
                    </Hide>
                    <Box flex='1'>
                        <Flex alignItems={'center'}>
                            <Spacer />
                            {/* <Tooltip p={3} label="Voir l'historique" placement='left' bg={'orange.300'} hasArrow>
                                <Box cursor={'pointer'} onClick={() => {}} className='hvr-grow' bgColor={'#dedcdc'} p={2} rounded={'xl'} me={3}>
                                    <FontAwesomeIcon icon={faClockRotateLeft} />
                                </Box>
                            </Tooltip> */}
                            <Tooltip p={3} label='Recommencer à zéro' placement='left' bg={'orange.300'} hasArrow>
                                <Box cursor={'pointer'} onClick={restart_dis.onOpen} className='hvr-grow' bgColor={'#dedcdc'} p={2} rounded={'xl'} me={3}>
                                    <FontAwesomeIcon icon={faEraser} />
                                </Box>
                            </Tooltip>
                            <Tooltip p={3} label="C'est parti !" placement='left' bg={'#179E7E'} hasArrow>
                                <Link to={'/gamecode/' + slug + '/' + module}>
                                    <img className='playbutton' src={button_activated} style={{height:'75px', minHeight:'75px'}} />
                                </Link>
                            </Tooltip>
                        </Flex>
                    </Box>
                </Flex>
            </Container>
        </Skeleton>
    </>);
}

export default GCListElement;