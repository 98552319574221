import { AbsoluteCenter, Box, Button, Checkbox, Divider, Flex, Image, Input, Spacer, Tab, TabList, Tabs, Text, Tooltip } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import AceEditor from 'react-ace'

// import mode-<language> , this imports the style and colors for the selected language.
import 'ace-builds/src-noconflict/mode-c_cpp'
import 'ace-builds/src-noconflict/mode-text'
import 'ace-builds/src-noconflict/theme-dracula'
import 'ace-builds/src-noconflict/theme-chrome'
import 'ace-builds/src-noconflict/ext-language_tools'
import 'ace-builds/src-noconflict/ext-beautify'
import { CheckIcon } from "@chakra-ui/icons";
import { IDynamicFragment } from "../../../types";
import TextEditor from "../TextEditor";
import { faEyeSlash, faFileCirclePlus, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


type CodeFragmentProps = {
    body: IDynamicFragment;
    update_callback: Function;
};

const CodeFragment: React.FC<CodeFragmentProps> = ({body, update_callback}) => {

    const [code, setCode] = useState<IDynamicFragment>(body);
    const [currentFile, setCurrentFile] = useState<number>(0);
    const [filenameChange, setFilenameChange] = useState<boolean>(false);

    useEffect(() => {
        // Add drop event to every component which classname is ace-editor
        const ace_editors = document.getElementsByClassName("ace_content");
        for (let i = 0; i < ace_editors.length; i++) {
            const element = ace_editors[i];
            element.addEventListener("drop", (event: any) => {
                event.preventDefault();
                event.stopPropagation();
                return false;
            });
        }

        // enter key
        document.addEventListener("keydown", (event) => {
            if (event.key == "Enter") {
                setFilenameChange(false);
            }
        });
    }, []);

    const update_code_content = (section:string, new_code:string) => {
        if (!code.initial_code)
            code.initial_code = [];
        let new_body:IDynamicFragment = {
            ...code,
            initial_code: code.initial_code.map((file, index) => {
                if (index == currentFile) {
                    return {
                        ...file,
                        [section]: new_code
                    }
                }
                return file;
            })
        }
        setCode(new_body);
        update_callback(new_body);
    }

    const update_output_content = (new_output:string) => {
        let new_body:IDynamicFragment = {
            ...code,
            expected_output: new_output
        }
        setCode(new_body);
        update_callback(new_body);
    }

    const update_statement = (new_statement:string) => {
        let new_body:IDynamicFragment = {
            ...code,
            content:new_statement
        }
        setCode(new_body);
        update_callback(new_body);
    }

    const update_filename = (index:number, new_file:string) => {
        if (!code.initial_code)
            code.initial_code = [];
        if (new_file == '')
            new_file = 'new_file.c';
        let new_body:IDynamicFragment = {
            ...code,
            initial_code: code.initial_code.map((file, i) => {
                if (i == index) {
                    return {
                        ...file,
                        file:new_file
                    }
                }
                return file;
            })
        }
        setCode(new_body);
        update_callback(new_body);
    }

    const create_file = () => {
        if (!code.initial_code)
            code.initial_code = [];
        let new_body:IDynamicFragment = {
            ...code,
            initial_code: [...code.initial_code, {
                file: 'new_file.c',
                before: '',
                content: '',
                after: '',
                secret:false
            }]
        }
        setCode(new_body);
        update_callback(new_body);
    }

    const delete_file = (index:number) => {
        if (!code.initial_code)
            code.initial_code = [];
        let new_body:IDynamicFragment = {
            ...code,
            initial_code: code.initial_code.filter((_, i) => i !== index)
        }
        setCode(new_body);
        update_callback(new_body);
        if (currentFile >= index)
            setCurrentFile(0);
    }

    const file_mode = () => {
        if (!code.initial_code)
            return 'text';
        const filename:string = code.initial_code[currentFile].file;
        if (filename.endsWith(".c") || filename.endsWith(".h"))
            return 'c_cpp';
        else if (filename.endsWith(".txt"))
            return 'text';
        else if (filename.endsWith(".s"))
            return 'assembly_x86';
        return 'c_cpp';
    }

    return <>
        <Text fontSize={'sm'} fontWeight={'bold'} color={'purple.500'} mb={2} cursor={'default'}>Énoncé (facultatif)</Text>
        
        <TextEditor content={code.content} update_callback={update_statement} />
        
        <Text mt={2} fontSize={'sm'} fontWeight={'bold'} color={'purple.500'} mb={2} cursor={'default'}>Code initial (facultatif)</Text>

        <Flex mt={0} p={1} mb={1}>
            <Text fontSize={'sm'} me={2}>Nom du fichier : </Text>
            <Input errorBorderColor={'red.300'} 
                isInvalid={code.initial_code && code.initial_code[currentFile].file == ''}
                onChange={(e:any) => { update_filename(currentFile, e.target.value); }}
                value={code.initial_code ? code.initial_code[currentFile].file : 'file.c'}
                width={'150px'}
                placeholder='Nom du fichier'
                size='xs' />
            <Spacer />
            <Checkbox isChecked={code.initial_code && code.initial_code[currentFile].secret} 
                    onChange={
                        (e:any) => { update_code_content("secret", e.target.checked); }
                    }
                    ms={3} size={'sm'} colorScheme="red" position={'relative'} top={'2px'}
            >Fichier invisible pour l'étudiant</Checkbox>
        </Flex>

        
        <Text mb={2} color={'gray.500'} fontSize={'xs'} ms={1}>Types de fichiers supportés : C (.c), Assembleur (.s), Texte (.txt)</Text>
        
        
        <Flex mt={0} border={'1pt solid gray'} borderBottom={0} bgColor={'#222226'} p={0} style={{ overflowX: 'auto' }}>
            <Tabs variant='enclosed' index={currentFile} onChange={(index) => {
                setCurrentFile(index);
            }}>
                <TabList>
                    {code.initial_code && code.initial_code.length > 0 && code.initial_code.map((file, index) => {
                        return <Tab key={index} fontSize={'sm'} fontFamily={'JetBrains Mono'} p={1} color={'gray'} _selected={{ color: 'white' }} onDoubleClick={() =>{
                            setFilenameChange(!filenameChange);
                        }}>
                            {!filenameChange &&
                                <Text>{file.file}</Text>
                            }
                            {filenameChange &&
                                <Input errorBorderColor={'red.300'} 
                                    isInvalid={file.file == ''}
                                    onChange={(e:any) => { update_filename(index, e.target.value); }}
                                    defaultValue={file.file}
                                    width={'100px'}
                                    placeholder='Nom du fichier' size='xs' />
                            }
                            {code.initial_code && code.initial_code.length > 1 &&
                                <Tooltip label="Supprimer le fichier" placement="top" hasArrow>
                                    <Box ms={2} p={1} pt={0} pb={0} rounded={'md'} _hover={{bgColor:'#3c3c42'}} onClick={() => {delete_file(index)}}><FontAwesomeIcon icon={faXmark} /></Box>
                                </Tooltip>
                            }
                        </Tab>
                    })}
                </TabList>
            </Tabs>
            
            <Tooltip label="Ajouter un fichier" placement="top" hasArrow>
                <Box ms={2} mt={1} color="gray" cursor={'pointer'} _hover={{color:'white'}} onClick={() => {create_file()}}>
                    <FontAwesomeIcon icon={faFileCirclePlus} />
                </Box>
            </Tooltip>
        </Flex>
        
        
        <Flex mt={0} border={'1pt solid gray'} borderBottom={0} bgColor={'#222226'} p={0}>
            <Text p={1} fontSize={'sm'} fontWeight={'bold'} color={'purple.300'} cursor={'default'}>Partie supérieure</Text>
        </Flex>
        <Box shadow={'sm'} border={'1pt solid gray'}>
            <AceEditor height="250px" width="100%" mode={file_mode()} fontSize={14} 
            onChange={
                (new_content) => { update_code_content("before", new_content); }
            } 
            defaultValue={code.initial_code && currentFile < code.initial_code.length && code.initial_code.length > 0 ? code.initial_code[currentFile].before : ''}
            value={code.initial_code && currentFile < code.initial_code.length && code.initial_code.length > 0 ? code.initial_code[currentFile].before : ''}
            theme={(file_mode() == "text") ? "chrome" : "dracula"}
            />
        </Box>

        <Flex mt={0} border={'1pt solid gray'} borderBottom={0} bgColor={'#222226'} p={0}>
            <Text p={1} fontSize={'sm'} fontWeight={'bold'} color={'purple.300'} cursor={'default'}>Partie modifiable par l'étudiant</Text>
        </Flex>
        <Box shadow={'sm'} border={'1pt solid gray'}>
            <AceEditor height="250px" width="100%" mode={file_mode()} fontSize={14} 
            onChange={
                (new_content) => { update_code_content("content", new_content); }
            } 
            defaultValue={code.initial_code && currentFile < code.initial_code.length && code.initial_code.length > 0 ? code.initial_code[currentFile].content : ''}
            value={code.initial_code && currentFile < code.initial_code.length && code.initial_code.length > 0 ? code.initial_code[currentFile].content : ''}
            theme={(file_mode() == "text") ? "chrome" : "dracula"}
            />
        </Box>


        <Flex mt={0} border={'1pt solid gray'} borderBottom={0} bgColor={'#222226'} p={0}>
            <Text p={1} fontSize={'sm'} fontWeight={'bold'} color={'purple.300'} cursor={'default'}>Partie inférieure</Text>
        </Flex>
        <Box shadow={'sm'} border={'1pt solid gray'}>
            <AceEditor height="250px" width="100%" mode={file_mode()} fontSize={14} 
            onChange={
                (new_content) => { update_code_content("after", new_content); }
            } 
            defaultValue={code.initial_code && currentFile < code.initial_code.length && code.initial_code.length > 0 ? code.initial_code[currentFile].after : ''}
            value={code.initial_code && currentFile < code.initial_code.length && code.initial_code.length > 0 ? code.initial_code[currentFile].after : ''}
            theme={(file_mode() == "text") ? "chrome" : "dracula"}
            />
        </Box>

        <Text fontSize={'sm'} fontWeight={'bold'} color={'purple.500'} mb={2} cursor={'default'} mt={2}>Output attendu</Text>

        <Box shadow={'sm'} border={code.expected_output != '' ? '1pt solid gray' : '1pt solid red'}>
            <AceEditor height="250px" width="100%" mode={'text'} fontSize={14} 
            onChange={
                (new_content) => { update_output_content(new_content); }
            } 
            defaultValue={code.expected_output}
            theme={'chrome'}
            />
        </Box>
        
    </>
};

export default CodeFragment;