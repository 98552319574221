// Rich text editor
import React, { useEffect } from "react";
import { Box, Button, Checkbox, Divider, Flex, IconButton, Input, InputGroup, InputLeftAddon, InputRightAddon, Spacer, Stack, Text, Tooltip } from '@chakra-ui/react';
import { DeleteIcon, PlusSquareIcon } from '@chakra-ui/icons';
import { IDynamicFragment } from '../../../types';
import TextEditor from '../TextEditor';

type FormFragmentProps = {
    body: IDynamicFragment;
    update_callback: Function;
};

const FormFragment: React.FC<FormFragmentProps> = ({body, update_callback}) => {

    const [form, setForm] = React.useState<IDynamicFragment>(body);

    const new_entry = () => {
        let new_form = {
            ...form,
            form_entries: [
                ...form.form_entries ?? [],
                ''
            ],
            form_solutions: [
                ...form.form_solutions ?? [],
                ''
            ]
        };
        setForm(new_form);
        update_form(new_form);
    }

    const delete_entry = (index:number) => {
        let new_form = {
            ...form,
            form_entries: form.form_entries?.filter((choice, i) => i !== index)
        };
        setForm(new_form);
        update_form(new_form);
    }

    const update_entry = (index:number, value:string) => {
        const new_form = {
            ...form,
            form_entries: form.form_entries?.map((choice, i) => {
                if (i === index) {
                    choice = value;
                }
                return choice;
            })
        };
        setForm(new_form);
        update_form(new_form);
    }

    const update_solution = (index:number, value:string) => {
        const new_form = {
            ...form,
            form_solutions: form.form_solutions?.map((choice, i) => {
                if (i === index) {
                    choice = value;
                }
                return choice;
            })
        };
        setForm(new_form);
        update_form(new_form);
    }

    const update_content = (value:string) => {
        const new_form = {
            ...form,
            content: value
        };
        setForm(new_form);
        update_form(new_form);
    }

    const update_form = (new_form:IDynamicFragment) => {
        update_callback(new_form);
    }

    return <Box ps={2} pe={2}>
        <Text fontSize={'sm'} fontWeight={'bold'} color={'purple.500'} mb={2} cursor={'default'}>Énoncé du formulaire</Text>
        
        <TextEditor content={form.content} update_callback={update_content} />

        <Text fontSize={'sm'} fontWeight={'bold'} color={'purple.500'} mb={2} mt={2} cursor={'default'}>Entrées</Text>

        <Stack spacing={4}>
            {form.form_entries?.map((entry:any, index:number) => {
                return <InputGroup key={index}>
                            <Input 
                                roundedEnd={'0'}
                                placeholder="Intitulé"
                                value={entry}
                                onChange={(e:any) => { update_entry(index, e.target.value); }}
                            />
                            <Input
                                roundedStart={0}
                                placeholder="Réponse attendue"
                                value={form.form_solutions ? form.form_solutions[index] : ''}
                                onChange={(e:any) => { update_solution(index, e.target.value); }}
                            />
                            <InputRightAddon>
                                <Tooltip label={'Supprimer l\'entrée'}>
                                    <IconButton isDisabled={form.form_entries?.length == 1}
                                                aria-label="Delete" 
                                                onClick={() => { delete_entry(index); }}
                                                icon={<DeleteIcon />} 
                                    />
                                </Tooltip>
                            </InputRightAddon>
                        </InputGroup>
            })}
        </Stack>
        <Flex>
        <Button mt={2} size={'sm'} onClick={ () => { new_entry(); }} colorScheme={'purple'} leftIcon={<PlusSquareIcon />}>Ajouter une entrée</Button>
        </Flex>
        </Box>

};


export default FormFragment;