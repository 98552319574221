import React, { useContext } from 'react';
import { getCurrentInstanceId } from './API/gcinstance';
import { logEvent } from './API/logs';
import { TabContext } from './TabContext';

class Logger extends React.Component {

  // Create an events types enum
  static EventType = {
    PAGE_VIEW: 'page_view',
    NEXT_STEP: 'next_step',
    MODULE_CHANGE: 'module_change',
    DISPLAY_HINT: 'display_hint',
  }

  static logEvent(eventName:any, eventData:any) {
    getCurrentInstanceId(eventData.slug).then((res) => {
      const instanceId = res.data.id;
      // remove the "slug" property from the event data
      delete eventData.slug;
      logEvent(instanceId, eventName, eventData); 
    });
  }

  render() {
    return null;
  }
}

export default Logger;