import { Box, Center, CircularProgress, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { getFragments, resolveFragmentIds } from "../API/fragment";
import { DynamicFragmentType, FragmentType, IAnyFragment, IDynamicFragment, IFragment, IStaticFragment, StaticFragmentType } from "../types";
import StaticFragmentComponent from "./fragments/static/StaticFragmentComponent";
import DynamicFragmentComponent from "./fragments/dynamic/DynamicFragmentComponent";
import { cp } from "fs";
import Sortable from "sortablejs";
import '../editor.css';
import { ItemInterface, ReactSortable } from "react-sortablejs";

class AnyFragment {
    deleted: boolean = false;

    constructor() {}
    render() {
        return <></>
    }
}

class StaticFragment extends AnyFragment implements IStaticFragment {
    _id: string;
    type: StaticFragmentType;
    content: string;
    delete_callback: Function;
    initial_code?: {file:string, before:string, content:string, after:string, secret:boolean}[];
    expected_output?: string;
    body:IStaticFragment;

    constructor(fragment:IStaticFragment, delete_callback:Function) {
        super();
        this._id = fragment._id;
        this.type = fragment.type;
        this.content = fragment.content;
        this.delete_callback = delete_callback;
        if (fragment.initial_code)
            this.initial_code = fragment.initial_code;
        if (fragment.expected_output)
            this.expected_output = fragment.expected_output;

        this.body = fragment;
    }

    render() {
        return <StaticFragmentComponent type={this.type} delete_callback={this.delete_callback} id={this._id} content={this.body} />
    }
}

class DynamicFragment extends AnyFragment implements IDynamicFragment {
    _id: string;
    type: DynamicFragmentType;
    association_id: string;
    cafe_correction: boolean;
    content: string;
    delete_callback: Function;
    mcq_choices?: {content:string, correct:boolean}[];
    form_entries?: string[];
    form_solutions?: string[];
    open_solution?: string;
    open_multiline?: boolean;
    initial_code?: {file:string, before:string, content:string, after:string, secret:boolean}[];
    expected_output?: string;
    body:IDynamicFragment;

    constructor(fragment:IDynamicFragment, delete_callback:Function) {
        super();
        this._id = fragment._id;
        this.type = fragment.type;
        this.association_id = fragment.association_id;
        this.cafe_correction = fragment.cafe_correction;
        this.content = fragment.content;
        this.delete_callback = delete_callback;
        if (fragment.mcq_choices)
            this.mcq_choices = fragment.mcq_choices;
        if (fragment.form_entries)
            this.form_entries = fragment.form_entries;
        if (fragment.form_solutions)
            this.form_solutions = fragment.form_solutions;
        if (fragment.open_solution)
            this.open_solution = fragment.open_solution;
        if (fragment.open_multiline)
            this.open_multiline = fragment.open_multiline;
        if (fragment.initial_code)
            this.initial_code = fragment.initial_code;
        if (fragment.expected_output)
            this.expected_output = fragment.expected_output;
        this.body = fragment;
    }

    render() {
        return <DynamicFragmentComponent 
                    type={this.type} 
                    delete_callback={this.delete_callback} 
                    id={this._id}
                    content={this.body} />
    }
}

type FragmentsListProps = {
    fragments_ids: string[]; // Fragment pointers
    delete_callback: Function;
    reorder_callback: Function;
};

const FragmentsList: React.FC<FragmentsListProps> = ({fragments_ids, delete_callback, reorder_callback}) => {
    const [fragments_components, setFragments] = useState<AnyFragment[]>([]);
    const [fetched, setFetched] = useState<boolean>(false);
    const [fragments_pointers, setFragmentsPointers] = useState<string[]>(fragments_ids);
    const [randomListKey, setRandomListKey] = useState<number>(Math.random() * 10000000000);

    useEffect(() => {
        if (randomListKey == 0)
            setRandomListKey(Math.random() * 10000000000);
    }, []);

    /**
     * Fetches fragments from the database and loads them into the editor
     */
    useEffect(() => {
        if (!fragments_ids || randomListKey == 0)
            return;
        setFragmentsPointers(fragments_ids);
        const fetchFragments = async () => {
            const fragmentResults = await getFragments(fragments_ids);
            // Reorganize fragmentResults such that the fragments are in the same order as the pointers
            let reordered_fragments:IFragment[] = [];
            for (let i = 0; i < fragments_ids.length; i++) {
                const fragment_pointer = fragments_ids[i];
                const fragment = fragmentResults.data.fragments.find((fragment:IFragment) => fragment._id === fragment_pointer);
                if (fragment)
                    reordered_fragments.push(fragment);
            }

            let loaded_fragments:IAnyFragment[] = [];
            if (reordered_fragments.length > 0)
                loaded_fragments = await resolveFragmentIds(reordered_fragments);
            const fragments = loaded_fragments.map((any_fragment) => {
                switch(any_fragment.type) {
                    case FragmentType.Static:
                        return new StaticFragment(any_fragment.fragment as IStaticFragment, delete_callback);
                    case FragmentType.Dynamic:
                        return new DynamicFragment(any_fragment.fragment as IDynamicFragment, delete_callback);
                    default:
                        return new AnyFragment();
                }
            });
            setFragments(fragments);
            setFetched(true);
        };
        fetchFragments();
    }
    , [fragments_ids, randomListKey]);

    const dragEnd = () => {
        // Reorganize the fragments pointers in the same order

        // Queryselector all on the fragments of this list
        const newOrderRaw = Array.from(document.querySelectorAll(".dragitem")).map((item) => item.id);
        // only keep those that have the randomListKey of this list
        const newOrder = newOrderRaw.filter((item) => item.split("-")[0] === randomListKey.toString());
        let new_list:string[] = [];
        for(let i = 0; i < newOrder.length; i++) {
            const new_fp = fragments_pointers.find((fragment, index) => index.toString() === newOrder[i].toString().split("-")[3]);
            if (new_fp) {
                if (!new_list.includes(new_fp))
                    new_list.push(new_fp);
            }
        }
        reorder_callback(new_list);
    }

    const [items, setItems] = useState<ItemInterface[]>([]);
    useEffect(() => {
        if (!fragments_components)
            return;
        
        /*
        Create a list of ItemInterface from fragments_components
        */
        let new_items:ItemInterface[] = [];
        for(let i = 0; i < fragments_components.length; i++) {
            const fragment = fragments_components[i];
            const id = randomListKey+"-fragment-"+fragments_pointers[i]+"-" + i;
            const html = fragment.render();
            new_items.push({id, html});
        }
        setItems(new_items);
    }, [fragments_components]);

    return <>
        <ReactSortable list={items} setList={setItems} className="fragments_column" animation={150} ghostClass="dragged-component" handle=".drag-handle">
            {items.map((item) => (
                <Box key={item.id} className="dragitem" onDragEnd={() => {dragEnd()}} id={item.id as string} >
                    {item.html}
                </Box>
            ))}
        </ReactSortable>

        {!fetched && <Center m={8}><CircularProgress isIndeterminate color='blue.300' /></Center>}
        
        {fetched && fragments_components.length === 0 && <Center><Text mt={3} color={'gray.400'}>Cette partie est vide.</Text></Center>}

    </>
};

export default FragmentsList;